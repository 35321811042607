<template>
    <b-modal
        ref="share-property-modal"
        hide-footer
        title="Share Property"
        centered
        id="shareModal"
    >
        <form @submit.prevent="sendEmail()">
            <div
                class="alert"
                role="alert"
                v-if="message"
                :class="error ? 'alert-danger' : 'alert-success'"
            >
                {{ message }}
            </div>
            <div class="form-group">
                <label for="sender_email" class="form-label"
                    >Sender Email</label
                >
                <input
                    type="email"
                    id="sender_email"
                    placeholder="Sender Email"
                    v-model="sender_email"
                />
            </div>
            <div class="mb-4">
                <label class="typo__label">Receiver Emails</label>
                <multiselect
                    v-model="receivers"
                    tag-placeholder="Add new email"
                    placeholder="Add Emails"
                    :options="[]"
                    :multiple="true"
                    :taggable="true"
                    @tag="addNewEmail"
                >
                    <!-- <template #noOptions><div></div></template>
                    <template #noResult><div></div></template> -->
                </multiselect>
            </div>
            <div class="text-right">
                <button
                    class="btn btn-primary"
                    :disabled="!receivers.length || isLoading || !sender_email"
                >
                    <template v-if="isLoading">
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Sending...
                    </template>
                    <template v-if="!isLoading">Send</template>
                </button>
            </div>
        </form>
    </b-modal>
</template>
<script>
import axios from "axios";

export default {
    name: "SharePropertyModal",
    props: {
        properties: {
            type: Array,
            default: () => [],
        },
        senderEmail: {
            default: "",
        },
    },
    emits:['onSuccess'],
    data() {
        return {
            receivers: [],
            isLoading: false,
            error: false,
            message: "",
            sender_email: "",
        };
    },
    mounted() {
        setTimeout(() => {
            this.sender_email = this.senderEmail;
        }, 0);
    },
    methods: {
        open() {
            this.receivers = [];
            this.error = false;
            this.message = "";
            this.isLoading = false;
            this.$refs["share-property-modal"].show();
        },
        close() {
            this.$refs["share-property-modal"].hide();
        },
        addNewEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = regex.test(email);
            if (isValidEmail) {
                this.receivers.push(email);
            }
        },
        sendEmail() {
            const { sender_email, properties, receivers } = this;
            if (!properties?.length || !receivers.length) return;

            const formData = new FormData();
            formData.append("sender_email", sender_email);

            for (let [index, email] of Object.entries(receivers))
                formData.append(`users_email[${index}]`, email);

            for (let [index, property] of Object.entries(properties)) {
                formData.append(`property[${index}][link]`, property.link);
                formData.append(
                    `property[${index}][address]`,
                    property.address
                );
                formData.append(
                    `property[${index}][beedroom]`,
                    property.beedroom
                );
                formData.append(
                    `property[${index}][bathroom]`,
                    property.bathroom
                );
                formData.append(`property[${index}][garage]`, property.garage);
                formData.append(`property[${index}][price]`, property.price);
            }
            this.isLoading = true;
            const baseUrl = window.location.origin;
            axios
                .post(baseUrl + "/api/share-property", formData)
                .then((res) => {
                    this.message = res?.data?.message;
                    this.error = false;
                    this.$emit('onSuccess');
                })
                .catch((err) => {
                    this.error = true;
                    this.message = "Something Went Wrong, Please Try Again!";
                    if (err?.response?.data?.error) {
                        this.message = res.data.message;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss">
#shareModal___BV_modal_header_ .close {
    background-color: transparent;
}
#shareModal___BV_modal_content_ {
    .multiselect {
        overflow-x: hidden !important;
        border: 1px solid #e8e8e8 !important;
    }
    .multiselect__content-wrapper {
        display: none !important;
    }
    .multiselect__select {
        display: none;
    }
    .multiselect__tags {
        border-width: 0 !important;
        input {
            width: 100% !important;
            height: 25px !important;
        }
    }
    #sender_email {
        height: 40px;
        border: 1px solid #e8e8e8;
        display: block;
        width: 100%;
        padding-left: 8px;
    }
}
</style>
