<template>
    <div>
        <section class="filter-top-sec" :class="{ 'loggedIn': isAuth }">
            <filter-list-header ref="filterComponent" :cities="cityArray" @onFilterChange="handleFilterChange"
                :hideBuySellOptions="true" :hideMapSearchBtn="true" :hideExclusiveBtn="true"
                listType="sold"></filter-list-header>
        </section>

        <div class="container mt-3">
            <div class="row">
                <div class="col-12">
                    <div class="common-property-list-grid mb-4">
                        <template v-if="isLoading">
                            <skeleton-loader v-for="item in skeletonList" :key="'idx-property-' + item" />
                        </template>
                        <div v-else-if="all_properties.length == 0" class="text-center">
                            <img src="/images/no_result.gif" alt="No Result"
                                class="img-responsive img-fluid mx-auto d-block">
                        </div>
                        <template v-else>
                            <common-property-card v-for="(value, index) in all_properties" :key="'sold-property-' + index"
                                :property_details="value" type="COMPARABLE_HOME" :image_base_url="url_web"
                                :canShare="!displayShareButton" :user_id="user_id" :userFavProperties="userFavProperties"
                                @onFavoriteSuccess="handleFavoriteSuccess" :hidePrice="true"/>
                        </template>
                    </div>

                    <template v-if="!isLoading">
                        <pagination :data="propertyData" align="center" :show-disabled="true" :limit="10"
                            :page="current_page" @pagination-change-page="getHomes"></pagination>
                    </template>
                </div>
            </div>
        </div>
        <auth-modal ref="authModal" :initialView="initialAuthView" @loginSuccess="loginSuccess"></auth-modal>
    </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import AuthModal, { VIEWS } from "../components/AuthModal/AuthModal.vue";
import SkeletonLoader from "./SkeletonLoader/SkeletonLoader.vue";
import CommonPropertyCard from "./PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import FilterListHeader from "../components/FilterListHeader/FilterListHeader.vue";
import { getUserFavPropertiesIds } from "../utils/app";

export default {
    name: "SoldProperties",
    props: {
        properties: {
            default: "[]",
        },
        cities: {
            default: "[]",
        },
        authuser: {
            default: "false",
        },
        user_id: {
            default: ""
        }
    },
    components: {
        'skeleton-loader': SkeletonLoader,
        'common-property-card': CommonPropertyCard,
        'auth-modal': AuthModal,
        'filter-list-header': FilterListHeader
    },
    data() {
        return {
            all_properties: [],
            propertyData: {},
            current_page: 1,
            page_url: "https://valuation.santhoshmathew.com/api/v1/sold/properties",
            per_page: 9,
            all_cities: [],
            sliderValue: 300000,
            bedrooms: "",
            bathrooms: "",
            square_footage: "",
            min_price: "",
            max_price: "",
            property_type: "",
            city: "",
            isLoading: true,
            name: "",
            userEmail: "",
            userPassword: "",
            phone: "",
            alertClass: "",
            alertMessage: "",
            url_web: '',
            selectedShareProperties: [],
            selectedSharePropertiesIds: [],
            userFavProperties: [],
            displayFavButton: true,
            displayShareEmailCard: false,
            displayShareButton: true,
            initialAuthView: VIEWS.LOGIN_FORM,
            loggedInUserEmail: "",
            skeletonList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        };
    },
    validations: {
        userEmail: {
            required,
            email,
            // validateEmail
        },
        userPassword: {
            required,
            minLength: minLength(8),
        },
    },
    computed: {
        isAuth() {
            if (this.authuser == "false") {
                return false;
            } else {
                return true;
            }

        },
        cityArray() {
            let cities = this?.cities;
            if (typeof this?.cities === 'string') {
                cities = JSON.parse(this.cities)
            }
            return cities?.map(item => item?.city)
        }
    },
    mounted() {
        this.getHomes(1, true)
        this.fetchUserFavProperties();
    },
    methods: {
        convertToSlug(val) {
            return val
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        getHomes(page = 1, isInitialCall = false) {
            this.current_page = page;
            const filterData = this.$refs['filterComponent'];

            let apiUrl = this.page_url + "?page=" + page;

            if (filterData.sortDir) {
                apiUrl = apiUrl + '&sortDir=' + filterData.sortDir;
            }
            else {
                apiUrl = apiUrl + '&sortDir=desc';
            }
            if (filterData.property_type) {
                apiUrl = apiUrl + '&property_type=' + filterData.property_type;
            }
            if (filterData.property_style) {
                const style = filterData.property_style === 'Split level' ? 'Multi-Level' : filterData.property_style
                apiUrl = apiUrl + '&property_style=' + style;
            }
            if (filterData.bedrooms) {
                apiUrl = apiUrl + '&bedrooms=' + filterData.bedrooms;
            }
            if (filterData.bathrooms) {
                apiUrl = apiUrl + '&bathrooms=' + filterData.bathrooms;
            }
            if (filterData.square_footage) {
                apiUrl = apiUrl + '&square_footage=' + filterData.square_footage;
            }

            if (filterData.min_price !== "") {
                apiUrl = apiUrl + '&min_price=' + filterData.min_price;
            }
            if (filterData.max_price) {
                apiUrl = apiUrl + '&max_price=' + filterData.max_price;
            }
            if (this.last_week) {
                apiUrl = apiUrl + '&get_last_week=' + this.last_week;
            }
            if (filterData.sortBy) {
                apiUrl = apiUrl + '&sortBy=' + filterData.sortBy;
            }
            else {
                apiUrl = apiUrl + '&sortBy=list_price';
            }
            if (filterData.city) {
                apiUrl = apiUrl + '&city=' + filterData.city;
            }

            this.isLoading = true;
            axios
                .get(apiUrl)
                .then((response) => {
                    this.all_properties = response.data.data;
                    this.page_url = response.data.meta["path"];
                    this.propertyData = response.data;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally((result) => {
                    this.isLoading = false;
                });
            return true;
        },
        goToPropertyDetails(value) {
            if (this.isAuth) {
                window.location.href =
                    "/idx-listings/sold/properties/" +
                    this.convertToSlug(
                        value["address"] +
                        ", " +
                        value["city"] +
                        ", " +
                        value["county"] +
                        ", " +
                        value["zip"]
                    ) +
                    "/" +
                    value["id"];
            } else {
                document.getElementById("signUpButton").click();
            }
        },
        registerValuationMembership() {
            const formData = new FormData();

            formData.append("name", this.name);
            formData.append("email", this.userEmail);
            formData.append("password", this.userPassword);
            formData.append("phone", this.phone);

            axios
                .post("/idx-listings/valuation/membership", formData)
                .then((response) => {
                    this.authResult = true;
                    this.alertClass = "success";
                    this.alertMessage = response.data.message;
                    window.location.reload();
                })
                .catch((error) => {
                    this.alertClass = "danger";
                    this.alertMessage = error.response.data.error;
                })
                .finally((result) => {
                    setTimeout(() => {
                        if (this.alertClass == "success") {
                            this.userEmail = this.email;
                            this.userPassword = "";
                            this.$v.$reset();
                            this.alertClass = "";
                            this.alertMessage = "";
                            document.getElementById("closeForm").click();
                        }
                    }, 3000);
                });
        },
        registerMembership() {
            this.$v.$touch();
            if (this.$v.userEmail.$error || this.$v.userPassword.$error) {
                return false;
            } else {
                this.registerValuationMembership();
            }
        },
        handleFilterChange() {
            this.getHomes()
        },
        loginSuccess(user) {
            this.loggedInUserEmail = user.email;
            if (this.loggedInUserEmail) {
                this.openShareModal()
            }
        },
        fetchUserFavProperties() {
            getUserFavPropertiesIds(this.user_id, 'idx')
                .then(res => {
                    console.log(res)
                    this.userFavProperties = res;
                });
        },
        handleFavoriteSuccess(res) {
            if (this.userFavProperties.includes(res.id)) {
                this.userFavProperties = this.userFavProperties.filter(propertyId => propertyId !== res.id)
            }
            else {
                this.userFavProperties = [...this.userFavProperties, res.id]
            }
        },
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>

<style scoped>
.auth-block {
    position: absolute;
    top: 70px;
    left: 25px;
    right: 15px;
    color: #fff;
    text-shadow: 2px 2px #333;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
<style lang="scss">
@import './IdxResults.scss';
</style>
