<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 range-card-body calculation-text">
        <h4 class="big-heading px-4 text-left word-case">Sell Your Home at just 1.4% Service Charge.</h4>
        <h4 class="big-heading px-4 text-left word-case">Including buyer agent fees you only pay 3.9% .</h4>
        <br />
        <p
          class="pl-4 text-left calculation-description"
        >You don't pay the hefty 5% fees charged by traditional brokages anymore!!</p>
      </div>
      <div class="col-lg-6 px-4">
        <div class="card valuation-card">
          <div class="card-body range-card-body">
            <form action>
              <p class="saving-header">Your Savings</p>
              <h1 class="savings-amount">${{ totalSavings | commafy }}</h1>
              <h6 class="home-sale-price-text">
                Home sale price
                <span class="price-value">${{ currentRange | commafy }}</span>
              </h6>
              <input
                type="range"
                class="range-slider"
                id="vol"
                name="vol"
                v-model="currentRange"
                :min="min"
                :step="step"
                :max="max"
                :style="sliderProgressStyle"
              />
              <div class="range-label-container">
                <span class="value">$200,000</span>
                <span class="value">$2,000,000</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingTwoValuationSavings",
  data() {
    return {
      currentRange: 625000,
      min: 200000,
      max: 2000000,
      step: 1000
    };
  },
  computed: {
    totalSavings() {
      let num = this.currentRange;
      let savings = Math.floor(num * 0.011);
      return savings;
    },
    sliderProgressStyle() {
      let globalVal = (this.currentRange - this.min) / (this.max - this.min);
      let globalPercent = globalVal * 100;

      let result = 'background-image: -webkit-gradient(linear, left top, right top, color-stop(' + globalPercent + '%, orange), ' + 'color-stop(' + globalPercent + '%, #ddd)' + ')';
      return result;
    }
  },
  filters: {
    commafy(inVal) {
      return inVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
};
</script>

<style scoped>
input.range-slider[type="range"] {
  width: 80px;
  -webkit-appearance: none;
  -moz-apperance: none;
  appearance: none;
  background-image: -webkit-gradient(linear,
  left top, 
  right top, 
  color-stop(23.61%, orange),
  color-stop(23.61%, #ddd));
  width: 90%;
  border-radius: 10px;
  flex: 1;
  outline: none;
}
input.range-slider[type="range"]::-webkit-slider-progress {
  height: 10px;
  border-radius: 10px;
}
input.range-slider[type="range"]::-webkit-slider-runnable-track {
  height: 12px;
  appearance: none;
  border-radius: 10px;
}
input.range-slider[type="range"]::-webkit-slider-thumb {
  appearance: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  margin-top: -8px;
  border: 2px solid orange;
  box-sizing: border-box;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 4;
  position: relative;
}
.range-label-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.range-label-container .value {
  float: left;
}
.range-label-container .value:last-of-type {
  float: right;
}
span.value {
  color: #868c8f;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
</style>