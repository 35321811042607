<template>
  <!-- <footer class="bg-dark text-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12 footer-header-wrapper">
          <h4 class="text-white text-left">Get in Touch</h4>
        </div>
      </div>
      <div class="row py-4">
        <div class="col-md-6">
          <div class="row">
            <div class="col-lg-4">
              <div class="d-flex mb-3 align-items-center">
                <i class="fas fa-mobile-alt fa-2x text-smoke mr-2"></i>
                <div class="connect-info">
                  <span class="text-smoke contact-info-header__footer">Phone</span>
                  <br />
                  <a
                    class="fw-semi-bold text-white contact-info__footer"
                    :href="'tel:'+contactnumber"
                  >{{ displaynumber }}</a>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="d-flex mb-3 align-items-center">
                <i class="fas fa-envelope fa-2x text-smoke mr-2"></i>
                <div class="connect-info">
                  <span class="text-smoke contact-info-header__footer">Email</span>
                  <br />
                  <a
                    class="fw-semi-bold text-white contact-info__footer"
                    :href="'mailto:'+infoemail"
                  >{{ infoemail }}</a>
                </div>
              </div>
            </div>
            <div class="col-lg-3 text-white">
              <p class="mb-1 contact-info-header__footer">Follow Us</p>
              <div class="d-flex text-white">
                <a :href="linkedin" target="_blank" class="mr-2 fa-tip text-white" title="LinkedIn">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a :href="twitter" target="_blank" class="mx-2 fa-tip text-white" title="Twitter">
                  <i class="fab fa-twitter"></i>
                </a>
                <a :href="facebook" target="_blank" class="mx-2 fa-tip text-white" title="Facebook">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  :href="instagram"
                  target="_blank"
                  class="mx-2 fa-tip text-white"
                  title="Instagram"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a :href="youtube" target="_blank" class="mx-2 fa-tip text-white" title="Youtube">
                  <i class="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
      <p class="mt-2">
        <span class="footer-disclaimer-section">
          Disclaimer: Not intended to solicit sellers already under contract
          with another brokerage. Above market value offer is based on our
          home evaluation of the area’s similar home sales over the last 2
          years
        </span>
      </p>
    </div>

    <hr class="bg-white" />
    <div class="container footer-copyright-section">
      <p class="mb-1">
        <b>© {{ fullYear }} redbuyers.com. All rights reserved.</b>
      </p>
      <p class="m-0">
        RedBuyers Realty. Brokerage, is fully registered under the Real Estate
        and Business brokerage, Act, 2002 of Ontario
      </p>
    </div>
  </footer> -->
    <footer>
    <div class="footer-dark">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6 item">
                  <a class="text-grey" :href="'tel:'+contactnumber">
                    <p><i class="fa fa-phone"></i>&nbsp;&nbsp;{{ displaynumber }}</p>
                  </a>
                </div>
                <div class="col-md-6 item">
                  <a class="text-grey" :href="'mailto:'+infoemail">
                    <p><i class="fa fa-envelope"></i>&nbsp;&nbsp;{{ infoemail }}</p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 item text footer-text">
              <p>RedBuyers Realty. Brokerage, is fully registered under the Real Estate and Business brokerage, Act, 2002 of Ontario.</p>
            </div>

            <div class="col item social">
              <a :href="linkedin" target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a :href="twitter" target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
              <a :href="facebook" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a :href="instagram" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
              <a :href="youtube" target="_blank">
                <i class="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <p class="copyright">RedBuyers © {{ fullYear }} All Right Reserved</p>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LandingTwoFooter",
  props: {
    displaynumber: {
      default: ""
    },
    infoemail: {
      default: ""
    },
    contactnumber: {
      default: ""
    },
    linkedin: {
      default: ""
    },
    twitter: {
      default: ""
    },
    facebook: {
      default: ""
    },
    instagram: {
      default: ""
    },
    youtube: {
      default: ""
    }
  },
  computed: {
    fullYear() {
      let d = new Date();
      let n = d.getFullYear();
      return n;
    }
  }
};
</script>

<style scoped>
.footer-header-wrapper {
  margin-top: 60px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.footer-copyright-section {
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}
.footer-disclaimer-section {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: normal;
}
.contact-info__footer {
  font-size: 13px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
.contact-info__footer:hover {
  text-decoration: none;
}
.contact-info-header__footer {
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
</style>