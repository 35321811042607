<template>
    <div>
        <div class="new_multi_step_box">
            <!-- --------------------------- Nav Control Buttons -------------------------- -->
            <template v-if="this.currentStepIndex !== 0">
                <!-- Prev Up Button -->
                <button class="nav-btn--prev-up" @click="prev()">
                    <img
                        src="assets/presentation/image/new_next_arrow.png"
                        class="img-fluid"
                        alt="Prev"
                    />
                </button>
                <!-- Prev Down Button -->
                <button class="nav-btn--prev-down" @click="prev()">
                    <img
                        src="assets/presentation/image/new_next_arrow.png"
                        class="img-fluid"
                        alt="Prev"
                    />
                </button>

                <!-- Next Up Button -->
                <button
                    class="nav-btn--next-up"
                    @click="next()"
                    v-if="!isLastStep"
                >
                    <img
                        src="assets/presentation/image/new_next_arrow.png"
                        class="img-fluid"
                        alt="Prev"
                    />
                </button>

                <!-- Next Up Down -->
                <button
                    class="nav-btn--next-down"
                    @click="next()"
                    v-if="!isLastStep"
                >
                    <img
                        src="assets/presentation/image/new_next_arrow.png"
                        class="img-fluid"
                        alt="Prev"
                    />
                </button>
            </template>

            <div class="new_multi_step1" id="step1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-8">
                            <div class="new_multi_step1_content">
                                <div class="new_multi_step1_content_logo">
                                    <a href="#!"
                                        ><img
                                            src="assets/presentation/image/New_logo_color.png"
                                            class="img-fluid"
                                            alt="logo"
                                    /></a>
                                </div>
                                <div class="new_multi_step1_content_text">
                                    <h1>Selling Options with Redbuyers</h1>
                                    <p>{{ getAddress }}</p>
                                </div>
                                <div class="new_button_box_step1">
                                    <span>RedBuyers Realty.Brokerage.</span>
                                    <button
                                        class="new_next_button"
                                        id="go_step2"
                                        @click="next()"
                                    >
                                        <img
                                            src="assets/presentation/image/new_next_arrow.png"
                                            class="img-fluid"
                                            alt="next"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step2 d-none" id="step2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="new_multi_step2_title">
                                <h2>Problems with Traditional Sales?</h2>
                            </div>
                            <div class="new_multi_step2_content">
                                <div class="new_multi_step2_content_box ncbox1">
                                    <span>Lots of Procedures</span>
                                    <ul>
                                        <li>Finding the right agent</li>
                                        <li>Sale Prep. – Renovation/Staging</li>
                                        <li>Listing, showings, offers</li>
                                        <li>conditions, inspections</li>
                                        <li>Too many hassles</li>
                                    </ul>
                                </div>
                                <div class="new_multi_step2_content_box ncbox2">
                                    <span>No assurance of sale</span>
                                    <ul>
                                        <li>No written offers</li>
                                        <li>Uncertainty</li>
                                        <li>More days on MLS is unfavorable</li>
                                    </ul>
                                </div>
                                <div class="new_multi_step2_content_box ncbox3">
                                    <span>Stressful</span>
                                    <ul>
                                        <li>
                                            Stress of not selling, once listed
                                        </li>
                                        <li>Pressure from agents to sell</li>
                                        <li>
                                            List history will show up on MLS
                                        </li>
                                    </ul>
                                </div>
                                <div class="new_button_box_step2">
                                    <button
                                        class="new_next_button"
                                        id="go_step3"
                                    >
                                        <img
                                            src="assets/presentation/image/new_next_arrow.png"
                                            class="img-fluid"
                                            alt="next"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step3 d-none" id="step3">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="new_multi_step2_title">
                                <h2>The modern way of selling</h2>
                            </div>
                        </div>
                        <div class="col-md-8 back_step3_color mb-4 mb-md-5">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="new_multi_step3_content">
                                        <div
                                            class="new_multi_step3_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/step3_side_img1.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="new_multi_step3_content">
                                        <div
                                            class="new_multi_step3_content_text"
                                        >
                                            <span>Sell to RedBuyers</span>
                                            <p>With cash offer</p>
                                            <ul>
                                                <li>Hassle-free sale</li>
                                                <li>Sold in as-is condition</li>
                                                <li>No listing, no showings</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10 back_step3_color mb-3">
                            <div class="row align-items-center">
                                <div class="col-md-6 order-2 order-md-1">
                                    <div class="new_multi_step3_content">
                                        <div
                                            class="new_multi_step3_content_text"
                                        >
                                            <span>List with RedBuyers</span>
                                            <p>Maximize the price</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 order-1 order-md-2">
                                    <div class="new_multi_step3_content mb-3">
                                        <div
                                            class="new_multi_step3_content_image text-left"
                                        >
                                            <img
                                                src="assets/presentation/image/step3_side_img2.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 order-3">
                                    <div class="new_multi_step3_content">
                                        <div
                                            class="new_multi_step3_content_text"
                                        >
                                            <span>MLS listing</span>
                                            <ul>
                                                <li>
                                                    Premium listing services
                                                </li>
                                                <li>
                                                    Advanced data-driven
                                                    marketing
                                                </li>
                                                <li>“Sell to us” - option</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 order-4">
                                    <div class="new_multi_step3_content">
                                        <div
                                            class="new_multi_step3_content_text"
                                        >
                                            <span>Exclusive Listing</span>
                                            <ul>
                                                <li>Hassle-free Listing</li>
                                                <li>Limited showings</li>
                                                <li>
                                                    No Listing history for
                                                    public
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="new_button_box_step3">
                                <button class="new_next_button" id="go_step4">
                                    <img
                                        src="assets/presentation/image/new_next_arrow.png"
                                        class="img-fluid"
                                        alt="next"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step4 d-none" id="step4">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="new_multi_step2_title">
                                <h2>Market Trends</h2>
                            </div>
                            <div class="new_multi_step4_content">
                                <div
                                    class="new_multi_step4_content_table table-responsive"
                                >
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Detached Homes</th>
                                                <th>Median Price</th>
                                                <th>Avg Days on Market</th>
                                                <th>Median Price Change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td>Aug 22</td>
                                                <td>Aug 22</td>
                                                <td>
                                                    <table
                                                        class="table table-borderless mb-0"
                                                    >
                                                        <tr>
                                                            <td class="p-0">
                                                                1 Year
                                                            </td>
                                                            <td class="p-0">
                                                                5 Year
                                                            </td>
                                                            <td class="p-0">
                                                                10 Year
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>GTA</th>
                                                <td class="color_price">
                                                    $1248000
                                                </td>
                                                <td class="color_succ">
                                                    22 Days
                                                </td>
                                                <td>
                                                    <table
                                                        class="table table-borderless mb-0"
                                                    >
                                                        <tr>
                                                            <td
                                                                class="p-0 color_dec"
                                                            >
                                                                -7.7%
                                                            </td>
                                                            <td
                                                                class="p-0 color_succ"
                                                            >
                                                                +48%
                                                            </td>
                                                            <td
                                                                class="p-0 color_succ"
                                                            >
                                                                +134%
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Northwest Brampton</th>
                                                <td class="color_price">
                                                    $1367500
                                                </td>
                                                <td class="color_succ">
                                                    15 Days
                                                </td>
                                                <td>
                                                    <table
                                                        class="table table-borderless mb-0"
                                                    >
                                                        <tr>
                                                            <td
                                                                class="p-0 color_succ"
                                                            >
                                                                +1.2%
                                                            </td>
                                                            <td
                                                                class="p-0 color_succ"
                                                            >
                                                                +65%
                                                            </td>
                                                            <td
                                                                class="p-0 color_succ"
                                                            >
                                                                +186%
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="new_multi_step4_content_arrow_box">
                                    <div
                                        class="new_multi_step4_content_arrow_box_title"
                                    >
                                        <span>Market Status</span>
                                    </div>
                                    <div
                                        class="new_multi_step4_content_arrow_box_arrow"
                                    >
                                        <ul class="arrow">
                                            <li><span>1</span></li>
                                            <li><span>2</span></li>
                                            <li><span>3</span></li>
                                        </ul>
                                        <ul class="content">
                                            <li>Buyer’s Market</li>
                                            <li>Balanced Market</li>
                                            <li>Seller’s Market</li>
                                        </ul>
                                        <div class="comment">
                                            <span>Sales / Listing: 53%</span>
                                            <p>
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="new_multi_step4_content_arrow_box">
                                    <div
                                        class="new_multi_step4_content_arrow_box_title"
                                    >
                                        <span>GTA Median Price</span>
                                    </div>
                                    <div class="new_multi_step4_content_graph">
                                        <canvas id="chart"></canvas>
                                    </div>
                                </div>
                                <div class="new_button_box_step4">
                                    <button
                                        class="new_next_button"
                                        id="go_step5"
                                    >
                                        <img
                                            src="assets/presentation/image/new_next_arrow.png"
                                            class="img-fluid"
                                            alt="next"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step5 d-none" id="step5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step2_title">
                                <h2>What goes in RedBuyers Home Assessment?</h2>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4 mb-md-5">
                            <div class="new_multi_step5_content">
                                <div class="new_multi_step5_content_title">
                                    <span>Comparable Home Sales</span>
                                </div>
                                <div class="new_multi_step5_content_box">
                                    <div
                                        class="new_multi_step5_content_box_text"
                                    >
                                        <ul>
                                            <li>
                                                We analyze similar homes in the
                                                neighbourhood that are recently
                                                sold
                                            </li>
                                            <li>
                                                We consider all the upgrades to
                                                give you a more accurate value.
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="new_multi_step5_content_box_img"
                                    >
                                        <img
                                            src="assets/presentation/image/home_assis1.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4 mb-md-5">
                            <div class="new_multi_step5_content">
                                <div class="new_multi_step5_content_title">
                                    <span>Market Trends</span>
                                </div>
                                <div class="new_multi_step5_content_box">
                                    <div
                                        class="new_multi_step5_content_box_text"
                                    >
                                        <ul>
                                            <li>
                                                We always keep an eye on the
                                                latest market trends.
                                            </li>
                                            <li>
                                                Pricing is based on latest
                                                market status.
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="new_multi_step5_content_box_img"
                                    >
                                        <img
                                            src="assets/presentation/image/home_assis2.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4 mb-md-0">
                            <div class="new_multi_step5_content">
                                <div class="new_multi_step5_content_title">
                                    <span>Experienced assessment experts</span>
                                </div>
                                <div class="new_multi_step5_content_box">
                                    <div
                                        class="new_multi_step5_content_box_text"
                                    >
                                        <ul>
                                            <li>
                                                We have experts for manual
                                                valuations with years of
                                                experience
                                            </li>
                                            <li>
                                                We are 17 years in business, and
                                                Listed/ SOLD 100’s of home in
                                                Ontario
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="new_multi_step5_content_box_img"
                                    >
                                        <img
                                            src="assets/presentation/image/home_assis3.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="new_multi_step5_content">
                                <div class="new_multi_step5_content_title">
                                    <span>Automated Valuation Methods</span>
                                </div>
                                <div class="new_multi_step5_content_box">
                                    <div
                                        class="new_multi_step5_content_box_text"
                                    >
                                        <ul>
                                            <li>
                                                We use Artificial intelligence
                                                price modelling
                                            </li>
                                            <li>
                                                Data based value for better
                                                accuracy.
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="new_multi_step5_content_box_img"
                                    >
                                        <img
                                            src="assets/presentation/image/home_assis4.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3 mt-md-4 mb-2 text-right">
                            <div class="new_button_box_step5">
                                <button class="new_next_button" id="go_step6">
                                    <img
                                        src="assets/presentation/image/new_next_arrow.png"
                                        class="img-fluid"
                                        alt="next"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step6 d-none" id="step6">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 mb-4 mb-md-4 mb-lg-5">
                            <div class="new_multi_step2_title">
                                <h2>Your Home Value</h2>
                                <p>Based on Automated Valuation Method (AVM)</p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="new_multi_step6_content">
                                <div class="new_multi_step6_content_image">
                                    <img
                                        src="assets/presentation/image/new_multi_step6_content_image.png"
                                        class="img-fluid"
                                        alt="next"
                                    />
                                </div>
                                <div class="new_multi_step6_content_price">
                                    <div
                                        class="new_multi_step6_content_price_box"
                                    >
                                        <div
                                            class="new_multi_step6_content_price_title"
                                        >
                                            <h3>
                                                If you sell your house in the
                                                open market
                                            </h3>
                                            <p>
                                                <span
                                                    ><img
                                                        src="assets/presentation/image/location_new.png"
                                                        class="img-fluid"
                                                        alt="location" /></span
                                                >{{ getAddress }}
                                            </p>
                                        </div>
                                        <div
                                            class="new_multi_step6_content_price_value"
                                        >
                                            <h4>
                                                ${{
                                                    average_price
                                                        | round_off
                                                        | numberWithCommas
                                                }}
                                            </h4>
                                            <small
                                                >Approximate value based on
                                                similar homes in the
                                                area.</small
                                            >
                                        </div>
                                        <div
                                            class="new_multi_step6_content_price_value_slide"
                                        >
                                            <div class="slider_bar">
                                                <div
                                                    class="slider_bar_ball"
                                                ></div>
                                            </div>
                                            <ul class="slider_price">
                                                <li>
                                                    <span>Lower range</span>${{
                                                        min_price
                                                            | round_off
                                                            | numberWithCommas
                                                    }}
                                                </li>
                                                <li>
                                                    <span
                                                        >Median Assessment</span
                                                    >${{
                                                        min_price
                                                            | round_off
                                                            | numberWithCommas
                                                    }}
                                                    - ${{
                                                        max_price
                                                            | round_off
                                                            | numberWithCommas
                                                    }}
                                                </li>
                                                <li>
                                                    <span>Upper range</span>>
                                                    ${{
                                                        max_price
                                                            | round_off
                                                            | numberWithCommas
                                                    }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="new_multi_step6_content_text">
                                    <p>
                                        This assessment based on information you
                                        provided, using our Automated Valuation
                                        Method, This assessment doesn’t factor
                                        in your recent renovations, new
                                        additions, and superior features. Your
                                        home’s final asking price and selling
                                        strategy will decided by you based on
                                        our expert guidance.
                                    </p>
                                </div>
                                <div class="new_button_box_step6">
                                    <button
                                        class="new_next_button"
                                        id="go_step7"
                                    >
                                        <img
                                            src="assets/presentation/image/new_next_arrow.png"
                                            class="img-fluid"
                                            alt="next"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step7 d-none" id="step7">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step2_title">
                                <h2>RedBuyers Cash Offer and other options</h2>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="new_multi_step7_content_main_first">
                                <div class="new_multi_step7_content">
                                    <div class="new_multi_step7_content_box1">
                                        <span>What is our Cash Offer?</span>
                                        <p>
                                            We provide an offer without any
                                            conditions, which is called cash
                                            offer.
                                        </p>
                                        <p>Estimated market value for your house</p>
                                        <p>Based on recent selling price by Current Market Analysis</p>
                                        <h3>
                                            ${{
                                                average_price
                                                    | round_off
                                                    | numberWithCommas
                                            }}
                                        </h3>
                                        <p>
                                            Expected value if you sell in the
                                            open market
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <div
                                        class="new_multi_step7_content_main_second"
                                    >
                                        <div class="new_multi_step7_content">
                                            <div
                                                class="new_multi_step7_content_box2"
                                            >
                                                <div
                                                    class="new_multi_step7_content_box2_top"
                                                >
                                                    <span
                                                        >Sell to RedBuyers</span
                                                    >
                                                    <p>with our Cash Offer</p>
                                                    <div
                                                        class="new_multi_step7_content_box2_ul"
                                                    >
                                                        <small
                                                            >Benefit of our cash
                                                            offer</small
                                                        >
                                                        <ul>
                                                            <li>
                                                                Your home sold
                                                                as-is condition
                                                            </li>
                                                            <li>
                                                                No listing &
                                                                showing required
                                                            </li>
                                                            <li>
                                                                No hassle sale
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div
                                                    class="new_multi_step7_content_box2_bottom"
                                                >
                                                    <small
                                                        >RedBuyers Estimated
                                                        cash offer</small
                                                    >
                                                    <span
                                                        >${{
                                                            new_discount_amount
                                                                | round_off
                                                                | numberWithCommas
                                                        }}</span
                                                    >
                                                    <small
                                                        >Risk-Adjusted Home
                                                        Value.</small
                                                    >
                                                    <p>
                                                        Because of down market,
                                                        a risk adjustment of
                                                        10-20% to offset to
                                                        protect our investment.
                                                    </p>
                                                    <h6 class="text-white font-weight-bold">An accurate cash offer will be provided after viewing the property.</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div
                                        class="new_multi_step7_content_main_third"
                                    >
                                        <div class="new_multi_step7_content">
                                            <div
                                                class="new_multi_step7_content_box2"
                                            >
                                                <div
                                                    class="new_multi_step7_content_box2_top"
                                                >
                                                    <span>List with us</span>
                                                    <p>
                                                        Do you want to maximize
                                                        sale price?
                                                    </p>
                                                    <div
                                                        class="new_multi_step7_content_box2_ul_box"
                                                    >
                                                        <div
                                                            class="new_multi_step7_content_box2_ul"
                                                        >
                                                            <small
                                                                >Exclusive
                                                                Listing
                                                                Benefits</small
                                                            >
                                                            <ul>
                                                                <li>
                                                                    Less Hassle
                                                                </li>
                                                                <li>
                                                                    Limited
                                                                    Showing, our
                                                                    clients
                                                                </li>
                                                                <li>
                                                                    List history
                                                                    will not
                                                                    show up
                                                                </li>
                                                                <li>
                                                                    Get our cash
                                                                    offer any
                                                                    time
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div
                                                            class="new_multi_step7_content_box2_ul"
                                                        >
                                                            <small
                                                                >MLS listing
                                                                Benefits</small
                                                            >
                                                            <ul>
                                                                <li>
                                                                    Open to
                                                                    public, more
                                                                    exposure
                                                                </li>
                                                                <li>
                                                                    Free
                                                                    staging, and
                                                                    more
                                                                </li>
                                                                <li>
                                                                    Primum
                                                                    selling/realtor
                                                                    service
                                                                </li>
                                                                <li>
                                                                    Get our cash
                                                                    offer
                                                                    anytime
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="new_multi_step7_content_box2_bottom"
                                                >
                                                    <small
                                                        >Estimated additional
                                                        equity if you</small
                                                    >
                                                    <em
                                                        >List and sell with
                                                        us.</em
                                                    >
                                                    <span
                                                        >${{
                                                            discount_amount
                                                                | round_off
                                                                | numberWithCommas
                                                        }}</span
                                                    >
                                                    <p>
                                                        An estimated value - Not
                                                        a guaranteed price
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div
                                        class="new_multi_step7_content_main_fourth"
                                    >
                                        <div class="row align-items-center">
                                            <div
                                                class="col-lg-6 col-md-12 order-2 order-md-2 order-lg-1"
                                            >
                                                <div
                                                    class="new_multi_step7_content_main_fourth_text"
                                                >
                                                    <span
                                                        >If our cash offer is
                                                        not acceptable to you,
                                                        Don`t worry.</span
                                                    >
                                                    <h4>
                                                        List with RedBuyers and
                                                        try to maximize your
                                                        sale price!!
                                                    </h4>
                                                    <h5>
                                                        Try RedBuyers exclusive
                                                        listing first. (Most
                                                        Opted Option)
                                                    </h5>
                                                    <p>
                                                        Exclusive listing is a
                                                        hassle-free service
                                                        where we bring our own
                                                        qualified buyers to get
                                                        your house sold.
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="col-lg-6 col-md-12 order-1 order-md-1 order-lg-2"
                                            >
                                                <div
                                                    class="new_multi_step7_content_main_fourth_image"
                                                >
                                                    <img
                                                        src="assets/presentation/image/new_multi_step7_content_main_fourth_image.png"
                                                        class="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="new_button_box_step7">
                                        <button
                                            class="new_next_button"
                                            id="go_step8"
                                        >
                                            <img
                                                src="assets/presentation/image/new_next_arrow.png"
                                                class="img-fluid"
                                                alt="next"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step8ext d-none" id="step8">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step2_title text-center">
                                <h2>
                                    Advantages of exclusive listing with
                                    RedBuyers
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step8ext_box">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <div class="new_multi_step8ext_image">
                                            <img
                                                src="assets/presentation/image/aoe1.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="new_multi_step8ext_content">
                                            <span
                                                >Hassle free home preparations,
                                                repairs. Avoid unqualified buyer
                                                showings</span
                                            >
                                            <p>
                                                We guide you from basic
                                                decluttering to make your home
                                                showing ready. We bring our own
                                                qualified buyers, and investors
                                                who has interest in buying your
                                                home.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step8ext_box">
                                <div class="row align-items-center">
                                    <div class="col-md-8 order-2 order-md-1">
                                        <div
                                            class="new_multi_step8ext_content mar-left"
                                        >
                                            <span
                                                >You decide the showing timings
                                                and terms</span
                                            >
                                            <p>
                                                With our own buyers and agents,
                                                we have the flexibility in
                                                accommodating your convenience
                                                in showing time and terms.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 order-1 order-md-2">
                                        <div class="new_multi_step8ext_image">
                                            <img
                                                src="assets/presentation/image/aoe2.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step8ext_box">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <div class="new_multi_step8ext_image">
                                            <img
                                                src="assets/presentation/image/aoe3.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="new_multi_step8ext_content">
                                            <span
                                                >Your house will not be listed
                                                on MLS, therefore no history of
                                                listing, and days on the
                                                market</span
                                            >
                                            <p>
                                                More days on the market is an
                                                advantage to buyers. If you list
                                                through MLS your history of
                                                listing will show up to the
                                                public and realtors. In our
                                                exclusive listings none of these
                                                information’s are easily
                                                accessible to other realtors and
                                                public.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step8ext_box">
                                <div class="row align-items-center">
                                    <div class="col-md-8 order-2 order-md-1">
                                        <div
                                            class="new_multi_step8ext_content mar-left"
                                        >
                                            <span
                                                >Leverage the marketing muscle
                                                of RedBuyers to sell your
                                                home</span
                                            >
                                            <p>
                                                Sellers who list exclusively
                                                with RedBuyers have a wide range
                                                of amazing brand and technology
                                                tools to help market their home
                                                and attract potential buyers.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 order-1 order-md-2">
                                        <div class="new_multi_step8ext_image">
                                            <img
                                                src="assets/presentation/image/aoe4.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step8ext_box">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <div class="new_multi_step8ext_image">
                                            <img
                                                src="assets/presentation/image/aoe5.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="new_multi_step8ext_content">
                                            <span
                                                >Our experienced realtors with
                                                an entire team of staff to
                                                support you</span
                                            >
                                            <p>
                                                We have over 17 years of
                                                experience in the real estate
                                                sales in Ontario, and most
                                                importantly, we have our entire
                                                team to support you at every
                                                stage of your buying and selling
                                                process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step8ext_box">
                                <div class="row align-items-center">
                                    <div class="col-md-8 order-2 order-md-1">
                                        <div
                                            class="new_multi_step8ext_content mar-left"
                                        >
                                            <span
                                                >Options to cancel anytime, or
                                                list on MLS or Sell to us with
                                                cash offer</span
                                            >
                                            <p>
                                                During the exclusive listing
                                                period, you are allowed to
                                                cancel anytime with a 10 days’
                                                notice. You also have options to
                                                list it on MLS and to open your
                                                listing to the public. Most
                                                importantly, you can use our
                                                cash offer and sell to us
                                                anytime during the listings and
                                                end all the showings.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 order-1 order-md-2">
                                        <div class="new_multi_step8ext_image">
                                            <img
                                                src="assets/presentation/image/aoe6.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step8ext_box">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <div class="new_multi_step8ext_image">
                                            <img
                                                src="assets/presentation/image/aoe7.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="new_multi_step8ext_content">
                                            <span
                                                >Do not waste time with planning
                                                and preparing your home for
                                                sale</span
                                            >
                                            <p>
                                                We can list exclusively
                                                immediately with very limited
                                                sale preparation. Market is
                                                unstable and prices are going
                                                down. It is better to act
                                                earlier than waiting. With our
                                                exclusive listing, you can
                                                cancel anytime, and no history
                                                will not show up to the public.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="new_button_box_step7">
                                <button class="new_next_button" id="go_step9">
                                    <img
                                        src="assets/presentation/image/new_next_arrow.png"
                                        class="img-fluid"
                                        alt="next"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step8 d-none" id="step9">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step2_title">
                                <h2>RedBuyers Listing Services</h2>
                                <span>Home Prep & Marketing Services</span>
                            </div>
                        </div>
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="row align-items-center">
                                <div class="col-md-8 order-2 order-md-1">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span>Unmatched MLS exposure</span>
                                            <p>
                                                You have multiple options you
                                                can choose to have full exposure
                                                of MLS or the privacy and
                                                convenience of our exclusive
                                                listing
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 order-1 order-md-2 mb-3 mb-md-0"
                                >
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_1.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="row align-items-center">
                                <div class="col-md-4 mb-3 mb-md-0">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_2.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span>Showings & open houses</span>
                                            <p>
                                                Our team of experts will help
                                                you plan the showings for your
                                                home and schedule the open
                                                houses. You will get our
                                                expertise at every step of your
                                                buying and selling process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="row align-items-center">
                                <div class="col-md-8 order-2 order-md-1">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span
                                                >Technology-Driven
                                                Marketing</span
                                            >
                                            <p>
                                                With our technology-driven
                                                marketing platforms, you can now
                                                maximize your sale price. We
                                                have multiple buyers and
                                                investors ready to buy your home
                                                in our marketplace.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 order-1 order-md-2 mb-3 mb-md-0"
                                >
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_3.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="row align-items-center">
                                <div class="col-md-4 mb-3 mb-md-0">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_4.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span
                                                >Dedicated staging Experts</span
                                            >
                                            <p>
                                                As the most trusted realtor with
                                                over 17 years of experience, we
                                                have our dedicated team to
                                                provide you with our premium
                                                services like repairing,
                                                remodelling, and home staging.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10 mb-4 mb-md-5">
                                    <div class="row align-items-center">
                                        <div
                                            class="col-md-8 order-2 order-md-1"
                                        >
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_text"
                                                >
                                                    <span
                                                        >Professional
                                                        photography</span
                                                    >
                                                    <p>
                                                        We will provide
                                                        professional photography
                                                        and designing experts
                                                        for your home to promote
                                                        on virtual platforms for
                                                        greater reach.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-4 order-1 order-md-2 mb-3 mb-md-0"
                                        >
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_image"
                                                >
                                                    <img
                                                        src="assets/presentation/image/side_img_5.png"
                                                        class="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10 mb-4 mb-md-5">
                                    <div class="row align-items-center">
                                        <div class="col-md-4 mb-3 mb-md-0">
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_image"
                                                >
                                                    <img
                                                        src="assets/presentation/image/side_img_6.png"
                                                        class="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_text"
                                                >
                                                    <span
                                                        >We offer multiple
                                                        options</span
                                                    >
                                                    <p>
                                                        Whether it’s buying or
                                                        selling, we offer
                                                        multiple choices. You
                                                        choose the options that
                                                        best suit your needs.
                                                        You are in control of
                                                        the whole process.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10 mb-4 mb-md-5">
                                    <div class="row align-items-center">
                                        <div
                                            class="col-md-8 order-2 order-md-1"
                                        >
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_text"
                                                >
                                                    <span
                                                        >Home assessment
                                                        report</span
                                                    >
                                                    <p>
                                                        We will help you with
                                                        one of the most accurate
                                                        home assessment reports
                                                        for your home so that
                                                        your home will be on top
                                                        of the market in every
                                                        possible way.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-4 order-1 order-md-2 mb-3 mb-md-0"
                                        >
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_image"
                                                >
                                                    <img
                                                        src="assets/presentation/image/side_img_7.png"
                                                        class="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row align-items-center">
                                        <div class="col-md-4 mb-3 mb-md-4">
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_image"
                                                >
                                                    <img
                                                        src="assets/presentation/image/side_img_8.png"
                                                        class="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div
                                                class="new_multi_step8_content"
                                            >
                                                <div
                                                    class="new_multi_step8_content_text"
                                                >
                                                    <span
                                                        >Home Sold
                                                        Guaranteed</span
                                                    >
                                                    <p>
                                                        You can apply for our
                                                        cash offer anytime
                                                        during the listing. If
                                                        circumstances change or
                                                        you simply want to end
                                                        showings and sell to us,
                                                        you are in complete
                                                        control.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="new_button_box_step7">
                                <button class="new_next_button" id="go_step10">
                                    <img
                                        src="assets/presentation/image/new_next_arrow.png"
                                        class="img-fluid"
                                        alt="next"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="new_multi_step9 d-none" id="step10">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="row align-items-center">
                                <div class="col-md-8 order-2 order-md-1">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span
                                                >Professional photography</span
                                            >
                                            <p>
                                                We will provide professional
                                                photography and designing
                                                experts for your home to promote
                                                on virtual platforms for greater
                                                reach.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 order-1 order-md-2 mb-3 mb-md-0"
                                >
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_5.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="row align-items-center">
                                <div class="col-md-4 mb-3 mb-md-0">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_6.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span
                                                >We offer multiple options</span
                                            >
                                            <p>
                                                Whether it’s buying or selling,
                                                we offer multiple choices. You
                                                choose the options that best
                                                suit your needs. You are in
                                                control of the whole process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="row align-items-center">
                                <div class="col-md-8 order-2 order-md-1">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span>Home assessment report</span>
                                            <p>
                                                We will help you with one of the
                                                most accurate home assessment
                                                reports for your home so that
                                                your home will be on top of the
                                                market in every possible way.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 order-1 order-md-2 mb-3 mb-md-0"
                                >
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_7.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="row align-items-center">
                                <div class="col-md-4 mb-3 mb-md-4">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/side_img_8.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="new_multi_step8_content">
                                        <div
                                            class="new_multi_step8_content_text"
                                        >
                                            <span>Home Sold Guaranteed</span>
                                            <p>
                                                You can apply for our cash offer
                                                anytime during the listing. If
                                                circumstances change or you
                                                simply want to end showings and
                                                sell to us, you are in complete
                                                control.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="new_button_box_step7">
                                <button class="new_next_button" id="go_step11">
                                    <img
                                        src="assets/presentation/image/new_next_arrow.png"
                                        class="img-fluid"
                                        alt="next"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="new_multi_step11 d-none" id="step11">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step2_title">
                                <h2>What is next?</h2>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row align-items-center">
                                <div class="col-md-6 mb-4 mb-md-0">
                                    <div class="new_multi_step11_content">
                                        <div
                                            class="new_multi_step11_content_image"
                                        >
                                            <img
                                                src="assets/presentation/image/step11_side_new.png"
                                                class="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="new_multi_step11_content">
                                        <div
                                            class="new_multi_step11_content_text"
                                        >
                                            <ul>
                                                <li>
                                                    <span
                                                        >Step 1. Schedule an
                                                        appointment for
                                                        in-person visits.</span
                                                    >
                                                    <p>
                                                        To discuss all the
                                                        options.
                                                        <a
                                                            href="javascript:void(0)"
                                                            @click="
                                                                expertValuation
                                                            "
                                                            >Click here to
                                                            schedule an
                                                            appointment</a
                                                        >
                                                    </p>
                                                </li>
                                                <li>
                                                    <span
                                                        >Step 2 In-home
                                                        visit</span
                                                    >
                                                    <p>
                                                        Our experienced realtors
                                                        will meet you and
                                                        explain all the options.
                                                        Max. 30-minute visit
                                                        time
                                                    </p>
                                                </li>
                                                <li>
                                                    <span
                                                        >Step 3: If you decide
                                                        to Sell to
                                                        RedBuyers</span
                                                    >
                                                    <p>
                                                        We will provide a final
                                                        cash offer in 24 hours,
                                                        and we will send
                                                        agreements to sign.
                                                    </p>
                                                </li>
                                                <li>
                                                    <span
                                                        >Sep 4 If you decide to
                                                        List with
                                                        RedBuyers</span
                                                    >
                                                    <p>
                                                        We prepare listing
                                                        documents for you to
                                                        sign. We will send our
                                                        stagers, photographers
                                                        to prepare the home
                                                        before listing the house
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-2">
                                    <div
                                        class="new_multi_step11_content_text_bittom"
                                    >
                                        <p>
                                            Whatever your decision we have a
                                            whole team of experienced
                                            professionals to support you till
                                            you close your home
                                        </p>
                                    </div>
                                    <div class="new_button_box_step11">
                                        <button
                                            class="new_next_button"
                                            id="go_step12"
                                        >
                                            <img
                                                src="assets/presentation/image/new_next_arrow.png"
                                                class="img-fluid"
                                                alt="next"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_multi_step10 d-none" id="step12">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step2_title">
                                <h2>Summary – Selling your home</h2>
                            </div>
                        </div>
                        <div class="col-md-10 mb-4 mb-md-5">
                            <div class="row">
                                <div
                                    class="col-lg-6 col-md-12 mb-4 mb-md-5 mb-lg-0"
                                >
                                    <div class="new_multi_step10_content">
                                        <div
                                            class="new_multi_step10_content_text"
                                        >
                                            <span>Sell to RedBuyers</span>
                                            <p>with Cash offer</p>
                                            <h4>
                                                ${{
                                                    new_discount_amount
                                                        | round_off
                                                        | numberWithCommas
                                                }}
                                            </h4>
                                            <small>Now</small>
                                            <small>5 days validity</small>
                                            <small
                                                >Final cash offer will be
                                                provided after in-person visit
                                                to your home</small
                                            >
                                        </div>
                                        <div
                                            class="new_multi_step10_content_ul"
                                        >
                                            <span>Benefits:</span>
                                            <ul>
                                                <li>Hassle free</li>
                                                <li>
                                                    No listing, & showing
                                                    required
                                                </li>
                                                <li>Sold as-is condition</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="new_multi_step10_content">
                                        <div
                                            class="new_multi_step10_content_text"
                                        >
                                            <span>List with RedBuyers</span>
                                            <p>Estimated list and sell price</p>
                                            <small
                                                >Not a guaranteed sale
                                                price</small
                                            >
                                            <h4>
                                                ${{
                                                    average_price
                                                        | round_off
                                                        | numberWithCommas
                                                }}
                                            </h4>
                                            <small
                                                >Additional equity expected if
                                                list and sell comparing to cash
                                                offer</small
                                            >
                                            <h4>
                                                + ${{
                                                    discount_amount
                                                        | round_off
                                                        | numberWithCommas
                                                }}
                                            </h4>
                                        </div>
                                        <div
                                            class="new_multi_step10_content_ul"
                                        >
                                            <span>Benefits:</span>
                                            <ul>
                                                <li>Maximize the price</li>
                                                <li>Free staging and more</li>
                                                <li>Get our cash anytime</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 mb-md-5">
                            <div class="new_multi_step_title2">
                                <h3>
                                    Benefits of Exclusive listing – Best option
                                    to maximize price
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row align-items-center">
                                <div class="col-md-7 order-2 order-md-1">
                                    <div class="new_multi_step10_content_ul2">
                                        <div
                                            class="new_multi_step5_content_box_text"
                                        >
                                            <ul>
                                                <li>
                                                    No listing history to the
                                                    public, and realtors.
                                                </li>
                                                <li>
                                                    Limited listing preparation,
                                                    and repairs.
                                                </li>
                                                <li>
                                                    Showings at your timings,
                                                    and at your terms.
                                                </li>
                                                <li>
                                                    Only qualified buyers to
                                                    view your home.
                                                </li>
                                                <li>
                                                    No open houses and
                                                    unnecessary hassles.
                                                </li>
                                                <li>
                                                    Our 17 years of expertise
                                                    will be an added bonus.
                                                </li>
                                                <li>
                                                    Cancel anytime or change to
                                                    MLS listing.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-md-5 order-1 order-md-2 mb-4 mb-md-0"
                                >
                                    <div class="new_multi_step10_content_image">
                                        <img
                                            src="assets/presentation/image/boe_side.png"
                                            class="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-2 mt-2">
                            <div class="new_multi_step10_content_ul2">
                                <p>
                                    We can do exclusive listing right away to
                                    avoid further market drops.
                                </p>
                                <p>
                                    It is a no obligation service with anytime
                                    cancellation policy.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="new_button_box_step7">
                                <button class="new_next_button" id="go_step13">
                                    <img
                                        src="assets/presentation/image/new_next_arrow.png"
                                        class="img-fluid"
                                        alt="next"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="new_multi_step12 d-none" id="step13">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-2 order-md-1">
                            <div class="new_multi_step12_content">
                                <div class="new_multi_step12_content_text">
                                    <h5>
                                        Schedule an Appointment With Our
                                        Property Specialists
                                    </h5>
                                    <ul>
                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                @click="expertValuation"
                                                ><img
                                                    src="assets/presentation/image/new_phone.png"
                                                    alt="phone"
                                                />Phone Call</a
                                            >
                                        </li>
                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                @click="expertValuation"
                                                >In Person</a
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 order-1 order-md-2">
                            <div class="new_multi_step12_content">
                                <div class="new_multi_step12_content_image">
                                    <img
                                        src="assets/presentation/image/new_appo_side.png"
                                        class="img-fluid"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    name: "MultiStepPresentation",
    data() {
        return {
            min_price: "",
            max_price: "",
            average_price: "",
            valuation_properties: {},
            discount_amount: "",
            new_discount_amount: "",
            currentStepIndex: 0,
            steps: [1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13],
        };
    },
    computed: {
        getAddress() {
            if (sessionStorage.getItem("valuation_address")) {
                return sessionStorage.getItem("valuation_address");
            } else {
                return "";
            }
        },
        isLastStep() {
            // console.log({
            //     current: this.currentStepIndex,
            //     length: this.steps.length,
            // });
            return this.currentStepIndex === this.steps.length - 1;
        },
    },
    mounted() {
        if (sessionStorage.getItem("valuation_data")) {
            let localValuation = JSON.parse(
                sessionStorage.getItem("valuation_data")
            );
            if (
                localValuation.min &&
                localValuation.max &&
                localValuation.average
            ) {
                this.min_price = localValuation.min;
                this.max_price = localValuation.max;
                this.average_price = localValuation.average;
                this.valuation_properties = localValuation.properties;
                this.setupMarketTrend();
                this.get_discountend_price();
                // console.log(this.valuation_properties);
                // console.log(localValuation.properties);
            }
        }
    },
    methods: {
        expertValuation() {
            window.location.href = `/schedule/appointment`;
        },
        get_discountend_price() {
            let discounted_price = (this.average_price * 15) / 100;
            let total_discounted = this.average_price - discounted_price;
            this.discount_amount = discounted_price;
            this.new_discount_amount = total_discounted;
        },
        setupMarketTrend() {
            if (
                this.valuation_properties &&
                this.valuation_properties.length > 0
            ) {
                var chart = document.getElementById("chart").getContext("2d");
                let properties = this.valuation_properties;
                let stats_arr = [];
                let price_data_arr = [];
                for (let i = 0; i < properties.length; i++) {
                    stats_arr.push(properties[i].sold_date);
                    price_data_arr.push(properties[i].sold_price);
                }
                var data = {
                    labels: stats_arr,
                    datasets: [
                        {
                            backgroundColor: "#B6D7F9",
                            pointBackgroundColor: "#B6D7F9",
                            borderWidth: 1,
                            borderColor: "#B6D7F9",
                            data: price_data_arr,
                        },
                    ],
                };

                var chartInstance = new Chart(chart, {
                    type: "line",
                    data: data,
                    options: {
                        hover: {
                            animationDuration: 0,
                        },
                        animation: {
                            duration: 1,
                        },
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: true,
                        },
                        scales: {
                            yAxes: [
                                {
                                    display: true,
                                    gridLines: {
                                        display: true,
                                    },
                                    ticks: {
                                        max:
                                            Math.max(...data.datasets[0].data) +
                                            100000,
                                        display: true,
                                        beginAtZero: true,
                                        callback: function (
                                            value,
                                            index,
                                            values
                                        ) {
                                            return "$" + value;
                                        },
                                    },
                                },
                            ],

                            xAxes: [
                                {
                                    gridLines: {
                                        display: true,
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                    },
                });
            }
        },
        prev() {
            const currentStep = this.steps[this.currentStepIndex];
            const prevStep = this.steps[this.currentStepIndex - 1];
            if (prevStep) {
                this.currentStepIndex = this.currentStepIndex - 1;
                $(`#step${currentStep}`).addClass("d-none");
                $(`#step${prevStep}`).removeClass("d-none");
                $(window).scrollTop(0);
            }
        },
        next() {
            const currentStep = this.steps[this.currentStepIndex];
            const nextStep = this.steps[this.currentStepIndex + 1];
            if (nextStep) {
                this.currentStepIndex = this.currentStepIndex + 1;
                $(`#step${currentStep}`).addClass("d-none");
                $(`#step${nextStep}`).removeClass("d-none");
                $(window).scrollTop(0);
            }
        },
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>
