<template>
  <div class="sidebar_listing_grid1 mb30 dn-lg">
    <div class="sidebar_listing_list">
      <div class="sidebar_advanced_search_widget">
        <ul class="sasw_list mb0">
          <li>
            <h4 class="mb0">Find Your New Home</h4>
          </li>
          <li class="search_area">
            <div class="form-group">
              <input
                type="text"
                class="form-control form_control"
                placeholder="Enter Keyword"
              />
            </div>
          </li>
          <li>
            <div class="search_option_two">
              <div class="sidebar_select_options">
                <select class="selectpicker w100 show-tick">
                  <option value="">Status</option>
                  <option value="Active">Active</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          </li>
          <li>
            <div class="search_option_two">
              <div class="sidebar_select_options">
                <select class="selectpicker w100 show-tick">
                  <option>Type</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Condo">Condo</option>
                  <option value="Studio">Studio</option>
                  <option value="Villa">Villa</option>
                </select>
              </div>
            </div>
          </li>
          <li>
            <div class="search_option_two">
              <div class="sidebar_select_options">
                <select class="selectpicker w100 show-tick">
                  <option>All Cities</option>
                  <option value="London">London</option>
                  <option value="NewYork">New York</option>
                  <option value="Paris">Paris</option>
                  <option value="Istanbul">Istanbul</option>
                  <option value="Amsterdam">Amsterdam</option>
                  <option value="Rome">Rome</option>
                  <option value="LogAngeles">Log Angeles</option>
                </select>
              </div>
            </div>
          </li>
          <li>
            <div class="search_option_two">
              <div class="sidebar_select_options">
                <select class="selectpicker w100 show-tick">
                  <option>Bedrooms</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
          </li>
          <li class="search_area">
            <div class="form-group">
              <input
                type="text"
                class="form-control form_control"
                placeholder="Min. Area"
              />
            </div>
          </li>
          <li class="search_area">
            <div class="form-group">
              <input
                type="text"
                class="form-control form_control"
                placeholder="Max. Area"
              />
            </div>
          </li>
          <li class="search_area">
            <div class="form-group">
              <input
                type="text"
                class="form-control form_control"
                placeholder="Property ID"
              />
            </div>
          </li>
          <li>
            <div class="sidebar_priceing_slider_desktop">
              <div class="wrapper">
                <p class="mb0">Price Range</p>
                <div class="slider-range"></div>
                <input type="text" class="amount" placeholder="$11,200" />
                <input type="text" class="amount2" placeholder="$15,200" />
              </div>
            </div>
          </li>
          <li>
            <div class="search_option_button text-center mt25">
              <button type="submit" class="btn btn-block btn-thm mb25">
                Search
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdxListingSearchFilter",
};
</script>