<template>
    <div class="modal-content border-0">
        <div class="modal-header bg-transparent border-bottom-0 pb-0">
            <h3 v-if="!isMailSend" class="modal-title text-primary">
                Reset Your Password
            </h3>
            <button
                type="button"
                class="close bg-transparent"
                data-dismiss="modal"
                aria-label="Close"
                @click="$emit('closeModal')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div
                class="alert"
                role="alert"
                v-if="message"
                :class="error ? 'alert-danger' : 'alert-success'"
            >
                {{ message }}
            </div>
            <form v-if="!isMailSend">
                <!-- Email -->
                <div class="mb-2 mr-sm-2">
                    <label for="email" class="form-label mb-0">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        :class="emailError ? 'is-invalid' : ''"
                        v-model="email"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ emailError }}
                    </span>
                </div>

                <div class="text-right mt-2 mb-4">
                    <a
                        href="#"
                        @click.prevent="openLoginView"
                        class="fs-small text-decoration-underline"
                    >
                        Click here to Log in into an existing account
                    </a>
                </div>

                <button
                    type="button"
                    @click="handleSubmit"
                    class="btn btn-primary btn-block btn-lg"
                    :disabled="loading"
                >
                    <template v-if="loading">
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </template>
                    <template v-if="!loading"
                        >Send Password Reset Link</template
                    >
                </button>
            </form>

            <div class="confirmation-container" v-if="isMailSend">
                <img
                    src="/assets/images/mail-send.png"
                    alt="Mail Send"
                    class="img-fluid mb-4"
                />
                <h3 class="text-primary">Check your Inbox</h3>
                <p class="text-muted">
                    We have sent your password recovery instructions to
                    {{ email }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { VIEWS, BASE_PATH } from "./AuthModal.vue";

export default {
    name: "ResetPasswordModal",
    data: () => ({
        email: "",
        isMailSend: false,
        message: "",
        error: false,
        loading: false,
    }),
    methods: {
        openLoginView() {
            this.$emit("openView", VIEWS.LOGIN_FORM);
        },
        handleSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                const { email, password } = this;
                axios
                    .post(BASE_PATH + "/password/email", {
                        email,
                    })
                    .then((res) => {
                        this.message = "";
                        this.error = false;
                        this.isMailSend = true;
                    })
                    .catch((err) => {
                        console.log(err.response.data.error);
                        this.error = true;
                        this.message =
                            "Something Went Wrong, Please Try Again!";
                            const hasEmailError = err?.response?.data?.errors?.email?.[0];
                        if (hasEmailError) {
                            this.message = hasEmailError;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
        emailError() {
            if (this.$v?.email.$error) {
                if (!this.$v?.email.required) {
                    return "Email is required";
                } else if (!this.$v?.email.email) {
                    return "Please enter a valid email address";
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
    },
    validations() {
        return {
            email: { required, email },
        };
    },
};
</script>

<style scoped lang="scss">
.modal-header {
    min-height: 40px;
}
.fs-small {
    font-size: 13px;
}
.confirmation-container {
    display: grid;
    grid-auto-columns: 85%;
    justify-content: center;
    text-align: center;
    justify-items: center;
}
</style>
