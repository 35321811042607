<template>
    <main>
        <div id="heroSlider" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li
                    data-target="#heroSlider"
                    data-slide-to="0"
                    class="active"
                ></li>
                <li data-target="#heroSlider" data-slide-to="1"></li>
                <li data-target="#heroSlider" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <section class="hero">
                        <div class="container">
                            <h1>
                                <span>Affordable</span>
                                <span class="big">Buying</span>
                                <span>with RedBuyers</span>
                            </h1>
                        </div>
                    </section>
                </div>
                <div class="carousel-item">
                    <section class="hero">
                        <div class="container">
                            <h1>
                                <span>Affordable</span>
                                <span class="big">Buying</span>
                                <span>with RedBuyers</span>
                            </h1>
                        </div>
                    </section>
                </div>
                <div class="carousel-item">
                    <section class="hero">
                        <div class="container">
                            <h1>
                                <span>Affordable</span>
                                <span class="big">Buying</span>
                                <span>with RedBuyers</span>
                            </h1>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <section>
            <div class="container">
                <inline-properties-carousel
                    :user_id="user_id"
                    :listFor="'NEWLY_ADDED_MLS_HOMES'"
                    key="Newly Added Homes"
                    heading="Newly Added Homes"
                ></inline-properties-carousel>
            </div>
        </section>

        <home-properties-tabs :hideHeading="false"></home-properties-tabs>

        <section class="pt-0">
            <div class="container">
                <inline-properties-carousel
                    :user_id="user_id"
                    listFor="RECENTLY_SOLD_HOMES"
                    key="Recently Sold Homes"
                    heading="Recently Sold Homes"
                ></inline-properties-carousel>
            </div>
        </section>

        <!-- <section class="pt-0">
            <div class="container">
                <inline-properties-carousel
                    :user_id="user_id"
                    listFor="EXCLUSIVE_OFF_MARKET"
                    key="Off Market Homes"
                    heading="RedBuyers Exclusive - Off Market Homes"
                ></inline-properties-carousel>
            </div>
        </section> -->

        <section class="pt-0">
            <div class="container">
                <inline-properties-carousel
                    :user_id="user_id"
                    listFor="BUILDER_DEALS"
                    key="Builder Deals"
                    heading="Builder Deals"
                ></inline-properties-carousel>
            </div>
        </section>

        <section class="pt-0">
            <div class="container">
                <inline-properties-carousel
                    :user_id="user_id"
                    listFor="RENTAL_DEALS"
                    key="Rental Deals"
                    heading="Rental Deals"
                ></inline-properties-carousel>
            </div>
        </section>

        <section class="pt-0">
            <div class="container">
                <div class="d-block d-sm-none mb-4 pb-1">
                    <h2 class="heading text-center mb-2">
                        RedBuyers Technology Tools
                    </h2>
                    <p class="text-center">Tools to help you</p>
                </div>
                <div class="navigation-img-grid">
                    <a href="/idx-listings/search-result">
                        <img
                            class="img-fluid"
                            src="/assets/new-buy/images/rb-technology-tools.png"
                            alt="Browse Homes"
                        />
                        <h4>RedBuyers Technology Tools</h4>
                    </a>
                    <a href="/find-your-home-value">
                        <img
                            class="img-fluid"
                            src="/assets/new-buy/images/find-your-home-value.png"
                            alt="Browse Homes"
                        />
                        <h4>Find Your Home Value</h4>
                    </a>
                    <a href="#">
                        <img
                            class="img-fluid"
                            src="/assets/new-buy/images/mortgage-calculator.png"
                            alt="Browse Homes"
                        />
                        <h4>Mortgage Calculator</h4>
                    </a>
                    <a href="#">
                        <img
                            class="img-fluid"
                            src="/assets/new-buy/images/how-much-you-qualify.png"
                            alt="Browse Homes"
                        />
                        <h4>How Much You Qualify</h4>
                    </a>
                </div>
            </div>
        </section>

        <!-- <section class="pt-0">
            <div class="container">
                <inline-properties-carousel
                    :user_id="user_id"
                    key="Power of Sales"
                    heading="Power of Sales"
                ></inline-properties-carousel>
            </div>
        </section>

        <section class="pt-0">
            <div class="container">
                <inline-properties-carousel
                    :user_id="user_id"
                    key="Price Change"
                    heading="Price Change"
                ></inline-properties-carousel>
            </div>
        </section> -->
    </main>
</template>
<script>
import InlinePropertiesCarousel from "../../components/InlinePropertiesCarousel/InlinePropertiesCarousel.vue";

export default {
    name: "BuyNewPage",
    props: {
        user_id: "",
    },
    components: {
        "inline-properties-carousel": InlinePropertiesCarousel,
    },
};
</script>
