<template>
    <div>
        <div class="card builders-form-card">
            <div class="card-body">
                <h3 class="builder-form__title">Upload Your Photos Now</h3>
                <div class="form-group">
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        id="firstDropZone"
                        ref="firstDropZoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handlePhotoUploadError"
                        @vdropzone-complete="updatePropertyImages"
                        @vdropzone-files-added="onAttachmentAdd"
                        @vdropzone-removed-file="onRemovedFile"
                    >
                        <div class="dropzone-custom-content">
                            <img
                                src="/assets/images/builders-form/dropzone-picture-icon.png"
                                alt="Drop Image"
                                class="img-responsive img-fluid"
                            />
                            <h4>Drop Your Image Here!</h4>
                        </div>
                    </vue-dropzone>
                    <p>
                        <b>
                            <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Allowed
                            types – jpg, jpeg and png. Maximum file size – 5MB
                        </b>
                    </p>
                    <div class="text-danger" v-if="photoUploadError">
                        The file must be a file of type: jpg, jpeg, png. and
                        less than or equals to 5 MB in size.
                    </div>
                </div>
                <!-- <h3 class="builder-form__title">Upload Building Layouts Now</h3>
                <div class="form-group">
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        id="secondDropZone"
                        ref="secondDropZoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handleLayoutUploadError"
                        @vdropzone-complete="updatePropertyLayouts"
                        @vdropzone-files-added="onLayoutAttachmentAdd"
                        @vdropzone-removed-file="onLayoutRemovedFile"
                    >
                        <div class="dropzone-custom-content">
                            <img
                                src="/assets/images/builders-form/dropzone-layout-icon.png"
                                alt="Drop Layout"
                                class="img-responsive img-fluid"
                            />
                            <h4>Drop Your Layout Here!</h4>
                        </div>
                    </vue-dropzone>
                    <p>
                        <b>
                            <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Allowed
                            types – jpg, jpeg and png. Convert to image format
                            if your file format is other than jpeg, jpg or png.
                            Maximum file size – 5MB
                        </b>
                    </p>
                    <div class="text-danger" v-if="layoutUploadError">
                        The file must be a file of type: jpg, jpeg, png. and
                        less than or equals to 5 MB in size.
                    </div>
                    <p>
                        <b>
                            <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Upload
                            all images and click submit button
                        </b>
                    </p>
                </div> -->
                <div class="form-group text-center">
                    <button
                        class="btn btn-danger builders-form-prev-btn"
                        @click.prevent="backToFormThreeFromFour"
                        :disabled="final_form_processing"
                    >
                        BACK
                    </button>
                    <button
                        class="btn btn-danger builders-form-next-btn"
                        @click.prevent="submitFormData"
                        :disabled="final_form_processing"
                    >
                        {{ final_form_processing ? "PROCESSING..." : "SUBMIT" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "BuilderFormFour",
    data() {
        return {
            dropzoneOptions: {
                url: "/builders/upload-property-image",
                // autoProcessQueue: false,
                addRemoveLinks: true,
            },
            propertyImages: [],
            propertyLayouts: [],
            photoUploadError: false,
            layoutUploadError: false,
        };
    },
    props: {
        final_form_processing: {
            default: false,
        },
    },
    methods: {
        backToFormThreeFromFour(payload) {
            this.$emit("backToFormThreeFromFour", {});
        },
        submitFormFour(payload) {
            this.$emit("submitFormFour", {});
        },
        updatePropertyLayouts(response) {
            if (response?.xhr?.status == 200) {
                this.photoUploadError = false;
                if (response?.xhr?.response) {
                    let serverResponse = JSON.parse(response?.xhr?.response);
                    if (
                        serverResponse?.data?.thumbnail_path &&
                        serverResponse?.data?.image_path
                    ) {
                        this.propertyLayouts.push(
                            serverResponse.data.thumbnail_path
                        );
                        this.propertyLayouts.push(
                            serverResponse.data.image_path
                        );
                        localStorage.setItem(
                            "property_layouts",
                            JSON.stringify(this.propertyLayouts)
                        );
                    }
                }
            }
        },
        updatePropertyImages(response) {
            if (response?.xhr?.status == 200) {
                this.layoutUploadError = false;
                if (response?.xhr?.response) {
                    let serverResponse = JSON.parse(response?.xhr?.response);
                    if (
                        serverResponse?.data?.thumbnail_path &&
                        serverResponse?.data?.image_path
                    ) {
                        this.propertyImages.push(
                            serverResponse.data.thumbnail_path
                        );
                        this.propertyImages.push(
                            serverResponse.data.image_path
                        );
                        localStorage.setItem(
                            "property_images",
                            JSON.stringify(this.propertyImages)
                        );
                    }
                }
            }
        },
        submitFormData() {
            this.$emit("submitFormData", {});
        },
        handlePhotoUploadError(file, message, xhr) {
            this.photoUploadError = true;
            this.$refs.firstDropZoneRef.removeFile(file);
        },
        handleLayoutUploadError(file, message, xhr) {
            this.layoutUploadError = true;
            this.$refs.secondDropZoneRef.removeFile(file);
        },
        onAttachmentAdd(files) {
            // this.attachments = [...this.attachments, ...files].filter(
            //     (item) => item instanceof File
            // );
        },
        onRemovedFile(file) {
            console.log(file);
            // console.log("Removed File", file);
            // const { status, name } = file;
            // if (status === "queued") {
            //     this.attachments = this.attachments.filter(
            //         (fileObj) => fileObj.name !== name
            //     );
            // }
        },
        onLayoutAttachmentAdd(files) {
            // this.attachments = [...this.attachments, ...files].filter(
            //     (item) => item instanceof File
            // );
        },
        onLayoutRemovedFile(file) {
            console.log("Layout", file);
            // this.attachments = [...this.attachments, ...files].filter(
            //     (item) => item instanceof File
            // );
        },
        deletePropertyImage(url) {
            const formData = new FormData();
            formData.append("url", JSON.stringify(url));
            formData.append("pid", this.property_id);
            axios
                .post("/builders/delete-property-file", formData)
                .then((response) => {
                    console.log(response?.data);
                })
                .catch((error) => {
                    console.error(error?.response?.data);
                });
        },
    },
};
</script>
