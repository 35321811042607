<template>
    <b-modal id="authModal" :size="isForgetPasswordView ? '' : 'lg'" centered>
        <login-form
            v-if="isLoginView"
            v-on:openView="openView"
            @loginSuccess="loginSuccess"
            @closeModal="close"
        ></login-form>
        <signup-form
            v-if="isSignupView"
            v-on:openView="openView"
            @loginSuccess="loginSuccess"
            @closeModal="close"
            :address = "address"
            :street_address="street_address"
        ></signup-form>
        <reset-password
            v-if="isForgetPasswordView"
            v-on:openView="openView"
            @closeModal="close"
        ></reset-password>
    </b-modal>
    <!-- <div class="modal fade" id="authModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <login-form
                v-if="isLoginView"
                v-on:openView="openView"
                @loginSuccess="loginSuccess"
            ></login-form>
            <signup-form
                v-if="isSignupView"
                v-on:openView="openView"
                @loginSuccess="loginSuccess"
            ></signup-form>
            <reset-password
                v-if="isForgetPasswordView"
                v-on:openView="openView"
            ></reset-password>
        </div>
    </div> -->
</template>

<script>
import Login from "./Login.vue";
import ResetPassword from "./ResetPassword.vue";
import Signup from "./Signup.vue";
import { setBodyAuthClass } from "../../utils";

export const BASE_PATH = window.location.origin;
// console.log({BASE_PATH})
export const VIEWS = {
    LOGIN_FORM: "LOGIN_FORM",
    SIGNUP_FORM: "SIGNUP_FORM",
    FORGET_PASSWORD_FORM: "FORGET_PASSWORD_FORM",
};
const initialView = VIEWS.SIGNUP_FORM;
export default {
    name: "LoginModal",
    props: {
        address: {
            default: "",
        },
        street_address: {
            default: "",
        },
        initialView: {
            default: VIEWS.SIGNUP_FORM
        }
    },
    emits: ['loginSuccess'],
    data(){
        return {
            currentView: this.initialView,
        }
    },
    components: {
        "login-form": Login,
        "signup-form": Signup,
        "reset-password": ResetPassword,
    },
    methods: {
        openView(type) {
            this.currentView = type;
        },
        loginSuccess(res) {
            setBodyAuthClass(true);
            this.$emit('loginSuccess',res)
            setTimeout(() => {
                this.close();
            }, 1000);
        },
        open() {
            this.currentView = this.initialView;
            this.$bvModal.show("authModal");
        },
        close() {
            this.$bvModal.hide("authModal");
        },
    },
    computed: {
        isLoginView() {
            return this.currentView === VIEWS.LOGIN_FORM;
        },
        isSignupView() {
            return this.currentView === VIEWS.SIGNUP_FORM;
        },
        isForgetPasswordView() {
            return this.currentView === VIEWS.FORGET_PASSWORD_FORM;
        },
    },
};
</script>

<style lang="scss">
.fs-small {
    font-size: 13px;
}
#authModal___BV_modal_content_ {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}
#authModal___BV_modal_header_ {
    display: none;
}
#authModal___BV_modal_footer_ {
    display: none;
}
#authModal___BV_modal_body_ {
    padding: 20px;
    input {
        font-size: 14px !important;
    }
}
</style>
