<template>
  <div class="wrapper">
    <div class="col-md-12 my-4">
      <div class="row">
        <div class="col-md-6">
          <div class="card shadow">
            <div class="card-header">
              <h6 class="card-title text-center">Edit SEO Page</h6>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label for="page_name">Page Name</label>
                <input
                  type="text"
                  name="page_name"
                  id="page_name"
                  class="form-control"
                  placeholder="Enter meta name"
                  v-model="page_name"
                />
              </div>
              <div class="form-group">
                <label for="page_link">Page Link</label>
                <input
                  type="text"
                  v-model="page_link"
                  id="page_link"
                  name="page_link"
                  class="form-control"
                  placeholder="Enter page link"
                />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <button
                      class="btn btn-success w-100"
                      :disabled="api_requesting"
                      @click.prevent="updateSeoPage"
                    >{{ api_requesting ? 'Processing...' : 'Update' }}</button>
                  </div>
                  <div class="col-md-6">
                    <a
                      class="btn btn-danger w-100"
                      :href="api_requesting ? 'javascript:void(0)' : '/seo/seo-page-list'"
                    >Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row mb-4">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <b-button
                class="w-100"
                :disabled="api_requesting"
                variant="outline-primary"
                v-b-modal.add-meta-modal
              >Add Meta</b-button>
            </div>
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" class="w-25">Meta Name</th>
                <!-- <th scope="col" class="w-50">Meta Description</th> -->
                <th scope="col" class="w-50">Meta Value</th>
                <th scope="col" class="w-25">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="meta_tags_available">
                <tr v-for="(item, index) in meta_tags" :key="'meta-item-'+index">
                  <td class="w-25">{{ item.meta_name }}</td>
                  <td class="w-50">{{ item.meta_description }}</td>
                  <td class="w-25">
                    <!-- <a
                      href="javascript:void(0);"
                      class="btn btn-icon btn-sm text-success mr-1 fa-tip"
                      title="Show"
                    >
                      <i class="fa fa-eye pointer-icon"></i>
                    </a> -->
                    <a
                      href="javascript:void(0);"
                      class="btn btn-icon btn-sm text-info fa-tip"
                      title="Edit"
                      @click.prevent="showEditMetaModal(item)"
                      data-original-title="Edit"
                    >
                      <i class="fa fa-pen pointer-icon"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      @click.prevent="deleteMetaTag(item.id)"
                      class="btn btn-icon btn-sm mx-1 text-danger fa-tip"
                      title="Delete"
                      data-original-title="Delete"
                    >
                      <i class="fa fa-trash pointer-icon"></i>
                    </a>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3" class="text-center">No tags available</td>
                </tr>
              </template>
            </tbody>
          </table>
          <!-- Add Meta Modal -->
          <b-modal ref="addMetaModal" id="add-meta-modal" centered title="Add Meta Tag">
            <div class="form-group">
              <label for="meta_name">Meta Name</label>
              <input
                type="text"
                v-model="meta_name"
                name="meta_name"
                id="meta_name"
                class="form-control"
                placeholder="Enter meta name"
              />
            </div>
            <div class="form-group">
              <label for="meta_description">Meta Description</label>
              <textarea
                type="text"
                name="meta_description"
                v-model="meta_description"
                id="meta_description"
                rows="5"
                style="resize:none;"
                class="form-control"
                placeholder="Enter meta description"
              ></textarea>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-success"
                  class="float-left"
                  @click="saveMetaTag"
                  :disabled="api_requesting"
                >{{ api_requesting ? 'Processing...' : 'Add Meta' }}</b-button>
                <b-button
                  variant="outline-danger"
                  class="float-right"
                  @click="closeAddMetaModal"
                  :disabled="api_requesting"
                >Close</b-button>
              </div>
            </template>
          </b-modal>
          <!-- ./Add Meta Modal -->
          <!-- Delete Meta Confirm Modal -->
          <b-modal ref="deleteMetaModal" id="delete-meta-modal" centered>
            <p class="lead">
              Are you sure? Do you really want to
              <a
                href="javascript:void(0);"
                v-b-tooltip
                title="This action cannot be reverted"
                class="text-danger"
              >delete</a> this meta?
            </p>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="danger"
                  class="float-left"
                  @click="confirmDeleteMetaTag"
                  :disabled="api_requesting"
                >{{ api_requesting ? 'Processing...' : 'Confirm' }}</b-button>
                <b-button
                  variant="success"
                  class="float-right"
                  @click="closeDeleteMetaModal"
                  :disabled="api_requesting"
                >Cancel</b-button>
              </div>
            </template>
          </b-modal>
          <!-- ./Delete Meta Confirm Modal -->
          <!-- Edit Meta Tag Modal -->
          <b-modal ref="editMetaModal" id="edit-meta-modal" centered>
            <div class="form-group">
              <label for="meta_name">Meta Name</label>
              <input
                type="text"
                id="meta_name"
                name="meta_name"
                class="form-control"
                v-model="meta_name"
                placeholder="Enter meta name"
              />
            </div>
            <div class="form-group">
              <label for="meta_description">Meta Description</label>
              <textarea
                name="meta_description"
                id="meta_description"
                class="form-control"
                rows="5"
                style="resize:none;"
                v-model="meta_description"
                placeholder="Enter meta description"
              ></textarea>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="success"
                  class="float-left"
                  @click.prevent="confirmUpdateMeta"
                  :disabled="api_requesting"
                >{{ api_requesting ? 'Processing...' : 'Confirm' }}</b-button>
                <b-button
                  variant="danger"
                  class="float-right"
                  @click="closeUpdateMetaModal"
                  :disabled="api_requesting"
                >Cancel</b-button>
              </div>
            </template>
          </b-modal>
          <!-- ./Edit Meta Tag Modal -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditSEO",
  props: {
    page_id: {
      default: 0
    },
    page_content: {
      default: ""
    }
  },
  data() {
    return {
      meta_name: "",
      meta_description: "",
      page_name: "",
      page_link: "",
      seo_details: {},
      meta_tags: [],
      delete_meta_id: 0,
      api_requesting: false,
      edit_meta_id: 0
    };
  },
  mounted() {
    if (this.page_content) {
      this.seo_details = JSON.parse(this.page_content);
      if (this.seo_details?.page_name) {
        this.page_name = this.seo_details.page_name;
      }
      if (this.seo_details?.seo_tags) {
        this.meta_tags = this.seo_details.seo_tags;
      }
      if (this.seo_details?.page_link) {
        this.page_link = this.seo_details.page_link;
      }
    }
  },
  methods: {
    showEditMetaModal(item) {
      this.meta_name = item.meta_name;
      this.meta_description = item.meta_description;
      this.edit_meta_id = item.id;
      this.$refs['editMetaModal'].show();
    },
    confirmUpdateMeta() {
      this.api_requesting = true;
      const formData = new FormData();

      formData.append("meta_name", this.meta_name);
      formData.append("meta_description", this.meta_description);
      let url = "/seo/update-seo-page-meta/" + this.edit_meta_id;
      axios.post(url, formData)
      .then(res => {
        let data = res.data?.data;
        let index = this.meta_tags.findIndex(el => data.id == el.id);
        this.meta_tags[index] = data;
        this.closeUpdateMetaModal();
      }).catch(err => {
        console.error(err?.response?.data);
      })
      .finally(res => {
        this.api_requesting = false;
      });
    },
    closeUpdateMetaModal() {
      this.meta_name = "";
      this.meta_description = "";
      this.edit_meta_id = 0;
      this.$refs['editMetaModal'].hide();
    },
    deleteMetaTag(id) {
      this.delete_meta_id = id;
      this.openDeleteMetaModal();
    },
    confirmDeleteMetaTag() {
      this.api_requesting = true;
      let url = "/seo/delete-meta-tag/" + this.delete_meta_id;
      axios
        .post(url)
        .then(res => {
          let data = res.data?.data;
          let element = this.meta_tags.findIndex(
            el => el.id == this.delete_meta_id
          );
          this.$delete(this.meta_tags, element);
          this.delete_meta_id = 0;
          this.closeDeleteMetaModal();
        })
        .catch(err => {
          let error = err?.response?.data;
          console.error(error);
        })
        .finally(res => {
          this.api_requesting = false;
        });
    },
    saveMetaTag() {
      this.api_requesting = true;
      const formData = new FormData();

      formData.append("meta_name", this.meta_name);
      formData.append("meta_description", this.meta_description);

      let url = "/seo/add-seo-page-tag/" + this.page_id;
      axios
        .post(url, formData)
        .then(response => {
          this.meta_name = "";
          this.meta_description = "";
          this.meta_tags.push(response?.data?.data);
          this.closeAddMetaModal();
        })
        .catch(error => {
          console.error(error?.response?.data);
        })
        .finally(res => (this.api_requesting = false));
    },
    closeAddMetaModal() {
      this.$refs["addMetaModal"].hide();
    },
    openDeleteMetaModal() {
      this.$refs["deleteMetaModal"].show();
    },
    closeDeleteMetaModal() {
      this.$refs["deleteMetaModal"].hide();
    },
    updateSeoPage() {
      this.api_requesting = true;
      const formData = new FormData();

      formData.append("page_name", this.page_name);
      formData.append("page_link", this.page_link);

      let url = "/seo/" + this.page_id + "/update-seo";

      axios
        .post(url, formData)
        .then(response => {
          this.seo_details = response.data?.data;
        })
        .catch(error => {
          console.error(error?.response?.data);
        })
        .finally(res => {
          this.api_requesting = false;
        });
    }
  },
  computed: {
    meta_tags_available() {
      if (this.meta_tags?.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
#add-meta-modal___BV_modal_footer_,
#delete-meta-modal___BV_modal_footer_,
#edit-meta-modal___BV_modal_footer_ {
  background: #fff !important;
}
</style>