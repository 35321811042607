<template>
  <div class="card mt-4 shadow rounded">
    <div class="card-title">
      <h6 class="text-center pt-4">Edit Property - Step 2</h6>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label for="intersection">Closest Intersection</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="intersection"
          placeholder="Enter closest intersection"
          v-model="intersection"
        />
      </div>
      <div class="form-group">
        <label for="neighbourhood">Neighbourhood</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="neighbourhood"
          placeholder="Enter neighbourhood"
          v-model="neighbourhood"
        />
      </div>
      <div class="form-group">
        <label for="unit">Unit</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="unit"
          placeholder="Enter unit (If Apartment)"
          v-model="unit"
        />
      </div>
      <div class="form-group">
        <label for="architect">Architect</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="architect"
          placeholder="Enter architect name"
          v-model="architect"
        />
      </div>
      <div class="form-group">
        <label for="marketing_company">Marketing Company</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="marketing_company"
          placeholder="Enter marketing company name"
          v-model="marketing_company"
        />
      </div>
      <div class="form-group">
        <label for="builder_type">Builder Type</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="builder_type"
          placeholder="Sales/Brokerage"
          v-model="builder_type"
        />
      </div>
      <div class="form-group">
        <label for="building_type">Building Type</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="building_type"
          placeholder="Enter Building Type"
          v-model="building_type"
        />
      </div>
      <div class="form-group">
        <label for="interior_designer">Interior Designer</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="interior_designer"
          placeholder="Enter interior designer name"
          v-model="interior_designer"
        />
      </div>
      <div class="form-group">
        <label for="sale_address">Sale Address</label>
        <input type="hidden" v-model="sale_address" />
        <vue-google-autocomplete
          id="sale_address"
          ref="mapElement"
          class="form-control builders-form__input"
          placeholder="Sale address"
          :country="['ca']"
          @placechanged="getAddressData"
          :value="sale_address"
        ></vue-google-autocomplete>
      </div>
      <div class="form-group">
        <label for="company">Company</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="company"
          placeholder="Enter company name"
          v-model="company"
        />
      </div>
      <div class="form-group">
        <label for="relation">Relation With Property</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="relation"
          placeholder="Enter your relation with the property"
          v-model="relation"
        />
      </div>
      <div class="form-group">
        <label for="corporation">Corporation</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="corporation"
          placeholder="Enter corporation name"
          v-model="corporation"
        />
      </div>
      <div class="form-group">
        <label for="actual_city">Actual City</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="actual_city"
          placeholder="Enter actual city"
          v-model="actual_city"
        />
      </div>
      <div class="row">
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-info w-100"
            @click.prevent="resetForm"
            :disabled="formSubmitting"
          >{{ formSubmitting ? "Processing..." : "Reset" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-success w-100"
            :disabled="formSubmitting"
            @click.prevent="updatePropertyDetails"
          >{{ formSubmitting ? "Processing..." : "Save" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-primary w-100"
            :disabled="formSubmitting"
            @click.prevent="moveToFormThree"
          >{{ formSubmitting ? "Processing..." : "Next" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormStepTwo",
  props: {
    property_details: {
      default: {}
    }
  },
  data() {
    return {
      intersection: "",
      neighbourhood: "",
      architect: "",
      unit: "",
      marketing_company: "",
      builder_type: "",
      building_type: "",
      interior_designer: "",
      sale_address: "",
      company: "",
      relation: "",
      corporation: "",
      actual_city: "",
      property_id: "",
      formSubmitting: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.resetForm();
    }, 1000);
  },
  methods: {
    getAddressData(addressData, placeResultData, id) {
      if (placeResultData?.formatted_address) {
        this.sale_address = placeResultData.formatted_address;
      }
    },
    moveToThree() {
      this.$emit("moveToThree", 3);
    },
    updatePropertyDetails() {
      const formData = new FormData();

      let property_id = this.property_id;
      if (property_id) {
        const formData = new FormData();

        formData.append("intersection", this.intersection);
        formData.append("neighbourhood", this.neighbourhood);
        formData.append("architect", this.architect);
        formData.append("unit", this.unit);
        formData.append("marketing_company", this.marketing_company);
        formData.append("builder_type", this.builder_type);
        formData.append("building_type", this.building_type);
        formData.append("interior_designer", this.interior_designer);
        formData.append("sale_address", this.sale_address);
        formData.append("company", this.company);
        formData.append("relation", this.relation);
        formData.append("corporation", this.corporation);
        formData.append("actual_city", this.actual_city);

        axios
          .post("/property-management/update-property-details/" + property_id, formData)
          .then(response => {
            this.formSubmitting = false;
            sessionStorage.setItem('formStep', 2);
          })
          .catch(error => {
            console.error(error?.response?.data);
          })
          .finally(res => {
            window.location.reload();
          });
      }
    },
    moveToFormThree() {
      this.$emit("moveToFormThree", 3);
    },
    resetForm() {
      // intersection
      if (this.property_details?.intersection) {
        this.intersection = this.property_details.intersection;
      }
      // neighbourhood
      if (this.property_details?.neighbourhood) {
        this.neighbourhood = this.property_details.neighbourhood;
      }
      // architect
      if (this.property_details?.architect) {
        this.architect = this.property_details.architect;
      }
      // unit
      if (this.property_details?.unit) {
        this.unit = this.property_details.unit;
      }
      // marketing company
      if (this.property_details?.marketing_company) {
        this.marketing_company = this.property_details.marketing_company;
      }
      // builder type
      if (this.property_details?.builder_type) {
        this.builder_type = this.property_details.builder_type;
      }

      // building type
      if (this.property_details?.building_type) {
        this.building_type = this.property_details.building_type;
      }
      // interior designer
      if (this.property_details?.interior_designer) {
        this.interior_designer = this.property_details.interior_designer;
      }
      // sale address
      if (this.property_details?.sale_address) {
        this.sale_address = this.property_details.sale_address;
      }
      // company
      if (this.property_details?.company) {
        this.company = this.property_details.company;
      }
      // relation
      if (this.property_details?.relation) {
        this.relation = this.property_details.relation;
      }
      // corporation
      if (this.property_details?.corporation) {
        this.corporation = this.property_details.corporation;
      }
      // actual city
      if (this.property_details?.actual_city) {
        this.actual_city = this.property_details.actual_city;
      }
      // id
      if (this.property_details?.id) {
        this.property_id = this.property_details.id;
      }
    }
  }
};
</script>
