<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step 3
            </h5>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label for="bedrooms">Select Bedrooms</label>
                <select
                    class="form-control builders-form__input"
                    id="bedrooms"
                    v-model="bedrooms"
                    :class="$v.bedrooms.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select Bedrooms</option>
                    <option
                        v-for="item in bedroomsOptions"
                        :value="item"
                        :key="'bedrooms-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.bedrooms.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="bathrooms">Select Bathrooms</label>
                <select
                    class="form-control builders-form__input"
                    id="bathrooms"
                    v-model="bathrooms"
                    :class="$v.bathrooms.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select Bathrooms</option>
                    <option
                        v-for="item in bathroomsOptions"
                        :value="item"
                        :key="'bathrooms-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.bathrooms.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="garage">Select Garage</label>
                <select
                    class="form-control builders-form__input"
                    id="garage"
                    v-model="garage"
                    :class="$v.garage.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select Garage</option>
                    <option
                        v-for="item in garageOptions"
                        :value="item"
                        :key="'garage-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.garage.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="square_footage">Square Footage</label>
                <input
                    type="text"
                    class="form-control builders-form__input"
                    id="square_footage"
                    v-model="square_footage"
                    placeholder="Enter square feet area"
                    :class="$v.square_footage.$error ? 'is-invalid' : ''"
                />
                <div class="invalid-feedback" v-if="$v.square_footage.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="property_status">Have Parking ?</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="1"
                            v-model="have_parking"
                            :class="$v.have_parking.$error ? 'is-invalid' : ''"
                        />
                        <label class="form-check-label" for="inlineRadio1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="0"
                            v-model="have_parking"
                            :class="$v.have_parking.$error ? 'is-invalid' : ''"
                        />
                        <label class="form-check-label" for="inlineRadio2"
                            >No</label
                        >
                    </div>
                </div>
                <div class="invalid-feedback" v-if="$v.have_parking.$error">
                    This is required.
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";
import { requiredValidationFields } from "../../../../utils";

const initFormFields = {
    bedrooms: "",
    bathrooms: "",
    garage: "",
    square_footage: "",
    have_parking: 0,
};
export default {
    name: "FormStepThree",
    props: {
        propertyDetails: {
            default: {},
        },
        isEdit: {
            default: false,
        },
    },
    data() {
        return {
            cities: {},
            communities: {},
            bedroomsOptions: [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            bathroomsOptions: [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            garageOptions: [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            ...initFormFields,
        };
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key];
                }
            }
        },
        next() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    4,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
    },
    validations: {
        ...requiredValidationFields(initFormFields),
    },
};
</script>
