<template>
  <div class="breadcrumb_content style2 mb100">
    <h2 class="breadcrumb_title">IDX Listings Search Results</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/idx-listings/search-result">MLS Listings</a></li>
        <li class="breadcrumb-item active" aria-current="page">
        Idx Listings Search Result
        </li>
    </ol>
    </div>
</template>

<script>
export default {
    name: "IdxListingBanner"
}
</script>