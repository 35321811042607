<template>
    <div>
        <p class="step-label">EDIT PROPERTY - Step 5</p>
        <div class="form-split">
            <!-- Form Column 1 -->
            <div>
                <!-- <div class="form-group">
                    <label for="short_description">Short Description</label>
                    <ckeditor :editor="editor" v-model="short_description"></ckeditor>
                </div> -->
                <div class="form-group">
                    <label>Add elevation images</label>
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="coverImageDropzoneOptions"
                        id="coverImagesDropzone"
                        ref="coverImagesDropzoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handleCoverImagesUploadError"
                        @vdropzone-complete="uploadCoverImages"
                        @vdropzone-sending="addParamToCoverImage"
                    >
                        <div class="dropzone-custom-content">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    opacity="0.5"
                                    d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M12 15V2M12 2L15 5.5M12 2L9 5.5"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p class="mt-2">add files</p>
                        </div>
                    </vue-dropzone>
                </div>
                <div class="row">
                    <div
                        class="col-md-4 mb-2"
                        v-for="(item, index) in oldPropertyImages"
                        :key="'property-image-' + index"
                    >
                        <img
                            :src="item.thumbnail_path"
                            :alt="getFileNameByPath(item.image_path)"
                            class="img-responsive img-fluid"
                        />
                        <small>{{ getFileNameByPath(item.image_path) }}</small>
                        <!-- <button class="btn btn-sm btn-outline-danger" @click.prevent="deletePropertyUrl(item)"><i class="far fa-trash-alt"></i></button> -->
                    </div>
                </div>
            </div>

            <!-- Form Column 2 -->
            <div>
                <!-- <div class="form-group">
                    <label for="detailed_description">Detail Description</label>
                    <ckeditor :editor="editor" v-model="detailed_description" ></ckeditor>
                </div> -->
                <!-- <div class="form-group">
                    <label>Add pdf file</label>
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        id="coverImagesDropzoneRef"
                        ref="secondDropZoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handlePhotoUploadError"
                        @vdropzone-complete="updatePropertyImages"
                    >
                        <div class="dropzone-custom-content">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    opacity="0.5"
                                    d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M12 15V2M12 2L15 5.5M12 2L9 5.5"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p class="mt-2">add files</p>
                        </div>
                    </vue-dropzone>
                </div> -->
            </div>
        </div>
        <div class="form-button-group">
            <button class="save-btn save" @click="handleSave()">Save</button>
            <button class="reset-btn" @click="handleReset()">Reset</button>
            <button class="next-btn next" @click="handleSave()">Done</button>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import pick from "lodash/pick";
const initialFormFields = {
    short_description: "",
    detailed_description: "",
    propertyImages: [],
    property_id: "",
};

export default {
    name: "Step5",
    props: {
        propertyDetails: {},
        isSaving: false,
        propertyId: "",
    },
    data() {
        return {
            coverImageDropzoneOptions: {
                url: "/builders/upload-property-cover-image",
                paramName: "file[0]",
            },
            dropzoneOptions: {
                url: "/builders/upload-property-image",
            },
            oldPropertyImages: [],
            short_description: "",
            detailed_description: "",
            propertyImages: [],
            editor: ClassicEditor,
            builderTypeOptions: [
                {
                    label: "Sales",
                    value: "Sales",
                },
                {
                    label: "Broker",
                    value: "Broker",
                },
            ],
        };
    },
    mounted() {
        setTimeout(() => {
            this.separateImageLayouts();
        }, 1000);
    },
    methods: {
        handleSave() {
            const FormData = pick(this, Object.keys(initialFormFields));
            this.isSaving = true;
            this.updatePropertyFiles(
                this.propertyDetails?.id,
                this.propertyDetails?.images
            );
            axios
                .post(
                    "/property-management/update-property-details/" +
                        this.propertyDetails?.id,
                    FormData
                )
                .then((response) => {
                    this.isSaving = false;
                    console.log(response);
                })
                .catch((error) => {
                    console.error(error?.response?.data);
                })
                .finally((res) => {
                    // window.location.reload();
                    this.updateData();
                    this.$emit("skip");
                });
        },
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
        },
        setExistingFormData() {
            for (let key in initialFormFields) {
                this[key] = this.propertyDetails[key] || initialFormFields[key];
            }
            this.property_id = this.propertyDetails?.id;
        },
        updateData() {
            for (let key in initialFormFields) {
                this.propertyDetails[key] = this[key];
            }
        },
        uploadCoverImages(response) {
            if (response?.xhr?.status == 200) {
                this.layoutUploadError = false;
                if (response?.xhr?.response) {
                    let serverResponse = JSON.parse(response?.xhr?.response);
                    if (
                        serverResponse?.data?.thumbnail_path &&
                        serverResponse?.data?.image_path
                    ) {
                        this.propertyImages.push(
                            serverResponse.data.thumbnail_path
                        );
                        this.propertyImages.push(
                            serverResponse.data.image_path
                        );
                    }
                }
            }
        },
        handleCoverImagesUploadError(file, message, xhr) {
            this.photoUploadError = true;
            this.$refs.coverImagesDropzoneRef.removeFile(file);
        },
        updatePropertyImages(response) {
            if (response?.xhr?.status == 200) {
                this.layoutUploadError = false;
                if (response?.xhr?.response) {
                    let serverResponse = JSON.parse(response?.xhr?.response);
                    if (
                        serverResponse?.data?.thumbnail_path &&
                        serverResponse?.data?.image_path
                    ) {
                        this.propertyImages.push(
                            serverResponse.data.thumbnail_path
                        );
                        this.propertyImages.push(
                            serverResponse.data.image_path
                        );
                    }
                }
            }
        },
        handlePhotoUploadError(file, message, xhr) {
            this.photoUploadError = true;
            this.$refs.secondDropZoneRef.removeFile(file);
        },
        addParamToCoverImage(file, xhr, formData) {
            formData.append("property_id", this.property_id);
        },
        separateImageLayouts() {
            if (this.propertyDetails?.id) {
                this.property_id = this.propertyDetails.id;
            }
            if (this.propertyDetails?.property_images) {
                if (Array.isArray(this.propertyDetails.property_images)) {
                    let property_images = [
                        ...this.propertyDetails.property_images,
                    ];
                    property_images.forEach((el) => {
                        let thumbnail_path = "";
                        let image_path = "";
                        let file_url = el.file_url;
                        if (file_url.indexOf("/thumbnails") !== -1) {
                            image_path = file_url.replace("/thumbnails", "");
                            thumbnail_path = file_url;
                        }
                        if (el.file_type == "image") {
                            if (thumbnail_path && thumbnail_path) {
                                this.oldPropertyImages.push({
                                    thumbnail_path: thumbnail_path,
                                    image_path: thumbnail_path,
                                });
                            }
                        }
                        // } else if (el.file_type == "layout") {
                        //   if(thumbnail_path && thumbnail_path) {
                        //     this.oldPropertyLayouts.push({
                        //       thumbnail_path: thumbnail_path,
                        //       image_path: image_path
                        //     });
                        //   }
                        // }
                    });
                }
            }
        },
        getFileNameByPath(fullPath) {
            let filename = fullPath.replace(/^.*[\\\/]/, "");
            return filename;
        },
    },
    updatePropertyFiles(id, images) {
        const formData = new FormData();
        formData.append("images", JSON.stringify(images));
        formData.append("layouts", JSON.stringify([]));
        axios
            .post(`/builders/update-property-files/${id}`, formData)
            .then((res) => {
                window.location.href = "/property-management/all-properties";
            })
            .catch((error) => {
                let modal = this.$refs.errorInfo;
                if (error?.response?.data?.message) {
                    this.errorMessage = error?.response?.data?.message;
                    modal.open();
                    return;
                }
                if (error.message) {
                    this.errorMessage = error.message;
                    modal.open();
                }
            })
            .finally((res) => {
                this.isSaving = false;
            });
    },
};
</script>

<style>
.ck-editor__editable > p {
    height: 100px;
}
</style>
<style lang="scss">
.form-split {
    .form-group {
        .form-control {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }
        textarea.form-control {
            height: 10rem;
        }
        .input-group-col-2 select {
            width: 186px;
        }
    }
}
.form-button-group {
    .save-btn.save {
        background-color: #0061df !important;
        color: #fff !important;
    }
    .reset-btn {
        border: 0.5px solid #e8e8e8 !important;
        color: #5f6165 !important;
    }
    .next-btn.next {
        color: var(--clr-primary) !important;
        border: 0.5px solid var(--clr-primary) !important;
        color: #0061df !important;
        border: 0.5px solid #0061df !important;
    }
}
</style>
