require('./bootstrap');

import Vue from 'vue';

import Vuelidate from 'vuelidate';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import Notifications from 'vue-notification';
import vSelect from "vue-select";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Autocomplete from 'vuejs-auto-complete';
import VueSplide from '@splidejs/vue-splide';
import SweetModal from 'sweet-modal-vue/src/plugin.js';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import CKEditor from '@ckeditor/ckeditor5-vue2'; // CK Editor Plugin
import VueMask from "v-mask";
import Multiselect from 'vue-multiselect'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueMask);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(CKEditor); // CK Editor Plugin
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueSplide);
Vue.use(Vuelidate);
Vue.use(VueGoogleAutocomplete);
Vue.use(Notifications);
Vue.use(SweetModal);

// register globally
Vue.component('multiselect', Multiselect)

Vue.component('vue-google-autocomplete', VueGoogleAutocomplete); // package component
Vue.component('pagination', require('laravel-vue-pagination')); // Laravel Vue Pagination
Vue.component("v-select", vSelect); // vue-select plugin
Vue.component("vue-dropzone", vue2Dropzone); // vue-dropzone-2
Vue.component("vue-autocomplete", Autocomplete); // vue-autocomplete

// vue2Dropzone.autoDiscover = false; // auto dis

Vue.component("builders-info-form", require("./pages/BuildersInfoForm.vue").default);
Vue.component("add-property-page", require("./pages/Admin/AddPropertyPage.vue").default);
// Admin
Vue.component("admin-edit-property", require("./pages/Admin/AdminEditProperty.vue").default); // admin edit property
Vue.component("search-new-construction-component", require("./components/Builders/NewContructions/SearchNewConstructionContainer.vue").default);
Vue.component("search-idx-home-component", require("./components/IdxProperties/SearchHomeContainer.vue").default);
Vue.component("property-details", require("./components/IdxProperties/NewConstructionDetails/NewConstructionDetails.vue").default);
// Vue.component("property-details", require("./components/Builders/Properties/PropertyDetails.vue").default); // ! old new construction details page
// Vue.component("idx-property-details", require("./components/IdxProperties/IdxPropertyDetails.vue").default); // !old details page
Vue.component("idx-property-details", require("./components/IdxProperties/IdxPropertyDetailsNew/IdxPropertyDetails.vue").default);

Vue.component('value-your-property', require('./pages/LandingTwo.vue').default); // value your property component
Vue.component('sell-house-fast', require('./pages/LandingThree.vue').default); // sell house fast component

Vue.component("make-appointment-form", require("./components/Appointments/MakeAppointmentForm.vue").default);

// SEO management
Vue.component("add-seo", require("./pages/SEO/AddSEO.vue").default);
Vue.component("edit-seo", require("./pages/SEO/EditSEO.vue").default);
Vue.component("list-seo", require("./pages/SEO/ListSEO.vue").default);

Vue.component("idx-search-listing", require("./pages/IdxSearchListing.vue").default);
Vue.component("user-favourite-count", require("./components/UserFavouriteCount.vue").default);

Vue.component("idx-results", require("./pages/IdxResults.vue").default);
Vue.component("map-results", require("./pages/MapResults.vue").default);
Vue.component("sold-properties", require("./pages/SoldProperties.vue").default);
Vue.component("instant-valuation", require("./components/InstantValuation/InstantValuation.vue").default);
Vue.component("mls-sold-property-details", require("./components/IdxProperties/SoldPropertyDetails/SoldPropertyDetails.vue").default);

//Exclusive Listing 
Vue.component("add-exclusive-listing", require("./pages/Admin/AddExclusiveProperties.vue").default);
Vue.component("edit-exclusive-listing", require("./pages/Admin/EditExclusiveProperties.vue").default);
// Vue.component("exclusive-property-details", require("./pages/ExclusiveProperty/ExclsuivePropertyDetail.vue").default);
Vue.component("exclusive-property-details", require("./components/IdxProperties/ExclusivePropertyDetails/ExclusivePropertyDetails.vue").default);
Vue.component("exclusive-properties-results", require("./pages/ExclusiveProperty/ExclusivePropertyResult.vue").default);

Vue.component("list-with-multi-step-presentation", require("./pages/Presentation/MultiStepPresentation.vue").default);
Vue.component("sell-to-multi-step-presentation", require("./pages/Presentation/MultiStepPresentationSellTo.vue").default);
Vue.component("presentation-get-cash-offer", require("./pages/Presentation/PresentationGetCashoffer.vue").default);
Vue.component("home-properties-tabs", require("./components/HomePropertiesTabs/HomePropertiesTabs.vue").default);
Vue.component("new-buy-page", require("./pages/BuyNewPage/BuyNewPage.vue").default);

Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyABlrsdtJlypejWdegbPBnSPwYvInJUGT0',
      libraries: 'places',
      //// If you want to set the version, you can do so:
      v: '3.26',
    },
    
    installComponents: true,
  })
  Vue.component('gmap-cluster', GmapCluster);
  
const app = new Vue({
    el: "#app"
});