<template>
    <div class="page-container">
        <ul class="form-list pl-0">
            <li :class="{ active: Form === 'parent' }">
                <button @click="handleFormFormat()">Parent</button>
            </li>
            <li class="divider"></li>
            <li :class="{ active: Form === 'child' }">
                <button @click="handleFormFormat()">Child</button>
            </li>
        </ul>
        <div class="parent-form" v-if="Form === 'parent'">
            <ul class="tab-list pl-0">
                <li :class="{ active: currentForm === 1 }">
                    <button @click="handleTabChange(1)">
                        Step 1 (Basic Info)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 2 }">
                    <button
                        @click="handleTabChange(2)"
                        :disabled="!propertyDetails.building_status"
                    >
                        Step 2 (Property Details)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 3 }">
                    <button @click="handleTabChange(3)" :disabled="!propertyId">
                        Step 3 (Additional Info)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 4 }">
                    <button @click="handleTabChange(4)" :disabled="!propertyId">
                        Step 4 (Advanced Info)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 5 }">
                    <button @click="handleTabChange(5)" :disabled="!propertyId">
                        Step 5 (Image & Docs)
                    </button>
                </li>
            </ul>
            <div class="form-container">
                <step-1
                    :cityList="cityList"
                    v-if="currentForm == 1"
                    :propertyDetails="propertyDetails"
                    @save="handleSave"
                    @skip="handleSkip"
                    :isSaving="isSaving"
                />
                <step-2
                    v-if="currentForm == 2"
                    :propertyDetails="propertyDetails"
                    @save="handleSave"
                    @skip="handleSkip"
                    :isSaving="isSaving"
                />
                <step-3
                    v-if="currentForm == 3"
                    :propertyDetails="propertyDetails"
                    @save="handleSave"
                    @skip="handleSkip"
                    :isSaving="isSaving"
                />
                <step-4
                    v-if="currentForm == 4"
                    :propertyDetails="propertyDetails"
                    @save="handleSave"
                    @skip="handleSkip"
                    :isSaving="isSaving"
                />
                <step-5
                    v-if="currentForm == 5"
                    :propertyDetails="propertyDetails"
                    @save="handleSave"
                    @skip="handleSkip"
                    :isSaving="isSaving"
                    :propertyId="propertyId"
                />
            </div>
        </div>
        <div class="child-form" v-if="Form === 'child'">
            <div class="form-container">
                <child-form
                    :isSaving="isSaving"
                    :ParentPropertyData="builder_formatted_data"
                />
            </div>
        </div>
        <!-- Alert Success -->
        <!-- <sweet-modal icon="success" ref="successInfo">{{ successMessage }}</sweet-modal> -->
        <!-- ./Alert Success -->
        <sweet-modal icon="error" ref="errorInfo">{{
            errorMessage
        }}</sweet-modal>
    </div>
</template>

<script>
import Step1 from "../../components/Admin/AddProperty/Step1.vue";
import Step2 from "../../components/Admin/AddProperty/Step2.vue";
import Step3 from "../../components/Admin/AddProperty/Step3.vue";
import Step4 from "../../components/Admin/AddProperty/Step4.vue";
import Step5 from "../../components/Admin/AddProperty/Step5.vue";
import ChildForm from "../../components/Admin/AddProperty/ChildForm.vue";
export default {
    name: "AddPropertyPage",
    props: {
        cities: {
            default: "",
        },
        builder_formatted_data: {
            default: "",
        },
        user_type: {
            default: "admin",
        },
    },
    data() {
        return {
            Form: "parent",
            currentForm: 1,
            cityList: [],
            propertyDetails: {},
            propertyId: "",
            errorMessage: "",
            isSaving: false,
            completedSteps: [],
        };
    },
    created() {},
    mounted() {
        this.cityList = JSON.parse(this.cities);
    },
    components: {
        "step-1": Step1,
        "step-2": Step2,
        "step-3": Step3,
        "step-4": Step4,
        "step-5": Step5,
        "child-form": ChildForm,
    },
    methods: {
        handleFormFormat() {
            if (this.Form === "parent") {
                this.Form = "child";
            } else {
                this.Form = "parent";
            }
        },

        handleTabChange(step) {
            this.currentForm = step;
        },
        handleSave(data) {
            this.propertyDetails = { ...this.propertyDetails, ...data };
            if (this.propertyId) {
                this.updateProperty();
                return;
            }
            // add
            if (this.currentForm === 2 && !this.propertyId) {
                this.createProperty();
            } else {
                this.currentForm += 1;
            }
        },
        handleSkip() {
            this.currentForm += 1;
        },
        createProperty() {
            this.isSaving = true;
            const formData = new FormData();
            for (let key in this.propertyDetails) {
                const value = this.propertyDetails[key];
                if (key === "amnities") {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(`amnities[${i}]`, value[i]);
                    }
                } else {
                    formData.append(key, value);
                }
            }
            axios
                .post("/builders/submit-builder-property", formData)
                .then((response) => {
                    if (response?.data?.data?.id) {
                        this.currentForm += 1;
                        this.propertyId = response?.data?.data?.id;
                    }
                })
                .catch((error) => {
                    let modal = this.$refs.errorInfo;
                    if (error?.response?.data?.message) {
                        this.errorMessage = error?.response?.data?.message;
                        modal.open();
                        return;
                    }
                    if (error.message) {
                        this.errorMessage = error.message;
                        modal.open();
                    }
                })
                .finally((res) => {
                    this.isSaving = false;
                });
        },
        updateProperty() {
            this.isSaving = true;
            const formData = new FormData();
            for (let key in this.propertyDetails) {
                const value = this.propertyDetails[key];
                if (key === "amnities") {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(`amnities[${i}]`, value[i]);
                    }
                } else {
                    formData.append(key, value);
                }
            }
            axios
                .post(
                    "/property-management/update-property-details/" +
                        this.propertyId,
                    formData
                )
                .then((response) => {
                    this.isSaving = false;
                    if (this.currentForm === 5) {
                        if (this.propertyDetails?.images?.length) {
                            this.updatePropertyFiles(
                                this.propertyId,
                                this.propertyDetails?.images
                            );
                        } else {
                            window.location.href =
                                "/property-management/all-properties";
                        }
                    } else {
                        this.currentForm += 1;
                    }
                })
                .catch((error) => {
                    this.isSaving = false;
                    let modal = this.$refs.errorInfo;
                    if (error?.response?.data?.message) {
                        this.errorMessage = error?.response?.data?.message;
                        modal.open();
                        return;
                    }
                    if (error.message) {
                        this.errorMessage = error.message;
                        modal.open();
                    }
                })
                .finally((res) => {
                    this.isSaving = false;
                });
        },
        updatePropertyFiles(id, images) {
            const formData = new FormData();
            formData.append("images", JSON.stringify(images));
            formData.append("layouts", JSON.stringify([]));
            axios
                .post(`/builders/update-property-files/${id}`, formData)
                .then((res) => {
                    window.location.href =
                        "/property-management/all-properties";
                })
                .catch((error) => {
                    let modal = this.$refs.errorInfo;
                    if (error?.response?.data?.message) {
                        this.errorMessage = error?.response?.data?.message;
                        modal.open();
                        return;
                    }
                    if (error.message) {
                        this.errorMessage = error.message;
                        modal.open();
                    }
                })
                .finally((res) => {
                    this.isSaving = false;
                });
        },
    },
};
</script>
<style lang="scss">
.step-label {
    font-size: 14px;
    margin-bottom: 30px;
    color: #000;
}

.spinner-border-sm {
    border-width: 0.2em;
}

.form-container {
    .form-split {
        display: flex;
        justify-content: space-between;
        gap: 60px;
    }

    .form-split > * {
        flex: 1;
        max-width: 460px;
    }

    .form-button-group {
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: flex-end;

        button,
        a {
            border: none;
            outline: none;
            min-height: 31px;
            min-width: 50px;
            padding: 7px 8px;
            border-radius: 6px;
            background-color: transparent;

            &.save-btn {
                background-color: var(--clr-primary);
                color: #fff;
            }

            &.reset-btn {
                border: 0.5px solid #e8e8e8;
                color: #5f6165;
            }

            &.next-btn {
                color: var(--clr-primary);
                border: 0.5px solid var(--clr-primary);
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .input-group-col-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;

        input,
        select {
            width: 186px;
        }

        .form-group {
            margin-bottom: 0px;
        }
    }

    .form-check-label {
        color: #5f6165;
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 24px;

        & > label {
            color: #5f6165;
            font-weight: 500;

            span.required {
                color: #f00;
            }
        }

        &.radio-group > label {
            margin-bottom: 18px;
        }
    }
}
</style>
<style scoped lang="scss">
.page-container {
    background-color: #fff;
    margin: 0 50px;
    max-width: 1320px;
    margin-bottom: 70px;
}

.form-list {
    display: flex;
    gap: 8px;
    list-style: none;
    margin-bottom: 20px;
    align-items: center;

    button {
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--clr-primary);
        border-radius: 4px;
        padding: 10px;
        width: 175px;
        text-wrap: nowrap;
        font-size: 14px;
    }

    .active button {
        background: var(--clr-primary);
        color: #f4f4f4;
    }

    .divider {
        border-right: 2px solid #d9d9d9;
        height: 32px;
    }
}
.parent-form {
    .tab-list {
        display: flex;
        gap: 8px;
        list-style: none;
        margin-bottom: 50px;
        align-items: center;

        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: var(--clr-primary);
            border-radius: 4px;
            padding: 10px;
            width: 175px;
            text-wrap: nowrap;
            font-size: 14px;
        }

        .active button {
            background: #f4f4f4;
            color: #5f6165;
        }

        .divider {
            border-right: 2px solid #d9d9d9;
            height: 32px;
        }
    }
}
</style>
