<template>
    <div class="exclusive-properties">
        <form-step-one
            v-if="currentStep === 1"
            :currentStep="currentStep"
            :communityList="communityList"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
            :cityList="cityList"
        />
        <form-step-two
            v-if="currentStep === 2"
            :currentStep="currentStep"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
            :communityList="communityList"
        />
        <form-step-three
            v-if="currentStep === 3"
            :currentStep="currentStep"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
        />

        <form-step-five
            v-if="currentStep === 4"
            :currentStep="currentStep"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
            :cityList="cityList"
        />
        <form-step-six
            v-if="currentStep === 5"
            :currentStep="currentStep"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
            :cityList="cityList"
        />
        <form-step-seven
            v-if="currentStep === 6"
            :currentStep="currentStep"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
            :cityList="cityList"
        />
        <form-step-eight
            v-if="currentStep === 7"
            :currentStep="currentStep"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
            :cityList="cityList"
        />
        <!-- <form-step-nine
            v-if="currentStep === 8"
            :currentStep="currentStep"
            :propertyDetails="formData"
:isEdit="isEdit"
            @persistAndNext="persistAndNext"
            @back="back"
            :cityList="cityList"
        /> -->
        <form-step-four
            v-if="currentStep === 8"
            :currentStep="currentStep"
            :propertyDetails="formData"
            :isEdit="isEdit"
            @back="back"
            @persistAndNext="persistAndNext"
            @finalSubmit="finalSubmit"
            :base_url="base_url"
        />
    </div>
</template>

<script>
import axios from "axios";
import FormStepOne from "../../components/Admin/ExclusiveProperties/forms/FormStepOne.vue";
import FormStepTwo from "../../components/Admin/ExclusiveProperties/forms/FormStepTwo.vue";
import FormStepThree from "../../components/Admin/ExclusiveProperties/forms/FormStepThree.vue";
import FormStepFour from "../../components/Admin/ExclusiveProperties/forms/FormStepFour.vue";
import FormStepFive from "../../components/Admin/ExclusiveProperties/forms/FormStepFive.vue";
import FormStepSix from "../../components/Admin/ExclusiveProperties/forms/FormStepSix.vue";
import FormStepSeven from "../../components/Admin/ExclusiveProperties/forms/FormStepSeven.vue";
import FormStepEight from "../../components/Admin/ExclusiveProperties/forms/FormStepEight.vue";
import FormStepNine from "../../components/Admin/ExclusiveProperties/forms/FormStepNine.vue";
import Swal from "sweetalert2";

export default {
    name: "EditExclusiveProperties",
    props: {
        all_cities: {
            default: [],
        },
        all_communities: {
            default: [],
        },
        all_properties_detail: {
            default: {},
        },
        base_url: {
            default: "",
        },
        updated_by: {
            default: "",
        },
    },
    data() {
        return {
            cities: [],
            communities: [],
            currentStep: 5,
            formData: {},
            propertyDetails: {},
            isEdit: true,
        };
    },
    components: {
        "form-step-one": FormStepOne,
        "form-step-two": FormStepTwo,
        "form-step-three": FormStepThree,
        "form-step-four": FormStepFour,
        "form-step-five": FormStepFive,
        "form-step-six": FormStepSix,
        "form-step-seven": FormStepSeven,
        "form-step-eight": FormStepEight,
        "form-step-nine": FormStepNine,
    },
    created() {
        // this.formData = {
        //     id: 1,
        //     unique_code: "RB-1669985651",
        //     type: "admin",
        //     property_name: "Property Title:",
        //     property_class: "CondoProperty",
        //     address: "1867 Valley Farm Rd, Pickering, ON L1V 3Y7, Canada",
        //     street_address: "1867 Valley Farm Road",
        //     share_left: null,
        //     how_many_share: null,
        //     city: "Pickering",
        //     community: "Bramalea West Industrial",
        //     state: null,
        //     zip_code: "L1V 3Y7",
        //     area: "Pickering",
        //     county: "Ontario",
        //     latitude: "43.83955000",
        //     longitude: "-79.08002990",
        //     property_type: "Detached",
        //     sale_or_lease: "Sale",
        //     list_price: 640,
        //     style: "Bungalow",
        //     bedrooms: 3,
        //     bathrooms: 5,
        //     garage: 2,
        //     square_footage: "1000-50000",
        //     amenities: null,
        //     short_description: "<p>No information available.<\/p>",
        //     detailed_description: "<p>No information available.<\/p>",
        //     builder_type: null,
        //     sqft_area: null,
        //     have_parking: 1,
        //     is_active: 1,
        //     is_enabled: 1,
        //     actual_city: null,
        //     property_description:
        //         "<p>No information available property description.<\/p>",
        //     community_description: null,
        //     virtual_tour_url: "Test",
        //     basement_one: null,
        //     basement_two: null,
        //     kitchens: null,
        //     display_address_on_internet: null,
        //     directions_or_cross_street: null,
        //     approx_age: null,
        //     leased_terms: null,
        //     tax_year: null,
        //     seller_property_info_statement: null,
        //     total_parking_spaces: null,
        //     parking_included: null,
        //     parking_charges: null,
        //     garage_type: null,
        //     additional_monthly_fees: null,
        //     all_inclusive_charges: null,
        //     extras: null,
        //     link: null,
        //     additional_bedrooms: null,
        //     rooms: null,
        //     rooms_plus: null,
        //     den_family_room: null,
        //     ac: null,
        //     fire_place: null,
        //     heating: null,
        //     water_supply: null,
        //     drive_way: null,
        //     taxes: null,
        //     electricity: null,
        //     gas: null,
        //     cable: null,
        //     front_age: null,
        //     depth: null,
        //     acres: null,
        //     irreg: null,
        //     pool: null,
        //     sewer: null,
        //     zoning: null,
        //     parcel_id: null,
        //     constr_out: null,
        //     fronting_on: null,
        //     created_by: null,
        //     updated_by: null,
        //     created_at: "2022-12-02T12:54:11.000000Z",
        //     updated_at: "2022-12-02T12:54:11.000000Z",
        //     photos: [
        //         {
        //             id: 1,
        //             property_id: 1,
        //             url: "\/storage\/uploads\/exclusive_properties_1669985651_image-N5772903-1.jpg.jpg",
        //             created_at: "2022-12-02T12:54:11.000000Z",
        //             updated_at: "2022-12-02T12:54:11.000000Z",
        //         },
        //         {
        //             id: 2,
        //             property_id: 1,
        //             url: "\/storage\/uploads\/exclusive_properties_1669985651_image-W5792661-1.jpg.jpg",
        //             created_at: "2022-12-02T12:54:11.000000Z",
        //             updated_at: "2022-12-02T12:54:11.000000Z",
        //         },
        //     ],
        // };
        // this.formData.zipcode = this.formData.zip_code;
        // this.formData.property_status = this.formData.sale_or_lease;
        if (this.all_cities) {
            this.cities = JSON.parse(this.all_cities);
        }
        if (this.all_communities) {
            this.communities = JSON.parse(this.all_communities);
        }
        if (this.all_properties_detail) {
            this.propertyDetails = JSON.parse(this.all_properties_detail);
            this.formData = this.propertyDetails;
            this.formData.zipcode = this.formData.zip_code;
            this.formData.property_status = this.formData.sale_or_lease;
        }
    },
    computed: {
        cityList() {
            return this.cities.map((el) => ({ id: el.city, name: el.city }));
        },
        communityList() {
            return this.communities.map((el) => ({
                id: el.community,
                name: el.community,
            }));
        },
    },
    methods: {
        nextStep(step) {
            this.currentStep = step;
        },
        back() {
            this.currentStep = this.currentStep - 1;
        },
        persistAndNext(nextStep, data) {
            this.nextStep(nextStep);
            this.formData = { ...this.formData, ...data };
        },
        finalSubmit(data) {
            this.formData = { ...this.formData, ...data };
            console.log("Final Submit", this.formData);
            const formData = new FormData();
            // formData.append("updated_by", this.updated_by);
            
            for (let [key, value] of Object.entries(this.formData)) {
                if (key === "attachments") {
                    let fileCount = 0;
                    for (let file of value) {
                        formData.append(`attachments[${fileCount}]`, file);
                        fileCount++;
                    }
                } else if (key === "amenities") {
                    let itemCount = 0;
                    for (let item of value) {
                        formData.append(`amenities[${itemCount}]`, item);
                        itemCount++;
                    }
                } 
                else if(key === 'updated_by') {
                    formData.append('updated_by', this.updated_by)
                }
                else
                    formData.append(
                        key,
                        (!value && value !== 0) || value === "null" ? "" : value
                    );
            }
            axios
                .post("/api/update-exclusive-properties", formData)
                .then((response) => {
                    this.currentStep = 1;
                    // this.formData = {};
                    Swal.fire({
                        title: response.data.message,
                        icon: "success",
                        timer: 3000,
                    });
                })
                .catch((error) => {
                    // console.error(error?.response?.data);
                    const restObj = {};
                    if (error?.response?.data?.errors) {
                        restObj.html = "";
                        Object.values(error?.response?.data?.errors)?.forEach(
                            (err) => {
                                restObj.html += `<p style="color:red">${err[0]}</p>`;
                            }
                        );
                    }
                    if (error?.response?.data?.message) {
                        Swal.fire({
                            title: error?.response.data.message,
                            icon: "error",
                            timer: 3000,
                            ...restObj,
                        });
                    }
                })
                .finally((res) => {});
        },
    },
};
</script>
<style>
.exclusive-properties {
    max-width: min(600px, 100%);
    margin: 0 auto;
}
</style>
