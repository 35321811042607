<template>
  <div
    class="card floating-value-form__redbuyers"
    style="width:28rem;border-bottom-left-radius:30px;border-bottom-right-radius:30px;position:relative;flex:inherit;margin:auto;"
  >
    <div class="row text-center mt-4" id="heading">
      <template v-if="step == 1">
        <div class="col-lg-6 col-md-6 col-sm-6 w-100 p-0">
          <div class="card-title p-2" id="cardTitle">Enter Your Details Here</div>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-6 col-md-6 col-sm-6 w-100 p-0">
          <div class="card-title" style="background:#fff"></div>
        </div>
      </template>
      <template v-if="step == 2">
        <div class="col-lg-6 col-md-6 col-sm-6 w-100 p-0">
          <div class="card-title p-2" id="cardTitle">Enter Your Details Here</div>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-6 col-md-6 col-sm-6 w-100 p-0">
          <div class="card-title p-2" style="background:#fff;margin:0;padding:0!important;"></div>
        </div>
      </template>
    </div>
    <div class="card-body" style="padding-bottom: 0px;">
      <form>
        <template v-if="step == 1">
          <div class="form-group text-left">
            <input type="hidden" v-model="address" />
            <vue-google-autocomplete
              id="map"
              ref="mapElement"
              class="form-control"
              :class="addressError ? 'is-invalid' : ''"
              placeholder="Home address"
              :country="['ca']"
              @placechanged="getAddressData"
            ></vue-google-autocomplete>
            <small class="invalid-feedback" role="alert" v-if="addressError">
              <strong>{{ addressErrorMessage }}</strong>
            </small>
          </div>
          <div class="form-group text-left">
            <input
              type="text"
              placeholder="Full name"
              class="form-control"
              :class="nameError ? 'is-invalid' : ''"
              id="fullName"
              v-model="name"
            />
            <small class="invalid-feedback" role="alert" v-if="nameError">
              <strong>{{ nameErrorMessage }}</strong>
            </small>
          </div>
          <div class="form-group text-left">
            <input
              type="text"
              placeholder="Phone number"
              class="form-control"
              :class="phoneError ? 'is-invalid' : ''"
              id="phoneNumber"
              v-model="phone"
            />
            <small class="invalid-feedback" role="alert" v-if="phoneError">
              <strong>{{ phoneErrorMessage }}</strong>
            </small>
          </div>
          <div class="form-group text-left" style="padding-bottom: 20px;">
            <input
              type="email"
              placeholder="Email"
              class="form-control"
              :class="emailError ? 'is-invalid' : ''"
              id="email"
              v-model="email"
            />
            <small class="invalid-feedback" role="alert" v-if="emailError">
              <strong>{{ emailErrorMessage }}</strong>
            </small>
          </div>
        </template>
        <template v-else>
          <div class="form-group text-left">
            <select
              name="building_type"
              class="form-control"
              id="building_type"
              v-model="buildingType"
              :class="buildingTypeError ? 'is-invalid' : ''"
            >
              <option :value="null">Building Type</option>
              <option
                :value="buildingitem.value"
                v-for="(buildingitem, buildingindex) in localBuildingOptions"
                :key="'building-'+buildingindex"
              >{{ buildingitem.label }}</option>
            </select>
            <small class="invalid-feedback" role="alert" v-if="buildingTypeError">
              <strong>{{ buildingTypeErrorMessage }}</strong>
            </small>
          </div>
          <div class="form-group text-left">
            <select
              name="no_of_bedrooms"
              class="form-control"
              id="no_of_bedrooms"
              v-model="beds"
              :class="bedsError ? 'is-invalid' : ''"
            >
              <option :value="null">Bed</option>
              <option
                :value="beditem"
                v-for="(beditem, bedindex) in localBedOptions"
                :key="'bed-'+bedindex"
              >{{ beditem }}</option>
            </select>
            <small class="invalid-feedback" role="alert" v-if="bedsError">
              <strong>{{ bedsErrorMessage }}</strong>
            </small>
          </div>
          <div class="form-group text-left">
            <select
              name="no_of_bathrooms"
              class="form-control"
              :class="bathError ? 'is-invalid' : ''"
              id="no_of_bathrooms"
              v-model="bath"
            >
              <option :value="null">Bath</option>
              <option
                :value="bathitem"
                v-for="(bathitem, bathindex) in localBathOptions"
                :key="'bath-'+bathindex"
              >{{ bathitem }}</option>
            </select>
            <small class="invalid-feedback" role="alert" v-if="bathError">
              <strong>{{ bathErrorMessage }}</strong>
            </small>
          </div>
          <div class="form-group text-left" style="padding-bottom: 20px;">
            <select
              name="parking_space"
              class="form-control"
              id="parking_space"
              :class="parkingSpaceError ? 'is-invalid' : ''"
              v-model="parkingSpace"
            >
              <option :value="null">Parking Space</option>
              <option
                :value="parkingitem"
                v-for="(parkingitem, parkingindex) in localParkingOptions"
                :key="'parking-'+parkingindex"
              >{{ parkingitem }}</option>
            </select>
            <small class="invalid-feedback" role="alert" v-if="parkingSpaceError">
              <strong>{{ parkingSpaceErrorMessage }}</strong>
            </small>
          </div>
        </template>
        <!-- Honeypot -->
        <input 
          type="text" 
          name="contact_me_by_fax_only" 
          v-model="botInput"
          class="contact-me-by-fax-only"
          tabindex="-1" 
          autocomplete="off"
        >
        <!-- ./Honeypot -->
        <template v-if="submittingForm">
          <button
            type="button"
            @click.prevent=""
            style="opacity:0.6;cursor:not-allowed;"
            :disabled="true"
            id="submitBtn"
            class="btn btn-primary"
          >{{ buttonText }}</button>
        </template>
        <template v-else>
          <button
            type="button"
            @click.prevent="goToNext"
            id="submitBtn"
            class="btn btn-primary"
          >{{ buttonText }}</button>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
const canadaPhone = value => {
  let regx = /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return regx.test(value);
};
import Vue from 'vue';
// import { VueReCaptcha } from "vue-recaptcha-v3";
// Vue.use(VueReCaptcha, { siteKey: process.env.MIX_GOOGLE_RECAPTCHA_KEY});
export default {
  name: "LandingTwoBannerForm",
  props: {
    buildingoption: {
      default: ""
    },
    bedoption: {
      default: ""
    },
    bathoption: {
      default: ""
    },
    parkingoption: {
      default: ""
    },
    utm_source: {
      default: ""
    },
    utm_medium: {
      default: ""
    },
    utm_campaign: {
      default: ""
    },
    utm_id: {
      default: ""
    },
    utm_content: {
      default: ""
    }
  },
  data() {
    return {
      step: 1,
      address: "",
      name: "",
      phone: "",
      email: "",
      buildingType: null,
      beds: null,
      bath: null,
      parkingSpace: null,
      responseTitle: "",
      responseMessage: "Success",
      responseClass: "success",
      botInput: "",
      submittingForm: false
    };
  },
  validations: {
    address: {
      required
    },
    name: {
      required
    },
    phone: {
      required,
      canadaPhone
    },
    email: {
      required,
      email
    },
    buildingType: {
      required
    },
    beds: {
      required
    },
    bath: {
      required
    },
    parkingSpace: {
      required
    }
  },
  methods: {
    goToNext() {
      this.checkFormOneValidity();
      if (this.formOneValid) {
        if (this.step == 1) {
          this.step = 2;
        } else {
          this.checkFormOneValidity();
          this.checkFormTwoValidity();
          if (this.formOneValid) {
            if (this.formTwoValid) {
              // bot detection
              if(this.bot) {
                this.resetForm();
              } else {
                // call api in this condition
                const formData = new FormData();
  
                formData.append("address", this.address);
                formData.append("contact_name", this.name);
                formData.append("contact_email", this.email);
                formData.append("contact_phone", this.phone);
  
                formData.append("building_type", this.buildingType);
                formData.append("beds", this.beds);
                formData.append("bathroom", this.bath);
                formData.append("parking_space", this.parkingSpace);
                formData.append("utm_source", this.utm_source);
                formData.append("utm_medium", this.utm_medium);
                formData.append("utm_campaign", this.utm_campaign);
                formData.append("utm_id", this.utm_id);
                formData.append("utm_content", this.utm_content);
                this.submittingForm = true;
                // this.$recaptcha("login").then((token) => {
                  // formData.append("g-recaptcha-response", token);
                  // let utmSource = this.getParameterByName('utm_source');
                  // if(utmSource) {
                  //   formData.append("utm_source", utmSource);
                  // }
                  axios
                    .post("/submit-seller-leads", formData)
                    .then(response => {
                      this.resetForm();
                      let responseMessage = "Details added successfully!";
                      if (response?.data?.data?.message) {
                        responseMessage = response.data.data.message;
                      }
                      this.responseMessage = responseMessage;
                      let responseStatus = response?.data?.data?.status;
                      if (responseStatus == "danger") {
                        this.responseClass = "error";
                        this.responseTitle = "Error";
                      }
                      this.$notify({
                        group: 'foo',
                        type: this.responseClass,
                        title: this.responseTitle,
                        text: this.responseMessage,
                        duration: 6000,
                        speed: 1000
                      });
                      this.submittingForm = false;
                      if (responseStatus == "success") {
                        window.location.href = "/thanks-for-providing-your-home-details";
                      }
                    })
                    .catch(error => {
                      this.resetForm();
                      this.responseTitle = "Error";
                      this.responseClass = "error";
                      if (error?.response?.data?.message) {
                        this.responseMessage = error.response.data.message;
                      } else {
                        this.responseMessage = "Error occured during valuation.";
                      }
                      this.$notify({
                        group: 'foo',
                        type: this.responseClass,
                        title: this.responseTitle,
                        text: this.responseMessage,
                        duration: 6000,
                        speed: 1000
                      });
                      this.submittingForm = false;
                    });
                // }).catch(err => {
                //   console.error(err?.response);
                //   this.resetForm();
                //   this.submittingForm = false;
                // });
              }
            } else {
              this.checkFormOneValidity();
              if (this.formOneValid) {
                this.step = 2;
              } else {
                this.step = 1;
              }
            }
          } else {
            this.step = 1;
          }
        }
      }
    },
    getAddressData(addressData, placeResultData, id) {
      if (placeResultData?.formatted_address) {
        this.address = placeResultData.formatted_address;
      }
    },
    checkFormOneValidity() {
      this.$v.address.$touch();
      this.$v.name.$touch();
      this.$v.email.$touch();
      this.$v.phone.$touch();
    },
    checkFormTwoValidity() {
      this.$v.buildingType.$touch();
      this.$v.beds.$touch();
      this.$v.bath.$touch();
      this.$v.parkingSpace.$touch();
    },
    resetForm() {
      this.address = "";
      this.name = "";
      this.email = "";
      this.phone = "";
      this.buildingType = null;
      this.beds = null;
      this.bath = null;
      this.parkingSpace = null;
      this.$v.$reset();
      this.step = 1;
    },
    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  },
  computed: {
    buttonText() {
      if (this.step == 1) {
        return "Next";
      } else {
        if(this.submittingForm) {
          return 'Processing...'
        } else {
          return "Get My Instant Offer";
        }
      }
    },
    localBuildingOptions() {
      if (this.buildingoption) {
        return JSON.parse(this.buildingoption);
      } else {
        return [];
      }
    },
    localBedOptions() {
      if (this.bedoption) {
        return JSON.parse(this.bedoption);
      } else {
        return [];
      }
    },
    localBathOptions() {
      if (this.bathoption) {
        return JSON.parse(this.bathoption);
      } else {
        return [];
      }
    },
    localParkingOptions() {
      if (this.parkingoption) {
        return JSON.parse(this.parkingoption);
      } else {
        return [];
      }
    },
    addressError() {
      if (this.$v.address.$error) {
        return true;
      } else {
        return false;
      }
    },
    addressErrorMessage() {
      if (this.$v.address.$error) {
        return "Home address is required";
      } else {
        return "";
      }
    },
    nameError() {
      if (this.$v.name.$error) {
        return true;
      } else {
        return false;
      }
    },
    nameErrorMessage() {
      if (this.$v.name.$error) {
        return "Full name is required";
      } else {
        return "";
      }
    },
    emailError() {
      if (this.$v.email.$error) {
        return true;
      } else {
        return false;
      }
    },
    emailErrorMessage() {
      if (this.$v.email.$error) {
        if (!this.$v.email.required) {
          return "Email is required";
        } else if (!this.$v.email.email) {
          return "Email must be a valid email address";
        } else {
          return "Email is not valid";
        }
      } else {
        return "";
      }
    },
    phoneError() {
      if (this.$v.phone.$error) {
        return true;
      } else {
        return false;
      }
    },
    phoneErrorMessage() {
      if (this.$v.phone.$error) {
        if (!this.$v.phone.required) {
          return "Phone number is required";
        } else if (!this.$v.phone.canadaPhone) {
          return "Phone number be a valid number";
        } else {
          return "Phone number is not valid";
        }
      } else {
        return "";
      }
    },
    buildingTypeError() {
      if (this.$v.buildingType.$error) {
        return true;
      } else {
        return false;
      }
    },
    buildingTypeErrorMessage() {
      if (this.$v.buildingType.$error) {
        return "Building type is requried";
      } else {
        return "";
      }
    },
    bedsError() {
      if (this.$v.beds.$error) {
        return true;
      } else {
        return false;
      }
    },
    bedsErrorMessage() {
      if (this.$v.beds.$error) {
        return "Beds are requried";
      } else {
        return "";
      }
    },
    bathError() {
      if (this.$v.bath.$error) {
        return true;
      } else {
        return false;
      }
    },
    bathErrorMessage() {
      if (this.$v.bath.$error) {
        return "Bath is requried";
      } else {
        return "";
      }
    },
    parkingSpaceError() {
      if (this.$v.parkingSpace.$error) {
        return true;
      } else {
        return false;
      }
    },
    parkingSpaceErrorMessage() {
      if (this.$v.parkingSpace.$error) {
        return "Parking space is requried";
      } else {
        return "";
      }
    },
    formOneValid() {
      if (
        this.addressError ||
        this.nameError ||
        this.emailError ||
        this.phoneError
      ) {
        return false;
      } else {
        return true;
      }
    },
    formTwoValid() {
      if (
        this.buildingTypeError ||
        this.bedsError ||
        this.bathError ||
        this.parkingSpaceError
      ) {
        return false;
      } else {
        return true;
      }
    },
    bot() {
      if(this.botInput) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.form-control:active,
.form-control:focus {
  box-shadow: none;
  border-color: #991313;
  caret-color: #bd1616;
  font-family: "Roboto", sans-serif;
}
#submitBtn.btn.btn-primary,
#submitBtn.btn.btn-primary:active,
#submitBtn.btn.btn-primary:focus,
#submitBtn.btn.btn-primary:hover {
  box-shadow: none;
  font-family: "Roboto", sans-serif;
}
.form-control::placeholder {
  color: #991313;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
}
select.form-control option:checked {
  color: #991313;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
}
.contact-me-by-fax-only {
  display: none !important;
}
</style>
