<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step 1
            </h5>
        </div>
        <div class="card-body">
            <div class="form-group">
                <div class="form-row">
                    <label
                        class="col property_listing_type_label btn btn-outline mr-2"
                        :class="
                            property_listing_type === 'Exclusive Property'
                                ? 'bg-primary'
                                : 'bg-transparent'
                        "
                    >
                        <input
                            type="radio"
                            name="property_listing_type"
                            v-model="property_listing_type"
                            value="Exclusive Property"
                        />
                        Exclusive Property
                    </label>
                    <label
                        class="col property_listing_type_label btn btn-outline"
                        :class="
                            property_listing_type === 'Premium Property'
                                ? 'bg-primary'
                                : 'bg-transparent'
                        "
                    >
                        <input
                            type="radio"
                            name="property_listing_type"
                            v-model="property_listing_type"
                            value="Premium Property"
                        />
                        Premium Property
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label for="address">Full Address</label>
                <input
                    class="form-control builders-form__input"
                    id="address"
                    placeholder="Enter property name"
                    v-model="address"
                    type="hidden"
                />
                <input type="hidden" v-model="address" />
                <!-- <vue-google-autocomplete
                    id="address"
                    ref="mapElement"
                    class="form-control builders-form__input"
                    placeholder="Address Test"
                    :country="['ca']"
                    v-on:placechanged="getAddressData"
                    :value="address"
                ></vue-google-autocomplete> -->
                <vue-google-autocomplete
                    ref="address"
                    id="map"
                    classname="form-control builders-form__input"
                    placeholder="Please type your address"
                    v-on:placechanged="getAddressData"
                    country="ca"
                    v-model="address"
                    @inputChange="autoCompleteChange"
                >
                </vue-google-autocomplete>
            </div>
            <div class="form-group">
                <label for="street_address">Street Address</label>
                <input
                    type="text"
                    class="form-control builders-form__input"
                    id="street_address"
                    placeholder="Enter street address"
                    v-model="street_address"
                    :class="$v.street_address.$error ? 'is-invalid' : ''"
                />
                <div class="invalid-feedback" v-if="$v.street_address.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="area">Area</label>
                <input
                    type="text"
                    class="form-control builders-form__input"
                    id="area"
                    placeholder="Enter Area"
                    v-model="area"
                    :class="$v.area.$error ? 'is-invalid' : ''"
                />
                <div class="invalid-feedback" v-if="$v.area.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="city">Select City</label>
                <select
                    class="form-control builders-form__input"
                    id="city"
                    v-model="city"
                    :class="$v.city.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select City</option>
                    <option
                        v-for="item in cityList"
                        :value="item.id"
                        :key="'city-' + item.id"
                    >
                        {{ item.name }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.city.$error">
                    This is required.
                </div>
            </div>

            <!-- Select Community Input -->
            <div class="dropdown">
                <div class="form-group">
                    <label for="basic-url">Select Community</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            v-model="community"
                            placeholder="Enter Community"
                            :class="$v.community.$error ? 'is-invalid' : ''"
                            @focus="isShowCommunity = true"
                            @blur="onCommunityBlur"
                        />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i class="fas fa-caret-down"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="invalid-feedback d-block"
                        v-if="$v.community.$error && !isShowCommunity"
                    >
                        This is required.
                    </div>
                </div>
                <template v-if="filteredCommunities.length && isShowCommunity">
                    <div
                        class="dropdown-menu community-dropdown-menu w-100 show"
                    >
                        <a
                            class="dropdown-item"
                            href="#"
                            v-for="community in filteredCommunities"
                            @click="onSelectCommunity(community)"
                            >{{ community.name }}</a
                        >
                    </div>
                </template>
            </div>

            <!-- ---------------------------- -->

            <div class="form-group">
                <label for="zip_code">Zip Code</label>
                <input
                    type="text"
                    class="form-control builders-form__input"
                    id="zip_code"
                    placeholder="Enter zip code"
                    v-model="zipcode"
                    :class="$v.zipcode.$error ? 'is-invalid' : ''"
                    maxlength="7"
                />
                <div class="invalid-feedback" v-if="$v.zipcode.$error">
                    This is required.
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button class="btn btn-outline-info w-100" disabled>
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import pick from "lodash/pick";
import { requiredValidationFields } from "../../../../utils";

const initFormFields = {
    address: "",
    street_address: "",
    area: "",
    city: "",
    community: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    county: "",
    property_listing_type: "Exclusive Property",
};

export default {
    name: "FormStepOne",
    components: { VueGoogleAutocomplete },
    props: {
        cityList: {
            default: [],
        },
        propertyDetails: {
            default: {},
        },
        communityList: {
            default: [],
        },
        isEdit: {
            default: false,
        },
    },
    data() {
        return {
            cities: {},
            state: "",
            communities: [],
            ...initFormFields,
            isShowCommunity: false,
        };
    },
    mounted() {
        if (this.all_cities) {
            this.cities = JSON.parse(this.all_cities);
        }
        this.resetForm();
    },
    computed: {
        filteredCommunities() {
            if (this.community) {
                return this.communityList?.filter((item) => {
                    return item?.name
                        ?.toLowerCase()
                        .startsWith(this.community?.toLowerCase());
                });
            }
            return this.communityList;
        },
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                console.log(
                    "pickFormFields",
                    pickFormFields,
                    this.propertyDetails
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key];
                }
            }
        },
        getAddressData(addressData, placeResultData, id) {
            console.log(placeResultData.address_components);
         
                this.latitude = placeResultData.geometry.location.lat();
                this.longitude = placeResultData.geometry.location.lng();
                let city = placeResultData.address_components.find(
                    (el) => el.types[0] == "locality"
                );
                let zipCode = placeResultData.address_components.find(
                    (el) =>
                        el.types[0] == "postal_code" || el.types[1] == "postal_code"
                );
                this.zipcode = zipCode?.long_name;
                this.area = city?.long_name;
                this.city = city?.long_name;
                this.county = placeResultData.address_components.find(
                    (el) => el.types[0] == "administrative_area_level_1"
                )?.long_name;
            try {
                let new_street_address_second_first =
                    placeResultData.address_components.find(
                        (el) => el.types[0] == "street_number"
                    ).long_name;
                let new_street_address_second =
                    placeResultData.address_components.find(
                        (el) => el.types[0] == "route"
                    ).long_name;
                this.street_address =
                    new_street_address_second_first +
                    " " +
                    new_street_address_second;
            } catch (error) {
                // console.log("Address Parse Error",error)
                this.street_address = placeResultData.formatted_address?.split(',')?.[0] || "";
            }
                // if (placeResultData?.formatted_address) {
                //     this.address = placeResultData.formatted_address;
                // }
          
        },
        autoCompleteChange(e) {
            // console.log(e)
            if (e.newVal) {
                this.address = e.newVal;
            }
        },
        next() {
            this.$v.$touch();
            // console.log(" this.$v", this.$v);
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    2,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
        onSelectCommunity(communityObj) {
            this.community = communityObj?.name;
        },
        onCommunityBlur() {
            setTimeout(() => (this.isShowCommunity = false), 200);
        },
    },
    validations: {
        ...requiredValidationFields(initFormFields),
    },
};
</script>
<style>
.community-dropdown-menu {
    max-height: 230px;
    overflow-y: scroll;
}

.property_listing_type_label {
    border: 1px solid #ddd;
}
.property_listing_type_label input {
    opacity: 0;
    position: absolute;
}
</style>
