<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                Add Exclusive Property - Step {{ currentStep }}
            </h5>
        </div>
        <div class="card-body">
            <!-- Basement One -->
            <div class="form-group">
                <label for="basement_one">Basement One</label>
                <input
                    class="form-control builders-form__input"
                    id="basement_one"
                    placeholder="Enter property basement one"
                    v-model="basement_one"
                />
            </div>

            <!-- Basement Two -->
            <div class="form-group">
                <label for="basement_two">Basement Two</label>
                <input
                    class="form-control builders-form__input"
                    id="basement_two"
                    placeholder="Enter property basement two"
                    v-model="basement_two"
                />
            </div>

            <!-- Heating -->
            <div class="form-group">
                <label for="heating">Heating</label>
                <input
                    class="form-control builders-form__input"
                    id="heating"
                    placeholder="Enter about heating"
                    v-model="heating"
                />
            </div>

            <!-- Water Supply -->
            <div class="form-group">
                <label for="water_supply">Water Supply</label>
                <input
                    class="form-control builders-form__input"
                    id="water_supply"
                    placeholder="Enter about water supply"
                    v-model="water_supply"
                />
            </div>

            <!-- Fronting On -->
            <div class="form-group">
                <label for="fronting_on">Fronting On</label>
                <input
                    class="form-control builders-form__input"
                    id="fronting_on"
                    placeholder="Enter fronting on"
                    v-model="fronting_on"
                />
            </div>

            <!-- Lot Depth -->
            <div class="form-group">
                <label for="depth">Lot Depth</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="depth"
                    placeholder="Enter lot depth"
                    v-model="depth"
                />
            </div>

            <!-- Acres -->
            <div class="form-group">
                <label for="acres">Acres</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="acres"
                    placeholder="Enter property acres"
                    v-model="acres"
                />
            </div>

            <!-- Pool -->
            <div class="form-group">
                <label for="pool">Pool</label>
                <input
                    type="text"
                    class="form-control builders-form__input"
                    id="pool"
                    placeholder="Enter poll"
                    v-model="pool"
                />
            </div>

            <!-- Sewer -->
            <div class="form-group">
                <label for="sewer">Sewer</label>
                <input
                    class="form-control builders-form__input"
                    id="sewer"
                    placeholder="Enter Sewer"
                    v-model="sewer"
                />
            </div>

            <!-- Zoning -->
            <div class="form-group">
                <label for="zoning">Zoning</label>
                <input
                    class="form-control builders-form__input"
                    id="zoning"
                    placeholder="Enter Sewer"
                    v-model="zoning"
                />
            </div>

            <!-- Parcel Number -->
            <div class="form-group">
                <label for="parcel_id">Parcel Number</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="parcel_id"
                    placeholder="Enter Parcel Number"
                    v-model="parcel_id"
                />
            </div>

            <!-- Cross Street -->
            <div class="form-group">
                <label for="directions_or_cross_street">Cross Street</label>
                <input
                    class="form-control builders-form__input"
                    id="directions_or_cross_street"
                    placeholder="Enter Parcel Number"
                    v-model="directions_or_cross_street"
                />
            </div>

            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";
import { requiredValidationFields } from "../../../../utils";

const initFormFields = {
    basement_one: "",
    basement_two: "",
    heating: "",
    water_supply: "",
    fronting_on: "",
    depth: "",
    acres: "",
    pool: "",
    sewer: "",
    zoning: "",
    parcel_id: "",
    directions_or_cross_street: "",
};
const requiredFields = {};
export default {
    name: "FormStepSeven",
    props: {
        propertyDetails: {
            default: {},
        },
        currentStep: {},
    },
    data() {
        return {
            defaultPropertyOption: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            ...initFormFields,
        };
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key];
                }
            }
        },
        next() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    this.currentStep + 1,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
    },
    validations: {
        // ...requiredValidationFields(requiredFields),
    },
};
</script>
