<template>
  <div class="listing_filter_row dif db-767">
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-5">
      <div class="left_area tac-xsd mb30-767">
        <p class="mb0 mt10">
          <span class="heading-color">{{ total_properties }}</span> Showing
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdxListingsHeader",
  props: {
    total_properties: {
      default: "0",
    },
  },
};
</script>