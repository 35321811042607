<template>
    <b-modal id="schedule-appointment-modal" hide-footer hide-header size="md" centered @show="initModalData">
        <div class="d-block text-center pb-3">
            <button class="btn btn-light cancel-appointment" @click="$bvModal.hide('schedule-appointment-modal');"><i class="fa-solid fa-xmark"></i></button>
            <div v-if="formSubmitted && responseMessage" class="alert mb-0 mt-3" :class="responseClass" role="alert">{{
                responseMessage }}</div>
            <h3 class="pt-4 pb-3">Interested? <br />Schedule Appointment Now.</h3>
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name" v-model="name"
                        :class="{ 'is-invalid': nameError }">
                    <div class="invalid-feedback">
                        {{ nameError }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Contact Number" v-model="phone"
                        :class="{ 'is-invalid': phoneError }" v-mask="'###-###-####'">
                    <div class="invalid-feedback">
                        {{ phoneError }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Email" v-model="email"
                        :class="{ 'is-invalid': emailError }">
                    <div class="invalid-feedback">
                        {{ emailError }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="date" class="form-control" placeholder="Enter Date" v-model="date"
                        :class="{ 'is-invalid': dateError }">
                    <div class="invalid-feedback">
                        {{ dateError }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="time" class="form-control" placeholder="Enter time" v-model="time"
                        :class="{ 'is-invalid': timeError }">
                    <div class="invalid-feedback">
                        {{ timeError }}
                    </div>
                </div>
                <div class="form-group">
                    <textarea rows="5" type="text" class="form-control" placeholder="Enter your message here"
                        v-model="message" :class="{ 'is-invalid': messageError }"></textarea>
                    <div class="invalid-feedback">
                        {{ messageError }}
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <input type="checkbox" name="" id="is-check">
                    <label for="is-check" class="ml-3">Please send me information and include price lists and floor
                        plans.</label>
                </div>
                <button class="btn btn-primary w-100" type="button" @click.prevent="scheduleAppointment"
                    :disabled="processing" :style="processing ? 'opacity:0.6;cursor:not-allowed;' : ''">{{ processing ?
                        "Processing..." : "Schedule Appointment" }}</button>
            </form>
        </div>
    </b-modal>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
    name: "ScheduleAppointmentModal",
    props: {
        streetAddress: {
            default: "",
        },
        close: {
            type: Boolean
        }
    },
    data() {
        return {
            time: "",
            date: "",
            name: "",
            phone: "",
            email: "",
            message: "",
            processing: false,
            responseClass: "alert-light",
            responseMessage: "",
            formSubmitted: false,
            is_appoinment: 1,
        }
    },
    created() {
    },
    watch: {
        streetAddress(newValue, _oldValue) {
            this.setDefaultMessage(newValue)
        },
    },
    validations: {
        name: {
            required
        },
        email: {
            required,
            email
        },
        phone: {
            required,
            minLength: minLength(12)
        },
        message: {
            required
        },
        time: {
            required
        },
        date: {
            required
        },
    },
    computed: {
        nameError() {
            if (this.$v.name.$error) {
                return "Name is required";
            }
            return null
        },
        dateError() {
            const enteredDate = new Date(this.date);
            const currentDate = new Date();
            if (this.$v.date.$error) {
                return "Date is required";
            }
            else if (enteredDate <= currentDate) {
                return "Entered date must be greater than today.";
            }
            return null
        },
        timeError() {
            const enteredTime = this.time;
            if (!enteredTime) return null;
            const currentDateTime = new Date();
            const enteredDateTime = new Date(currentDateTime.toDateString() + ' ' + enteredTime);
            console.log(this.date)
            if (this.$v.time.$error) {
                return "Time is required";
            }
            // else if(enteredDateTime <= currentDateTime) {
            //     return "Entered time must be greater than the current time.";
            // }
            return null
        },
        messageError() {
            if (this.$v.message.$error) {
                return "Message is required";
            }
            return null
        },
        phoneError() {
            if (this.$v.phone.$error) {
                if (!this.$v.phone.required) {
                    return "Phone number is required";
                } else if (!this.$v.phone.minLength) {
                    return "Phone number should be 10 digits";
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        emailError() {
            if (this.$v.email.$error) {
                if (!this.$v.email.required) {
                    return "Email is required";
                } else if (!this.$v.email.email) {
                    return "Please enter a valid email address";
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
    },
    methods: {
        initModalData() {
            this.name = "";
            this.email = "";
            this.phone = "";
            this.setDefaultMessage(this.streetAddress);
        },
        setDefaultMessage(address) {
            if (address && address != "Address not available") {
                this.message =
                    "I would like an appointment scheduled on the above mentioned date and time to know more about this property at " +
                    address;
            } else {
                this.message =
                    "I would like an appointment scheduled on the above mentioned date and time to know more about this property at";
            }
        },
        scheduleAppointment() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.processing = true;
                const currentUrl = window.location.href;
                const formData = new FormData();
                formData.append("time", this.time);
                formData.append("date", this.date);
                formData.append("name", this.name);
                formData.append("email", this.email);
                formData.append("phone", this.phone);
                formData.append("property_url", currentUrl);
                formData.append("message", this.message);
                formData.append("street_address", this.streetAddress);
                formData.append("lead_type", 'buyer');
                formData.append("is_appoinment", this.is_appoinment);

                this.formSubmitted = true;
                axios
                    .post("/submit-callback-request", formData)
                    .then(response => {
                        let msg = "Successfully scheduled appointment!";
                        if (response?.data?.message) {
                            msg = response.data.message;
                        }
                        this.responseMessage = msg;
                        this.responseClass = "alert-success";
                        this.$v.$reset();
                        this.time = "";
                        this.date = "";
                        this.name = "";
                        this.email = "";
                        this.phone = "";
                        this.message = ""

                        setTimeout(() => {
                            this.$bvModal.hide('schedule-appointment-modal');
                            this.formSubmitted = false;
                            this.responseMessage = ""
                        }, 2000);
                    })
                    .catch(error => {
                        let msg = "Unable to schedule appointment!";
                        this.responseClass = "alert-danger";
                        if (error?.response?.data?.message) {
                            msg = error.response.data.message;
                        }
                        this.responseMessage = msg;
                    })
                    .finally(res => {
                        this.formSubmitted = true;
                        this.processing = false;
                    });
            }
        }
    }
}
</script>

<style lang="scss">
#schedule-appointment-modal {
    .modal-dialog {
        max-width: 400px;
    }

    form {
        .form-control {
            border-radius: 4px !important;
            border: 1px solid #B1B1B1 !important;
            padding: 8px 16px !important;
        }

        label {
            font-size: 14px;
            margin-bottom: 0;
            text-align: start;
        }

        .invalid-feedback {
            text-align: left;
        }
    }

    .alert {
        padding: 0.6rem 1rem;
        font-size: 14px;
    }

    .cancel-appointment{
        position: absolute;
        right: 1rem;
        top: 1rem;
        border-radius: 50%;
    }
}
</style>
