<template>
    <div class="modal-content border-0">
        <div class="modal-header bg-transparent border-bottom-0 pb-0">
            <h3 class="modal-title text-primary">Create your account</h3>
            <button
                type="button"
                class="close bg-transparent"
                data-dismiss="modal"
                aria-label="Close"
                @click="$emit('closeModal')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div
                class="alert"
                role="alert"
                v-if="message"
                :class="error ? 'alert-danger' : 'alert-success'"
            >
                {{ message }}
            </div>
            <form>
                <!-- Name -->
                <div class="mb-3 mr-sm-2">
                    <label for="name" class="form-label mb-0">Name</label>
                    <input
                        type="text"
                        class="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter your name"
                        :class="nameError ? 'is-invalid' : ''"
                        v-model="name"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ nameError }}
                    </span>
                </div>

                <!-- Phone Number -->
                <div class="mb-3 mr-sm-2">
                    <label for="phone" class="form-label mb-0"
                        >Phone Number</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        name="phone"
                        id="phone"
                        v-model="phone"
                        placeholder="Enter your phone number"
                        :class="phoneError ? 'is-invalid' : ''"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ phoneError }}
                    </span>
                </div>

                <!-- Email -->
                <div class="mb-3 mr-sm-2">
                    <label for="email" class="form-label mb-0">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        :class="emailError ? 'is-invalid' : ''"
                        v-model="email"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ emailError }}
                    </span>
                </div>

                <!-- Password -->
                <div class="mb-3 mr-sm-2">
                    <label for="password" class="form-label mb-0"
                        >Password</label
                    >
                    <input
                        type="password"
                        class="form-control"
                        name="password"
                        id="password"
                        placeholder="Enter new password"
                        :class="passwordError ? 'is-invalid' : ''"
                        v-model="password"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ passwordError }}
                    </span>
                </div>

                <!-- Re-Enter Password -->
                <div class="mb-3 mr-sm-2">
                    <label for="password_confirmation" class="form-label mb-0"
                        >Re-Enter Password</label
                    >
                    <input
                        type="password"
                        class="form-control"
                        name="password_confirmation"
                        id="password_confirmation"
                        placeholder="Enter new password"
                        :class="passwordConfirmError ? 'is-invalid' : ''"
                        v-model="password_confirmation"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ passwordConfirmError }}
                    </span>
                </div>

                <div
                    class="d-flex justify-content-between align-items-center mt-2 mb-4"
                >
                    <div class="custom-control custom-checkbox mb-0">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                        />
                        <label class="custom-control-label text-muted" for="customCheck1"
                            >Remember Me</label
                        >
                    </div>
                    <a
                        href="#"
                        @click.prevent="openLoginView"
                        class="fs-small text-decoration-underline"
                    >
                        Click here to Log in into an existing account
                    </a>
                </div>

                <button
                    type="button"
                    @click="handleSubmit"
                    class="btn btn-primary btn-lg btn-block"
                >
                    Sign Up
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { VIEWS, BASE_PATH } from "./AuthModal.vue";
import axios from "axios";
const canadaPhoneFormat = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const canadianNumber = (value) => canadaPhoneFormat.test(value);

export default {
    name: "SignupModal",
    props: {
        address: {
            default: "",
        },
        street_address: {
            default: "",
        },
    },
    data: () => ({
        name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
        error: false,
        message: "",
    }),
    methods: {
        openLoginView() {
            this.$emit("openView", VIEWS.LOGIN_FORM);
        },
        handleSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const { name, email, phone, password, address, street_address } = this;
                axios
                    .post(BASE_PATH + "/api/user-signup", {
                        name,
                        email,
                        phone,
                        password,
                        address, 
                        street_address

                    })
                    .then((res) => {
                        if (res.data.server_status) {
                            this.$emit("loginSuccess", res.data);
                            this.message = res.data.message;
                            this.error = false;
                        }
                    })
                    .catch((err) => {
                        this.error = true;
                        this.message =
                            "Something Went Wrong, Please Try Again!";
                        if (err?.response?.data?.message) {
                            this.message = err.response.data.message;
                        }
                    });
            }
        },
    },
    computed: {
        nameError() {
            if (this.$v.name.$error) {
                return "Name is required";
            } else {
                return "";
            }
        },
        nameError() {
            if (this.$v.name.$error) {
                return "Name is required";
            } else {
                return "";
            }
        },
        emailError() {
            if (this.$v?.email.$error) {
                if (!this.$v?.email.required) {
                    return "Email is required";
                } else if (!this.$v?.email.email) {
                    return "Please enter a valid email address";
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
        phoneError() {
            if (this.$v?.phone.$error) {
                if (!this.$v?.phone.required) {
                    return "Phone number is required";
                } else if (!this.$v?.email.canadianNumber) {
                    return "Phone number must be a canadian number";
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
        passwordError() {
            if (this.$v.password.$error) {
                if(!this.$v.password.required){
                return "Password is required";
                }else if(!this.$v.password.minLength){
                    return 'Password must be of 8 characters at least'
                }
            } else {
                return false;
            }
        },
        passwordConfirmError() {
            if (this.$v.password_confirmation.$error) {
                return "Confirm password must be same as password";
            }
            return false;
        },
    },
    validations() {
        return {
            name: { required },
            phone: { required, canadianNumber },
            email: { required, email },
            password: { required, minLength: minLength(8) },
            password_confirmation: { sameAs: sameAs("password") },
        };
    },
};
</script>

<style scoped lang="scss">
.fs-small {
    font-size: 13px;
}
</style>
