<template>
    <div class="modal-content border-0">
        <div class="modal-header bg-transparent border-bottom-0 pb-0">
            <h3 class="modal-title text-primary">Log In to your account</h3>
            <button
                type="button"
                class="close bg-transparent"
                data-dismiss="modal"
                aria-label="Close"
                @click="$emit('closeModal')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div
                class="alert"
                role="alert"
                v-if="message"
                :class="error ? 'alert-danger' : 'alert-success'"
            >
                {{ message }}
            </div>
            <!-- Login View -->
            <form>
                <!-- Email -->
                <div class="mb-3 mr-sm-2">
                    <label for="email" class="form-label mb-0">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        :class="emailError ? 'is-invalid' : ''"
                        v-model="email"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ emailError }}
                    </span>
                </div>

                <!-- Password -->
                <div class="mb-3 mr-sm-2">
                    <label for="password" class="form-label mb-0"
                        >Password</label
                    >
                    <input
                        type="password"
                        class="form-control"
                        name="password"
                        id="password"
                        placeholder="Enter new password"
                        :class="passwordError ? 'is-invalid' : ''"
                        v-model="password"
                    />
                    <span class="invalid-feedback" role="alert">
                        {{ passwordError }}
                    </span>
                </div>

                <div
                    class="d-flex justify-content-between align-items-center mt-2 mb-4"
                >
                    <div class="custom-control custom-checkbox mb-0">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                        />
                        <label
                            class="custom-control-label text-muted"
                            for="customCheck1"
                            >Remember Me</label
                        >
                    </div>
                    <a
                        href="#"
                        @click.prevent="openForgetPasswordView"
                        class="fs-small text-decoration-underline"
                    >
                        Forgot Password
                    </a>
                </div>

                <button
                    type="button"
                    @click="handleSubmit"
                    class="btn btn-primary btn-lg btn-block"
                    :disabled="loading"
                >
                    <template v-if="loading">
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </template>
                    <template v-if="!loading">Log In</template>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { VIEWS, BASE_PATH } from "./AuthModal.vue";

export default {
    name: "LoginModal",
    data: () => ({
        email: "",
        password: "",
        error: false,
        message: "",
        loading: false,
    }),
    methods: {
        openForgetPasswordView() {
            this.$emit("openView", VIEWS.FORGET_PASSWORD_FORM);
        },
        handleSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const { email, password } = this;
                this.loading = true;
                axios
                    .post(BASE_PATH + "/api/user-signup", {
                        email,
                        password,
                    })
                    .then((res) => {
                        if (res.data.server_status) {
                            this.$emit("loginSuccess",res.data);
                            this.message = res.data.message;
                            this.error = false;
                        }
                    })
                    .catch((err) => {
                        this.error = true;
                        this.message =
                            "Something Went Wrong, Please Try Again!";
                        if (err?.response?.data?.message) {
                            this.message = err.response.data.message;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
        emailError() {
            if (this.$v?.email.$error) {
                if (!this.$v?.email.required) {
                    return "Email is required";
                } else if (!this.$v?.email.email) {
                    return "Please enter a valid email address";
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
        passwordError() {
            if (this.$v.password.$error) {
                if(!this.$v.password.required){
                return "Password is required";
                }else if(!this.$v.password.minLength){
                    return 'Password must be of 8 characters at least'
                }
            } else {
                return false;
            }
        },
    },
    validations() {
        return {
            email: { required, email },
            password: { required, minLength: minLength(8) },
        };
    },
};
</script>

<style scoped lang="scss">
.fs-small {
    font-size: 13px;
}
</style>
