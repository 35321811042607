<template>
  <div class="card mt-4 shadow rounded">
    <div class="card-title">
      <h6 class="text-center pt-4">Edit Property - Step 3</h6>
    </div>
    <div class="card-body">
      
      <!-- Ownership -->
      <div class="form-group">
        <label for="ownership">Ownership</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="ownership"
          v-model="ownership"
          placeholder="Enter Ownership"
        />
      </div>
      <!-- ./Ownership -->

      <!-- Construction Status -->
      <div class="form-group">
        <label for="construction_status">Construction Status</label>
        <select name="construction_status" id="construction_status" v-model="construction_status" class="form-control builders-form__input">
          <option value="">Select Status</option>
          <option value="Completed">Completed</option>
        </select>
      </div>
      <!-- ./Construction Status -->

      <!-- List Price -->
      <div class="form-group">
        <label for="list_price">List Price</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="list_price"
          v-model="list_price"
          placeholder="Enter list price"
        />
      </div>
      <!-- ./List Price -->
      <!-- Property Tax -->
      <div class="form-group">
        <label for="property_tax">Property Tax</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="property_tax"
          v-model="property_tax"
          placeholder="Enter property tax"
        />
      </div>
      <!-- ./Property Tax -->
      <!-- Min Price -->
      <div class="form-group">
        <label for="min_price">Min Price</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="min_price"
          v-model="min_price"
          placeholder="Enter min price"
        />
      </div>
      <!-- ./Min Price -->
      <!-- Max Price -->
      <div class="form-group">
        <label for="max_price">Max Price</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="max_price"
          v-model="max_price"
          placeholder="Enter max price"
        />
      </div>
      <!-- ./Max Price -->
      <!-- Lot Size -->
      <div class="form-group">
        <label for="lot_size">Lot Size</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="lot_size"
          v-model="lot_size"
          placeholder="Enter lot size"
        />
      </div>
      <!-- ./Lot Size -->
      <!-- Home Type -->
      <div class="form-group">
        <label for="home_type">Home Type</label>
        <select class="form-control builders-form__input" id="home_type" v-model="home_type">
          <option :value="''">Select Option</option>
          <option
            v-for="(item, index) in home_type_list"
            :value="item"
            :key="'home-'+index"
          >{{ item }}</option>
        </select>
      </div>
      <!-- ./Home Type -->
      <!-- House Occupy -->
      <div class="form-group">
        <label for="house_occupy">House Occupy</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="house_occupy"
          v-model="house_occupy"
          placeholder="Enter house occupy details"
        />
      </div>
      <!-- ./House Occupy -->
      <!-- Bedroom -->
      <div class="form-group">
        <label for="bedroom">Bedroom</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="bedroom"
          v-model="bedroom"
          placeholder="Enter bedroom"
        />
      </div>
      <!-- ./Bedroom -->
      <!-- Bathroom -->
      <div class="form-group">
        <label for="bathroom">Bathroom</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="bathroom"
          v-model="bathroom"
          placeholder="Enter bathroom"
        />
      </div>
      <!-- ./Bathroom -->
      <!-- Built Year -->
      <div class="form-group">
        <label for="built_year">Built Year</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="built_year"
          v-model="built_year"
          placeholder="Enter built year"
        />
      </div>
      <!-- ./Built Year -->

      <!-- Sales Started -->
      <div class="form-group">
        <label for="sales_started_at">Sales Started</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="sales_started_at"
          v-model="sales_started_at"
          placeholder="Enter Sales Started"
        />
      </div>
      <!-- ./Construction Started -->
      
      <!-- Construction Started -->
      <div class="form-group">
        <label for="started_at">Construction Started</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="started_at"
          v-model="started_at"
          placeholder="Enter Construction Started"
        />
      </div>
      <!-- ./Construction Started -->

      <!-- Completed In -->
      <div class="form-group">
        <label for="completed_at">Completed In</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="completed_at"
          v-model="completed_at"
          placeholder="Enter Completed In"
        />
      </div>
      <!-- ./Completed In -->

      <!-- Sales Company -->
      <div class="form-group">
        <label for="sales_company">Sales Company</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="sales_company"
          v-model="sales_company"
          placeholder="Enter Sales Company"
        />
      </div>
      <!-- ./Sales Company -->

      <!-- C.C / Maint -->
      <div class="form-group">
        <label for="cc_maint">C.C / Maint</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="cc_maint"
          v-model="cc_maint"
          placeholder="Enter C.C / Maint"
        />
      </div>
      <!-- ./C.C / Maint -->
      
      <!-- Cost to Purchase Parking -->
      <div class="form-group">
        <label for="cost_to_purchase_parking">Cost to Purchase Parking</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="cost_to_purchase_parking"
          v-model="cost_to_purchase_parking"
          placeholder="Enter Cost to Purchase Parking"
        />
      </div>
      <!-- ./Cost to Purchase Parking -->

      <!-- Purchase Storage -->
      <div class="form-group">
        <label for="purchase_storage">Purchase Storage</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="purchase_storage"
          v-model="purchase_storage"
          placeholder="Enter Purchase Storage"
        />
      </div>
      <!-- ./Purchase Storage -->

      <!-- Co-op Fee Realtors -->
      <div class="form-group">
        <label for="co_op_fee">Co-op Fee Realtors</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="co_op_fee"
          v-model="co_op_fee"
          placeholder="Enter Co-op Fee Realtors"
        />
      </div>
      <!-- ./Co-op Fee Realtors -->

      <!-- Parking Type -->
      <div class="form-group">
        <label for="parking_type">Parking Type</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="parking_type"
          v-model="parking_type"
          placeholder="Enter parking type"
        />
      </div>
      <!-- ./Parking Type -->
      <!-- Square Footage -->
      <div class="form-group">
        <label for="square_footage">Square Footage</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="square_footage"
          v-model="square_footage"
          placeholder="Enter square footage"
        />
      </div>
      <!-- ./Square Footage -->
      <!-- Square Footage -->
      <div class="form-group">
        <label for="sqft_area">Sqft Area</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="sqft_area"
          v-model="sqft_area"
          placeholder="Enter square feet area"
        />
      </div>
      <!-- ./Square Footage -->
      <!-- Exterior -->
      <div class="form-group">
        <label for="exterior">Exterior</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="exterior"
          v-model="exterior"
          placeholder="Enter exterior detail"
        />
      </div>
      <!-- ./Exterior -->
      <!-- Basement -->
      <div class="form-group">
        <label for="basement">Basement</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="basement"
          v-model="basement"
          placeholder="Enter square feet area"
        />
      </div>
      <!-- ./Basement -->
      <!-- Swimming Pool -->
      <div class="form-group">
        <label for="swimming_pool">Swimming Pool</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="swimming_pool"
          v-model="swimming_pool"
          placeholder="Enter no of swimming pools"
        />
      </div>
      <!-- ./Swimming Pool -->

      <!-- Ammonites -->
      <div class="form-group">
          <label for="">Amenities</label>
          <multiselect
              v-model="amenities"
              :options="amenitiesOptions"
              :multiple="true"
          ></multiselect>
      </div>

      <!-- Short Description -->
      <div class="form-group">
        <label for="short_description">Short Description</label>
        <!-- <textarea
          rows="5"
          class="form-control builders-form__input"
          id="short_description"
          v-model="short_description"
          placeholder="Enter short description"
          style="resize:none"
        ></textarea> -->
        <ckeditor :editor="editor" v-model="short_description" :config="editorConfig"></ckeditor>
      </div>
      <!-- ./Short Description -->
      <!-- Detailed Description -->
      <div class="form-group">
        <label for="detailed_description">Detailed Description</label>
        <!-- <textarea
          rows="5"
          class="form-control builders-form__input"
          id="detailed_description"
          v-model="detailed_description"
          placeholder="Enter detailed description"
          style="resize:none"
        ></textarea> -->
        <ckeditor :editor="editor" v-model="detailed_description" :config="editorConfig"></ckeditor>
      </div>
      <!-- ./Detailed Description -->
      <!-- Website -->
      <div class="form-group">
        <label for="website_address">Website</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="website_address"
          v-model="website_address"
          placeholder="Enter website url (e.g. https://example.com)"
        />
      </div>
      <!-- ./Website -->
      <!-- Price Per Sqft -->
      <div class="form-group">
        <label for="price_per_sqft">Price Per Sqft</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="price_per_sqft"
          v-model="price_per_sqft"
          placeholder="Enter price per sqft"
        />
      </div>
      <!-- ./Price Per Sqft -->
      <!-- Cost Of Parking -->
      <div class="form-group">
        <label for="cost_of_parking">Cost Of Parking</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="cost_of_parking"
          v-model="cost_of_parking"
          placeholder="Enter cost of parking"
        />
      </div>
      <!-- ./Cost Of Parking -->
      <!-- Maintenance Charge -->
      <div class="form-group">
        <label for="maintenance_charge">Maintenance Charge</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="maintenance_charge"
          v-model="maintenance_charge"
          placeholder="Enter maintenance charge"
        />
      </div>
      <!-- ./Maintenance Charge -->
      <!-- Deposite Structure -->
      <div class="form-group">
        <label>Deposit Structure</label>
        <ckeditor
            :editor="editor"
            v-model="deposit_structure"
            :config="edtConfigForDepositStructure"
        ></ckeditor>
      </div>
      <!-- ./Deposite Structure -->
      <!-- Property Description -->
      <div class="form-group">
        <!-- <textarea
          rows="5"
          class="form-control builders-form__input"
          id="property_description"
          v-model="property_description"
          placeholder="Enter property description"
          style="resize:none"
        ></textarea> -->
        <ckeditor :editor="editor" v-model="property_description" :config="editorConfig"></ckeditor>
      </div>
      <!-- ./Property Description -->
      <!-- Community Description -->
      <div class="form-group">
        <label for="community_description">Community Description</label>
        <!-- <textarea
          rows="5"
          class="form-control builders-form__input"
          id="community_description"
          v-model="community_description"
          placeholder="Enter community description"
          style="resize:none"
        ></textarea> -->
        <ckeditor :editor="editor" v-model="community_description" :config="editorConfig"></ckeditor>
      </div>
      <!-- ./Community Description -->
      <!-- Have Parking -->
      <div class="form-group">
        <label for="have_parking">Have Parking?</label>
        <div>
          <input
            type="radio"
            name="have_parking"
            id="have_parking_yes"
            :value="1"
            v-model="have_parking"
          />&nbsp;Yes
          <input
            type="radio"
            name="have_parking"
            id="have_parking_no"
            :value="0"
            v-model="have_parking"
          />&nbsp;No
        </div>
      </div>
      <!-- ./Have Parking -->
      <!-- No of units -->
      <div class="form-group">
        <label for="no_of_units">No of units</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="no_of_units"
          v-model="unitno"
          placeholder="Enter no of units"
        />
      </div>
      <!-- ./No of units -->

        <!-- Virtual Tour -->
        <div class="form-group">
            <label for="virtual_tour">Virtual Tour</label>
            <input
                type="text"
                id="virtual_tour"
                v-model="virtual_tour"
                class="form-control builders-form__input"
                placeholder="Enter Virtual Tour Url"
            />
        </div>
      <div class="row">
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-info w-100"
            :disabled="formSubmitting"
            @click.prevent="resetForm"
          >{{ formSubmitting ? "Processing..." : "Reset" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-success w-100"
            :disabled="formSubmitting"
            @click.prevent="updatePropertyDetails"
          >{{ formSubmitting ? "Processing..." : "Save" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-primary w-100"
            :disabled="formSubmitting"
            @click.prevent="moveToFormFour"
          >{{ formSubmitting ? "Processing..." : "Next" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "FormStepThree",
  props: {
    property_details: {
      default: {}
    }
  },
  data() {
    return {
      home_type_list: [
        "Detached",
        "Semi-detached",
        "Town House",
        "Condo Town House",
        "Condo Apartment",
        "Condominium",
        "Condo"
      ],
      ownership:"",
      virtual_tour:"",
      construction_status:"",
      list_price: "",
      property_tax: "",
      min_price: "",
      max_price: "",
      lot_size: "",
      home_type: "",
      house_occupy: "",
      bedroom: "",
      bathroom: "",
      built_year: "",
      sales_started_at: "",
      started_at: "",
      completed_at: "",
      sales_company: "",
      cc_maint: "",
      cost_to_purchase_parking: "",
      purchase_storage: "",
      co_op_fee: "",
      parking_type: "",
      square_footage: "",
      sqft_area: "",
      exterior: "",
      basement: "",
      swimming_pool: "",
      amenities: "",
      short_description: "",
      detailed_description: "",
      website_address: "",
      price_per_sqft: "",
      cost_of_parking: "",
      maintenance_charge: "",
      deposit_structure: "",
      property_description: "",
      community_description: "",
      have_parking: 0,
      unitno: "",
      property_id: "",
      formSubmitting: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            "bold",
            "italic",
            "link",
            "outdent",
            "indent",
            "undo",
            "redo",
            "bulletedList",
            "numberedList",
            "|",
            "blockQuote",
            "insertTable"
          ]
        }
      },
      edtConfigForDepositStructure:{
          ...this.editorConfig,
          placeholder:'Enter Deposit Structure'
      },
      amenitiesOptions: [
        "Rooftop Terrace",
        "Lounge with Wet Bar",
        "Dog Wash Station",
        "24/7 Concierge",
        "Bike Storage",
        "Catering Kitchen",
        "Fitness Centre",
        "Guest Suite",
        "Outdoor Reading Rooms",
        "Entertainment Lounge",
        "Conference Room",
        "Garden Suite",
        "Lobby Lounge",
        "Sun bathing areas",
        "Private Elevators",
        "Party Room",
        "Fitness Room",
        "Garden Terrace",
        "Lobby Bar",
        "Valet Parking",
        "Infinity Pool",
        "Outdoor Pool",
        "Sun Deck",
        "Parcel and Mail Room",
        "Outdoor Terrace with Fire pit",
        "Cabanas",
        "Co-working Lounge",
        "Sauna",
        "Outdoor BBQ",
        "Concierge",
        "Entertainment Space",
        "Al Fresco Dining and Grilling Areas",
        "Green Roof",
        "Private Garden Patio",
        "Porte Cochere",
        "Rooftop Lounge",
        "Stretch Room",
        "Landscaped Courtyard",
        "Patio Lounge",
        "Outdoor Dining",
        "Package Room",
        "BBQ Station",
        "Catering Kitchen and Dining",
        "Fitness Studio",
        "Lobby",
        "24 Hour Virtual Concierge",
        "Parcel Room",
        "Outdoor Terrace",
        "Storage Room",
        "Ping Pong Table",
        "Pilates Studio",
        "Chef’s Table and Wine Tasting Room",
        "BBQ Terrace",
        "Foosball Table",
        "Zen Space",
        "WiFi Lounge",
        "Theatre Room",
        "Karaoke Area",
        "Outdoor Garden",
        "Lounge",
        "Pool",
        "Discovery Den",
        "Mail Room",
        "Kids Zone",
        "Lounge Area",
        "Pet Wash",
        "Potting Shed",
        "Playground",
        "Private Dining Room", 
        "Social Lounge", 
        "Dining Bar", 
        "Social Lounge", 
        "Pet Spa", 
        "Outdoor Movie Theatre", 
        "Fire Pit", 
        "Yoga and Stretching Space", 
        "Media Lounge", 
        "Indoor Bike Storage", 
        "EV Charging", 
        "Locker Rooms",
        "Smart Home Technology",
        "Smart Home Technology",
        "Concierge Attended Lobby",
        "Grand Lobby Lounge",
        "Fire Pit",
        "Party Rooms",
        "Yoga Studio",
        "outdoor terrace with BBQ",
        "Bike Parking",
        "Pet Grooming Room",
        "Video Games Room",
        "Relaxation Lounge",
        "Virtual Bike Room",
        "Yoga Room",
        "Billiards Room",
        "Co-Work Lounge",
        "Meeting Room",
        "heated stone lounger",
        "Meeting Room",
        "Outdoor Relaxation Area",
        "Swimming Pool",
        "Library",
        "Bike Gear Room",
        "Bowling Alley",
        "Bike Elevator",
        "Hot Tub",
        "Bike Lockers",
        "Steam Room",
        "Bike Wash Station",
        "Games Room",
        "spin class studio",
        "Conference Rooms",
        "KTV room",
        "parcel locker rooms",
        "Music Room",
        "Social Room",
        "Guest Suites",
        "Virtual Golf Simulator",
        "Outdoor Lounge",
        "Garden Cafe", 
        "Yoga Studio", 
        "Fireplace Terrace", 
        "Party Lounge", 
        "Gaming Hub", 
        "Sound Studio", 
        "Gym",
        "Spin Room", 
        "gym lounge", 
        "Weight Room", 
        "Whirlpool", 
        "Security System", 
        "Underground Private Parking Garage", 
        "Decks", 
        "Patio Spaces", 
        "In Suite Elevator", 
        "Heated Laneway", 
        "Boardroom", 
        "Private Terrace", 
        "Terrace", 
        "Dining Room", 
        "Indoor Pool", 
        "Bar", 
        "Private BBQ/Dining Area",
        "Outdoor Yoga and Zen Garden",
        "Art Studio",
        "Library Lounge",
        "Outdoor Theatre",
        "Demo Kitchen",
        "Juice & Coffee Bar",
        "Outdoor firepit lounge",
        "Shared Work Space",
        "Steam & Sauna Room",
        "24 Hour Concierge Service",
        "Spa Lounge",
        "Social Club",
        "Outdoor Games Lounge",
        "BBQ Area",
        "Parcel Storage",
        "Personal Training Studio",
        "Private Terraces and Balconies",
        "Parking Stackers",
        "Bike Racks",
        "Fitness Equipment",
        "BBQ Area",
        "Chet's Kitchen",
        "Outdoor Patio",
        "Rooftop Patio",
        "Party Room with Kitchen and Dining Room",
        "Bicycle Storage",
        "24 Hour Concierge",
        "Outdoor Terrace with BBQ and Pergola",
        "Lounge with seating and wetbar",
        "Parcel Storage Room",
        "Secure Key Fob",
        "Guest Parking",
        "Balconies and/or Terraces",
        "Guest Parking",
        "Balconies and/or Terraces",
        "outdoor bbq and seating",
        "reception parlour",
        "Covered Terrace",
        "Valet Service",
        "Private Function Room",
        "24 Hour Concierge Service",
        "Fireplace",
        "Courtyard",
        "Pet Grooming Station",
        "Seating Area",
        "Kitchen",
        "Car Share",
        "Electric Charging Station Available",
        "Games Deck",
        "The Speakeasy Room",
        "Yoga Area",
        "Recharge Retreat",
        "Parking",
        "Plush Lounge",
        "Entertainment Room",
        "Green Space",
        "Coworking Space"
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.resetForm();
    }, 1000);
  },
  methods: {
    resetForm() {
      // ownership
      if (this.property_details?.ownership) {
        this.ownership = this.property_details.ownership;
      }
      // virtual tour
      if (this.property_details?.virtual_tour) {
        this.virtual_tour = this.property_details.virtual_tour;
      }
      // Construction Status
      if (this.property_details?.construction_status) {
        this.construction_status = this.property_details.construction_status;
      }
      // list price
      if (this.property_details?.list_price) {
        this.list_price = this.property_details.list_price;
      }
      // property tax
      if (this.property_details?.property_tax) {
        this.property_tax = this.property_details.property_tax;
      }
      // min price
      if (this.property_details?.min_price) {
        this.min_price = this.property_details.min_price;
      }
      // max price
      if (this.property_details?.max_price) {
        this.max_price = this.property_details.max_price;
      }
      // lot size
      if (this.property_details?.lot_size) {
        this.lot_size = this.property_details.lot_size;
      }
      // home type
      if (this.property_details?.home_type) {
        this.home_type = this.property_details.home_type;
      }
      // house occupy
      if (this.property_details?.house_occupy) {
        this.house_occupy = this.property_details.house_occupy;
      }
      // bedroom
      if (this.property_details?.bedroom) {
        this.bedroom = this.property_details.bedroom;
      }
      // bathroom
      if (this.property_details?.bathroom) {
        this.bathroom = this.property_details.bathroom;
      }
      // built year
      if (this.property_details?.built_year) {
        this.built_year = this.property_details.built_year;
      }
      // Construction Started
      if (this.property_details?.started_at) {
        this.started_at = this.property_details.started_at;
      }

      // Sale Started
      if (this.property_details?.sales_started_at) {
        this.sales_started_at = this.property_details.sales_started_at;
      }

      // Completed In
      if (this.property_details?.completed_at) {
        this.completed_at = this.property_details.completed_at;
      }

      // Sales Company
      if (this.property_details?.sales_company) {
        this.sales_company = this.property_details.sales_company;
      }
      
      // CC Maint
      if (this.property_details?.cc_maint) {
        this.cc_maint = this.property_details.cc_maint;
      }

      // Cost to Purchase Parking
      if (this.property_details?.cost_to_purchase_parking) {
        this.cost_to_purchase_parking = this.property_details.cost_to_purchase_parking;
      }

      // Purchase Storage
      if (this.property_details?.purchase_storage) {
        this.purchase_storage = this.property_details.purchase_storage;
      }

      // Co-op Fee Realtors
      if (this.property_details?.co_op_fee) {
        this.co_op_fee = this.property_details.co_op_fee;
      }
      
      // parking type
      if (this.property_details?.parking_type) {
        this.parking_type = this.property_details.parking_type;
      }
      // square footage
      if (this.property_details?.square_footage) {
        this.square_footage = this.property_details.square_footage;
      }
      // square footage
      if (this.property_details?.sqft_area) {
        this.sqft_area = this.property_details.sqft_area;
      }
      // exterior
      if (this.property_details?.exterior) {
        this.exterior = this.property_details.exterior;
      }
      // basement
      if (this.property_details?.basement) {
        this.basement = this.property_details.basement;
      }
      // swimming pool
      if (this.property_details?.swimming_pool) {
        this.swimming_pool = this.property_details.swimming_pool;
      }
      // amenities
      if (this.property_details?.amenities) {
        this.amenities = this.property_details.amenities ? this.property_details.amenities : [];
      }
      // short description
      if (this.property_details?.short_description) {
        this.short_description = this.property_details.short_description;
      }
      // detailed description
      if (this.property_details?.detailed_description) {
        this.detailed_description = this.property_details.detailed_description;
      }
      // website address
      if (this.property_details?.website_address) {
        this.website_address = this.property_details.website_address;
      }
      // price per sqft
      if (this.property_details?.price_per_sqft) {
        this.price_per_sqft = this.property_details.price_per_sqft;
      }
      // cost of parking
      if (this.property_details?.cost_of_parking) {
        this.cost_of_parking = this.property_details.cost_of_parking;
      }
      // maintenance charge
      if (this.property_details?.maintenance_charge) {
        this.maintenance_charge = this.property_details.maintenance_charge;
      }
      // deposit structure
      if (this.property_details?.deposit_structure) {
        this.deposit_structure = this.property_details.deposit_structure;
      }
      // property description
      if (this.property_details?.property_description) {
        this.property_description = this.property_details.property_description;
      }
      // community description
      if (this.property_details?.community_description) {
        this.community_description = this.property_details.community_description;
      }
      // have parking
      if (this.property_details?.have_parking) {
        this.have_parking = this.property_details.have_parking;
      }
      // unitno
      if (this.property_details?.unitno) {
        this.unitno = this.property_details.unitno;
      }
      // id
      if (this.property_details?.id) {
        this.property_id = this.property_details.id;
      }
    },
    updatePropertyDetails: async function() {
      const formData = new FormData();

      let property_id = this.property_id;
      if (property_id) {
        const formData = new FormData();

        formData.append("ownership", this.ownership);
        formData.append("virtual_tour", this.virtual_tour);
        formData.append("construction_status", this.construction_status);
        formData.append("list_price", this.list_price);
        formData.append("property_tax", this.property_tax);
        formData.append("min_price", this.min_price);
        formData.append("max_price", this.max_price);
        formData.append("lot_size", this.lot_size);
        formData.append("home_type", this.home_type);
        formData.append("house_occupy", this.house_occupy);
        formData.append("bedroom", this.bedroom);
        formData.append("bathroom", this.bathroom);
        formData.append("built_year", this.built_year);
        formData.append("sales_started_at", this.sales_started_at);
        formData.append("started_at", this.started_at);
        formData.append("completed_at", this.completed_at);
        formData.append("sales_company", this.sales_company);
        formData.append("cc_maint", this.cc_maint);
        formData.append("cost_to_purchase_parking", this.cost_to_purchase_parking);
        formData.append("purchase_storage", this.purchase_storage);
        formData.append("co_op_fee", this.co_op_fee);
        formData.append("parking_type", this.parking_type);
        formData.append("square_footage", this.square_footage);
        formData.append("sqft_area", this.sqft_area);
        formData.append("exterior", this.exterior);
        formData.append("basement", this.basement);
        formData.append("swimming_pool", this.swimming_pool);
        // formData.append("amenities", this.amenities);
        formData.append("short_description", this.short_description);
        formData.append("detailed_description", this.detailed_description);
        formData.append("website_address", this.website_address);
        formData.append("price_per_sqft", this.price_per_sqft);
        formData.append("cost_of_parking", this.cost_of_parking);
        formData.append("maintenance_charge", this.maintenance_charge);
        formData.append("deposit_structure", this.deposit_structure);
        formData.append("property_description", this.property_description);
        formData.append("community_description", this.community_description);
        formData.append("have_parking", this.have_parking);
        formData.append("unitno", this.unitno);
        // handle amenities
        if(this.amenities.length>0){
          for(let i=0;i<this.amenities.length;i++){
            formData.append(`amenities[${i}]`, this.amenities[i]);
          }
        }else {
          formData.append(`amenities`, "");
        }

        await axios
          .post("/property-management/update-property-details/" + property_id, formData)
          .then(response => {
            this.formSubmitting = false;
          })
          .catch(error => {
            console.error(error?.response?.data);
          })
          .finally(res => {
            sessionStorage.setItem('formStep', 3);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      }
    },
    moveToFormFour() {
      this.$emit("moveToFormFour", 4);
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
