<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step 2
            </h5>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label for="property_class">Select Property Class</label>
                <select
                    class="form-control builders-form__input"
                    id="property_class"
                    v-model="property_class"
                    :class="$v.property_class.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select Property Class</option>
                    <option
                        v-for="item in propertyClassOptions"
                        :value="item"
                        :key="'class-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.property_class.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="property_type">Select Property Type</label>
                <select
                    class="form-control builders-form__input"
                    id="property_type"
                    v-model="property_type"
                    :class="$v.property_type.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select Property Type</option>
                    <option
                        v-for="item in propertyTypesOptions"
                        :value="item"
                        :key="'type-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.property_type.$error">
                    This is required.
                </div>
            </div>
            <div class="form-group">
                <label for="property_status">Select Property Status</label>
                <select
                    class="form-control builders-form__input"
                    id="property_status"
                    v-model="property_status"
                    :class="$v.property_status.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select Property Status</option>
                    <option
                        v-for="item in propertyStatusOptions"
                        :value="item"
                        :key="'status-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.property_status.$error">
                    This is required.
                </div>
            </div>

            <div class="form-group">
                <label for="style">Select Style</label>
                <select
                    class="form-control builders-form__input"
                    id="style"
                    v-model="style"
                    :class="$v.style.$error ? 'is-invalid' : ''"
                >
                    <option :value="''">Select Style</option>
                    <option
                        v-for="item in propertyStyleOptions"
                        :value="item"
                        :key="'style-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
                <div class="invalid-feedback" v-if="$v.style.$error">
                    This is required.
                </div>
            </div>

            <div class="form-group">
                <label for="list_price">Price</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="list_price"
                    placeholder="Enter property list price"
                    v-model="list_price"
                    :class="$v.list_price.$error ? 'is-invalid' : ''"
                />
                <div class="invalid-feedback" v-if="$v.list_price.$error">
                    This is required.
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";
import { requiredValidationFields } from "../../../../utils";

const initFormFields = {
    community: "",
    property_class: "",
    property_type: "",
    property_status: "",
    list_price: "",
    style: "",
};

export default {
    name: "FormStepTwo",
    props: {
        communityList: {
            default: [],
        },
        propertyDetails: {
            default: {},
        },
        isEdit: {
            default: false,
        },
    },
    data() {
        return {
            cities: {},
            communities: {},
            propertyClassOptions: ["ResidentialProperty", "CondoProperty"],
            propertyStatusOptions: ["Sale", "Lease"],
            propertyTypesOptions: [
                "Detached",
                "Semi-Detached",
                "Townhouse",
                "Vacant Land",
                "Rural Resid",
                "Duplex",
                "Store W/Apt/Offc",
                "Land",
                "Condo Apartment",
                "Condo Townhouse",
            ],
            propertyStyleOptions: [
                "2-Storey",
                "3-Storey",
                "Backsplit",
                "Bungalow",
                "Bungalow-Raised",
                "Other",
                "Sidesplt",
            ],
            ...initFormFields,
        };
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key];
                }
            }
        },
        next() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    3,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
    },
    validations: {
        ...requiredValidationFields(initFormFields),
    },
};
</script>
