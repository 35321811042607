<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step
                {{ currentStep }}
            </h5>
        </div>
        <div class="card-body">
            <!-- Den Family Room -->
            <div class="form-group">
                <label>Den Family Room</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="den_family_room"
                            id="den_family_room_1"
                            value="Yes"
                            v-model="den_family_room"
                        />
                        <label class="form-check-label" for="den_family_room_1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="den_family_room"
                            id="den_family_room_0"
                            value="No"
                            v-model="den_family_room"
                        />
                        <label class="form-check-label" for="den_family_room_0"
                            >No</label
                        >
                    </div>
                </div>
                <!-- <div class="invalid-feedback" v-if="$v.den_family_room.$error">
                    This is required.
                </div> -->
            </div>

            <!-- Electricity -->
            <div class="form-group">
                <label>Electricity</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="electricity"
                            id="electricity_1"
                            value="Yes"
                            v-model="electricity"
                        />
                        <label class="form-check-label" for="electricity_1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="electricity"
                            id="electricity_0"
                            value="No"
                            v-model="electricity"
                        />
                        <label class="form-check-label" for="electricity_0"
                            >No</label
                        >
                    </div>
                </div>
            </div>

            <!-- Gas -->
            <div class="form-group">
                <label>Gas</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="gas"
                            id="gas_1"
                            value="Yes"
                            v-model="gas"
                        />
                        <label class="form-check-label" for="gas_1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="gas"
                            id="gas_0"
                            value="No"
                            v-model="gas"
                        />
                        <label class="form-check-label" for="gas_0">No</label>
                    </div>
                </div>
            </div>

            <!-- Cable -->
            <div class="form-group">
                <label>Cable</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="cable"
                            id="cable_1"
                            value="Yes"
                            v-model="cable"
                        />
                        <label class="form-check-label" for="cable_1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="cable"
                            id="cable_0"
                            value="No"
                            v-model="cable"
                        />
                        <label class="form-check-label" for="cable_0">No</label>
                    </div>
                </div>
            </div>

            <!-- Telephone -->
            <div class="form-group">
                <label>Telephone</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="telephone"
                            id="telephone_1"
                            value="Yes"
                            v-model="telephone"
                        />
                        <label class="form-check-label" for="telephone_1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="telephone"
                            id="telephone_0"
                            value="No"
                            v-model="telephone"
                        />
                        <label class="form-check-label" for="telephone_0"
                            >No</label
                        >
                    </div>
                </div>
            </div>

            <!-- Fireplace -->
            <div class="form-group">
                <label>Fireplace</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="fire_place"
                            id="fire_place_1"
                            value="Yes"
                            v-model="fire_place"
                        />
                        <label class="form-check-label" for="fire_place_1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="fire_place"
                            id="fire_place_0"
                            value="No"
                            v-model="fire_place"
                        />
                        <label class="form-check-label" for="fire_place_0"
                            >No</label
                        >
                    </div>
                </div>
            </div>

            <!-- Air Conditioning -->
            <div class="form-group">
                <label for="ac">Air Conditioning</label>
                <input
                    class="form-control builders-form__input"
                    id="ac"
                    placeholder="Enter ac type"
                    v-model="ac"
                />
            </div>

            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";
import { requiredValidationFields } from "../../../../utils";

const initFormFields = {
    den_family_room: 0,
    fire_place: 0,
    electricity: 0,
    gas: 0,
    cable: 0,
    telephone: 0,
    ac: "",
};
export default {
    name: "FormStepFive",
    props: {
        propertyDetails: {
            default: {},
        },
        currentStep: {},
        isEdit: {
            default: false,
        },
    },
    data() {
        return {
            ...initFormFields,
        };
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key];
                }
            }
        },
        next() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    this.currentStep + 1,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
    },
    validations: {
        // ...requiredValidationFields(initFormFields),
    },
};
</script>
