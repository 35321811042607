<template>
    <div
        class="property_feature_listings_box_item"
        v-b-modal="'modal-center-' + unique_code"
    >
        <div class="property_feature_listings_box_item_image">
            <img
                :src="covers[0]"
                @error="handleError"
                class="img-fluid"
                :alt="property_name"
            />
            <span class="type_point">{{ building_status }}</span>
            <!-- <span class="sale">{{ saleorlease }}</span> -->
            <!-- <span class="image"
          ><img
            src="/images/camera.png"
            class="img-fluid"
          />{{ total_photos }}</span
        >
        <span class="video"
          ><img
            src="/images/play.png"
            class="img-fluid"
          />{{ total_videos }}</span
        > -->
        </div>
        <div class="property_feature_listings_box_item_content">
            <h4 class="project-name">{{ property_name }}</h4>
            <span class="location">{{ street_address }}</span>
        </div>
        <div class="property_feature_listings_box_item_bottomcontent">
            <span class="bedroom"
                ><img src="/images/double-bed.png" class="img-fluid" />{{
                    beds
                }}
                Beds</span
            >
            <span class="bathroom"
                ><img src="/images/fbath.png" class="img-fluid" />{{
                    bathrooms
                }}
                Baths</span
            >
            <span class="garage"
                ><img src="/images/garage.png" class="img-fluid" />{{
                    garage
                }}
                Garage</span
            >
            <span class="measure"
                ><img src="/images/measure.png" class="img-fluid" />{{
                    sqft
                }}
                Sq Ft</span
            >
        </div>
        <div class="property_feature_listings_box_item_price">
            <span class="price"
                >{{ price || 'Register Now' }}</span
            >
        </div>
        <!-- Modal Here Start -->
        <div>
            <b-modal
                :id="'modal-center-' + unique_code"
                centered
                title="Property Detail"
                hide-header
                hide-footer
            >
                <span
                    @click="
                        $bvModal.hide('modal-center-' + unique_code)
                    "
                >
                    <b-icon
                        icon="x-circle"
                        class="mb-3"
                        scale="2"
                        style="float: right; cursor: pointer"
                        variant="danger"
                    ></b-icon>
                </span>
                <div
                    id="carouselExampleIndicators"
                    class="carousel slide mt-3"
                    data-ride="carousel"
                >
                    <ol class="carousel-indicators">
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="0"
                            class="active"
                        ></li>
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="1"
                        ></li>
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="2"
                        ></li>
                    </ol>
                    <div class="carousel-inner">
                        <div
                            class="carousel-item active"
                        >
                            <img
                                :src="covers[0]"
                                @error="handleError"
                                class="rounded img-fluid"
                                alt="image"
                            />
                        </div>
                        <div
                            class="carousel-item"
                        >
                            <img
                                :src="covers[1]"
                                @error="handleError"
                                class="rounded img-fluid"
                                alt="image"
                            />
                        </div>
                        <div
                            class="carousel-item"
                        >
                            <img
                                :src="covers[2]"
                                @error="handleError"
                                class="rounded img-fluid"
                                alt="image"
                            />
                        </div>
                    </div>
                    <a
                        class="carousel-control-prev"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="prev"
                    >
                        <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                        ></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a
                        class="carousel-control-next"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="next"
                    >
                        <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                        ></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <p class="my-4">
                    {{ street_address }}
                </p>
                <div
                    class="property_feature_listings_box_item_bottomcontent text-center"
                >
                    <span class="bedroom"
                        ><img
                            src="/images/double-bed.png"
                            class="img-fluid"
                        />{{ beds }} Beds</span
                    >
                    <span class="bathroom"
                        ><img src="/images/fbath.png" class="img-fluid" />{{
                            bathrooms
                        }}
                        Baths</span
                    >
                    <span class="garage"
                        ><img src="/images/garage.png" class="img-fluid" />{{
                            garage
                        }}
                        Garage</span
                    >
                    <span class="measure"
                        ><img src="/images/measure.png" class="img-fluid" />{{
                            sqft
                        }}
                        Sq Ft</span
                    >
                </div>
                <div class="property_feature_listings_box_item_price">
                    <span class="price"
                        >{{ price || 'Register Now' }}</span
                    >
                    <a
                        class="btn btn-outline-primary ms-auto"
                        style="margin-left: 100px"
                        :href="plan_details_url"
                        >View Full Listing</a
                    >
                </div>
            </b-modal>
        </div>
        <!-- Modal Here end -->
    </div>
</template>

<script>
export default {
    name: "SearchHomePropertyCard",
    props: {
        property: {
            default: {},
        },
    },
    data() {
        return {
            unique_code: "",
            property_info: {},
        };
    },
    mounted() {
        this.property_info = this.property;
        this.unique_code = this.property?.unique_code;
    },
    methods: {
        strCommafy(inVal) {
            return inVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        handleError(event) {
            event.preventDefault();
            event.target.src = "/assets/images/no-image-available.png";
        },
        priceFormat(num) {
            console.log("num",num)
            try {
                const val = (Math.round(+num * 100) / 100).toFixed();
                if (val) {
                    return (
                        "$" +
                        val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                }
                return null;
            } catch (err) {
                console.log("Price Format Error", err);
                return null;
            }
            return null;
        },
    },
    computed: {
        street_address() {
            if (
                this.property_info?.street_address &&
                this.property_info?.street_address !== "null"
            ) {
                let street_address = this.property_info.street_address;
                return street_address;
            } else {
                return "No address available";
            }
        },
        area_address() {
            let area_address = "";
            if (this.property_info?.Municipality) {
                area_address =
                    area_address + ", " + this.property_info.Municipality;
                if (this.property_info?.County) {
                    area_address =
                        area_address + ", " + this.property_info.County;
                }
                if (this.property_info?.Zip) {
                    area_address = area_address + ", " + this.property_info.Zip;
                }
            }
            if (area_address) {
                return area_address;
            } else {
                return "-";
            }
        },
        full_address() {
            if (this.property_info?.Addr) {
                let full_address = this.property_info.Addr;
                if (this.property_info?.Municipality) {
                    full_address =
                        full_address + ", " + this.property_info.Municipality;
                    if (this.property_info?.County) {
                        full_address =
                            full_address + ", " + this.property_info.County;
                    }
                }
                return full_address;
            } else {
                return "-";
            }
        },
        property_name() {
            if (this.property_info?.Addr) {
                return this.property_info.Addr;
            } else if (this.property_info?.Ml_num) {
                return "Property " + this.property_info.Ml_num;
            } else {
                return "";
            }
        },
        plan_details_url() {
            return this.property_info?.plan_details_url;
        },
        price() {
            if (this.property_info?.list_price) {
                return this.priceFormat(this.property_info.list_price);
            } else {
                return null;
            }
        },
        beds() {
            if (this.property_info?.bedroom) {
                return this.property_info.bedroom;
            } else {
                return "0";
            }
        },
        bathrooms() {
            if (this.property_info?.bathroom) {
                return this.property_info.bathroom;
            } else {
                return "0";
            }
        },
        garage() {
            if (this.property_info?.garage) {
                return this.property_info.garage;
            } else {
                return "0";
            }
        },
        sqft() {
            if (this.property?.sqft_area) {
                return this.property.sqft_area;
            } else {
                return "0sqft";
            }
        },
        building_status() {
            if (this.property_info?.building_status) {
                return this.property_info.building_status;
            } else {
                return "New Construction";
            }
        },
        covers() {
            const images = this.property_info?.covers || [];
            return [0, 1, 2].map((_, index) => {
                console.log("index", index);
                return images[index] || "/assets/images/no-image-available.png";
            });
        },
        property_name(){
            return this.property_info?.property_name || "Property";
        }
    },
    filters: {
        capitalize(str) {
            let strVal = str.toString();
            return strVal.toUpperCase();
        },
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>
<style scoped>
    .project-name{
        font-size: 20px;
        font-weight: 600;
    }
</style>
