<template>
    <div>
        <p class="step-label">EDIT PROPERTY - Step 1</p>
        <div class="form-split">
            <div>
                <!-- Type of Sale -->
                <div class="form-group radio-group">
                    <label class="d-block">Type of Sale</label>
                    <div
                        v-for="option in typeOfSaleOptions"
                        class="form-check form-check-inline"
                        :key="option.value"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            v-model="building_status"
                            name="building_status"
                            :id="option.label"
                            :value="option.value"
                        />
                        <label class="form-check-label" :for="option.label">{{
                            option.label
                        }}</label>
                    </div>
                </div>

                <!-- Type of New Property -->
                <div class="form-group radio-group">
                    <label class="d-block">Type of New Property</label>
                    <div
                        v-for="option of typeOfNewPropertyOptions"
                        class="form-check form-check-inline"
                        :key="option.value"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            v-model="child_or_parent"
                            name="type_of_new_property"
                            :id="option.label"
                            :value="option.value"
                        />
                        <label class="form-check-label" :for="option.label">{{
                            option.label
                        }}</label>
                    </div>
                </div>

                <!-- Property / Project Name -->
                <div class="form-group">
                    <label class="d-block"
                        >Property / Project Name
                        <span class="required">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="project_name"
                        :class="builder_name_err ? 'is-invalid' : ''"
                    />
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="project_name_err"
                    >
                        Project name is required
                    </small>
                </div>

                <!-- Builder Name -->
                <div class="form-group">
                    <label class="d-block"
                        >Builder Name <span class="required">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="builder_name"
                        :class="builder_name_err ? 'is-invalid' : ''"
                    />
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="builder_name_err"
                    >
                        Builder name is required
                    </small>
                </div>

                <!-- Seller Name -->
                <div class="form-group">
                    <label class="d-block" for="seller">Seller Name</label>
                    <input
                        type="text"
                        id="seller"
                        class="form-control"
                        v-model="seller"
                    />
                </div>
            </div>
            <div>
                <!-- Address -->
                <div class="form-group">
                    <label class="d-block"
                        >Address <span class="required">*</span></label
                    >
                    <input type="hidden" v-model="builder_address" />
                    <vue-google-autocomplete
                        id="map"
                        ref="mapElement"
                        class="form-control"
                        placeholder="Builder address"
                        :country="['ca']"
                        :class="builder_address_err ? 'is-invalid' : ''"
                        @placechanged="getAddressData"
                    ></vue-google-autocomplete>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="builder_address_err"
                    >
                        Address is required
                    </small>
                </div>

                <!-- Select City -->
                <div class="form-group">
                    <label class="d-block" for="city"
                        >Select City <span class="required">*</span></label
                    >
                    <select
                        name="city"
                        id="city"
                        :class="project_city_err ? 'is-invalid' : ''"
                        v-model="project_city"
                        class="form-control"
                    >
                        <option value="">-- Select City --</option>
                        <option :value="item.id" v-for="item in cityList">
                            {{ item.city }}
                        </option>
                    </select>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="project_city_err"
                    >
                        Project city is required
                    </small>
                </div>

                <!-- House Type -->
                <!-- <div class="form-group">
                    <label class="d-block">House Type <span class="required">*</span></label>
                    <select name="project_city" id="project_city" class="form-control"
                        :class="home_type_err ? 'is-invalid' : ''" v-model="home_type">
                        <option value="">-- Select Type --</option>
                        <option :value="item" v-for="item in homeTypes">{{ item }}</option>
                    </select>
                    <small class="invalid-feedback" role="alert" v-if="home_type_err">
                        House type is required
                    </small>
                </div> -->

                <!-- Seller Type -->
                <div class="form-group">
                    <label class="d-block" for="seller_type">Seller Type</label>
                    <select
                        v-model="type"
                        id="seller_type"
                        class="form-control"
                    >
                        <option value="">-- Select Type --</option>
                        <option :value="item" v-for="item in sellerTypes">
                            {{ item }}
                        </option>
                    </select>
                </div>

                <!-- Seller Type -->
                <div class="form-group">
                    <label class="d-block" for="community">Community</label>
                    <input
                        type="text"
                        class="form-control"
                        id="community"
                        v-model="community"
                    />
                </div>
            </div>
        </div>
        <div class="form-button-group">
            <button
                class="save-btn"
                @click="handleSave('save')"
                :disabled="isSaving"
            >
                <span
                    v-if="isSaving && currentActionBtn === 'save'"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Save
            </button>
            <button
                class="reset-btn"
                @click="handleReset()"
                :disabled="isSaving"
            >
                Reset
            </button>
            <button
                class="next-btn"
                @click="handleSave('next')"
                :disabled="isSaving"
            >
                <span
                    v-if="isSaving && currentActionBtn === 'next'"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Next
            </button>
        </div>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import pick from "lodash/pick";

const initialFormFields = {
    building_status: "Pre-construction",
    child_or_parent: "Parent",
    home_type: "",
    builder_name: "",
    project_name: "",
    project_city: "",
    builder_address: "",
    community: "",
    type: "", //Seller type
    seller: "", // Seller name
};
export default {
    name: "Step1",
    props: {
        cityList: [],
        city: "",
        propertyDetails: {},
        isSaving: false,
    },
    data() {
        return {
            sellerTypes: ["seller", "builder", "admin"],
            ...initialFormFields,
            typeOfSaleOptions: [
                {
                    label: "Pre-construction",
                    value: "Pre-construction",
                },
                {
                    label: "Inventory",
                    value: "Inventory",
                },
                {
                    label: "Assignment Sale",
                    value: "Assignment sale",
                },
            ],
            typeOfNewPropertyOptions: [
                {
                    label: "Parent",
                    value: "Parent",
                },
                {
                    label: "Child",
                    value: "Child",
                },
            ],
            homeTypes: [
                "Detached",
                "Semi-detached",
                "Town House",
                "Condo Town House",
                "Condo Apartment",
                "Condominium",
                "Condo",
            ],
        };
    },
    created() {
        this.setExistingFormData();
    },
    validations: {
        project_name: {
            required,
        },
        builder_name: {
            required,
        },
        project_city: {
            required,
        },
        // home_type: {
        //     required,
        // },
        builder_address: {
            required,
        },
    },
    computed: {
        project_name_err() {
            return !!this.$v.project_name.$error;
        },
        builder_name_err() {
            return !!this.$v.builder_name.$error;
        },
        project_city_err() {
            return !!this.$v.project_city.$error;
        },
        home_type_err() {
            return !!this.$v.home_type.$error;
        },
        builder_address_err() {
            return !!this.$v.builder_address.$error;
        },
    },
    methods: {
        getAddressData(addressData, placeResultData, id) {
            if (placeResultData?.formatted_address) {
                this.builder_address = placeResultData.formatted_address;
            }
        },
        handleSave(actionType) {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.currentActionBtn = actionType;
                this.$v.$reset();
                const formData = pick(this, Object.keys(initialFormFields));
                this.$emit("save", formData);
            }
        },
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
        },
        setExistingFormData() {
            for (let key in initialFormFields) {
                this[key] = this.propertyDetails[key] || initialFormFields[key];
            }
        },
    },
};
</script>

<style></style>
