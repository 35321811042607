<template>
  <div class="reg_form_box property_appo" id="datepick">
    <div class="reg_form_box_title">
      <h1>Schedule Appointment</h1>
    </div>
    <form>
      <div class="form-row" v-if="formSubmitted">
        <div class="col-md-12 mb-4">
          <div class="alert" :class="responseClass" role="alert">{{ responseMessage }}</div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-4">
          <div class="input_star">
            <input type="date" class="form-control" v-model="date" />
          </div>
          <small class="text-danger d-block" role="alert" v-if="dateError">
            <strong>{{ dateErrorMessage }}</strong>
          </small>
        </div>
        <div class="col-md-12 mb-4">
          <div class="input_star">
            <vue-timepicker
              ref="appointment_time"
              v-model="time"
              :hour-range="['10a', '11a', '12p', '1p', '2p', '3p', '4p', '5p', '6p', '7p']"
              format="hh:mm A"
              :minute-interval="5"
              close-on-complete
              auto-scroll
            />
          </div>
          <small class="text-danger d-block" role="alert" v-if="timeError">
            <strong>{{ timeErrorMessage }}</strong>
          </small>
        </div>
        <div class="col-md-12 mb-4">
          <div class="input_star">
            <input
              type="text"
              class="form-control"
              :class="nameError ? 'is-invalid' : ''"
              placeholder="Name"
              v-model="name"
            />
          </div>
          <small class="text-danger d-block" role="alert" v-if="nameError">
            <strong>{{ nameErrorMessage }}</strong>
          </small>
        </div>
        <div class="col-md-12 mb-4">
          <div class="input_star">
            <input
              type="text"
              class="form-control"
              :class="phoneError ? 'is-invalid' : ''"
              placeholder="Phone Number (10 digits only)"
              v-mask="'###-###-####'"
              v-model="phone"
            />
          </div>
          <small class="text-danger d-block" role="alert" v-if="phoneError">
            <strong>{{ phoneErrorMessage }}</strong>
          </small>
        </div>
        <div class="col-md-12 mb-4">
          <div class="input_star">
            <input
              type="email"
              class="form-control"
              :class="emailError ? 'is-invalid' : ''"
              placeholder="Email"
              v-model="email"
            />
          </div>
          <small class="text-danger d-block" role="alert" v-if="emailError">
            <strong>{{ emailErrorMessage }}</strong>
          </small>
        </div>
        <div class="col-md-12 mb-4">
          <div class="input_star">
            <textarea
              class="form-control"
              :class="messageError ? 'is-invalid' : ''"
              placeholder="Message"
              v-model="message"
            ></textarea>
          </div>
          <small class="text-danger d-block" role="alert" v-if="messageError">
            <strong>{{ messageErrorMessage }}</strong>
          </small>
        </div>
        <div class="normal_text">
          <span>
            <i class="fas fa-info-circle"></i>Please send me information of and include price lists and floor plans.
          </span>
        </div>
        <div class="col-md-12">
          <div class="form_submit_button">
            <button
              type="button"
              @click.prevent="scheduleAppointment"
              :disabled="processing"
              :style="processing ? 'opacity:0.6;cursor:not-allowed;' : ''"
            >{{ processing ? "Processing..." : "Schedule Appointment" }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.js";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "MakeAppointmentForm",
  props: {
    contact_name: {
      default: ""
    },
    contact_email: {
      default: ""
    },
    contact_phone: {
      default: ""
    },
  },
  mounted() {
    this.name = this.contact_name;
    this.email = this.contact_email;
    this.phone = this.contact_phone;
  },
  data() {
    return {
      time: "",
      date: "",
      name: "",
      phone: "",
      message: "",
      email: "",
      processing: false,
      responseClass: "alert-light",
      responseMessage: "",
      formSubmitted: false
    };
  },
  components: {
    "vue-timepicker": VueTimepicker
  },
  methods: {
    scheduleAppointment() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const formData = new FormData();
        formData.append("time", this.time);
        formData.append("date", this.date);
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("message", this.message);
        axios
          .post("/submit-callback-request", formData)
          .then(response => {
            let msg = "Successfully scheduled appointment!";
            if (response?.data?.message) {
              msg = response.data.message;
            }
            this.responseMessage = msg;
            this.responseClass = "alert-success";
            this.$v.$reset();
            this.time = "";
            this.date = "";
            this.name = "";
            this.email = "";
            this.phone = "";
            this.message = "";
          })
          .catch(error => {
            let msg = "Unable to schedule appointment!";
            this.responseClass = "alert-danger";
            if (error?.response?.data?.message) {
              msg = error.response.data.message;
            }
            this.responseMessage = msg;
          })
          .finally(res => {
            this.formSubmitted = true;
            setTimeout(() => {
              this.formSubmitted = false;
              this.responseClass = "alert-light";
              this.responseMessage = "";
              this.$refs.appointment_time.clearTime();
            }, 5000);
          });
      }
    }
  },
  validations: {
    time: {
      required
    },
    date: {
      required
    },
    name: {
      required
    },
    email: {
      required,
      email
    },
    phone: {
      required,
      minLength: minLength(12)
    },
    message: {
      required
    }
  },
  computed: {
    timeError() {
      if (this.$v.time.$error) {
        return true;
      } else {
        return false;
      }
    },
    timeErrorMessage() {
      if (this.$v.time.$error) {
        return "Please select e valid time";
      } else {
        return "";
      }
    },
    dateError() {
      if (this.$v.date.$error) {
        return true;
      } else {
        return false;
      }
    },
    dateErrorMessage() {
      if (this.$v.date.$error) {
        return "Please select e valid date";
      } else {
        return "";
      }
    },
    nameError() {
      if (this.$v.name.$error) {
        return true;
      } else {
        return false;
      }
    },
    nameErrorMessage() {
      if (this.$v.name.$error) {
        return "Name is required";
      } else {
        return "";
      }
    },
    phoneError() {
      if (this.$v.phone.$error) {
        return true;
      } else {
        return false;
      }
    },
    phoneErrorMessage() {
      if (this.$v.phone.$error) {
        if (!this.$v.phone.required) {
          return "Phone number is required";
        } else if (!this.$v.phone.minLength) {
          return "Phone number should be 10 digits";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    emailError() {
      if (this.$v.email.$error) {
        return true;
      } else {
        return false;
      }
    },
    emailErrorMessage() {
      if (this.$v.email.$error) {
        if (!this.$v.email.required) {
          return "Email is required";
        } else if (!this.$v.email.email) {
          return "Please enter a valid email address";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    messageError() {
      if (this.$v.message.$error) {
        return true;
      } else {
        return false;
      }
    },
    messageErrorMessage() {
      if (this.$v.message.$error) {
        if (!this.$v.message.required) {
          return "Message is required";
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }
};
</script>

<style>
span.vue__time-picker.time-picker {
  height: calc(1.5em + 0.75rem + 9px);
  margin-top: 0;
  width: 100%;
}
span.vue__time-picker.time-picker > input.display-time {
  border: 1px solid #e9eaeb;
  border-radius: 3px;
  height: calc(1.5em + 0.75rem + 9px);
  padding: 5px 14px !important;
  position: relative;
  width: 100%;
  height: 100%;
}
span.vue__time-picker.time-picker > div.dropdown.drop-down {
  width: 100%;
}
span.vue__time-picker.time-picker > div.dropdown.drop-down > .select-list {
  width: 100%;
}
</style>