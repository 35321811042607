<template>
    <div>
        <div class="card builders-form-card">
            <div class="card-body">
                <h3 class="builder-form__title">Home Information’s</h3>
                <p class="builder-form__subtitle">Enter Details</p>
            
                <div class="form-group row">
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="price"
                            class="form-control builders-form__input"
                            placeholder="Price"
                            :class="price_err ? 'is-invalid' : ''"
                        />
                        <p class="invalid-feedback" role="alert" v-if="price_err">
                            {{ price_err }}
                        </p>
                    </div>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="completed_at"
                            class="form-control builders-form__input"
                            placeholder="Completion Date"
                            title="Completion Date"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="bed"
                            class="form-control builders-form__input"
                            placeholder="Bed"
                            :class="bed_err ? 'is-invalid' : ''"
                        />
                        <p class="invalid-feedback" role="alert" v-if="bed_err">
                            {{ bed_err }}
                        </p>
                    </div>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="bath"
                            class="form-control builders-form__input"
                            placeholder="Bath"
                            :class="bath_err ? 'is-invalid' : ''"
                        />
                        <p class="invalid-feedback" role="alert" v-if="bath_err">
                            {{ bath_err }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="sqft"
                            class="form-control builders-form__input"
                            placeholder="Sqft"
                            :class="sqft_err ? 'is-invalid' : ''"

                        />
                        <p class="invalid-feedback" role="alert" v-if="sqft_err">
                            {{ sqft_err }}
                        </p>
                    </div>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="price_per_sqft"
                            class="form-control builders-form__input"
                            placeholder="Price/sqft"
                        />
                    </div>
                </div>
                
                <!-- Ammonites -->
                <div class="form-group">
                    <label for="">Amenities</label>
                    <multiselect
                        v-model="amnities"
                        :options="amenitiesOptions"
                        :multiple="true"
                    ></multiselect>
                </div>
                <!-- Virtual Tour -->
                <div class="form-group">
                    <label for="virtual_tour">Virtual Tour</label>
                    <input
                        type="text"
                        id="virtual_tour"
                        v-model="virtual_tour"
                        class="form-control builders-form__input"
                        placeholder="Enter Virtual Tour Url"
                    />
                </div>
                <!-- Deposite Structure -->
                <div class="form-group">
                    <ckeditor
                        :editor="editor"
                        v-model="deposit_structure"
                        :config="edtConfigForDepositStructure"
                    ></ckeditor>
                </div>

                <div class="form-group">
                    <ckeditor
                        :editor="editor"
                        v-model="project_description"
                        :config="editorConfig"
                    ></ckeditor>
                </div>
                <div class="form-group">
                    <ckeditor
                        :editor="editor"
                        v-model="community_description"
                        :config="editorConfig"
                    ></ckeditor>
                </div>
                <div class="form-group text-center">
                    <button
                        class="btn btn-danger builders-form-prev-btn"
                        @click.prevent="goBackToFormOne"
                    >
                        BACK
                    </button>
                    <button
                        class="btn btn-danger builders-form-next-btn"
                        @click.prevent="moveToFormThree"
                    >
                        CONTINUE
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const initialFormFields = {
    price: "",
    completed_at: "",
    bed: "",
    bath: "",
    sqft: "",
    price_per_sqft: "",
    deposit_structure: "",
    amnities: [],
    virtual_tour: "",
    project_description: "<p>No property description available.</p>",
    community_description: "<p>No community description available.</p>",
};
export default {
    name: "BuilderFormTwo",
    data() {
        return {
            ...initialFormFields,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "outdent",
                        "indent",
                        "undo",
                        "redo",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "blockQuote",
                        "insertTable",
                    ],
                }
            },
            edtConfigForDepositStructure:{
                ...this.editorConfig,
                placeholder:'Enter Deposit Structure'
            },
            amenitiesOptions: [
                "Rooftop Terrace",
                "Lounge with Wet Bar",
                "Dog Wash Station",
                "24/7 Concierge",
                "Bike Storage",
                "Catering Kitchen",
                "Fitness Centre",
                "Guest Suite",
                "Outdoor Reading Rooms",
                "Entertainment Lounge",
                "Conference Room",
                "Garden Suite",
                "Lobby Lounge",
                "Sun bathing areas",
                "Private Elevators",
                "Party Room",
                "Fitness Room",
                "Garden Terrace",
                "Lobby Bar",
                "Valet Parking",
                "Infinity Pool",
                "Outdoor Pool",
                "Sun Deck",
                "Parcel and Mail Room",
                "Outdoor Terrace with Fire pit",
                "Cabanas",
                "Co-working Lounge",
                "Sauna",
                "Outdoor BBQ",
                "Concierge",
                "Entertainment Space",
                "Al Fresco Dining and Grilling Areas",
                "Green Roof",
                "Private Garden Patio",
                "Porte Cochere",
                "Rooftop Lounge",
                "Stretch Room",
                "Landscaped Courtyard",
                "Patio Lounge",
                "Outdoor Dining",
                "Package Room",
                "BBQ Station",
                "Catering Kitchen and Dining",
                "Fitness Studio",
                "Lobby",
                "24 Hour Virtual Concierge",
                "Parcel Room",
                "Outdoor Terrace",
                "Storage Room",
                "Ping Pong Table",
                "Pilates Studio",
                "Chef’s Table and Wine Tasting Room",
                "BBQ Terrace",
                "Foosball Table",
                "Zen Space",
                "WiFi Lounge",
                "Theatre Room",
                "Karaoke Area",
                "Outdoor Garden",
                "Lounge",
                "Pool",
                "Discovery Den",
                "Mail Room",
                "Kids Zone",
                "Lounge Area",
                "Pet Wash",
                "Potting Shed",
                "Playground",
                "Private Dining Room", 
                "Social Lounge", 
                "Dining Bar", 
                "Social Lounge", 
                "Pet Spa", 
                "Outdoor Movie Theatre", 
                "Fire Pit", 
                "Yoga and Stretching Space", 
                "Media Lounge", 
                "Indoor Bike Storage", 
                "EV Charging", 
                "Locker Rooms",
                "Smart Home Technology",
                "Smart Home Technology",
                "Concierge Attended Lobby",
                "Grand Lobby Lounge",
                "Fire Pit",
                "Party Rooms",
                "Yoga Studio",
                "outdoor terrace with BBQ",
                "Bike Parking",
                "Pet Grooming Room",
                "Video Games Room",
                "Relaxation Lounge",
                "Virtual Bike Room",
                "Yoga Room",
                "Billiards Room",
                "Co-Work Lounge",
                "Meeting Room",
                "heated stone lounger",
                "Meeting Room",
                "Outdoor Relaxation Area",
                "Swimming Pool",
                "Library",
                "Bike Gear Room",
                "Bowling Alley",
                "Bike Elevator",
                "Hot Tub",
                "Bike Lockers",
                "Steam Room",
                "Bike Wash Station",
                "Games Room",
                "spin class studio",
                "Conference Rooms",
                "KTV room",
                "parcel locker rooms",
                "Music Room",
                "Social Room",
                "Guest Suites",
                "Virtual Golf Simulator",
                "Outdoor Lounge",
                "Garden Cafe", 
                "Yoga Studio", 
                "Fireplace Terrace", 
                "Party Lounge", 
                "Gaming Hub", 
                "Sound Studio", 
                "Gym",
                "Spin Room", 
                "gym lounge", 
                "Weight Room", 
                "Whirlpool", 
                "Security System", 
                "Underground Private Parking Garage", 
                "Decks", 
                "Patio Spaces", 
                "In Suite Elevator", 
                "Heated Laneway", 
                "Boardroom", 
                "Private Terrace", 
                "Terrace", 
                "Dining Room", 
                "Indoor Pool", 
                "Bar", 
                "Private BBQ/Dining Area",
                "Outdoor Yoga and Zen Garden",
                "Art Studio",
                "Library Lounge",
                "Outdoor Theatre",
                "Demo Kitchen",
                "Juice & Coffee Bar",
                "Outdoor firepit lounge",
                "Shared Work Space",
                "Steam & Sauna Room",
                "24 Hour Concierge Service",
                "Spa Lounge",
                "Social Club",
                "Outdoor Games Lounge",
                "BBQ Area",
                "Parcel Storage",
                "Personal Training Studio",
                "Private Terraces and Balconies",
                "Parking Stackers",
                "Bike Racks",
                "Fitness Equipment",
                "BBQ Area",
                "Chet's Kitchen",
                "Outdoor Patio",
                "Rooftop Patio",
                "Party Room with Kitchen and Dining Room",
                "Bicycle Storage",
                "24 Hour Concierge",
                "Outdoor Terrace with BBQ and Pergola",
                "Lounge with seating and wetbar",
                "Parcel Storage Room",
                "Secure Key Fob",
                "Guest Parking",
                "Balconies and/or Terraces",
                "Guest Parking",
                "Balconies and/or Terraces",
                "outdoor bbq and seating",
                "reception parlour",
                "Covered Terrace",
                "Valet Service",
                "Private Function Room",
                "24 Hour Concierge Service",
                "Fireplace",
                "Courtyard",
                "Pet Grooming Station",
                "Seating Area",
                "Kitchen",
                "Car Share",
                "Electric Charging Station Available",
                "Games Deck",
                "The Speakeasy Room",
                "Yoga Area",
                "Recharge Retreat",
                "Parking",
                "Plush Lounge",
                "Entertainment Room",
                "Green Space",
                "Coworking Space"
            ],
        };
    },
    created() {
        for (let key of Object.keys(initialFormFields)) {
            const prevValue = localStorage.getItem(key);
            if (key === "amnities") {
                try {
                    this[key] = JSON.parse(prevValue);
                } catch (err) {
                    this[key] = initialFormFields[key];
                }
            } else {
                this[key] = prevValue || initialFormFields[key];
            }
        }
    },
    validations: {
        // price:{
        //     required
        // },
        // bed: {
        //     required,
        // },
        // bath: {
        //     required,
        // },
        // sqft: {
        //     required,
        // },
    },
    methods: {
        goBackToFormOne() {
            this.$emit("backToFormOne", {});
        },
        moveToFormThree() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                // do nothing
            } else {
                this.$v.$reset();
                this.$emit("moveToFormThree", {
                    price: this.price,
                    completed_at: this.completed_at,
                    bed: this.bed,
                    bath: this.bath,
                    sqft: this.sqft,
                    price_per_sqft: this.price_per_sqft,
                    deposit_structure: this.deposit_structure,
                    amnities: JSON.stringify(this.amnities),
                    virtual_tour: this.virtual_tour,
                    project_description: this.project_description,
                    community_description: this.community_description,
                });
            }
        },
    },
    computed: {
        // price_err(){
        //     if(this.$v.price.$error) return "Price is required"
        //     return null
        // },
        // bed_err() {
        //     if (this.$v.bed.$error) {
        //         return "Bedroom is required";
        //     } else {
        //         return null;
        //     }
        // },
        // bath_err() {
        //     if (this.$v.bath.$error) {
        //         return "Bathroom is required";
        //     } else {
        //         return null;
        //     }
        // },
        // sqft_err() {
        //     if (this.$v.sqft.$error) {
        //         return "Sqft is required";
        //     } else {
        //         return null;
        //     }
        // },
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
