<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step
                {{ currentStep }}
            </h5>
        </div>
        <div class="card-body">
            <!-- Select Bedrooms Plus Form Group -->
            <div class="form-group">
                <label for="additional_bedrooms">Select Bedrooms Plus</label>
                <select
                    class="form-control builders-form__input"
                    id="additional_bedrooms"
                    v-model="additional_bedrooms"
                >
                    <option :value="''">Select Bedrooms Plus</option>
                    <option
                        v-for="item in defaultPropertyOption"
                        :value="item"
                        :key="'additional_bedrooms-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>

            <!-- Select Kitchen Form Group -->
            <div class="form-group">
                <label for="kitchens">Select Kitchen</label>
                <select
                    class="form-control builders-form__input"
                    id="kitchens"
                    v-model="kitchens"
                >
                    <option :value="''">Select Kitchen</option>
                    <option
                        v-for="item in defaultPropertyOption"
                        :value="item"
                        :key="'kitchens-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>

            <!-- Select Kitchen Plus Form Group -->
            <div class="form-group">
                <label for="kitchen_plus">Select Kitchen Plus</label>
                <select
                    class="form-control builders-form__input"
                    id="kitchen_plus"
                    v-model="kitchen_plus"
                >
                    <option :value="''">Select Kitchen Plus</option>
                    <option
                        v-for="item in defaultPropertyOption"
                        :value="item"
                        :key="'kitchen_plus-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>

            <!-- Select Rooms Form Group -->
            <div class="form-group">
                <label for="rooms">Select Rooms</label>
                <select
                    class="form-control builders-form__input"
                    id="rooms"
                    v-model="rooms"
                >
                    <option :value="''">Select Rooms</option>
                    <option
                        v-for="item in defaultPropertyOption"
                        :value="item"
                        :key="'rooms-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>

            <!-- Select Room Plus Form Group -->
            <div class="form-group">
                <label for="rooms_plus">Select Room Plus</label>
                <select
                    class="form-control builders-form__input"
                    id="rooms_plus"
                    v-model="rooms_plus"
                >
                    <option :value="''">Select Room Plus</option>
                    <option
                        v-for="item in defaultPropertyOption"
                        :value="item"
                        :key="'rooms_plus-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label for="taxes">Taxes</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="taxes"
                    placeholder="Enter property taxes"
                    v-model="taxes"
                />
            </div>
            <div class="form-group">
                <label for="tax_year">Tax Year</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="tax_year"
                    placeholder="Enter property tax year"
                    v-model="tax_year"
                />
            </div>
            <div class="form-group">
                <label for="approx_age">Approx Age</label>
                <input
                    class="form-control builders-form__input"
                    id="approx_age"
                    placeholder="Enter approx age"
                    v-model="approx_age"
                />
            </div>
            <!-- Ammonites -->
            <div class="form-group">
                <label for="">Amenities</label>
                <multiselect
                    v-model="amenities"
                    :options="amenitiesOptions"
                    :multiple="true"
                ></multiselect>
            </div>

            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";
import Multiselect from "vue-multiselect";

const initFormFields = {
    additional_bedrooms: "",
    kitchens: "",
    kitchen_plus: "",
    rooms_plus: "",
    rooms: "",
    taxes: "",
    tax_year: "",
    approx_age: "",
    amenities: [],
};
const requiredFields = {};
export default {
    name: "FormStepSix",
    props: {
        propertyDetails: {
            default: {},
        },
        currentStep: {},
        isEdit: {
            default: false,
        },
    },
    data() {
        return {
            cities: {},
            communities: {},
            defaultPropertyOption: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            amenitiesOptions: [
                "Vehicle Parking facility",
                "SitoutLiving room",
                "Dining room,Work area",
                "Hall",
                "Landscape Garden",
                "Kitchen",
                "Vitrified",
                "Tiles",
                "Flooring",
                "Study Room",
                "Electricity",
                "Connection",
                "Good residential area",
                "Lorry Access",
            ],
            ...initFormFields,
        };
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key] || initFormFields[key];
                }
            }
        },
        next() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    this.currentStep + 1,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
    },
    validations: {
        // ...requiredValidationFields(requiredFields),
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
