<template>
    <div class="filter-list-header">
        <div class="container filter-list-header-container">
            <!-- Search Box -->
            <div class="search-box mr-3">
                <i class="fas fa-map-marker-alt"></i>
                <input type="text" :placeholder="placeholder" @focus="onSearchFocus" @blur="onSearchBlur"
                    v-model="city">
                <div class="dropdown" v-if="!hideBuySellOptions">
                    <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ sale_or_rent === 'Sale' ? 'Buy' : 'Rent' }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="javascript:void(0)"
                            @click="handleFilterChange('sale_or_rent', 'Sale')"
                            :class="{ 'active': sale_or_rent === 'Sale' }">Buy</a>
                        <a class="dropdown-item" href="javascript:void(0)"
                            @click="handleFilterChange('sale_or_rent', 'Lease')"
                            :class="{ 'active': sale_or_rent === 'Lease' }">Rent</a>
                    </div>
                </div>
                <a :href="city ? '?city=' + city : 'javascript:void(0)'" class="search-btn btn-md">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="d-inline-block d-sm-none">
                        <path
                            d="M12.9179 11.6669H12.2595L12.0262 11.4419C12.547 10.8369 12.9276 10.1242 13.1408 9.3549C13.354 8.5856 13.3946 7.77868 13.2595 6.99189C12.8679 4.67522 10.9345 2.82522 8.6012 2.54189C7.78088 2.43811 6.94769 2.52337 6.16539 2.79113C5.38308 3.0589 4.6724 3.50207 4.08773 4.08675C3.50305 4.67143 3.05987 5.38211 2.79211 6.16441C2.52434 6.94671 2.43909 7.7799 2.54287 8.60022C2.8262 10.9336 4.6762 12.8669 6.99287 13.2586C7.77966 13.3936 8.58658 13.3531 9.35588 13.1398C10.1252 12.9266 10.8378 12.546 11.4429 12.0252L11.6679 12.2586V12.9169L15.2095 16.4586C15.5512 16.8002 16.1095 16.8002 16.4512 16.4586C16.7929 16.1169 16.7929 15.5586 16.4512 15.2169L12.9179 11.6669ZM7.91787 11.6669C5.84287 11.6669 4.16787 9.99189 4.16787 7.91689C4.16787 5.84189 5.84287 4.16689 7.91787 4.16689C9.99287 4.16689 11.6679 5.84189 11.6679 7.91689C11.6679 9.99189 9.99287 11.6669 7.91787 11.6669Z"
                            fill="white" />
                    </svg>
                    <span class="btn-text">Search</span>
                </a>
                <div class="list-group city-dropdown" v-if="showCityDropdown && filteredCities.length">
                    <a v-for="city in filteredCities" href="javascript:void(0)"
                        class="list-group-item list-group-item-action" @click="e => handleSelectCity(e, city)">
                        {{ city }}
                    </a>
                </div>
            </div>

            <div class="dropdown dropdown__filter  mr-1">
                <button class="btn btn-grey btn-md dropdown-toggle" type="button" id="filterDropdownMenu"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="openFilter()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        class="mr-1">
                        <g clip-path="url(#clip0_1076_3980)">
                            <path
                                d="M3.07692 8.61527C4.22398 8.61527 5.15385 7.6854 5.15385 6.53835C5.15385 5.3913 4.22398 4.46143 3.07692 4.46143C1.92987 4.46143 1 5.3913 1 6.53835C1 7.6854 1.92987 8.61527 3.07692 8.61527Z"
                                stroke="#0061DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.07812 8.61539V19M3.07812 1V4.46154" stroke="#0061DF" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M16.9226 8.61527C18.0697 8.61527 18.9996 7.6854 18.9996 6.53835C18.9996 5.3913 18.0697 4.46143 16.9226 4.46143C15.7756 4.46143 14.8457 5.3913 14.8457 6.53835C14.8457 7.6854 15.7756 8.61527 16.9226 8.61527Z"
                                stroke="#0061DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.9238 4.46154V1M16.9238 19V8.61539" stroke="#0061DF" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M10.0008 12.0767C11.1478 12.0767 12.0777 11.1468 12.0777 9.99978C12.0777 8.85272 11.1478 7.92285 10.0008 7.92285C8.8537 7.92285 7.92383 8.85272 7.92383 9.99978C7.92383 11.1468 8.8537 12.0767 10.0008 12.0767Z"
                                stroke="#0061DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 1V7.92308M10 12.0769V19" stroke="#0061DF" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1076_3980">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span class="btn-text">Filter</span>
                </button>

                <!-- Filter Menu Content -->
                <div class="dropdown-menu" aria-labelledby="filterDropdownMenu" @click="stopPropagation">
                    <div class="mb-4 d-flex align-items-center justify-content-between">
                        <p class="mb-0"><span class="text-primary">Filters</span> <span class="text-muted">(120,000
                                Results)</span></p>
                        <button class="btn btn-link text-primary p-0 text-decoration-underline close-dropdown" @click="resetFilter">Reset
                            Filters</button>
                    </div>

                    <!-- House Type Filter Field -->
                    <div class="filter-field mb-3">
                        <label>House Type</label>
                        <div class="filter-field__options">
                            <button v-for="item in homeTypes" class="btn btn-sm btn-grey"
                                @click="handleFilterChange('property_type_state', item.value)"
                                :class="{ 'active': item.value === property_type_state }">
                                {{ item.label }}
                            </button>
                        </div>
                    </div>

                    <!-- Building Status Filter Field -->
                    <div class="filter-field mb-3" v-if="showBuildingStatus">
                        <label>Building Status</label>
                        <div class="filter-field__options">
                            <button v-for="item in buildingStatusList" class="btn btn-sm btn-grey"
                                @click="handleFilterChange('building_status_state', item)"
                                :class="{ 'active': isActive('building_status_state', item) }">{{ item }}</button>
                        </div>
                    </div>

                    <!-- House Style Filter Field -->
                    <div class="filter-field mb-3" v-else>
                        <label>House Style</label>
                        <div class="filter-field__options">
                            <button v-for="item in homeStyles" class="btn btn-sm btn-grey"
                                @click="handleFilterChange('property_style_state', item)"
                                :class="{ 'active': isActive('property_style_state', item) }">{{ item }}</button>
                        </div>
                    </div>

                    <!-- Price Range Filter Field -->
                    <div class="filter-field  mb-3">
                        <label>Price Range</label>
                        <div class="price-range-wrapper">
                            <div class="price-range-select-group">
                                <div class="form-group">
                                    <select class="form-control" v-model="min_price_input_state" name="min_price_input">
                                        <option value="">No Min</option>
                                        <option v-for="price in priceOptions" :value="price">{{ price }}</option>
                                    </select>
                                </div>
                                <span class="dash">-</span>
                                <div class="form-group">
                                    <select class="form-control" v-model="max_price_input_state" name="max_price_input">
                                        <option value="">No Max</option>
                                        <option v-for="price in priceOptions" :value="price">{{ price }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="filter-field__options">
                                <button v-for="item in priceList" class="btn btn-sm btn-grey"
                                    :class="{ 'active': selectedPriceOptionState === item }"
                                    @click="handlePriceTagChange(item)">{{
                                        item }}</button>
                            </div>
                        </div>
                    </div>

                    <div class="bed-bath-parking-group">
                        <div class="filter-field">
                            <label>Bedrooms</label>
                            <div class="filter-field__options">
                                <button v-for="item in bedList" class="btn btn-sm btn-grey"
                                    @click="handleFilterChange('bedrooms_state', item)"
                                    :class="{ 'active': isActive('bedrooms_state', item) }">{{ item }}</button>
                            </div>
                        </div>
                        <div class="filter-field">
                            <label>Bathrooms</label>
                            <div class="filter-field__options">
                                <button v-for="item in bathList" class="btn btn-sm btn-grey"
                                    @click="handleFilterChange('bathrooms_state', item)"
                                    :class="{ 'active': isActive('bathrooms_state', item) }">{{ item }}</button>
                            </div>
                        </div>
                        <!-- <div class="filter-field">
                            <label>Parking space</label>
                            <div class="filter-field__options">
                                <button v-for="item in parkingSpaceList" class="btn btn-sm btn-grey"
                                    @click="handleFilterChange('parking_space', item)"
                                    :class="{ 'active': isActive('parking_space', item) }">{{ item }}</button>
                            </div>
                        </div> -->
                    </div>
                    <div class="text-right mt-4 pt-1">
                        <button class="btn btn-light mr-2 close-dropdown">Cancel</button>
                        <button class="btn btn-primary text-white close-dropdown" @click="submitFilterData()">Submit</button>
                    </div>
                </div>
                <!-- Filter Menu Content End -->

            </div>

            <div class="dropdown dropdown__sort-by mr-3">
                <button class="btn btn-md dropdown-toggle" type="button" id="sortDropdownMenu" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                            d="M3.33333 15H6.66667C7.125 15 7.5 14.625 7.5 14.1667C7.5 13.7083 7.125 13.3333 6.66667 13.3333H3.33333C2.875 13.3333 2.5 13.7083 2.5 14.1667C2.5 14.625 2.875 15 3.33333 15ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM3.33333 10.8333H11.6667C12.125 10.8333 12.5 10.4583 12.5 10C12.5 9.54167 12.125 9.16667 11.6667 9.16667H3.33333C2.875 9.16667 2.5 9.54167 2.5 10C2.5 10.4583 2.875 10.8333 3.33333 10.8333Z"
                            fill="#0061DF" />
                    </svg>
                    <span class="btn-text">Sort By</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="sortDropdownMenu">
                    <div class="filter-field__options sort-by-options">
                        <button v-for="item in sortByOptions" class="btn btn-sm btn-grey"
                            :class="{ 'active': isActive('sortByFilter', item) }" @click="handleSortFilter(item)">{{ item
                            }}</button>
                    </div>
                </div>
            </div>
            <template v-if="!hideExclusiveBtn">
                <a v-if="listType === 'exclusive' || listType === 'map'" :href="'/idx-listings/search-result'"
                    class="btn btn-md exclusive-listing-btn primary mr-3">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1555_3829)">
                            <path
                                d="M19.9931 9.98047C19.9931 10.6836 19.4722 11.2344 18.8819 11.2344H17.7708L17.7951 17.4922C17.7951 17.5977 17.7882 17.7031 17.7778 17.8086V18.4375C17.7778 19.3008 17.1562 20 16.3889 20H15.8333C15.7951 20 15.7569 20 15.7188 19.9961C15.6701 20 15.6215 20 15.5729 20H13.6111C12.8438 20 12.2222 19.3008 12.2222 18.4375V15C12.2222 14.3086 11.7257 13.75 11.1111 13.75H8.88889C8.27431 13.75 7.77778 14.3086 7.77778 15V18.4375C7.77778 19.3008 7.15625 20 6.38889 20H4.44792C4.39583 20 4.34375 19.9961 4.29167 19.9922C4.25 19.9961 4.20833 20 4.16667 20H3.61111C2.84375 20 2.22222 19.3008 2.22222 18.4375V14.0625C2.22222 14.0273 2.22222 13.9883 2.22569 13.9531V11.2344H1.11111C0.486111 11.2344 0 10.6875 0 9.98047C0 9.62891 0.104167 9.31641 0.347222 9.04297L9.25 0.3125C9.49306 0.0390625 9.77083 0 10.0139 0C10.2569 0 10.5347 0.078125 10.7431 0.273438L19.6111 9.04297C19.8889 9.31641 20.0278 9.62891 19.9931 9.98047Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1555_3829">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    Browse Homes
                </a>
                <a v-else :href="'/exclusive-property/search-result'" class="btn btn-md exclusive-listing-btn mr-3">

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_1555_3829)">
                            <path
                                d="M19.9931 9.98047C19.9931 10.6836 19.4722 11.2344 18.8819 11.2344H17.7708L17.7951 17.4922C17.7951 17.5977 17.7882 17.7031 17.7778 17.8086V18.4375C17.7778 19.3008 17.1562 20 16.3889 20H15.8333C15.7951 20 15.7569 20 15.7188 19.9961C15.6701 20 15.6215 20 15.5729 20H13.6111C12.8438 20 12.2222 19.3008 12.2222 18.4375V15C12.2222 14.3086 11.7257 13.75 11.1111 13.75H8.88889C8.27431 13.75 7.77778 14.3086 7.77778 15V18.4375C7.77778 19.3008 7.15625 20 6.38889 20H4.44792C4.39583 20 4.34375 19.9961 4.29167 19.9922C4.25 19.9961 4.20833 20 4.16667 20H3.61111C2.84375 20 2.22222 19.3008 2.22222 18.4375V14.0625C2.22222 14.0273 2.22222 13.9883 2.22569 13.9531V11.2344H1.11111C0.486111 11.2344 0 10.6875 0 9.98047C0 9.62891 0.104167 9.31641 0.347222 9.04297L9.25 0.3125C9.49306 0.0390625 9.77083 0 10.0139 0C10.2569 0 10.5347 0.078125 10.7431 0.273438L19.6111 9.04297C19.8889 9.31641 20.0278 9.62891 19.9931 9.98047Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1555_3829">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Exclusive Listings
                </a>
            </template>

            <a v-if="!hideMapSearchBtn" href="/idx-map-search" class="btn btn-md map-search-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M9.9987 9.99984C10.457 9.99984 10.8495 9.8365 11.1762 9.50984C11.5023 9.18373 11.6654 8.7915 11.6654 8.33317C11.6654 7.87484 11.5023 7.48234 11.1762 7.15567C10.8495 6.82956 10.457 6.6665 9.9987 6.6665C9.54036 6.6665 9.14814 6.82956 8.82203 7.15567C8.49537 7.48234 8.33203 7.87484 8.33203 8.33317C8.33203 8.7915 8.49537 9.18373 8.82203 9.50984C9.14814 9.8365 9.54036 9.99984 9.9987 9.99984ZM9.9987 18.0207C9.88759 18.0207 9.77648 17.9998 9.66537 17.9582C9.55425 17.9165 9.45703 17.8609 9.3737 17.7915C7.34592 15.9998 5.83203 14.3368 4.83203 12.8023C3.83203 11.2673 3.33203 9.83317 3.33203 8.49984C3.33203 6.4165 4.00231 4.75678 5.34286 3.52067C6.68286 2.28456 8.23481 1.6665 9.9987 1.6665C11.7626 1.6665 13.3145 2.28456 14.6545 3.52067C15.9951 4.75678 16.6654 6.4165 16.6654 8.49984C16.6654 9.83317 16.1654 11.2673 15.1654 12.8023C14.1654 14.3368 12.6515 15.9998 10.6237 17.7915C10.5404 17.8609 10.4431 17.9165 10.332 17.9582C10.2209 17.9998 10.1098 18.0207 9.9987 18.0207Z"
                        fill="white" />
                </svg>
                Map Search
            </a>
        </div>
    </div>
</template>

<script>
const filterProperties = ['property_type', 'building_status', 'property_style', 'min_price_input', 'max_price_input', 'bedrooms', 'bathrooms']
export default {
    name: 'FilterListHeader',
    props: {
        homeTypes: {
            default() {
                return [
                    { label: "Any", value: "" },
                    { label: "Detached", value: "Detached" },
                    { label: "Semi-Detached", value: "Semi-Detached" },
                    { label: "Town House", value: "Att/Row/Twnhouse" },
                    { label: "Condo Town House", value: "Condo Townhouse" },
                    { label: "Condo Apartment", value: "Condo Apt" }
                ]
            }
        },
        homeStyles: {
            default() {
                return ['Any', '2-Storey', '3-Storey', 'Bungalow', 'Split level', 'Other']
            }
        },
        priceList: {
            default() {
                return ['0-500', '500-1M', '1M-1.5M']
            }
        },
        bedList: {
            default() {
                return ['Any', '1+', '2+', '3+', '4+', '5+']
            }
        },
        bathList: {
            default() {
                return ['Any', '1+', '2+', '3+', '4+', '5+']
            }
        },
        parkingSpaceList: {
            default() {
                return ['Any', '1+', '2+', '3+', '4+', '5+']
            }
        },

        sortByOptions: {
            default() {
                return ['Recommended', 'Most Recent', 'Oldest', 'Most Expensive', 'Least Expensive', 'Most Bedroom']
            }
        },
        cities: {
            default() {
                return []
            }
        },
        hideExclusiveBtn: {
            default: false
        },
        hideMapSearchBtn: {
            default: false
        },
        showBuildingStatus: {
            default: false
        },
        hideBuySellOptions: {
            default: false
        },
        listType: {
            default: ""
        },
    },
    emits: ['onFilterChange'],
    data() {
        return {
            city: "",
            sale_or_rent: "Sale",
            property_type: "",
            property_style: "",
            bedrooms: "",
            bathrooms: "",
            parking_space: "",
            min_price: "",
            max_price: "",
            showCityDropdown: false,
            sortBy: 'timestamp_idx',
            sortDir: 'desc',
            sortByFilter: "Most Recent",
            priceOptions: ['0', '500', '1M', '1.5M', '2M', '3M'],
            min_price_input: "",
            max_price_input: "",
            selectedPriceOption: "",
            building_status: "",
            buildingStatusList: [
                "Any",
                "Pre-construction",
                "Inventory",
                "Assignment sale"
            ],
            property_type_state: "",
            building_status_state:"",
            property_style_state:"",
            min_price_input_state: "",
            max_price_input_state: "",
            selectedPriceOptionState: "",
            bedrooms_state: "",
            bathrooms_state: "",
        }
    },
    created() {
        const searchParams = new URLSearchParams(window?.location?.search);
        const searchCity = searchParams?.get('city')
        if(searchParams?.get('sale_or_rent')){
            this.sale_or_rent = searchParams?.get('sale_or_rent') // Lease | Sale (default)
        }
        if (searchCity) {
            this.city = searchCity;
        }
        if (this.listType === 'sold') {
            this.sortBy = "sold_date"
        } else {
            this.sortBy = this.listType === 'new_construction' || this.listType === 'exclusive' ? 'created_at' : 'timestamp_idx';
        }
    },
    methods: {
        stopPropagation(e) {
            if (!e.target.matches('.close-dropdown'))
                e.stopPropagation()
        },
        onSearchFocus() {
            this.showCityDropdown = true;
        },
        onSearchBlur() {
            setTimeout(() => {
                this.showCityDropdown = false;
            }, 300);
        },
        handleSelectCity(e, city) {
            this.city = city;
        },
        handleFilterChange(name, value) {
            if (value === 'Any') {
                this[name] = ""
            } else {
                this[name] = value
            }
            if(name === 'sale_or_rent'){
                this.$emit('onFilterChange')
            }
        },
        submitFilterData(){
            
            for(let key of filterProperties){
                this[key] = this?.[`${key}_state`] || ""
            }
            this.selectedPriceOption = this.selectedPriceOptionState;
            this.$emit('onFilterChange')
        },
        isActive(name, value) {
            if (!this[name] && value === 'Any') return true;
            if (this[name] === value) return true;
            return false
        },
        openFilter(){
            for(let key of filterProperties){
                this[`${key}_state`] = this[key] || ""
            }
        },
        resetFilter() {
            for(let key of filterProperties){
                this[key] = "";
                this[`${key}_state`] = ""
            }
            this.selectedPriceOption = "";
            this.selectedPriceOptionState = "";
            this.$emit('onFilterChange')
        },
        handleSortFilter(val) {
            if (val === 'Most Recent') {
                this.sortBy = this.listType === 'new_construction' || this.listType === 'exclusive' ? 'created_at' : 'timestamp_idx';
                this.sortDir = 'desc';
            }
            else if (val === 'Oldest') {
                this.sortBy = this.listType === 'new_construction' || this.listType === 'exclusive' ? 'created_at' : 'timestamp_idx';
                this.sortDir = 'asc';
            }
            else if (val === 'Most Expensive') {
                this.sortBy = 'list_price';
                this.sortDir = 'desc';
            }
            else if (val === 'Least Expensive') {
                this.sortBy = 'list_price';
                this.sortDir = 'asc';
            }
            else if (val === 'Most Bedroom') {
                this.sortBy = this.listType === 'new_construction' ? 'bedroom' : 'bedrooms';
                this.sortDir = 'desc';
            }
            else {
                this.sortBy = this.listType === 'new_construction' ? '' : 'list_price';
                this.sortDir = this.listType === 'new_construction' ? '' : 'desc';
            }
            if (this.listType === 'sold' && this.sortBy === 'timestamp_idx') {
                this.sortBy = "sold_date"
            }
            if (this.listType === 'sold' && this.sortBy === 'list_price') {
                this.sortBy = "sold_price"
            }
            // console.log(val);
            this.sortByFilter = val;
            this.$emit('onFilterChange')
        },
        handleSelectPrice(val) {
            const [min, max = ''] = val.split('-')
            this.min_price = this.getPriceToNumber(min);
            this.max_price = this.getPriceToNumber(max);
            this.selectedPriceOption = val;
        },
        handlePriceTagChange(val) {
            const [min, max = ''] = val.split('-')
            this.min_price_input = min;
            this.max_price_input = max;
            this.min_price = this.getPriceToNumber(min);
            this.max_price = this.getPriceToNumber(max);
            this.handleFilterChange('selectedPriceOption', val);
        },
        getPriceToNumber(val) {
            if (val == '0') {
                return 0;
            } else if (val == '500') {
                return 500000;
            } else if (val == '1M') {
                return 1000000;
            } else if (val == '1.5M') {
                return 1500000;
            } else if (val == '2M') {
                return 2000000;
            } else if (val == '3M') {
                return 3000000;
            } else {
                return '';
            }
        }

    },
    computed: {
        filteredCities() {
            return this.cities.filter(city => city?.toLowerCase()?.startsWith(this.city?.toLowerCase()))
        },
        placeholder(){
            return this.listType === 'new_construction' ? 'Area' : 'Area, address or MLS#';
        }
    },
    watch: {
        min_price_input(minPrice) {
            const minPriceInNumber = this.getPriceToNumber(minPrice);
            const maxPriceInNumber = this.getPriceToNumber(this.max_price_input)
            if (maxPriceInNumber != "" && minPriceInNumber > this.max_price_input) {
                this.max_price_input = minPrice;
                this.min_price_input = this.max_price_input;
            }
            this.min_price = this.getPriceToNumber(this.min_price_input);
            this.max_price = this.getPriceToNumber(this.max_price_input);
            const minMax = `${this.min_price_input}-${this.max_price_input}`
            if (this.priceList.includes(minMax)) {
                this.selectedPriceOption = minMax
            } else {
                this.selectedPriceOption = ""
            }

            this.$emit('onFilterChange')
        },
        max_price_input(maxPrice) {
            const maxPriceInNumber = this.getPriceToNumber(maxPrice)
            const minPriceInNumber = this.getPriceToNumber(this.max_price_input);
            if (minPriceInNumber !== "" && minPriceInNumber > maxPriceInNumber) {
                this.min_price_input = maxPrice;
                this.max_price_input = this.min_price_input;
            }
            this.min_price = this.getPriceToNumber(this.min_price_input);
            this.max_price = this.getPriceToNumber(this.max_price_input);

            const minMax = `${this.min_price_input}-${this.max_price_input}`
            if (this.priceList.includes(minMax)) {
                this.selectedPriceOption = minMax
            } else {
                this.selectedPriceOption = ""
            }

            this.$emit('onFilterChange')
        }
    }
}
</script>

<style lang="scss" scoped>
button,
a {

    &,
    &:hover,
    &:focus {
        border: none;
        text-decoration: none;
    }
}

.text-primary {
    color: #0061DF !important;
}

.btn-md {
    padding: 0 20px;
    font-weight: 600;
    font-size: 14px;
    height: 50px;
    border-radius: 10px;
}

.btn-grey {
    background: #F2F2F2;
    color: #0061DF !important;

    &:not(.active):hover {
        background: #edebeb !important;
    }
}

.filter-list-header {
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
    height: 72px;
    display: flex;
    align-items: center;
}

.filter-list-header-container {
    display: flex;
    align-items: center;

    .dropdown-item:focus,
    .dropdown-item:hover {
        background-color: var(--bs-dropdown-link-hover-bg);
        color: var(--bs-dropdown-link-hover-color) !important;
    }
}

.search-box {
    height: 55px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 3px;
    position: relative;

    .fa-map-marker-alt {
        font-size: 18px;
        margin-right: 10px;
    }

    input {
        flex: 1;
        border: none;
        box-shadow: none;
    }

    .dropdown {
        margin-right: 5px;
    }

    .search-btn {
        border-radius: 10px;
        background: #0061DF;
        box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.30);
        height: calc(100% - 6px);
        color: #fff;
        border: none;
        padding-inline: 20px;
        display: flex;
        align-items: center;
    }
}



.dropdown__sort-by {
    button {
        color: #0061DF;
        padding-inline: 0;
    }
}

.exclusive-listing-btn {
    box-shadow: 0px 0px 4px 0px rgba(252, 161, 32, 0.10);
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 6px;

    &,
    &:hover {
        background: #FCA120;
        color: #fff;
    }
    &.primary {
        background-color: #0061DF;
    }
}

.map-search-btn {
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.30);
    display: flex;
    align-items: center;
    gap: 6px;

    &,
    &:hover {
        background: #0061DF;
        color: #fff;
    }
}

.city-dropdown {
    max-height: 250px;
    overflow-y: auto;
    margin-top: 2px;
    position: absolute;
    z-index: 2;
    width: 100%;
    border: 1px solid grey;
    top: 100%;
    left: 0;
    display: block;
    border-radius: 8px;

    a {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
}

.filter-field__options {
    display: flex;
    gap: 6px;
    align-items: center;
    flex-wrap: wrap;

    button {
        padding: 6px 14px;
        border-radius: 5px;
        line-height: normal;
    }

    .active {
        background: #0061DF;
        color: #fff !important;
    }
}

.dropdown__filter {
    .dropdown-menu {
        min-width: 600px;
        border-radius: 0px 0px 15px 15px;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
        padding: 25px;

        * {
            font-weight: 500;
            font-size: 14px;
            color: #6E6E73;
        }

        label {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
        }



        .price-range-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .price-range-select-group {
            display: flex;
            align-items: center;
            gap: 10px;

            .form-group {
                margin-bottom: 0 !important;
            }

            select {
                border-radius: 5px !important;
                border: 1px solid #0061DF !important;
                padding: 0px 10px !important;
                height: 32px !important;
                font-size: 14px !important;
                color: #6E6E73 !important;
            }
        }

        .bed-bath-parking-group {
            display: grid;
            gap: 1rem;
        }


    }



}

.sort-by-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding-inline: 10px;

    button {
        white-space: nowrap;
    }
}

button#filterDropdownMenu,button#sortDropdownMenu {
    background-color:transparent;
    padding: 0px 10px;
    &:hover,
    &:active,
    &[aria-expanded=true] {
        background-color:#F2F2F2;
    }
}

@media (min-width:992px) {
    .bed-bath-parking-group {
        grid-template-columns: repeat(2, 1fr);

        .filter-field__options {
            flex-wrap: nowrap !important;
        }
    }

    .search-box {
        min-width: 376px;
    }
}

@media (max-width: 575.98px) {
    .filter-top-sec {
        margin-top: 72px !important;
    }

    .filter-list-header {
        height: 140px;
    }

    .filter-list-header-container {
        flex-wrap: wrap;
        row-gap: 10px;
        justify-content: space-between;

        &>* {
            margin-right: 0 !important;
        }

        .search-box {
            flex-basis: 100%;
            order: 1;

            .search-btn {
                padding-inline: 18px;
            }

            .dropdown-menu {
                --bs-dropdown-min-width: 6rem;
            }
        }

        .dropdown__filter {
            order: 2;
        }

        .exclusive-listing-btn {
            order: 3;
            margin-left: 0;
        }

        .dropdown__sort-by {


            order: 4;
        }

        .map-search-btn {
            display: none;
        }

        .btn-text {
            display: none;
        }

        #sortDropdownMenu,
        #filterDropdownMenu {
            &::after {
                display: none;
            }
        }

        .dropdown__sort-by,
        .dropdown__filter {
            &>button {
                padding: 0 20px;
                font-weight: 600;
                font-size: 14px;
                height: 50px;
                border-radius: 10px;
                background: #D1DFF6;
                color: #0061DF !important;

                &:not(.active):hover {
                    background: #D1DFF6 !important;
                }

                svg {
                    margin-right: 0 !important;
                }
            }
        }

        .dropdown__filter {
            .dropdown-menu {
                width: 100vw;
                min-width: auto;
                top: 12px !important;
                left: 0px !important;
                transform: translate3d(-12px, 50px, 0px) !important;
                box-shadow: none;
                padding: 1rem;

                .price-range-wrapper {
                    flex-wrap: wrap;

                    .price-range-select-group {
                        flex-basis: 100%;

                        .form-group {
                            flex: 1;
                        }
                    }
                }
            }
        }

        [aria-labelledby="sortDropdownMenu"] {
            transform: translate3d(calc(-100% + 60px), 60px, 0px) !important;
        }
    }
}

@media (max-width:375px) {
    .search-box {
        input {
            width: 175px;
        }
    }
}
</style>
<style>
.filter-top-sec {
    margin-top: 72px !important;
    z-index: 4 !important;
}

.filter-top-sec.loggedIn {
    margin-top: 78px !important;
}

@media (max-width: 575.98px) {
    .filter-top-sec {
        margin-top: 50px !important;
    }
}
</style>
