var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"filter-top-sec",attrs:{"id":"working"}},[_c('filter-list-header',{ref:"filterComponent",attrs:{"listType":'map',"hideMapSearchBtn":true,"cities":_vm.cityArray},on:{"onFilterChange":_vm.handleFilterChange}})],1),_vm._v(" "),_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"map-list-row"},[(_vm.isMapLoading)?_c('div',{staticClass:"map-loader"},[_vm._m(0)]):_vm._e(),_vm._v(" "),_c('GmapMap',{staticClass:"map-container",attrs:{"id":"gmap","center":_vm.center,"zoom":_vm.selected_cities.length ? 11 : 8,"map-type-id":"roadmap","options":{
                    scrollwheel: true,
                    streetViewControl: false,
                    mapTypeControl: false,
                    clickableIcons: false,
                }},on:{"tilesloaded":_vm.removeMapLoader,"center_changed":_vm.handleCenterChanged,"zoom_changed":_vm.handleMapZoomChange},nativeOn:{"click":function($event){return _vm.handleMapClick.apply(null, arguments)}}},[_c('gmap-cluster',{attrs:{"zoomOnClick":true}},_vm._l((_vm.all_properties),function(property,index){return _c('gmap-custom-marker',{key:property.unique_id,ref:("marker-" + (property.unique_id)),refInFor:true,attrs:{"marker":property.position,"position":property.position},nativeOn:{"click":function($event){return _vm.handleMarkerClick(property)}}},[_c('div',{staticClass:"property-marker badge"},[(_vm.isExclusiveProperty(property))?_c('i',{staticClass:"fas fa-home"}):_vm._e(),_vm._v("\n                            "+_vm._s(_vm._f("compactPrice")(property["list_price"]))+"\n                        ")])])}),1),_vm._v(" "),_c('gmap-info-window',{attrs:{"options":{
                    maxWidth: 300,
                    pixelOffset: { width: 0, height: -35 },
                },"position":_vm.infoWindow.position,"opened":_vm.infoWindow.open}},[(_vm.infoWindow.propertyDetails)?_c('map-property-card',{attrs:{"property_details":_vm.infoWindow.propertyDetails,"image_base_url":_vm.url_web,"isExclusive":_vm.isExclusiveProperty(_vm.infoWindow.propertyDetails)}}):_c('div',{staticClass:"property-details-loader"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border",staticStyle:{"width":"4rem","height":"4rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }