<template>
  <div class="card mt-4 shadow rounded">
    <div class="card-title">
      <h6 class="text-center pt-4">Edit Property - Step 4</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="col-md-4 mb-2"
          v-for="(item, index) in oldPropertyImages"
          :key="'property-image-'+index"
        >
          <img
            :src="item.thumbnail_path"
            :alt="getFileNameByPath(item.image_path)"
            class="img-responsive img-fluid"
          />
          <small>{{ getFileNameByPath(item.image_path) }}</small>
          <!-- <button class="btn btn-sm btn-outline-danger" @click.prevent="deletePropertyUrl(item)"><i class="far fa-trash-alt"></i></button> -->
        </div>
      </div>
      <h5 class="builder-form__title">Upload Your Photos Now</h5>
      <div class="form-group">
        <vue-dropzone
          :duplicateCheck="true"
          :options="dropzoneOptions"
          id="firstDropZone"
          ref="firstDropZoneRef"
          :useCustomSlot="true"
          @vdropzone-error="handlePhotoUploadError"
          @vdropzone-complete="updatePropertyImages"
        >
          <div class="dropzone-custom-content">
            <img
              src="/assets/images/builders-form/dropzone-picture-icon.png"
              alt="Drop Image"
              class="img-responsive img-fluid"
            />
            <h4>Drop Your Image Here!</h4>
          </div>
        </vue-dropzone>
        <p>
          <b>
            <i class="fas fa-info-circle"></i>&nbsp;&nbsp;Allowed types – jpg, jpeg and png. Maximum file size – 5MB
          </b>
        </p>
      </div>
      <div class="row">
        <div
          class="col-md-4 mb-2"
          v-for="(item, index) in oldPropertyLayouts"
          :key="'property-image-'+index"
        >
          <img
            :src="item.thumbnail_path"
            :alt="getFileNameByPath(item.image_path)"
            class="img-responsive img-fluid"
          />
          <small>{{ getFileNameByPath(item.image_path) }}</small>
          <!-- <button class="btn btn-sm btn-outline-danger" @click.prevent="deletePropertyUrl(item)"><i class="far fa-trash-alt"></i></button> -->
        </div>
      </div>
      <!-- <h5 class="builder-form__title">Upload Building Layouts Now</h5>
      <div class="form-group">
        <vue-dropzone
          :duplicateCheck="true"
          :options="dropzoneOptions"
          id="secondDropZone"
          ref="secondDropZoneRef"
          :useCustomSlot="true"
          @vdropzone-error="handleLayoutUploadError"
          @vdropzone-complete="updatePropertyLayouts"
        >
          <div class="dropzone-custom-content">
            <img
              src="/assets/images/builders-form/dropzone-layout-icon.png"
              alt="Drop Layout"
              class="img-responsive img-fluid"
            />
            <h4>Drop Your Layout Here!</h4>
          </div>
        </vue-dropzone>
        <p>
          <b>
            <i class="fas fa-info-circle"></i>&nbsp;&nbsp;Allowed types – jpg, jpeg and png. Convert to image format if your file format is other than jpeg, jpg or png. Maximum file size – 5MB
          </b>
        </p>
        <p>
          <b>
            <i class="fas fa-info-circle"></i>&nbsp;&nbsp;Upload all images and click submit button
          </b>
        </p>
      </div> -->
      <!-- Button Section -->
      <div class="row">
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-info w-100"
            :disabled="formSubmitting"
            @click.prevent="resetForm"
          >{{ formSubmitting ? "Processing..." : "Reset" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-success w-100"
            :disabled="formSubmitting"
            @click.prevent="updatePropertyDetails"
          >{{ formSubmitting ? "Processing..." : "Save" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-primary w-100"
            :disabled="formSubmitting"
            @click.prevent="moveToFormThree"
          >{{ formSubmitting ? "Processing..." : "Previous" }}</button>
        </div>
      </div>
      <!-- ./Button Section -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FormStepFour",
  props: {
    property_details: {
      default: {}
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: "/builders/upload-property-image"
      },
      propertyImages: [],
      oldPropertyImages: [],
      propertyLayouts: [],
      oldPropertyLayouts: [],
      photoUploadError: false,
      layoutUploadError: false,
      property_id: "",
      formSubmitting: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.separateImageLayouts();
    }, 1000);
  },
  methods: {
    updatePropertyLayouts(response) {
      if (response?.xhr?.status == 200) {
        this.photoUploadError = false;
        if (response?.xhr?.response) {
          let serverResponse = JSON.parse(response?.xhr?.response);
          if (
            serverResponse?.data?.thumbnail_path &&
            serverResponse?.data?.image_path
          ) {
            this.propertyLayouts.push(serverResponse.data.thumbnail_path);
            this.propertyLayouts.push(serverResponse.data.image_path);
            localStorage.setItem(
              "property_layouts",
              JSON.stringify(this.propertyLayouts)
            );
          }
        }
      }
    },
    updatePropertyImages(response) {
      if (response?.xhr?.status == 200) {
        this.layoutUploadError = false;
        if (response?.xhr?.response) {
          let serverResponse = JSON.parse(response?.xhr?.response);
          if (
            serverResponse?.data?.thumbnail_path &&
            serverResponse?.data?.image_path
          ) {
            this.propertyImages.push(serverResponse.data.thumbnail_path);
            this.propertyImages.push(serverResponse.data.image_path);
            localStorage.setItem(
              "property_images",
              JSON.stringify(this.propertyImages)
            );
          }
        }
      }
    },
    handlePhotoUploadError(file, message, xhr) {
      this.photoUploadError = true;
      this.$refs.firstDropZoneRef.removeFile(file);
    },
    handleLayoutUploadError(file, message, xhr) {
      this.layoutUploadError = true;
      this.$refs.secondDropZoneRef.removeFile(file);
    },
    separateImageLayouts() {
      if(this.property_details?.id) {
        this.property_id = this.property_details.id;
      }
      if (this.property_details?.property_images) {
        if (Array.isArray(this.property_details.property_images)) {
          let property_images = [...this.property_details.property_images];
          property_images.forEach(el => {
            let thumbnail_path = "";
            let image_path = "";
            let file_url = el.file_url;
            if (file_url.indexOf("/thumbnails") !== -1) {
              image_path = file_url.replace("/thumbnails", "");
              thumbnail_path = file_url;
            }
            if (el.file_type == "image") {
              if(thumbnail_path && thumbnail_path) {
                this.oldPropertyImages.push({
                  thumbnail_path: thumbnail_path,
                  image_path: thumbnail_path
                });
              }
            } else if (el.file_type == "layout") {
              if(thumbnail_path && thumbnail_path) {
                this.oldPropertyLayouts.push({
                  thumbnail_path: thumbnail_path,
                  image_path: image_path
                });
              }
            }
          });
        }
      }
    },
    getFileNameByPath(fullPath) {
      let filename = fullPath.replace(/^.*[\\\/]/, "");
      return filename;
    },
    deletePropertyUrl(objStr) {
      this.formSubmitting = true;
      const formData = new FormData();
      formData.append("url", JSON.stringify(objStr));
      formData.append("pid", this.property_id);
      axios.post('/builders/delete-property-file', formData)
      .then(response => {
        console.log(response?.data);
        this.formSubmitting = false;
        sessionStorage.setItem('formStep', 4);
        window.location.reload();
      }).catch(error => {
        console.error(error?.response?.data);
        this.formSubmitting = false;
      });
    },
    resetForm() {
      sessionStorage.setItem('formStep', 4);
      window.location.reload();
    },
    updatePropertyDetails() {
      this.formSubmitting = true;
      const formData = new FormData();

      formData.append("images", JSON.stringify(this.propertyImages));
      formData.append("layouts", JSON.stringify(this.propertyLayouts));

      axios.post('/builders/update-property-files/'+this.property_id, formData)
      .then(response => {
        console.log(response?.data);
        this.formSubmitting = false;
        sessionStorage.setItem('formStep', 4);
        window.location.reload();
      }).catch(error => {
        this.formSubmitting = false;
        console.error(error?.response?.data);
      });
    },
    moveToFormThree() {
      this.$emit("moveToFormThree", 3);
    }
  }
};
</script>
