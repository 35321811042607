<template>
    <div>
        <div class="form-split">
            <div>
                <div class="form-group">
                    <label class="d-block"
                        >Link to parent property<span class="required"
                            >*</span
                        ></label
                    >
                    <select
                        name="project_city"
                        id="project_city"
                        class="form-control"
                        :class="parent_property_err ? 'is-invalid' : ''"
                        v-model="parent_property"
                        @change="updateParentId"
                    >
                        <option value="">-- Select Type --</option>
                        <option
                            :value="name"
                            v-for="{ id, name } in parentProperties"
                        >
                            {{ name }}
                        </option>
                    </select>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="parent_property_err"
                    >
                        Parent Property is required
                    </small>
                </div>
                <!-- House Type -->
                <div class="form-group">
                    <label class="d-block"
                        >House Type <span class="required">*</span></label
                    >
                    <select
                        name="project_city"
                        id="project_city"
                        class="form-control"
                        :class="home_type_err ? 'is-invalid' : ''"
                        v-model="home_type"
                    >
                        <option value="">-- Select Type --</option>
                        <option :value="item" v-for="item in homeTypes">
                            {{ item }}
                        </option>
                    </select>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="home_type_err"
                    >
                        House type is required
                    </small>
                </div>

                <!-- Bathroom -->
                <div class="form-group">
                    <label class="d-block"
                        >Bathroom <span class="required">*</span></label
                    >
                    <select
                        v-model="bathroom"
                        id="bathroom"
                        class="form-control"
                        :class="bathroom_err ? 'is-invalid' : ''"
                    >
                        <option value="">Select</option>
                        <option :value="item" v-for="item in numOptions">
                            {{ item }}
                        </option>
                    </select>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="bathroom_err"
                    >
                        Bathroom is required
                    </small>
                </div>
                <!-- Bedroom -->
                <div class="form-group">
                    <label class="d-block"
                        >Bedroom <span class="required">*</span></label
                    >
                    <select
                        v-model="bedroom"
                        id="bathroom"
                        class="form-control"
                        :class="bedroom_err ? 'is-invalid' : ''"
                    >
                        <option value="">Select</option>
                        <option :value="item" v-for="item in numOptions">
                            {{ item }}
                        </option>
                    </select>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="bedroom_err"
                    >
                        Bathroom is required
                    </small>
                </div>
                <!-- markup price -->
                <div class="form-group">
                    <label class="d-block"
                        >Markup price <span class="required">*</span></label
                    >
                    <textarea
                        class="form-control"
                        rows="4"
                        v-model="markup_price"
                        :class="markup_price_err ? 'is-invalid' : ''"
                    ></textarea>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="markup_price_err"
                    >
                        markup price is required
                    </small>
                </div>
                <!-- Builder Name -->

                <!-- Seller Name -->
            </div>
            <div>
                <!-- garage -->
                <div class="form-group">
                    <label class="d-block"
                        >Garage <span class="required">*</span></label
                    >
                    <select
                        v-model="garage"
                        id="bathroom"
                        class="form-control"
                        :class="garage_err ? 'is-invalid' : ''"
                    >
                        <option value="">Select</option>
                        <option :value="item" v-for="item in numOptions">
                            {{ item }}
                        </option>
                    </select>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="garage_err"
                    >
                        Bathroom is required
                    </small>
                </div>
                <!-- Sqft -->
                <div class="form-group">
                    <label> Sqft Area <span class="required">*</span></label>
                    <div class="input-group-col-12">
                        <div>
                            <input
                                type="number"
                                class="form-control"
                                placeholder="sqft"
                                v-model="sqft_area"
                                :class="sqft_area_err ? 'is-invalid' : ''"
                            />
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="sqft_area_err"
                            >
                                sqft is required
                            </small>
                        </div>
                    </div>
                </div>
                <!-- price sqft -->
                <div class="form-group">
                    <label>
                        Price Sqft Area <span class="required">*</span></label
                    >
                    <div class="input-group-col-12">
                        <div>
                            <input
                                type="number"
                                class="form-control"
                                placeholder="Price Sqft Area"
                                v-model="price_per_sqft"
                                :class="price_per_sqft_err ? 'is-invalid' : ''"
                            />
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="price_per_sqft_err"
                            >
                                sqft is required
                            </small>
                        </div>
                    </div>
                </div>
                <!-- price -->
                <div class="form-group">
                    <label> Price<span class="required">*</span></label>
                    <div class="input-group-col-12">
                        <div>
                            <input
                                type="number"
                                class="form-control"
                                placeholder="Price"
                                v-model="min_price"
                                :class="price_err ? 'is-invalid' : ''"
                            />
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="price_err"
                            >
                                sqft is required
                            </small>
                        </div>
                    </div>
                </div>
                <!-- child property file uploader  -->
                <div class="form-group">
                    <label>Upload Images</label>
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        id="coverImagesDropzoneRef"
                        ref="secondDropZoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handlePhotoUploadError"
                        @vdropzone-complete="updatePropertyImages"
                    >
                        <div class="dropzone-custom-content">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    opacity="0.5"
                                    d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M12 15V2M12 2L15 5.5M12 2L9 5.5"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p class="mt-2">add files</p>
                        </div>
                    </vue-dropzone>
                </div>
            </div>
        </div>
        <div class="form-button-group">
            <button
                class="save-btn"
                @click="handleSave('save')"
                :disabled="isSaving"
            >
                <span
                    v-if="isSaving && currentActionBtn === 'save'"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Save
            </button>
            <button
                class="reset-btn"
                @click="handleReset()"
                :disabled="isSaving"
            >
                Reset
            </button>
            <!-- <button
                class="next-btn"
                @click="handleSave('next')"
                :disabled="isSaving"
            >
                <span
                    v-if="isSaving && currentActionBtn === 'next'"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Next
            </button> -->
        </div>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import pick from "lodash/pick";

const initialFormFields = {
    parent_id: null,
    child_or_parent: "Child",
    sqft_area: "",
    garage: "",
    bathroom: "",
    bedroom: "",
    min_price: "",
    price_per_sqft: "",
    floorplanImage: [],
    markup_price: "",
    home_type: "",
};
export default {
    name: "ChildForm",
    props: {
        isSaving: false,
        ParentPropertyData: "",
    },
    data() {
        return {
            ...initialFormFields,
            numOptions: [1, 2, 3, 4, 5],
            homeTypes: [
                "Detached",
                "Semi-detached",
                "Town House",
                "Condo Town House",
                "Condo Apartment",
                "Condominium",
                "Condo",
            ],
            propertyImages: [],
            dropzoneOptions: {
                url: "/builders/upload-property-image",
            },
            parent_property: "",
            parentProperties: [],
        };
    },
    mounted() {
        // When the component is mounted, parse the string into an array
        this.parentProperties = JSON.parse(this.ParentPropertyData);
    },
    validations: {
        home_type: {
            required,
        },
        price_per_sqft: {
            required,
        },
        bedroom: {
            required,
        },
        bathroom: {
            required,
        },
        garage: {
            required,
        },
        sqft_area: {
            required,
        },
        markup_price: {
            required,
        },
        parent_property: {
            required,
        },
        min_price: {
            required,
        },
    },
    computed: {
        home_type_err() {
            return !!this.$v.home_type.$error;
        },
        price_per_sqft_err() {
            return !!this.$v.price_per_sqft.$error;
        },
        bedroom_err() {
            return !!this.$v.bedroom.$error;
        },
        bathroom_err() {
            return !!this.$v.bathroom.$error;
        },
        garage_err() {
            return !!this.$v.garage.$error;
        },
        sqft_area_err() {
            return !!this.$v.sqft_area.$error;
        },
        price_per_sqft_err() {
            return !!this.$v.price_per_sqft.$error;
        },
        markup_price_err() {
            return !!this.$v.markup_price.$error;
        },
        parent_property_err() {
            return !!this.$v.parent_property.$error;
        },
        price_err() {
            return !!this.$v.min_price.$error;
        },
    },
    methods: {
        handleSave(actionType) {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.currentActionBtn = actionType;
                this.$v.$reset();
                const formData = pick(this, Object.keys(initialFormFields));
                this.isSaving = true;
                axios
                    .post("/builders/submit-builder-property", formData)
                    .then((response) => {
                        this.isSaving = false;
                        console.log(response);
                    })
                    .catch((error) => {
                        console.error(error?.response?.data);
                    })
                    .finally((res) => {
                        // window.location.reload();
                        this.handleReset();
                    });
            }
        },
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
            this.parent_property = "";
        },
        setExistingFormData() {
            for (let key in initialFormFields) {
                this[key] = this.propertyDetails[key] || initialFormFields[key];
            }
            console.warn(this.propertyDetails);
        },
        handlePhotoUploadError(file, message, xhr) {
            this.photoUploadError = true;
            this.$refs.secondDropZoneRef.removeFile(file);
        },
        addParamToCoverImage(file, xhr, formData) {
            formData.append("property_id", this.propertyId);
        },
        updateParentId() {
            const selectedOption = this.parentProperties.find(
                (property) => property.name === this.parent_property
            );
            this.parent_id = selectedOption ? selectedOption.id : null;
        },
    },
};
</script>

<style></style>
