<template>
    <section class="property_exclusive_listings py-0">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="property_exclusive_listings_title">
                        <h3 class="mb-30">Similar Homes</h3>
                    </div>
                    <div v-if="isLoading" class="skeleton-grid">
                        <skeleton-loader v-for="value in skeletonList" :key="value" />
                    </div>
                </div>
                <div v-if="!isLoading && related_properties.length" class="blur col-12">
                    <div class="common-property-list-grid">
                        <common-property-card v-for="property in related_properties" :key="property.id"
                            :property_details="property" type="COMPARABLE_HOME" :user_id="user_id"
                            :userFavProperties="userFavProperties" @onFavoriteSuccess="handleFavoriteSuccess"
                            :hidePrice="true" />
                    </div>
                </div>
                <div v-if="!isLoading && !related_properties.length" class="text-center">
                    <img src="/images/no_result.gif" alt="No Result" class="img-responsive img-fluid text-center" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import SkeletonLoader from "../../../pages/SkeletonLoader/SkeletonLoader.vue";
import CommonPropertyCard from "../../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import { getUserFavPropertiesIds } from "../../../utils/app";


// const apiUrl = "https://idxapiserver.test/api/v1/related/sold/properties/123";
const apiUrl = "https://valuation.santhoshmathew.com/api/v1/related/sold/properties/";
export default {
    name: "MLSRelatedPropertySold",
    props: ["property_id", "isAuth", "user_id"],
    data: () => ({
        related_properties: [],
        id: null,
        isLoading: true,
        skeletonList: [1, 2, 3, 4],
        userFavProperties: [],
    }),
    components: {
        "skeleton-loader": SkeletonLoader,
        "common-property-card": CommonPropertyCard
    },
    mounted() {
        this.id = this.property_id;
        this.getRelatedSoldProperty();
        this.fetchUserFavProperties()
    },
    methods: {
        fetchUserFavProperties() {
            getUserFavPropertiesIds(this.user_id, 'vow')
                .then(res => {
                    console.log(res)
                    this.userFavProperties = res;
                });
        },
        handleFavoriteSuccess(res) {
            if (this.userFavProperties.includes(res.id)) {
                this.userFavProperties = this.userFavProperties.filter(propertyId => propertyId !== res.id)
            }
            else {
                this.userFavProperties = [...this.userFavProperties, res.id]
            }
        },
        getRelatedSoldProperty() {
            axios
                .get(apiUrl + this.id)
                .then((res) => {
                    this.related_properties = res.data.data?.filter(el => el.id != this.id);
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log("Error", err);
                    this.isLoading = false;
                });
        },
        handleImageError(event, url) {
            event.preventDefault();
            event.target.src = "/assets/images/no-image-available.png";
            // event.target.src = "https://s3.us-west-1.wasabisys.com/redbuyers-vow/" + url;
        },
        goToPropertyDetails(value) {
            if (this.isAuth) {
                window.location.href =
                    "/idx-listings/sold/properties/" +
                    this.convertToSlug(
                        value["address"] +
                        ", " +
                        value["city"] +
                        ", " +
                        value["county"] +
                        ", " +
                        value["zip"]
                    ) +
                    "/" +
                    value["id"];
            } else {
                document.getElementById("signUpButton").click();
            }
        },
        get_pro(value, type) {
            let new_val = JSON.parse(value);
            return new_val[type];
        },
    },
};
</script>
<style>
.skeleton-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;
}

.skeleton-card {
    width: auto;
}

.sec-title {
    font-weight: 700;
    font-size: 1.8rem;
    color: #0061df;
}
</style>
<style lang="scss" scoped>
.property_exclusive_listings {
    background: transparent !important;
}

.mb-30 {
    margin-bottom: 30px;
}

@media (max-width:567px) {
    .common-property-list-grid {
        display: flex !important;
        overflow-x: auto;
        padding-bottom: 20px;
        width: 100%;

        &>* {
            min-width: 100%;
        }

        &::-webkit-scrollbar-track {
            border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-appearance: none;
            border-radius: 100px;
        }

        &::-webkit-scrollbar {
            height: 8px;
        }

    }

    h3.mb-30 {
        margin-bottom: 25px !important;
        font-size: 24px !important;
        text-align: center !important;
    }
}
</style>
