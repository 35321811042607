<template>
    <!-- Multi-step form -->
    <div class="container-fluid">
        <h1>Get Instant Valuation</h1>
        <div class="row justify-content-center">
            <div class="col-md-10 text-center p-0 mt-3 mb-2">
                <div class="card p-4 mt-3 mb-3">
                    <div id="msform">
                        <!-- <h2 id="heading"> Sign Up Form </h2> -->
                        <ul id="progressbar">
                            <li :class="step >= 1 ? 'active' : ''" id="account">
                                <strong> Select Address </strong>
                            </li>
                            <li :class="step >= 2 ? 'active' : ''" id="personal">
                                <strong> Fill Additional Information </strong>
                            </li>
                            <li :class="step >= 3 ? 'active' : ''" id="payment">
                                <strong> Fill Personal Details </strong>
                            </li>
                            <li :class="step >= 4 ? 'active' : ''" id="confirm">
                                <strong> Get Instant Valuation </strong>
                            </li>
                        </ul>
                        <!-- <div class="progress">
                            <div
                                class="pbar pbar-striped pbar-animated"
                                role="progressbar"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <br /> -->
                        <fieldset v-if="step == 1">
                            <div class="form-card">
                                <div class="row">
                                    <div class="col-7">
                                        <h2 class="fs-title">
                                            Select Address:
                                        </h2>
                                        <p>All Fields Required</p>
                                    </div>
                                    <div class="col-5">
                                        <h2 class="steps">Step 1 - 4</h2>
                                    </div>
                                </div>
                                <label class="fieldlabels">
                                    Home Address: *
                                </label>
                                <input type="hidden" v-model="address" />
                                <vue-google-autocomplete
                                    id="map"
                                    ref="mapElement"
                                    class="form-control"
                                    :class="addressError ? 'is-invalid' : ''"
                                    placeholder="Home address"
                                    :country="['ca']"
                                    @placechanged="getAddressData"
                                ></vue-google-autocomplete>
                                <small
                                    class="invalid-feedback"
                                    role="alert"
                                    v-if="addressError"
                                >
                                    <strong>{{ addressErrorMessage }}</strong>
                                </small>
                            </div>
                            <button
                                :disabled="!formOneValid"
                                class="next action-button"
                                @click="goToNext"
                            >Next</button>
                        </fieldset>
                        <fieldset v-if="step == 2">
                            <div class="form-card">
                                <div class="row">
                                    <div class="col-7">
                                        <h2 class="fs-title">
                                            Fill Additional Information:
                                        </h2>
                                    </div>
                                    <div class="col-5">
                                        <h2 class="steps">Step 2 - 4</h2>
                                    </div>
                                </div>
                                <label class="fieldlabels">
                                    Bedrooms: *
                                </label>
                                <select class="form-control" v-model="bedroom">
                                    <option :value="item" v-for="(item, index) in bed_list" :key="index + '-bed'">{{ item }} Bedrooms</option>
                                </select>
                                <label class="fieldlabels">
                                    Bathroom: *
                                </label>
                                <select class="form-control" v-model="bathroom">
                                    <option :value="item" v-for="(item, index) in bath_list" :key="index + '-bath'">{{ item }} Bathrooms</option>
                                </select>
                                <label class="fieldlabels">
                                    House Type: *
                                </label>
                                <select class="form-control" v-model="type">
                                    <option :value="item.value" v-for="(item, index) in house_types" :key="index + '-tyoe'">{{ item.label }}</option>
                                </select>
                                <label class="fieldlabels">
                                    Zip Code: *
                                </label>
                                <input
                                    type="text"
                                    v-model="zip_code"
                                    placeholder="Enter zip code"
                                    :disabled="true"
                                />
                            </div>
                            <button
                                class="next action-button"
                                @click="goToNext"
                            >Next</button>
                            <button
                                class="next action-button-pre"
                                @click="goToPrevious"
                            >Pre</button>
                        </fieldset>
                        <fieldset v-if="step == 3">
                            <div class="form-card">
                                <div class="row">
                                    <div class="col-7">
                                        <h2 class="fs-title">
                                            Fill Personal Details:
                                        </h2>
                                    </div>
                                    <div class="col-5">
                                        <h2 class="steps">Step 3 - 4</h2>
                                    </div>
                                </div>
                                <label class="fieldlabels">
                                    Name: *
                                </label>
                                <input
                                    type="text"
                                    v-model="name"
                                    placeholder="Enter your name"
                                />
                                <label class="fieldlabels">
                                    Email: *
                                </label>
                                <input
                                    type="email"
                                    v-model="email"
                                    placeholder="Enter your email"
                                />
                                <label class="fieldlabels">
                                    Phone: *
                                </label>
                                <input
                                    type="text"
                                    v-model="phone"
                                    placeholder="Enter your phone"
                                />
                            </div>
                            <button
                                class="next action-button"
                                @click="goToNext"
                            >Next</button>
                            <button
                                class="next action-button-pre"
                                @click="goToPrevious"
                            >Pre</button>
                        </fieldset>
                        <fieldset v-if="step == 4">
                            <div class="form-card">
                                <div class="row">
                                    <div class="col-7">
                                        <h2 class="fs-title">
                                            Get Instant Valuation:
                                        </h2>
                                    </div>
                                    <div class="col-5">
                                        <h2 class="steps">Step 4 - 4</h2>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <h2 class="purple-text text-center">
                                    <strong> SUCCESS ! Your valuation generated successfully</strong>
                                </h2>
                                <br />
                                <div class="row justify-content-center">
                                    <div class="col-3">
                                        <!-- <img src="1.png" class="fit-image" /> -->
                                    </div>
                                </div>
                                <br /><br />
                                <div class="row justify-content-center">
                                    <div class="col-7 text-center">
                                        <h3 class="purple-text text-center display-3">
                                            ${{ valuation.min > 0 ? valuation.min.toLocaleString() : valuation.min }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ./Multi-step form -->
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
const canadaPhone = (value) => {
    let regx = /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return regx.test(value);
};
export default {
    name: "InstantValuation",
    data() {
        return {
            step: 1,
            address: "",
            bedroom: 1,
            bathroom: 1,
            type: 'Detached',
            zip_code: '',
            name: '',
            email: '',
            phone: '',
            valuation: {
                min: 0,
                max: 0
            },
            bed_list: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10
            ],
            bath_list: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10
            ],
            house_types: [
                {
                    value: "Att/Row/Twnhouse",
                    label: "Freehold Townhouse"
                },
                {
                    value: "Condo Townhouse",
                    label: "Condo Townhouse"
                },
                {
                    value: "Condo Apt",
                    label: "Condo Apartment"
                },
                {
                    value: "Semi-Detached",
                    label: "Semi-Detached"
                },
                {
                    value: "Detached",
                    label: "Detached"
                }
            ],
            latitude: '',
            longitude: ''
        };
    },
    validations: {
        address: {
            required,
        },
    },
    methods: {
        getValuation() {
            axios.get(`https://192.53.122.51/api/v1/get-home-value-estimation?address=${this.address}&bedrooms=${this.bedroom}&bathrooms=${this.bathroom}&type=${this.type}&zip_code=${this.zip_code}&latitude=${this.latitude}&longitude=${this.longitude}`)
            .then(res => {
                this.valuation = res.data.data;
            }).catch(err => {
                console.error(err);
            })
        },
        goToPrevious() {
            if(this.step > 1) {
                this.step = this.step - 1;
            }
        },
        goToNext() {
            if(this.step >= 1 && this.step < 4) {
                if(this.step == 3) {
                    this.getValuation();
                }
                this.step = this.step + 1;
            }
            // this.checkFormOneValidity();
            // if (this.formOneValid) {
            //     if (this.step == 1) {
            //         this.step = 2;
            //     } else {
            //         this.checkFormOneValidity();
            //         // this.checkFormTwoValidity();
            //         if (this.formOneValid) {
            //             // if (this.formTwoValid) {
            //                 // bot detection
            //                 if (this.bot) {
            //                     this.resetForm();
            //                 } else {
            //                     // call api in this condition
            //                     const formData = new FormData();

            //                     formData.append("address", this.address);
            //                     formData.append("contact_name", this.name);
            //                     formData.append("contact_email", this.email);
            //                     formData.append("contact_phone", this.phone);

            //                     formData.append(
            //                         "building_type",
            //                         this.buildingType
            //                     );
            //                     formData.append("beds", this.beds);
            //                     formData.append("bathroom", this.bath);
            //                     formData.append(
            //                         "parking_space",
            //                         this.parkingSpace
            //                     );
            //                     formData.append("utm_source", this.utm_source);
            //                     formData.append("utm_medium", this.utm_medium);
            //                     formData.append(
            //                         "utm_campaign",
            //                         this.utm_campaign
            //                     );
            //                     formData.append("utm_id", this.utm_id);
            //                     formData.append(
            //                         "utm_content",
            //                         this.utm_content
            //                     );
            //                     this.submittingForm = true;
            //                     // this.$recaptcha("login").then((token) => {
            //                     // formData.append("g-recaptcha-response", token);
            //                     // let utmSource = this.getParameterByName('utm_source');
            //                     // if(utmSource) {
            //                     //   formData.append("utm_source", utmSource);
            //                     // }
            //                     axios
            //                         .post("/submit-seller-leads", formData)
            //                         .then((response) => {
            //                             this.resetForm();
            //                             let responseMessage =
            //                                 "Details added successfully!";
            //                             if (response?.data?.data?.message) {
            //                                 responseMessage =
            //                                     response.data.data.message;
            //                             }
            //                             this.responseMessage = responseMessage;
            //                             let responseStatus =
            //                                 response?.data?.data?.status;
            //                             if (responseStatus == "danger") {
            //                                 this.responseClass = "error";
            //                                 this.responseTitle = "Error";
            //                             }
            //                             this.$notify({
            //                                 group: "foo",
            //                                 type: this.responseClass,
            //                                 title: this.responseTitle,
            //                                 text: this.responseMessage,
            //                                 duration: 6000,
            //                                 speed: 1000,
            //                             });
            //                             this.submittingForm = false;
            //                             if (responseStatus == "success") {
            //                                 window.location.href =
            //                                     "/thanks-for-providing-your-home-details";
            //                             }
            //                         })
            //                         .catch((error) => {
            //                             this.resetForm();
            //                             this.responseTitle = "Error";
            //                             this.responseClass = "error";
            //                             if (error?.response?.data?.message) {
            //                                 this.responseMessage =
            //                                     error.response.data.message;
            //                             } else {
            //                                 this.responseMessage =
            //                                     "Error occured during valuation.";
            //                             }
            //                             this.$notify({
            //                                 group: "foo",
            //                                 type: this.responseClass,
            //                                 title: this.responseTitle,
            //                                 text: this.responseMessage,
            //                                 duration: 6000,
            //                                 speed: 1000,
            //                             });
            //                             this.submittingForm = false;
            //                         });
            //                     // }).catch(err => {
            //                     //   console.error(err?.response);
            //                     //   this.resetForm();
            //                     //   this.submittingForm = false;
            //                     // });
            //                 }
            //             // } else {
            //             //     this.checkFormOneValidity();
            //             //     if (this.formOneValid) {
            //             //         this.step = 2;
            //             //     } else {
            //             //         this.step = 1;
            //             //     }
            //             // }
            //         } else {
            //             this.step = 1;
            //         }
            //     }
            // }
        },
        getAddressData(addressData, placeResultData, id) {
            this.latitude = addressData.latitude;
            this.longitude = addressData.longitude;
            let zip_code = placeResultData.address_components.find(el => el.types[0] == 'postal_code');
            if(zip_code.long_name) {
                this.zip_code = zip_code.long_name;
            }
            if (placeResultData?.formatted_address) {
                this.address = placeResultData.formatted_address;
            }
        },
        checkFormOneValidity() {
            this.$v.address.$touch();
        },
        resetForm() {
            this.address = "";
            this.name = "";
            this.email = "";
            this.phone = "";
            this.type = null;
            this.bedroom = null;
            this.bathroom = null;
            this.valuation = {
                min: 0,
                max: 0
            };
            this.$v.$reset();
            this.step = 1;
        },
        getParameterByName(name, url = window.location.href) {
            name = name.replace(/[\[\]]/g, "\\$&");
            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
    },
    computed: {
        buttonText() {
            if (this.step == 1) {
                return "Next";
            } else {
                if (this.submittingForm) {
                    return "Processing...";
                } else {
                    return "Get My Instant Offer";
                }
            }
        },
        addressError() {
            if (this.$v.address.$error) {
                return true;
            } else {
                return false;
            }
        },
        addressErrorMessage() {
            if (this.$v.address.$error) {
                return "Home address is required";
            } else {
                return "";
            }
        },
        formOneValid() {
            if (
                this.addressError
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

<style scoped>
html {
    height: 100%;
}

p {
    color: grey;
}

h1 {
    text-transform: uppercase;
    color: green;
    font-weight: normal;
    text-align: center;
    margin: 10;
    padding: 10;
}

#heading {
    text-transform: uppercase;
    color: green;
    font-weight: normal;
}

#msform {
    text-align: center;
    position: relative;
    margin-top: 20px;
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative;
}

.form-card {
    text-align: left;
}

#msform fieldset:not(:first-of-type) {
    display: none;
}

#msform input {
    padding: 8px 15px 8px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 15px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2c3e50;
    background-color: #eceff1;
    font-size: 16px;
    letter-spacing: 1px;
}

#msform textarea {
    padding: 8px 15px 8px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 15px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2c3e50;
    background-color: #eceff1;
    font-size: 16px;
    letter-spacing: 1px;
}

#msform input:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid green;
    outline-width: 0;
}

#msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid green;
    outline-width: 0;
}

#msform .action-button {
    width: 100px;
    background: green;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 0px 10px 5px;
    float: right;
}

#msform .action-button:hover {
    background-color: #311b92;
}

#msform .action-button:focus {
    background-color: #311b92;
}

#msform .action-button-pre {
    width: 100px;
    background: #616161;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px 10px 0px;
    float: right;
}

#msform .action-button-pre:hover {
    background-color: #000000;
}

#msform .action-button-pre:focus {
    background-color: #000000;
}

.card {
    z-index: 0;
    border: none;
    position: relative;
}

.fs-title {
    font-size: 25px;
    color: green;
    margin-bottom: 15px;
    font-weight: normal;
    text-align: left;
}

.purple-text {
    color: green;
    font-weight: normal;
}

.steps {
    font-size: 15px;
    color: gray;
    margin-bottom: 1px;
    font-weight: normal;
    text-align: right;
}

.fieldlabels {
    color: gray;
    text-align: left;
}

#progressbar {
    margin-bottom: 20px;
    overflow: hidden;
    color: lightgrey;
}

#progressbar .active {
    color: green;
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
}

#progressbar #account:before {
    font-family: FontAwesome;
    content: "\f13e";
}

#progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007";
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "\f030";
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c";
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

#progressbar li:after {
    content: "";
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
}

#progressbar li.active:before {
    background: green;
}

#progressbar li.active:after {
    background: green;
}

.progress {
    height: 20px;
}

.pbar {
    background-color: green;
}

.fit-image {
    width: 100%;
    object-fit: cover;
}
button:disabled {
    cursor: not-allowed;
}
</style>
