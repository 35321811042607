import { required, maxLength } from "vuelidate/lib/validators";

export function requiredValidationFields(initFormFields) {
    return Object.keys(initFormFields).reduce(
        (p, n) =>
            (p = {
                ...p,
                [n]: {
                    required,
                },
            }),
        {}
    );
}