<template>
    <div>
        <h2 v-if="heading" class="heading">{{ heading }}</h2>
        <div class="properties-carousel-container">
            <div
                key="property-loader"
                class="common-property-list-grid"
                v-if="isLoading"
            >
                <skeleton-loader
                    v-for="item in [1, 2, 3, 4]"
                    :key="'property-' + item"
                />
            </div>
            <div
                class="owl-carousel owl-theme"
                v-if="!isLoading && propertyList.length !== 0"
            >
                <common-property-card
                    :user_id="user_id"
                    v-for="(property, index) in propertyList"
                    :key="'idx-property-' + index"
                    :property_details="property"
                    :type="currentListMetadata.cardType"
                    :hidePrice="hidePrice"
                    :displayTimeLabel="currentListMetadata.displayTimeLabel"
                />
            </div>
            <div
                class="view-all-btn-container"
                v-if="propertyList.length && propertyList.length > 4"
            >
                <a :href="viewAllLink" class="view-all-btn mt-0"
                    >View All Properties</a
                >
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import CommonPropertyCard from "../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import SkeletonLoader from "../../pages/SkeletonLoader/SkeletonLoader.vue";
export const LIST_METADATA = {
    MLS: {
        cardType: "BROWSE_HOME",
        endpoint:
            "https://valuation.santhoshmathew.com/api/v1/properties/residential?page=1&perPage=8",
        viewAllLink: "/idx-listings/search-result",
    },
    EXCLUSIVE: {
        cardType: "EXCLUSIVE",
        endpoint:
            window.location.origin +
            "/exclusive-property-filter?page=1&perPage=8",
        viewAllLink: "/exclusive-property/search-result",
    },
    NEWLY_ADDED_MLS_HOMES: {
        cardType: "BROWSE_HOME",
        endpoint:
            "https://valuation.santhoshmathew.com/api/v1/properties/residential?page=1&perPage=9&sortBy=timestamp_idx&sortDir=desc&sale_or_lease=Sale",
        viewAllLink: "/idx-listings/search-result",
        displayTimeLabel: true,
    },
    RECENTLY_SOLD_HOMES: {
        cardType: "COMPARABLE_HOME",
        endpoint:
            "https://valuation.santhoshmathew.com/api/v1/sold/properties?page=1&sortDir=desc&sortBy=sold_date",
        viewAllLink: "/idx-listings/sold/properties",
    },
    EXCLUSIVE_OFF_MARKET: {
        cardType: "BROWSE_HOME",
        endpoint:
            "https://valuation.santhoshmathew.com/api/v1/get-with-realtor?sale_with_redbuyers=REDBUYERS%20REALTY,%20BROKERAGE&page=1&perPage=9",
        viewAllLink: "/exclusive-property/search-result",
    },
    BUILDER_DEALS: {
        cardType: "NEW_CONSTRUCTION",
        endpoint: window.location.origin + "/fetch-homes?page=1",
        viewAllLink: "/new-construction/search-home",
        method: "post",
    },
    RENTAL_DEALS: {
        cardType: "BROWSE_HOME",
        endpoint:
            "https://valuation.santhoshmathew.com/api/v1/properties/residential?page=1&perPage=9&sortBy=timestamp_idx&sortDir=desc&sale_or_lease=Lease",
        viewAllLink: "/idx-listings/search-result?sale_or_rent=Lease",
    },
};
export default {
    name: "InlinePropertiesCarousel",
    props: {
        listFor: {
            default: "", // MLS | EXCLUSIVE | NEWLY_ADDED_MLS_HOMES | RECENTLY_SOLD_HOMES
        },
        heading: {
            default: null,
        },
        user_id: {
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            propertyList: [
                {
                    id: "C7203614",
                    unique_id: 2051928,
                    property_description:
                        "Experience modern comfort in this newly renovated lower unit, ideally located in the desirable Deer Park neighborhood, just steps from Yonge & St. Clair. Set within a classic 3-story home, enjoy seamless access to shops, dining, and all public transportation. Revel in the beauty and brightness of well-appointed common areas, and relish the privacy of a separate entrance. The generously sized bedroom boasts two spacious closets, while a private and secure storage unit adds convenience. Recent updates include a renovated bathroom, kitchen, and brand-new floors and carpeting, creating an inviting and move-in-ready living space.",
                    community: "Yonge-St. Clair",
                    basement_one: "Finished",
                    basement_two: "Sep Entrance",
                    property_type: "Multiplex",
                    bedrooms: 1,
                    bathrooms: 1,
                    kitchens: "1",
                    square_footage: "",
                    area: "Toronto",
                    city: "Toronto",
                    property_style: "2-Storey",
                    list_price: 2200,
                    sale_or_lease: "Lease",
                    virtual_tour_url: "",
                    virtual_tour_upload_date: "",
                    address: "56 Oriole Gdns",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Yonge & St. Clair",
                    zip_code: "M4V 1V7",
                    county: "Ontario",
                    realtor: "SOTHEBY`S INTERNATIONAL REALTY CANADA",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "0.0",
                    parking_included: "Y",
                    parking_charges: "",
                    garage_type: "None",
                    garage_spaces: "0.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "In-Suite Laundry With Washer & Dryer, Intercom And Security System.",
                    link: "",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-28.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-29.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-30.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-C7203614-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:25:45",
                    pictures_updated_at: "2023-12-18 10:48:02.0",
                    last_updated_at: "2023-12-28 09:25:45",
                    additional_bathroom: "",
                    rooms: "4",
                    rooms_plus: "",
                    den_family_room: "N",
                    ac: "Central Air",
                    fire_place: "N",
                    heating: "Radiant",
                    water_supply: "Municipal",
                    drive_way: "Private",
                    taxes: null,
                    electricity: "",
                    gas: "",
                    cable: "",
                    prop_feat1_out: "Park",
                    prop_feat2_out: "Public Transit",
                    prop_feat3_out: "School",
                    prop_feat4_out: "",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "",
                    depth: "",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "",
                    parcel_id: "",
                    garage: "Private",
                    mls_num: "C7203614",
                    constr_out: "Brick",
                    fronting_on: "N",
                    latitude: "43.69068800",
                    longitude: "-79.39992500",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text:
                            "Experience modern comfort in this newly renovated lower unit, ideally located in the desirable Deer Park neighborhood, just steps from Yonge & St. Clair. Set within a classic 3-story home, enjoy seamless access to shops, dining, and all public transportation. Revel in the beauty and brightness of well-appointed common areas, and relish the privacy of a separate entrance. The generously sized bedroom boasts two spacious closets, while a private and secure storage unit adds convenience. Recent updates include a renovated bathroom, kitchen, and brand-new floors and carpeting, creating an inviting and move-in-ready living space.",
                        Addl_mo_fee: "",
                        Addr: "56 Oriole Gdns",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "4-Lower",
                        Area: "Toronto",
                        Area_code: "01",
                        Ass_year: "",
                        Bath_tot: "1",
                        Br: "1",
                        Br_plus: "",
                        Bsmt1_out: "Finished",
                        Bsmt2_out: "Sep Entrance",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Yonge-St. Clair",
                        Community_code: "01.C02.0830",
                        Comp_pts: "N",
                        Constr1_out: "Brick",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "Yonge & St. Clair",
                        Den_fr: "N",
                        Depth: "",
                        Disp_addr: "Y",
                        Drive: "Private",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "",
                        Elevator: "",
                        Extras: "In-Suite Laundry With Washer & Dryer, Intercom And Security System.",
                        Farm_agri: "",
                        Fpl_num: "N",
                        Front_ft: "",
                        Fuel: "Gas",
                        Furnished: "N",
                        Gar_spaces: "0.0",
                        Gar_type: "None",
                        Gas: "",
                        Heat_inc: "Y",
                        Heating: "Radiant",
                        Hydro_inc: "Y",
                        Idx_dt: "2017-05-01 12:21:15.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "Ensuite",
                        Laundry_lev: "",
                        Legal_desc: "",
                        Level1: "Lower",
                        Level10: "",
                        Level11: "",
                        Level12: "",
                        Level2: "Lower",
                        Level3: "Lower",
                        Level4: "",
                        Level5: "",
                        Level6: "",
                        Level7: "",
                        Level8: "",
                        Level9: "",
                        Link_comment: "",
                        Link_yn: "",
                        Lotsz_code: "",
                        Lp_dol: "2200.00",
                        Lse_terms: "",
                        Ml_num: "C7203614",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Toronto",
                        Municipality_code: "01.C02",
                        Municipality_district: "Toronto C02",
                        Num_kit: "1",
                        Occ: "",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "",
                        Park_chgs: "",
                        Park_spcs: "0",
                        Pix_updt: "2023-12-18 10:48:02.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "Y",
                        Prop_feat1_out: "Park",
                        Prop_feat2_out: "Public Transit",
                        Prop_feat3_out: "School",
                        Prop_feat4_out: "",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "Y",
                        Retirement: "",
                        Rltr: "SOTHEBY`S INTERNATIONAL REALTY CANADA",
                        Rm1_dc1_out: "Closet",
                        Rm1_dc2_out: "Window",
                        Rm1_dc3_out: "",
                        Rm1_len: "",
                        Rm1_out: "Living",
                        Rm1_wth: "",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "",
                        Rm10_wth: "",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "",
                        Rm12_wth: "",
                        Rm2_dc1_out: "Stainless Steel Appl",
                        Rm2_dc2_out: "Backsplash",
                        Rm2_dc3_out: "Window",
                        Rm2_len: "",
                        Rm2_out: "Kitchen",
                        Rm2_wth: "",
                        Rm3_dc1_out: "4 Pc Bath",
                        Rm3_dc2_out: "Double Closet",
                        Rm3_dc3_out: "Broadloom",
                        Rm3_len: "",
                        Rm3_out: "Prim Bdrm",
                        Rm3_wth: "",
                        Rm4_dc1_out: "",
                        Rm4_dc2_out: "",
                        Rm4_dc3_out: "",
                        Rm4_len: "",
                        Rm4_out: "",
                        Rm4_wth: "",
                        Rm5_dc1_out: "",
                        Rm5_dc2_out: "",
                        Rm5_dc3_out: "",
                        Rm5_len: "",
                        Rm5_out: "",
                        Rm5_wth: "",
                        Rm6_dc1_out: "",
                        Rm6_dc2_out: "",
                        Rm6_dc3_out: "",
                        Rm6_len: "",
                        Rm6_out: "",
                        Rm6_wth: "",
                        Rm7_dc1_out: "",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "",
                        Rm7_out: "",
                        Rm7_wth: "",
                        Rm8_dc1_out: "",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "",
                        Rm8_out: "",
                        Rm8_wth: "",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "",
                        Rm9_out: "",
                        Rm9_wth: "",
                        Rms: "4",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Lease",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "",
                        St: "Oriole",
                        St_dir: "",
                        St_num: "56",
                        St_sfx: "Gdns",
                        Status: "A",
                        Style: "2-Storey",
                        Taxes: "",
                        Timestamp_sql: "2023-12-28 09:25:45.0",
                        Tot_park_spcs: "0.0",
                        Tour_url: "",
                        Tv: "",
                        Type_own_srch: ".M.",
                        Type_own1_out: "Multiplex",
                        Uffi: "",
                        Util_cable: "",
                        Util_tel: "",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "Y",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "4",
                        Wcloset_p2: "",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "Bsmt",
                        Wcloset_t2: "",
                        Wcloset_t2lvl: "",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "",
                        Yr_built: "",
                        Zip: "M4V 1V7",
                        Zoning: "",
                        Portion_property_lease1_out: "Basement",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: ".AB.",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "2-Storey",
                        },
                        {
                            name: "Home Type",
                            value: "Multiplex",
                        },
                        {
                            name: "Basement",
                            value: "Finished",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: null,
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:25:45",
                        },
                        {
                            name: "MLS",
                            value: "C7203614",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "SOTHEBY`S INTERNATIONAL REALTY CANADA",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Lease",
                                },
                                {
                                    name: "Type",
                                    value: "Multiplex",
                                },
                                {
                                    name: "Style",
                                    value: "2-Storey",
                                },
                                {
                                    name: "Area",
                                    value: "Toronto",
                                },
                                {
                                    name: "Community",
                                    value: "Yonge-St. Clair",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 1,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 1,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "4",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "N",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "N",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Finished",
                                },
                                {
                                    name: "Heating",
                                    value: "Radiant",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                                {
                                    name: "Garage",
                                    value: "0.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "0.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "",
                                },
                                {
                                    name: "Tax Year",
                                    value: "",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "",
                                },
                                {
                                    name: "Gas",
                                    value: "",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "Park",
                                },
                                {
                                    name: "Feature",
                                    value: "Public Transit",
                                },
                                {
                                    name: "Feature",
                                    value: "School",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "N",
                                },
                                {
                                    name: "Frontage",
                                    value: "",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Yonge & St. Clair",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Toronto",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "De La Salle College  Oaklands",
                            school_address:
                                "De La Salle College Oaklands, 131 Farnham Ave, Toronto, Ontario M4V 1G6, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Upper Canada College",
                            school_address:
                                "Upper Canada College, 200 Lonsdale Rd., Toronto, Ontario M4V 2L9, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "English School of Canada",
                            school_address:
                                "English School of Canada, 79 St. Clair Ave. E, Toronto, Ontario M4T 2Y8, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "The York School",
                            school_address:
                                "The York School, 1320 Yonge St, Toronto, Ontario M4T 1X2, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Avenue Road Arts School",
                            school_address:
                                "Avenue Road Arts School, 460 Avenue Rd., Toronto, Ontario M4V 2P1, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name:
                                "OzPrep LSAT Tutoring & Law Admissions",
                            school_address:
                                "OzPrep LSAT Tutoring & Law Admissions, 21 Tichester Rd, York, Ontario M5P 3L8, Canada",
                            school_category: "private school, school",
                        },
                        {
                            school_name: "The Sewing Studio Toronto",
                            school_address:
                                "The Sewing Studio Toronto, 1225 Yonge Street, Toronto, Ontario M4T 1W4, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "F45 Training Yonge & Eglinton",
                            school_address:
                                "F45 Training Yonge & Eglinton, 1 Eglinton Avenue East, Toronto, Ontario M4P 1A1, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "185 Dream House Private",
                            school_address:
                                "185 Dream House Private, 185, York, Ontario M6C 2M4, Canada",
                            school_category: "language school, school",
                        },
                        {
                            school_name: "English Central",
                            school_address:
                                "English Central, 60 St. Clair Avenue East, Toronto, Ontario M4T 1M9, Canada",
                            school_category: "language school, school",
                        },
                    ],
                },
                {
                    id: "E7369412",
                    unique_id: 2051922,
                    property_description:
                        "Spacious Bungalow In The Country, But Close To All Amenities. Just North Of Taunton. Country In The City. Master Bedroom Has Ensuite, Main Floor Family Room. Tenant Responsible For Cutting Grass, Landscape & Shovel Snow. Landlord Will Provide Riding Lawn Mower and Snow Blower - To Be Maintained By The Tenant. AAA Applicants Only. No Pets.",
                    community: "Taunton North",
                    basement_one: "Part Fin",
                    basement_two: "W/O",
                    property_type: "Detached",
                    bedrooms: 4,
                    bathrooms: 2,
                    kitchens: "1",
                    square_footage: "",
                    area: "Durham",
                    city: "Whitby",
                    property_style: "Bungalow",
                    list_price: 4200,
                    sale_or_lease: "Lease",
                    virtual_tour_url: "",
                    virtual_tour_upload_date: "",
                    address: "4650 Garden St N",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Garden/Taunton",
                    zip_code: "L1R 3K5",
                    county: "Ontario",
                    realtor: "ROYAL LEPAGE FRANK REAL ESTATE",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "9.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "Attached",
                    garage_spaces: "1.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "Applications to Include: Schedule B & A  and Tenant Application (Attached), Equifax Credit Report & Score, Employment Letter, Last 3 Paystubs.  Deposit - First & Last Months Rent.",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-28.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-29.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369412-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:21:47",
                    pictures_updated_at: "2023-12-28 09:21:59.0",
                    last_updated_at: "2023-12-28 09:21:47",
                    additional_bathroom: "1",
                    rooms: "8",
                    rooms_plus: "1",
                    den_family_room: "Y",
                    ac: "Central Air",
                    fire_place: "Y",
                    heating: "Forced Air",
                    water_supply: "Well",
                    drive_way: "Circular",
                    taxes: null,
                    electricity: "",
                    gas: "",
                    cable: "",
                    prop_feat1_out: "",
                    prop_feat2_out: "",
                    prop_feat3_out: "",
                    prop_feat4_out: "",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "",
                    depth: "",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Septic",
                    zoning: "",
                    parcel_id: "265690097",
                    garage: "Circular",
                    mls_num: "E7369412",
                    constr_out: "Brick",
                    fronting_on: "W",
                    latitude: "43.92944400",
                    longitude: "-78.95322800",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text:
                            "Spacious Bungalow In The Country, But Close To All Amenities. Just North Of Taunton. Country In The City. Master Bedroom Has Ensuite, Main Floor Family Room. Tenant Responsible For Cutting Grass, Landscape & Shovel Snow. Landlord Will Provide Riding Lawn Mower and Snow Blower - To Be Maintained By The Tenant. AAA Applicants Only. No Pets.",
                        Addl_mo_fee: "",
                        Addr: "4650 Garden St N",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Durham",
                        Area_code: "10",
                        Ass_year: "",
                        Bath_tot: "2",
                        Br: "3",
                        Br_plus: "1",
                        Bsmt1_out: "Part Fin",
                        Bsmt2_out: "W/O",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Taunton North",
                        Community_code: "10.06.0060",
                        Comp_pts: "W",
                        Constr1_out: "Brick",
                        Constr2_out: "Stone",
                        County: "Ontario",
                        Cross_st: "Garden/Taunton",
                        Den_fr: "Y",
                        Depth: "",
                        Disp_addr: "Y",
                        Drive: "Circular",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "",
                        Elevator: "",
                        Extras: "Applications to Include: Schedule B & A  and Tenant Application (Attached), Equifax Credit Report & Score, Employment Letter, Last 3 Paystubs.  Deposit - First & Last Months Rent.",
                        Farm_agri: "",
                        Fpl_num: "Y",
                        Front_ft: "",
                        Fuel: "Electric",
                        Furnished: "N",
                        Gar_spaces: "1.0",
                        Gar_type: "Attached",
                        Gas: "",
                        Heat_inc: "",
                        Heating: "Forced Air",
                        Hydro_inc: "",
                        Idx_dt: "2018-05-17 15:55:21.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "Ensuite",
                        Laundry_lev: "",
                        Legal_desc: "",
                        Level1: "Main",
                        Level10: "",
                        Level11: "",
                        Level12: "",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "Main",
                        Level7: "Main",
                        Level8: "Main",
                        Level9: "Bsmt",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "",
                        Lp_dol: "4200.00",
                        Lse_terms: "",
                        Ml_num: "E7369412",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Whitby",
                        Municipality_code: "10.06",
                        Municipality_district: "Whitby",
                        Num_kit: "1",
                        Occ: "",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "265690097",
                        Park_chgs: "",
                        Park_spcs: "8",
                        Pix_updt: "2023-12-28 09:21:59.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "",
                        Prop_feat2_out: "",
                        Prop_feat3_out: "",
                        Prop_feat4_out: "",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "Y",
                        Retirement: "",
                        Rltr: "ROYAL LEPAGE FRANK REAL ESTATE",
                        Rm1_dc1_out: "Ceramic Floor",
                        Rm1_dc2_out: "",
                        Rm1_dc3_out: "",
                        Rm1_len: "3.05",
                        Rm1_out: "Kitchen",
                        Rm1_wth: "2.74",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "",
                        Rm10_wth: "",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "",
                        Rm12_wth: "",
                        Rm2_dc1_out: "Ceramic Floor",
                        Rm2_dc2_out: "",
                        Rm2_dc3_out: "",
                        Rm2_len: "5.12",
                        Rm2_out: "Breakfast",
                        Rm2_wth: "2.56",
                        Rm3_dc1_out: "Hardwood Floor",
                        Rm3_dc2_out: "",
                        Rm3_dc3_out: "",
                        Rm3_len: "3.51",
                        Rm3_out: "Dining",
                        Rm3_wth: "3.39",
                        Rm4_dc1_out: "Hardwood Floor",
                        Rm4_dc2_out: "",
                        Rm4_dc3_out: "",
                        Rm4_len: "5.39",
                        Rm4_out: "Living",
                        Rm4_wth: "3.96",
                        Rm5_dc1_out: "Hardwood Floor",
                        Rm5_dc2_out: "",
                        Rm5_dc3_out: "",
                        Rm5_len: "5.12",
                        Rm5_out: "Family",
                        Rm5_wth: "4.45",
                        Rm6_dc1_out: "Hardwood Floor",
                        Rm6_dc2_out: "5 Pc Ensuite",
                        Rm6_dc3_out: "W/I Closet",
                        Rm6_len: "5.12",
                        Rm6_out: "Prim Bdrm",
                        Rm6_wth: "3.93",
                        Rm7_dc1_out: "Hardwood Floor",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "3.23",
                        Rm7_out: "2nd Br",
                        Rm7_wth: "3.14",
                        Rm8_dc1_out: "Hardwood Floor",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "3.69",
                        Rm8_out: "3rd Br",
                        Rm8_wth: "3.26",
                        Rm9_dc1_out: "Broadloom",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "5.03",
                        Rm9_out: "4th Br",
                        Rm9_wth: "4.63",
                        Rms: "8",
                        Rooms_plus: "1",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Lease",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Septic",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "",
                        St: "Garden",
                        St_dir: "N",
                        St_num: "4650",
                        St_sfx: "St",
                        Status: "A",
                        Style: "Bungalow",
                        Taxes: "",
                        Timestamp_sql: "2023-12-28 09:21:47.0",
                        Tot_park_spcs: "9.0",
                        Tour_url: "",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "",
                        Util_tel: "",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Well",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "None",
                        Wcloset_p1: "5",
                        Wcloset_p2: "",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "2",
                        Wcloset_t1lvl: "Main",
                        Wcloset_t2: "",
                        Wcloset_t2lvl: "",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "",
                        Yr_built: "",
                        Zip: "L1R 3K5",
                        Zoning: "",
                        Portion_property_lease1_out: "Entire Property",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: ".AA.",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "Bungalow",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Part Fin",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: null,
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:21:47",
                        },
                        {
                            name: "MLS",
                            value: "E7369412",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "ROYAL LEPAGE FRANK REAL ESTATE",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Lease",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "Bungalow",
                                },
                                {
                                    name: "Area",
                                    value: "Durham",
                                },
                                {
                                    name: "Community",
                                    value: "Taunton North",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 4,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "1",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 2,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "8",
                                },
                                {
                                    name: "Room Plus",
                                    value: "1",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "Y",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Part Fin",
                                },
                                {
                                    name: "Heating",
                                    value: "Forced Air",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Well",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Circular",
                                },
                                {
                                    name: "Garage",
                                    value: "1.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "9.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Circular",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "",
                                },
                                {
                                    name: "Tax Year",
                                    value: "",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "",
                                },
                                {
                                    name: "Gas",
                                    value: "",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "W",
                                },
                                {
                                    name: "Frontage",
                                    value: "",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Septic",
                                },
                                {
                                    name: "Zoining",
                                    value: "",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "265690097",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Garden/Taunton",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Whitby",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Whitby Montessori School",
                            school_address:
                                "Whitby Montessori School, Whitby, Ontario L1R 0H4, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Sinclair Secondary School",
                            school_address:
                                "Sinclair Secondary School, 300 Taunton Rd., Whitby, Ontario L1R 2K5, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Sir Samuel Steele PS",
                            school_address:
                                "Sir Samuel Steele PS, Whitby, Ontario L1R 2N4, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "Donald A. Wilson S.S.",
                            school_address:
                                "Donald A. Wilson S.S., Whitby, Ontario L1R 2P2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "All Saints Catholic",
                            school_address:
                                "All Saints Catholic, 3001 country lane, Whitby, Ontario L1P 1M1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "St. Bridget Catholic School",
                            school_address:
                                "St. Bridget Catholic School, Whitby, Ontario L1M 2G3, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Pringle Creek Public School",
                            school_address:
                                "Pringle Creek Public School, 80 Ribblesdale Dr., Whitby, Ontario L1N 6K5, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name:
                                "R S McLaughlin Collegiate & Vocational School",
                            school_address:
                                "R S McLaughlin Collegiate & Vocational School, Stevenson Road North, Oshawa, Ontario L1J 5P1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Trafalgar Castle",
                            school_address:
                                "Trafalgar Castle, 401 reynolds st, Whitby, Ontario L1N 3W8, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Romeo Dallaire Public School",
                            school_address:
                                "Romeo Dallaire Public School, 300 Williamson Rd East, Ajax, Ontario L1Z 1Y2, Canada",
                            school_category: "education, school",
                        },
                    ],
                },
                {
                    id: "X7369410",
                    unique_id: 2051931,
                    property_description:
                        "Beautiful 5 bedroom Century home in quiet Concescon, 2 baths, over 2,100 sq.ft. with details/Millwork from over 90 years.  New windows, HVAC, 200 amp service. Close to 401, parks, trails and County's vineyards, eateries.  Huge lot.",
                    community: "Ameliasburgh",
                    basement_one: "Unfinished",
                    basement_two: "",
                    property_type: "Detached",
                    bedrooms: 5,
                    bathrooms: 2,
                    kitchens: "1",
                    square_footage: "",
                    area: "Prince Edward ",
                    city: "Prince Edward County",
                    property_style: "1 1/2 Storey",
                    list_price: 524900,
                    sale_or_lease: "Sale",
                    virtual_tour_url: "",
                    virtual_tour_upload_date: "",
                    address: "195 County Road 29 Rd",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Hwy.33, Consecon & City Rd 29",
                    zip_code: "K0K 1T0",
                    county: "Ontario",
                    realtor: "RE/MAX HALLMARK CORBO & KELOS GROUP REALTY LTD.",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "2023",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "2.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "None",
                    garage_spaces: "0.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369410-1.jpg",
                    ],
                    updated_at: "2023-12-28 09:18:21",
                    pictures_updated_at: "2023-12-28 09:18:23.0",
                    last_updated_at: "2023-12-28 09:18:21",
                    additional_bathroom: "",
                    rooms: "14",
                    rooms_plus: "",
                    den_family_room: "Y",
                    ac: "None",
                    fire_place: "Y",
                    heating: "Water",
                    water_supply: "Municipal",
                    drive_way: "Private",
                    taxes: "1950.87",
                    electricity: "",
                    gas: "",
                    cable: "",
                    prop_feat1_out: "",
                    prop_feat2_out: "",
                    prop_feat3_out: "",
                    prop_feat4_out: "",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "75.00",
                    depth: "160.00",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "",
                    parcel_id: "550210137",
                    garage: "Private",
                    mls_num: "X7369410",
                    constr_out: "Insulbrick",
                    fronting_on: "N",
                    latitude: "43.99590100",
                    longitude: "-77.52188400",
                    property_details: {
                        A_c: "None",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text:
                            "Beautiful 5 bedroom Century home in quiet Concescon, 2 baths, over 2,100 sq.ft. with details/Millwork from over 90 years.  New windows, HVAC, 200 amp service. Close to 401, parks, trails and County's vineyards, eateries.  Huge lot.",
                        Addl_mo_fee: "",
                        Addr: "195 County Road 29 Rd",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Prince Edward ",
                        Area_code: "36",
                        Ass_year: "",
                        Bath_tot: "2",
                        Br: "5",
                        Br_plus: "",
                        Bsmt1_out: "Unfinished",
                        Bsmt2_out: "",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Ameliasburgh",
                        Community_code: "36.01.0010",
                        Comp_pts: "N",
                        Constr1_out: "Insulbrick",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "Hwy.33, Consecon & City Rd 29",
                        Den_fr: "Y",
                        Depth: "160.00",
                        Disp_addr: "Y",
                        Drive: "Private",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "",
                        Elevator: "",
                        Extras: "",
                        Farm_agri: "",
                        Fpl_num: "Y",
                        Front_ft: "75.00",
                        Fuel: "Gas",
                        Furnished: "",
                        Gar_spaces: "0.0",
                        Gar_type: "None",
                        Gas: "",
                        Heat_inc: "",
                        Heating: "Water",
                        Hydro_inc: "",
                        Idx_dt: "2016-05-27 14:59:15.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "",
                        Legal_desc:
                            "Pt.Lt.50,PL2 Consecon Ameliasburgh PT1 47R5551",
                        Level1: "Main",
                        Level10: "2nd",
                        Level11: "2nd",
                        Level12: "2nd",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "Main",
                        Level7: "Main",
                        Level8: "Main",
                        Level9: "2nd",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "524900.00",
                        Lse_terms: "",
                        Ml_num: "X7369410",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Prince Edward County",
                        Municipality_code: "36.01",
                        Municipality_district: "Prince Edward County",
                        Num_kit: "1",
                        Occ: "Immediate",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "550210137",
                        Park_chgs: "",
                        Park_spcs: "2",
                        Pix_updt: "2023-12-28 09:18:23.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "",
                        Prop_feat2_out: "",
                        Prop_feat3_out: "",
                        Prop_feat4_out: "",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "",
                        Retirement: "",
                        Rltr: "RE/MAX HALLMARK CORBO & KELOS GROUP REALTY LTD.",
                        Rm1_dc1_out: "",
                        Rm1_dc2_out: "",
                        Rm1_dc3_out: "",
                        Rm1_len: "5.71",
                        Rm1_out: "Sunroom",
                        Rm1_wth: "2.43",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "3.27",
                        Rm10_out: "Br",
                        Rm10_wth: "3.70",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "2.81",
                        Rm11_out: "Br",
                        Rm11_wth: "3.20",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "2.82",
                        Rm12_out: "Br",
                        Rm12_wth: "3.20",
                        Rm2_dc1_out: "",
                        Rm2_dc2_out: "",
                        Rm2_dc3_out: "",
                        Rm2_len: "1.99",
                        Rm2_out: "Foyer",
                        Rm2_wth: "4.33",
                        Rm3_dc1_out: "",
                        Rm3_dc2_out: "",
                        Rm3_dc3_out: "",
                        Rm3_len: "3.56",
                        Rm3_out: "Living",
                        Rm3_wth: "4.33",
                        Rm4_dc1_out: "",
                        Rm4_dc2_out: "",
                        Rm4_dc3_out: "",
                        Rm4_len: "4.30",
                        Rm4_out: "Family",
                        Rm4_wth: "3.71",
                        Rm5_dc1_out: "",
                        Rm5_dc2_out: "",
                        Rm5_dc3_out: "",
                        Rm5_len: "3.11",
                        Rm5_out: "Office",
                        Rm5_wth: "2.22",
                        Rm6_dc1_out: "",
                        Rm6_dc2_out: "",
                        Rm6_dc3_out: "",
                        Rm6_len: "2.41",
                        Rm6_out: "Dining",
                        Rm6_wth: "4.29",
                        Rm7_dc1_out: "",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "4.38",
                        Rm7_out: "Kitchen",
                        Rm7_wth: "4.29",
                        Rm8_dc1_out: "",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "4.00",
                        Rm8_out: "Br",
                        Rm8_wth: "3.50",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "3.32",
                        Rm9_out: "Prim Bdrm",
                        Rm9_wth: "4.30",
                        Rms: "14",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "",
                        St: "County Road 29",
                        St_dir: "",
                        St_num: "195",
                        St_sfx: "Rd",
                        Status: "A",
                        Style: "1 1/2 Storey",
                        Taxes: "1950.87",
                        Timestamp_sql: "2023-12-28 09:18:21.0",
                        Tot_park_spcs: "2.0",
                        Tour_url: "",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "",
                        Util_tel: "",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "3",
                        Wcloset_p2: "4",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "Main",
                        Wcloset_t2: "1",
                        Wcloset_t2lvl: "2nd",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2023",
                        Yr_built: "",
                        Zip: "K0K 1T0",
                        Zoning: "",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "1 1/2 Storey",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Unfinished",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: "1950.87",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:18:21",
                        },
                        {
                            name: "MLS",
                            value: "X7369410",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "RE/MAX HALLMARK CORBO & KELOS GROUP REALTY LTD.",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "1 1/2 Storey",
                                },
                                {
                                    name: "Area",
                                    value: "Prince Edward ",
                                },
                                {
                                    name: "Community",
                                    value: "Ameliasburgh",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 5,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 2,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "14",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "None",
                                },
                                {
                                    name: "Fireplace",
                                    value: "Y",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Unfinished",
                                },
                                {
                                    name: "Heating",
                                    value: "Water",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                                {
                                    name: "Garage",
                                    value: "0.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "2.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "1950.87",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2023",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "",
                                },
                                {
                                    name: "Gas",
                                    value: "",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "N",
                                },
                                {
                                    name: "Frontage",
                                    value: "75.00",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "160.00",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "550210137",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Hwy.33, Consecon & City Rd 29",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Prince Edward County",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Trenton High School",
                            school_address:
                                "Trenton High School, Trenton, Ontario K8V 5N4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Bayside High School",
                            school_address:
                                "Bayside High School, 1247 Old Hwy 2, Belleville, Ontario K8R 0A1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Centennial Secondary School",
                            school_address:
                                "Centennial Secondary School, 160 Palmer St., Belleville, Ontario K8P 4E1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Albert College",
                            school_address:
                                "Albert College, 160 Dundas St W, Belleville, Ontario K8P 1A5, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Parkdale Public School",
                            school_address:
                                "Parkdale Public School, Belleville, Ontario K8P 4J3, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Harry J. Clarke Public School",
                            school_address:
                                "Harry J. Clarke Public School, 77 Rollins Dr, Belleville, Ontario K8N 4J6, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "St. Mary Catholic Secondary School",
                            school_address:
                                "St. Mary Catholic Secondary School, 1050 Birchwood Trail, Cobourg, Ontario K9A 5S2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Napanee District Secondary School",
                            school_address:
                                "Napanee District Secondary School, 245 Belleville Road, Napanee, Ontario K7R 2J9, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Trinity College School",
                            school_address:
                                "Trinity College School, 55 Deblaquire St, Port Hope, Ontario L1A 4K7, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Kenner CVI",
                            school_address:
                                "Kenner CVI, Peterborough, Ontario K9J 5J2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                    ],
                },
                {
                    id: "X7369408",
                    unique_id: 2051929,
                    property_description:
                        "Tucked inside a dense cedar forest in Roslin Ontario is where you will find this very private, and very unique custom built home. Paired with a heat-pump for cooling, the giant south facing windows allow the passive solar technology in the floor to heat and cool the home at a fraction of the cost for a building with comparable square footage. Enjoy luxurious living on a downsizers budget in front of your built-in six by twelve foot projector screen. Finished concrete with in-floor heating throughout the home deliver a comforting feel with a stylish and durable industrial aesthetic. Both bathrooms include a distinctive open-concept shower, ensuring the eye-popping design choices remain consistent throughout the space. The building has been prepped for the installation of a hot tub, allowing a buyer to easily maximise the outdoor space and view of the forest. A full list of features, bills and inclusions are available upon request.",
                    community: "",
                    basement_one: "None",
                    basement_two: "",
                    property_type: "Detached",
                    bedrooms: 3,
                    bathrooms: 2,
                    kitchens: "1",
                    square_footage: "1500-2000",
                    area: "Hastings",
                    city: "Tyendinaga",
                    property_style: "Bungalow",
                    list_price: 1050000,
                    sale_or_lease: "Sale",
                    virtual_tour_url:
                        "https://my.matterport.com/show/?m=wZRxXVPC3rF&brand=0",
                    virtual_tour_upload_date: "",
                    address: "3529 Shannonville Rd",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Highway 37 To Shannonville Rd",
                    zip_code: "K0K 2Y0",
                    county: "Ontario",
                    realtor: "ROYAL LEPAGE PROALLIANCE REALTY",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "2023",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "11.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "Attached",
                    garage_spaces: "1.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-28.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-29.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-30.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-31.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-32.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-33.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-34.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-35.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-36.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369408-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:17:38",
                    pictures_updated_at: "2023-12-28 09:18:03.0",
                    last_updated_at: "2023-12-28 09:17:38",
                    additional_bathroom: "",
                    rooms: "9",
                    rooms_plus: "",
                    den_family_room: "Y",
                    ac: "Wall Unit",
                    fire_place: "N",
                    heating: "Water",
                    water_supply: "Well",
                    drive_way: "Circular",
                    taxes: "2285.00",
                    electricity: "Y",
                    gas: "N",
                    cable: "",
                    prop_feat1_out: "Electric Car Charger",
                    prop_feat2_out: "Level",
                    prop_feat3_out: "School Bus Route",
                    prop_feat4_out: "Wooded/Treed",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "342.00",
                    depth: "500.35",
                    acres: "2-4.99",
                    irreg: "",
                    pool: "None",
                    sewer: "Septic",
                    zoning: "RR",
                    parcel_id: "405630192",
                    garage: "Circular",
                    mls_num: "X7369408",
                    constr_out: "Wood",
                    fronting_on: "E",
                    latitude: "44.29599400",
                    longitude: "-77.19423400",
                    property_details: {
                        A_c: "Wall Unit",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "2-4.99",
                        Ad_text:
                            "Tucked inside a dense cedar forest in Roslin Ontario is where you will find this very private, and very unique custom built home. Paired with a heat-pump for cooling, the giant south facing windows allow the passive solar technology in the floor to heat and cool the home at a fraction of the cost for a building with comparable square footage. Enjoy luxurious living on a downsizers budget in front of your built-in six by twelve foot projector screen. Finished concrete with in-floor heating throughout the home deliver a comforting feel with a stylish and durable industrial aesthetic. Both bathrooms include a distinctive open-concept shower, ensuring the eye-popping design choices remain consistent throughout the space. The building has been prepped for the installation of a hot tub, allowing a buyer to easily maximise the outdoor space and view of the forest. A full list of features, bills and inclusions are available upon request.",
                        Addl_mo_fee: "",
                        Addr: "3529 Shannonville Rd",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Hastings",
                        Area_code: "28",
                        Ass_year: "2023",
                        Bath_tot: "2",
                        Br: "3",
                        Br_plus: "",
                        Bsmt1_out: "None",
                        Bsmt2_out: "",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "",
                        Community_code: "",
                        Comp_pts: "E",
                        Constr1_out: "Wood",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "Highway 37 To Shannonville Rd",
                        Den_fr: "Y",
                        Depth: "500.35",
                        Disp_addr: "Y",
                        Drive: "Circular",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "Y",
                        Elevator: "",
                        Extras: "",
                        Farm_agri: "",
                        Fpl_num: "N",
                        Front_ft: "342.00",
                        Fuel: "Propane",
                        Furnished: "",
                        Gar_spaces: "1.0",
                        Gar_type: "Attached",
                        Gas: "N",
                        Heat_inc: "",
                        Heating: "Water",
                        Hydro_inc: "",
                        Idx_dt: "2023-03-28 16:31:32.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "Main",
                        Legal_desc:
                            "PART LOT 5 CONCESSION 8 TYENDINAGA, BEING PART 2, 21R26102; TYENDINAGA ; COUNTY OF HASTINGS",
                        Level1: "Main",
                        Level10: "",
                        Level11: "",
                        Level12: "",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "Main",
                        Level7: "Main",
                        Level8: "Main",
                        Level9: "",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "1050000.00",
                        Lse_terms: "",
                        Ml_num: "X7369408",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Tyendinaga",
                        Municipality_code: "28.15",
                        Municipality_district: "Tyendinaga",
                        Num_kit: "1",
                        Occ: "Flexible",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "405630192",
                        Park_chgs: "",
                        Park_spcs: "10",
                        Pix_updt: "2023-12-28 09:18:03.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "Electric Car Charger",
                        Prop_feat2_out: "Level",
                        Prop_feat3_out: "School Bus Route",
                        Prop_feat4_out: "Wooded/Treed",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "",
                        Retirement: "",
                        Rltr: "ROYAL LEPAGE PROALLIANCE REALTY",
                        Rm1_dc1_out: "",
                        Rm1_dc2_out: "",
                        Rm1_dc3_out: "",
                        Rm1_len: "7.64",
                        Rm1_out: "Foyer",
                        Rm1_wth: "4.56",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "",
                        Rm10_wth: "",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "",
                        Rm12_wth: "",
                        Rm2_dc1_out: "Combined W/Dining",
                        Rm2_dc2_out: "",
                        Rm2_dc3_out: "",
                        Rm2_len: "10.26",
                        Rm2_out: "Living",
                        Rm2_wth: "4.76",
                        Rm3_dc1_out: "",
                        Rm3_dc2_out: "",
                        Rm3_dc3_out: "",
                        Rm3_len: "3.34",
                        Rm3_out: "Kitchen",
                        Rm3_wth: "4.76",
                        Rm4_dc1_out: "",
                        Rm4_dc2_out: "",
                        Rm4_dc3_out: "",
                        Rm4_len: "1.88",
                        Rm4_out: "Pantry",
                        Rm4_wth: "1.05",
                        Rm5_dc1_out: "",
                        Rm5_dc2_out: "",
                        Rm5_dc3_out: "",
                        Rm5_len: "2.48",
                        Rm5_out: "Laundry",
                        Rm5_wth: "2.54",
                        Rm6_dc1_out: "",
                        Rm6_dc2_out: "",
                        Rm6_dc3_out: "",
                        Rm6_len: "4.25",
                        Rm6_out: "Prim Bdrm",
                        Rm6_wth: "3.59",
                        Rm7_dc1_out: "",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "4.57",
                        Rm7_out: "2nd Br",
                        Rm7_wth: "3.14",
                        Rm8_dc1_out: "",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "2.92",
                        Rm8_out: "3rd Br",
                        Rm8_wth: "2.62",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "",
                        Rm9_out: "",
                        Rm9_wth: "",
                        Rms: "9",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Septic",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "1500-2000",
                        St: "Shannonville",
                        St_dir: "",
                        St_num: "3529",
                        St_sfx: "Rd",
                        Status: "A",
                        Style: "Bungalow",
                        Taxes: "2285.00",
                        Timestamp_sql: "2023-12-28 09:17:38.0",
                        Tot_park_spcs: "11.0",
                        Tour_url:
                            "https://my.matterport.com/show/?m=wZRxXVPC3rF&brand=0",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "N",
                        Util_tel: "A",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Well",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "4",
                        Wcloset_p2: "4",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "Main",
                        Wcloset_t2: "1",
                        Wcloset_t2lvl: "Main",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "Drilled Well",
                        Yr: "2023",
                        Yr_built: "",
                        Zip: "K0K 2Y0",
                        Zoning: "RR",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "Bungalow",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "None",
                        },
                        {
                            name: "Size",
                            value: "2-4.99",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: "2285.00",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:17:38",
                        },
                        {
                            name: "MLS",
                            value: "X7369408",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "ROYAL LEPAGE PROALLIANCE REALTY",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "Bungalow",
                                },
                                {
                                    name: "Area",
                                    value: "Hastings",
                                },
                                {
                                    name: "Community",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 3,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 2,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "9",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Wall Unit",
                                },
                                {
                                    name: "Fireplace",
                                    value: "N",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "None",
                                },
                                {
                                    name: "Heating",
                                    value: "Water",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Well",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Circular",
                                },
                                {
                                    name: "Garage",
                                    value: "1.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "11.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Circular",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "2285.00",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2023",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "Y",
                                },
                                {
                                    name: "Gas",
                                    value: "N",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "Electric Car Charger",
                                },
                                {
                                    name: "Feature",
                                    value: "Level",
                                },
                                {
                                    name: "Feature",
                                    value: "School Bus Route",
                                },
                                {
                                    name: "Feature",
                                    value: "Wooded/Treed",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "E",
                                },
                                {
                                    name: "Frontage",
                                    value: "342.00",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "500.35",
                                },
                                {
                                    name: "Lot Size",
                                    value: "2-4.99",
                                },
                                {
                                    name: "Acres",
                                    value: "2-4.99",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Septic",
                                },
                                {
                                    name: "Zoining",
                                    value: "RR",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "405630192",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Highway 37 To Shannonville Rd",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Tyendinaga",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Harry J. Clarke Public School",
                            school_address:
                                "Harry J. Clarke Public School, 77 Rollins Dr, Belleville, Ontario K8N 4J6, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Napanee District Secondary School",
                            school_address:
                                "Napanee District Secondary School, 245 Belleville Road, Napanee, Ontario K7R 2J9, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Parkdale Public School",
                            school_address:
                                "Parkdale Public School, Belleville, Ontario K8P 4J3, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Albert College",
                            school_address:
                                "Albert College, 160 Dundas St W, Belleville, Ontario K8P 1A5, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Centennial Secondary School",
                            school_address:
                                "Centennial Secondary School, 160 Palmer St., Belleville, Ontario K8P 4E1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Bayside High School",
                            school_address:
                                "Bayside High School, 1247 Old Hwy 2, Belleville, Ontario K8R 0A1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Trenton High School",
                            school_address:
                                "Trenton High School, Trenton, Ontario K8V 5N4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Fairfield Elementary School",
                            school_address:
                                "Fairfield Elementary School, 59 Kildare Ave, Amherstview, Ontario K7N 1J1, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Renaissance Music",
                            school_address:
                                "Renaissance Music, 1057 Midland Ave, Kingston, Ontario K7P 2X8, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name:
                                "Loyalist Collegiate Vocational Institute",
                            school_address:
                                "Loyalist Collegiate Vocational Institute, 153 Van Order Drive, Kingston, Ontario K7M 1B9, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                    ],
                },
                {
                    id: "W7369402",
                    unique_id: 2051935,
                    property_description:
                        "Beautiful Home on the wider lot, specular curb appeal. Double Entry Door leading to a Large Foyer. Separate Living, Dining and Family Room. The kitchen offers servery, a Granite Centre Island, Pot lights, and Freshly painted throughout. Separate Family Room offers a cozy space for the family. A finished Basement with a Separate entrance offers huge potential for rental income. Formal Dining Room, Garage Access, Deck, Gazebo, Pecola, and Garden Tool Shed. Professional Landscape and Stamped Concrete Driveway and Backyard. Must see home!!",
                    community: "Sandringham-Wellington",
                    basement_one: "Finished",
                    basement_two: "Sep Entrance",
                    property_type: "Detached",
                    bedrooms: 6,
                    bathrooms: 4,
                    kitchens: "1",
                    square_footage: "",
                    area: "Peel",
                    city: "Brampton",
                    property_style: "2-Storey",
                    list_price: 999000,
                    sale_or_lease: "Sale",
                    virtual_tour_url: "",
                    virtual_tour_upload_date: "",
                    address: "8 Openbay Gdns",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Dixie/ Countryside",
                    zip_code: "L6R 0V3",
                    county: "Ontario",
                    realtor: "IPRO REALTY LTD",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "2023",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "6.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "Built-In",
                    garage_spaces: "2.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "Newer Central A/C (2022), Newer Furnace (2022), Newer Tankless Water tank (2022), Newer Dishwasher (2023), Washer & Dryer (2021). Pot Lights throughout on Main Floor & exterior soffits. Stamped Concrete Driveway (2022).",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369402-1.jpg",
                    ],
                    updated_at: "2023-12-28 09:14:19",
                    pictures_updated_at: "2023-12-28 09:14:20.0",
                    last_updated_at: "2023-12-28 09:14:19",
                    additional_bathroom: "2",
                    rooms: "9",
                    rooms_plus: "",
                    den_family_room: "Y",
                    ac: "Central Air",
                    fire_place: "Y",
                    heating: "Forced Air",
                    water_supply: "Municipal",
                    drive_way: "Private",
                    taxes: "7494.00",
                    electricity: "",
                    gas: "",
                    cable: "",
                    prop_feat1_out: "Hospital",
                    prop_feat2_out: "Park",
                    prop_feat3_out: "Public Transit",
                    prop_feat4_out: "Rec Centre",
                    prop_feat5_out: "School",
                    prop_feat6_out: "",
                    front_age: "46.97",
                    depth: "90.31",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "Residential",
                    parcel_id: "",
                    garage: "Private",
                    mls_num: "W7369402",
                    constr_out: "Brick",
                    fronting_on: "S",
                    latitude: "43.75459500",
                    longitude: "-79.77785700",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text:
                            "Beautiful Home on the wider lot, specular curb appeal. Double Entry Door leading to a Large Foyer. Separate Living, Dining and Family Room. The kitchen offers servery, a Granite Centre Island, Pot lights, and Freshly painted throughout. Separate Family Room offers a cozy space for the family. A finished Basement with a Separate entrance offers huge potential for rental income. Formal Dining Room, Garage Access, Deck, Gazebo, Pecola, and Garden Tool Shed. Professional Landscape and Stamped Concrete Driveway and Backyard. Must see home!!",
                        Addl_mo_fee: "",
                        Addr: "8 Openbay Gdns",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Peel",
                        Area_code: "05",
                        Ass_year: "",
                        Bath_tot: "4",
                        Br: "4",
                        Br_plus: "2",
                        Bsmt1_out: "Finished",
                        Bsmt2_out: "Sep Entrance",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Sandringham-Wellington",
                        Community_code: "05.02.0240",
                        Comp_pts: "S",
                        Constr1_out: "Brick",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "Dixie/ Countryside",
                        Den_fr: "Y",
                        Depth: "90.31",
                        Disp_addr: "Y",
                        Drive: "Private",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "",
                        Elevator: "",
                        Extras: "Newer Central A/C (2022), Newer Furnace (2022), Newer Tankless Water tank (2022), Newer Dishwasher (2023), Washer & Dryer (2021). Pot Lights throughout on Main Floor & exterior soffits. Stamped Concrete Driveway (2022).",
                        Farm_agri: "",
                        Fpl_num: "Y",
                        Front_ft: "46.97",
                        Fuel: "Gas",
                        Furnished: "",
                        Gar_spaces: "2.0",
                        Gar_type: "Built-In",
                        Gas: "",
                        Heat_inc: "",
                        Heating: "Forced Air",
                        Hydro_inc: "",
                        Idx_dt: "2023-03-28 16:31:32.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "",
                        Legal_desc: "LOT 112, PLAN 43M1764, BRAMPTON",
                        Level1: "Main",
                        Level10: "Bsmt",
                        Level11: "Bsmt",
                        Level12: "Bsmt",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "2nd",
                        Level7: "2nd",
                        Level8: "2nd",
                        Level9: "2nd",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "999000.00",
                        Lse_terms: "",
                        Ml_num: "W7369402",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Brampton",
                        Municipality_code: "05.02",
                        Municipality_district: "Brampton",
                        Num_kit: "1",
                        Occ: "TBD",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "",
                        Park_chgs: "",
                        Park_spcs: "4",
                        Pix_updt: "2023-12-28 09:14:20.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "Hospital",
                        Prop_feat2_out: "Park",
                        Prop_feat3_out: "Public Transit",
                        Prop_feat4_out: "Rec Centre",
                        Prop_feat5_out: "School",
                        Prop_feat6_out: "",
                        Pvt_ent: "",
                        Retirement: "N",
                        Rltr: "IPRO REALTY LTD",
                        Rm1_dc1_out: "Hardwood Floor",
                        Rm1_dc2_out: "Open Concept",
                        Rm1_dc3_out: "Combined W/Dining",
                        Rm1_len: "",
                        Rm1_out: "Living",
                        Rm1_wth: "",
                        Rm10_dc1_out: "Closet",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "Br",
                        Rm10_wth: "",
                        Rm11_dc1_out: "4 Pc Bath",
                        Rm11_dc2_out: "Laminate",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "Br",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "Living",
                        Rm12_wth: "",
                        Rm2_dc1_out: "Hardwood Floor",
                        Rm2_dc2_out: "Open Concept",
                        Rm2_dc3_out: "Combined W/Living",
                        Rm2_len: "",
                        Rm2_out: "Dining",
                        Rm2_wth: "",
                        Rm3_dc1_out: "Hardwood Floor",
                        Rm3_dc2_out: "Open Concept",
                        Rm3_dc3_out: "Fireplace",
                        Rm3_len: "",
                        Rm3_out: "Family",
                        Rm3_wth: "",
                        Rm4_dc1_out: "Hardwood Floor",
                        Rm4_dc2_out: "Open Concept",
                        Rm4_dc3_out: "W/O To Deck",
                        Rm4_len: "",
                        Rm4_out: "Breakfast",
                        Rm4_wth: "",
                        Rm5_dc1_out: "Modern Kitchen",
                        Rm5_dc2_out: "Granite Counter",
                        Rm5_dc3_out: "Ceramic Floor",
                        Rm5_len: "",
                        Rm5_out: "Kitchen",
                        Rm5_wth: "",
                        Rm6_dc1_out: "W/I Closet",
                        Rm6_dc2_out: "4 Pc Ensuite",
                        Rm6_dc3_out: "",
                        Rm6_len: "",
                        Rm6_out: "Prim Bdrm",
                        Rm6_wth: "",
                        Rm7_dc1_out: "Window",
                        Rm7_dc2_out: "4 Pc Ensuite",
                        Rm7_dc3_out: "",
                        Rm7_len: "",
                        Rm7_out: "2nd Br",
                        Rm7_wth: "",
                        Rm8_dc1_out: "Window",
                        Rm8_dc2_out: "4 Pc Ensuite",
                        Rm8_dc3_out: "",
                        Rm8_len: "",
                        Rm8_out: "3rd Br",
                        Rm8_wth: "",
                        Rm9_dc1_out: "Window",
                        Rm9_dc2_out: "Semi Ensuite",
                        Rm9_dc3_out: "",
                        Rm9_len: "",
                        Rm9_out: "4th Br",
                        Rm9_wth: "",
                        Rms: "9",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "",
                        St: "Openbay",
                        St_dir: "",
                        St_num: "8",
                        St_sfx: "Gdns",
                        Status: "A",
                        Style: "2-Storey",
                        Taxes: "7494.00",
                        Timestamp_sql: "2023-12-28 09:14:19.0",
                        Tot_park_spcs: "6.0",
                        Tour_url: "",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "",
                        Util_tel: "",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "2",
                        Wcloset_p2: "4",
                        Wcloset_p3: "4",
                        Wcloset_p4: "4",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "",
                        Wcloset_t2: "1",
                        Wcloset_t2lvl: "",
                        Wcloset_t3: "1",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "1",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2023",
                        Yr_built: "",
                        Zip: "L6R 0V3",
                        Zoning: "Residential",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "2-Storey",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Finished",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: "7494.00",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:14:19",
                        },
                        {
                            name: "MLS",
                            value: "W7369402",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "IPRO REALTY LTD",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "2-Storey",
                                },
                                {
                                    name: "Area",
                                    value: "Peel",
                                },
                                {
                                    name: "Community",
                                    value: "Sandringham-Wellington",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 6,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "2",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 4,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "9",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "Y",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Finished",
                                },
                                {
                                    name: "Heating",
                                    value: "Forced Air",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                                {
                                    name: "Garage",
                                    value: "2.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "6.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "7494.00",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2023",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "",
                                },
                                {
                                    name: "Gas",
                                    value: "",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "Hospital",
                                },
                                {
                                    name: "Feature",
                                    value: "Park",
                                },
                                {
                                    name: "Feature",
                                    value: "Public Transit",
                                },
                                {
                                    name: "Feature",
                                    value: "Rec Centre",
                                },
                                {
                                    name: "Feature",
                                    value: "School",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "S",
                                },
                                {
                                    name: "Frontage",
                                    value: "46.97",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "90.31",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "Residential",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Dixie/ Countryside",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Brampton",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Carberry Public School",
                            school_address:
                                "Carberry Public School, 526 Fernforest, Brampton, Ontario L6R 0S2, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name:
                                "St. Marguerite d'Youville Secondary School",
                            school_address:
                                "St. Marguerite d'Youville Secondary School, 10815 Dixie Road, Brampton, Ontario L6R 2W5, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Stanley Mills Public School",
                            school_address:
                                "Stanley Mills Public School, 286 Sunny Meadow Blvd, Brampton, ON L6R 3C3, Brampton, Ontario L6R 2J7, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "Hewson Public School",
                            school_address:
                                "Hewson Public School, Brampton, Ontario L6R 0G2, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Harold Brathwaite Secondary School",
                            school_address:
                                "Harold Brathwaite Secondary School, 415 Great Lakes Dr., Brampton, Ontario L6R 2Z4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "St Isaac Jogues Catholic School.",
                            school_address:
                                "St Isaac Jogues Catholic School., 300 Great Lakes Dr, Brampton, Ontario L6R 3P5, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Sandalwood Heights Secondary School",
                            school_address:
                                "Sandalwood Heights Secondary School, Brampton, Ontario L6R 0K7, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Notre Dame Catholic Secondary School",
                            school_address:
                                "Notre Dame Catholic Secondary School, 2 Notre Dame Ave., Brampton, Ontario L6Z 4L5, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "North Park Secondary School",
                            school_address:
                                "North Park Secondary School, 10 North Park Drive, Brampton, Ontario L6S 0B9, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Chinguacousy Secondary School",
                            school_address:
                                "Chinguacousy Secondary School, 1370 Williams Parkway, Brampton, Ontario L6S 1J9, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                    ],
                },
                {
                    id: "X7369394",
                    unique_id: 2051930,
                    property_description:
                        "It's amazing landscaped curb appeal is reflective of it's interior. There is a nice foyer as you enter leading you into a large living room with hardwood floors, gas fireplace and a large front window cascading lots of natural light. The open layout seamlessly connects the living and dining spaces, creating an ideal setting for both relaxation and entertainment. Patio doors lead you to a large deck overlooking the fully fenced yard and access to the detached garage. The kitchen contains a generous amount of maple cabinets with lots of counter space for meal prep. The 3rd bedroom was converted into a grand laundry room with lots of storage helping make laundry day effortless. The 4 piece bathroom is updated and beautifully tiled. Two bedrooms with hardwood flooring with well placed windows allowing natural light to enter finish off the main level. Venture downstairs to find a versatile space with income potential - a basement that holds promise for various possibilities.",
                    community: "",
                    basement_one: "Finished",
                    basement_two: "Full",
                    property_type: "Detached",
                    bedrooms: 4,
                    bathrooms: 2,
                    kitchens: "2",
                    square_footage: "1500-2000",
                    area: "Middlesex",
                    city: "London",
                    property_style: "Bungalow",
                    list_price: 599900,
                    sale_or_lease: "Sale",
                    virtual_tour_url: "https://youtu.be/aDrNHstVo4Y",
                    virtual_tour_upload_date: "",
                    address: "61 Godfrey Dr",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Patann Drive",
                    zip_code: "N5V 2E8",
                    county: "Ontario",
                    realtor: "EXP REALTY",
                    approx_age: "51-99",
                    leased_terms: "",
                    tax_year: "2023",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "6.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "Detached",
                    garage_spaces: "1.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "IN TOTAL THERE ARE 2 FRIDGES, 2 STOVES, 2 DISHWASHER, 2 WASHERS, 2 DRYERS, 2 BUILT IN MICROWAVE OVENS, ELECTRIC FIREPLACE IN LOWER LEVEL, BOOK SHELVES ARE INCLUDED, WORKBENCH, PATIO FURNITURE",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-28.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-29.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-30.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-31.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-32.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-33.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-34.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-35.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-36.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-37.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-38.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-39.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-40.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X7369394-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:11:56",
                    pictures_updated_at: "2023-12-28 09:12:11.0",
                    last_updated_at: "2023-12-28 09:11:56",
                    additional_bathroom: "",
                    rooms: "10",
                    rooms_plus: "",
                    den_family_room: "Y",
                    ac: "Central Air",
                    fire_place: "Y",
                    heating: "Forced Air",
                    water_supply: "Municipal",
                    drive_way: "Pvt Double",
                    taxes: "3229.18",
                    electricity: "Y",
                    gas: "Y",
                    cable: "",
                    prop_feat1_out: "Library",
                    prop_feat2_out: "Park",
                    prop_feat3_out: "Place Of Worship",
                    prop_feat4_out: "Public Transit",
                    prop_feat5_out: "Rec Centre",
                    prop_feat6_out: "School",
                    front_age: "60.94",
                    depth: "124.99",
                    acres: "< .50",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "R1-8",
                    parcel_id: "081020334",
                    garage: "Pvt Double",
                    mls_num: "X7369394",
                    constr_out: "Brick",
                    fronting_on: "N",
                    latitude: "43.01909700",
                    longitude: "-81.21177200",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "< .50",
                        Ad_text:
                            "It's amazing landscaped curb appeal is reflective of it's interior. There is a nice foyer as you enter leading you into a large living room with hardwood floors, gas fireplace and a large front window cascading lots of natural light. The open layout seamlessly connects the living and dining spaces, creating an ideal setting for both relaxation and entertainment. Patio doors lead you to a large deck overlooking the fully fenced yard and access to the detached garage. The kitchen contains a generous amount of maple cabinets with lots of counter space for meal prep. The 3rd bedroom was converted into a grand laundry room with lots of storage helping make laundry day effortless. The 4 piece bathroom is updated and beautifully tiled. Two bedrooms with hardwood flooring with well placed windows allowing natural light to enter finish off the main level. Venture downstairs to find a versatile space with income potential - a basement that holds promise for various possibilities.",
                        Addl_mo_fee: "",
                        Addr: "61 Godfrey Dr",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Middlesex",
                        Area_code: "42",
                        Ass_year: "2023",
                        Bath_tot: "2",
                        Br: "4",
                        Br_plus: "",
                        Bsmt1_out: "Finished",
                        Bsmt2_out: "Full",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "",
                        Community_code: "",
                        Comp_pts: "N",
                        Constr1_out: "Brick",
                        Constr2_out: "Stone",
                        County: "Ontario",
                        Cross_st: "Patann Drive",
                        Den_fr: "Y",
                        Depth: "124.99",
                        Disp_addr: "Y",
                        Drive: "Pvt Double",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "Y",
                        Elevator: "N",
                        Extras: "IN TOTAL THERE ARE 2 FRIDGES, 2 STOVES, 2 DISHWASHER, 2 WASHERS, 2 DRYERS, 2 BUILT IN MICROWAVE OVENS, ELECTRIC FIREPLACE IN LOWER LEVEL, BOOK SHELVES ARE INCLUDED, WORKBENCH, PATIO FURNITURE",
                        Farm_agri: "",
                        Fpl_num: "Y",
                        Front_ft: "60.94",
                        Fuel: "Electric",
                        Furnished: "",
                        Gar_spaces: "1.0",
                        Gar_type: "Detached",
                        Gas: "Y",
                        Heat_inc: "",
                        Heating: "Forced Air",
                        Hydro_inc: "",
                        Idx_dt: "",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "Main",
                        Legal_desc:
                            "LOT 44, PLAN 872, SUBJECT TO 121798, 123202 LONDON/LONDON TOWNSHIP",
                        Level1: "Main",
                        Level10: "Lower",
                        Level11: "Lower",
                        Level12: "Lower",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "Main",
                        Level7: "Main",
                        Level8: "Lower",
                        Level9: "Lower",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "599900.00",
                        Lse_terms: "",
                        Ml_num: "X7369394",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "London",
                        Municipality_code: "42.08",
                        Municipality_district: "London",
                        Num_kit: "2",
                        Occ: "1 - 29 Days",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "081020334",
                        Park_chgs: "",
                        Park_spcs: "5",
                        Pix_updt: "2023-12-28 09:12:11.0",
                        Pool: "None",
                        Potl: "N",
                        Prkg_inc: "",
                        Prop_feat1_out: "Library",
                        Prop_feat2_out: "Park",
                        Prop_feat3_out: "Place Of Worship",
                        Prop_feat4_out: "Public Transit",
                        Prop_feat5_out: "Rec Centre",
                        Prop_feat6_out: "School",
                        Pvt_ent: "",
                        Retirement: "N",
                        Rltr: "EXP REALTY",
                        Rm1_dc1_out: "",
                        Rm1_dc2_out: "",
                        Rm1_dc3_out: "",
                        Rm1_len: "1.42",
                        Rm1_out: "Foyer",
                        Rm1_wth: "3.51",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "3.84",
                        Rm10_out: "Br",
                        Rm10_wth: "3.58",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "3.20",
                        Rm11_out: "Br",
                        Rm11_wth: "3.33",
                        Rm12_dc1_out: "3 Pc Bath",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "2.62",
                        Rm12_out: "Bathroom",
                        Rm12_wth: "2.01",
                        Rm2_dc1_out: "Fireplace",
                        Rm2_dc2_out: "",
                        Rm2_dc3_out: "",
                        Rm2_len: "4.75",
                        Rm2_out: "Living",
                        Rm2_wth: "3.51",
                        Rm3_dc1_out: "Sliding Doors",
                        Rm3_dc2_out: "W/O To Deck",
                        Rm3_dc3_out: "W/O To Balcony",
                        Rm3_len: "6.53",
                        Rm3_out: "Kitchen",
                        Rm3_wth: "3.53",
                        Rm4_dc1_out: "",
                        Rm4_dc2_out: "",
                        Rm4_dc3_out: "",
                        Rm4_len: "2.57",
                        Rm4_out: "Laundry",
                        Rm4_wth: "3.43",
                        Rm5_dc1_out: "",
                        Rm5_dc2_out: "",
                        Rm5_dc3_out: "",
                        Rm5_len: "3.43",
                        Rm5_out: "Prim Bdrm",
                        Rm5_wth: "3.43",
                        Rm6_dc1_out: "",
                        Rm6_dc2_out: "",
                        Rm6_dc3_out: "",
                        Rm6_len: "3.43",
                        Rm6_out: "Br",
                        Rm6_wth: "2.74",
                        Rm7_dc1_out: "4 Pc Bath",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "2.46",
                        Rm7_out: "Bathroom",
                        Rm7_wth: "2.46",
                        Rm8_dc1_out: "Fireplace",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "5.82",
                        Rm8_out: "Kitchen",
                        Rm8_wth: "3.68",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "6.22",
                        Rm9_out: "Family",
                        Rm9_wth: "3.78",
                        Rms: "10",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Other",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "1500-2000",
                        St: "Godfrey",
                        St_dir: "",
                        St_num: "61",
                        St_sfx: "Dr",
                        Status: "A",
                        Style: "Bungalow",
                        Taxes: "3229.18",
                        Timestamp_sql: "2023-12-28 09:11:56.0",
                        Tot_park_spcs: "6.0",
                        Tour_url: "https://youtu.be/aDrNHstVo4Y",
                        Tv: "221000",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "Y",
                        Util_tel: "Y",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "None",
                        Wcloset_p1: "4",
                        Wcloset_p2: "3",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "Main",
                        Wcloset_t2: "1",
                        Wcloset_t2lvl: "Lower",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2023",
                        Yr_built: "51-99",
                        Zip: "N5V 2E8",
                        Zoning: "R1-8",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "N",
                        Fractional_ownership: "N",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "Bungalow",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Finished",
                        },
                        {
                            name: "Size",
                            value: "< .50",
                        },
                        {
                            name: "Age",
                            value: "51-99",
                        },
                        {
                            name: "Taxes",
                            value: "3229.18",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:11:56",
                        },
                        {
                            name: "MLS",
                            value: "X7369394",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "EXP REALTY",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "Bungalow",
                                },
                                {
                                    name: "Area",
                                    value: "Middlesex",
                                },
                                {
                                    name: "Community",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 4,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 2,
                                },
                                {
                                    name: "Kitchen",
                                    value: "2",
                                },
                                {
                                    name: "Rooms",
                                    value: "10",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "Y",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Finished",
                                },
                                {
                                    name: "Heating",
                                    value: "Forced Air",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Pvt Double",
                                },
                                {
                                    name: "Garage",
                                    value: "1.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "6.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Pvt Double",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "2",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "3229.18",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2023",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "Y",
                                },
                                {
                                    name: "Gas",
                                    value: "Y",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "Library",
                                },
                                {
                                    name: "Feature",
                                    value: "Park",
                                },
                                {
                                    name: "Feature",
                                    value: "Place Of Worship",
                                },
                                {
                                    name: "Feature",
                                    value: "Public Transit",
                                },
                                {
                                    name: "Feature",
                                    value: "Rec Centre",
                                },
                                {
                                    name: "Feature",
                                    value: "School",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "N",
                                },
                                {
                                    name: "Frontage",
                                    value: "60.94",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "124.99",
                                },
                                {
                                    name: "Lot Size",
                                    value: "< .50",
                                },
                                {
                                    name: "Acres",
                                    value: "< .50",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "R1-8",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "081020334",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Patann Drive",
                                },
                                {
                                    name: "Municipality District",
                                    value: "London",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "D Building",
                            school_address:
                                "D Building, Fanshawe College, London, Ontario N5V 4Y9, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "East Carling Public School",
                            school_address:
                                "East Carling Public School, London, Ontario N5Y 3K5, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "Ecole Secondaire Monseigneur Bruyere",
                            school_address:
                                "Ecole Secondaire Monseigneur Bruyere, 920 Huron St., London, Ontario N5Y 4K4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "A.B. Lucas S.S.",
                            school_address:
                                "A.B. Lucas S.S., 656 Tennent Ave., London, Ontario N5X 1P2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Montessori Academy Of London",
                            school_address:
                                "Montessori Academy Of London, 719 Waterloo St, London, Ontario N6A 1S7, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "H.B. Beal Secondary School",
                            school_address:
                                "H.B. Beal Secondary School, 525 Dundas St., London, Ontario N6B 1T1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name:
                                "Lord Robert's French Immersion Public School",
                            school_address:
                                "Lord Robert's French Immersion Public School, London, Ontario N6B 2W7, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Catholic Central High School",
                            school_address:
                                "Catholic Central High School, 450 Dundas St, London, Ontario N6B 1X9, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Central High School",
                            school_address:
                                "Central High School, London, Ontario N6B 2P8, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "London Language Institute",
                            school_address:
                                "London Language Institute, 301-183 Central Ave, London, Ontario N6A 1M7, Canada",
                            school_category: "language school, school",
                        },
                    ],
                },
                {
                    id: "X6746326",
                    unique_id: 2051933,
                    property_description:
                        "Beautiful 43.3 acres parcel of land on the north west corner of Hamilton and Whites Road. This lot features approximately 28 acres that is currently rented to a local farmer but also has a series of wooded trails plus a lovely stream. Farm the land or build your dream home! Agricultural zoning permits a single detached dwelling, a bed and breakfast establishment, agricultural use, farm produce retail outlet, a commercial greenhouse, an equestrian centre plus so much more (see attached for full list). Quick access to Belleville, Trenton and the 401.",
                    community: "",
                    basement_one: "",
                    basement_two: "",
                    property_type: "Vacant Land",
                    bedrooms: 0,
                    bathrooms: 0,
                    kitchens: "",
                    square_footage: "",
                    area: "Hastings",
                    city: "Quinte West",
                    property_style: "",
                    list_price: 409000,
                    sale_or_lease: "Sale",
                    virtual_tour_url:
                        "https://www.londonhousephoto.ca/whites-road-quinte-west/?ub=true",
                    virtual_tour_upload_date: "",
                    address: "0 Hamilton Rd",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Whites Rd / Hamilton Rd",
                    zip_code: "K8V 5P8",
                    county: "Ontario",
                    realtor: "ROYAL LEPAGE PROALLIANCE REALTY",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "2023",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "",
                    garage_spaces: "",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "",
                    link: "",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-X6746326-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:09:55",
                    pictures_updated_at: "2023-08-16 11:29:15.0",
                    last_updated_at: "2023-12-28 09:09:55",
                    additional_bathroom: "",
                    rooms: "",
                    rooms_plus: "",
                    den_family_room: "",
                    ac: "",
                    fire_place: "",
                    heating: "",
                    water_supply: "None",
                    drive_way: "",
                    taxes: "1036.00",
                    electricity: "N",
                    gas: "N",
                    cable: "",
                    prop_feat1_out: "River/Stream",
                    prop_feat2_out: "Rolling",
                    prop_feat3_out: "School Bus Route",
                    prop_feat4_out: "",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "399.36",
                    depth: "2368.00",
                    acres: "25-49.99",
                    irreg: "",
                    pool: "",
                    sewer: "None",
                    zoning: "A",
                    parcel_id: "403690136",
                    garage: "",
                    mls_num: "X6746326",
                    constr_out: "",
                    fronting_on: "S",
                    latitude: "44.18035000",
                    longitude: "-77.57864700",
                    property_details: {
                        A_c: "",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "25-49.99",
                        Ad_text:
                            "Beautiful 43.3 acres parcel of land on the north west corner of Hamilton and Whites Road. This lot features approximately 28 acres that is currently rented to a local farmer but also has a series of wooded trails plus a lovely stream. Farm the land or build your dream home! Agricultural zoning permits a single detached dwelling, a bed and breakfast establishment, agricultural use, farm produce retail outlet, a commercial greenhouse, an equestrian centre plus so much more (see attached for full list). Quick access to Belleville, Trenton and the 401.",
                        Addl_mo_fee: "",
                        Addr: "0 Hamilton Rd",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Hastings",
                        Area_code: "28",
                        Ass_year: "",
                        Bath_tot: "0",
                        Br: "",
                        Br_plus: "",
                        Bsmt1_out: "",
                        Bsmt2_out: "",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "",
                        Community_code: "",
                        Comp_pts: "S",
                        Constr1_out: "",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "Whites Rd / Hamilton Rd",
                        Den_fr: "",
                        Depth: "2368.00",
                        Disp_addr: "Y",
                        Drive: "",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "N",
                        Elevator: "",
                        Extras: "",
                        Farm_agri: "",
                        Fpl_num: "",
                        Front_ft: "399.36",
                        Fuel: "",
                        Furnished: "",
                        Gar_spaces: "",
                        Gar_type: "",
                        Gas: "N",
                        Heat_inc: "",
                        Heating: "",
                        Hydro_inc: "",
                        Idx_dt: "2023-03-28 16:31:32.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "",
                        Legal_desc:
                            "PCL 1-1 SEC 21D3; LT 1 PL 21D3 SIDNEY (INDEX",
                        Level1: "",
                        Level10: "",
                        Level11: "",
                        Level12: "",
                        Level2: "",
                        Level3: "",
                        Level4: "",
                        Level5: "",
                        Level6: "",
                        Level7: "",
                        Level8: "",
                        Level9: "",
                        Link_comment: "",
                        Link_yn: "",
                        Lotsz_code: "Feet",
                        Lp_dol: "409000.00",
                        Lse_terms: "",
                        Ml_num: "X6746326",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Quinte West",
                        Municipality_code: "28.13",
                        Municipality_district: "Quinte West",
                        Num_kit: "",
                        Occ: "Flexible",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "403690136",
                        Park_chgs: "",
                        Park_spcs: "",
                        Pix_updt: "2023-08-16 11:29:15.0",
                        Pool: "",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "River/Stream",
                        Prop_feat2_out: "Rolling",
                        Prop_feat3_out: "School Bus Route",
                        Prop_feat4_out: "",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "",
                        Retirement: "",
                        Rltr: "ROYAL LEPAGE PROALLIANCE REALTY",
                        Rm1_dc1_out: "",
                        Rm1_dc2_out: "",
                        Rm1_dc3_out: "",
                        Rm1_len: "",
                        Rm1_out: "",
                        Rm1_wth: "",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "",
                        Rm10_wth: "",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "",
                        Rm12_wth: "",
                        Rm2_dc1_out: "",
                        Rm2_dc2_out: "",
                        Rm2_dc3_out: "",
                        Rm2_len: "",
                        Rm2_out: "",
                        Rm2_wth: "",
                        Rm3_dc1_out: "",
                        Rm3_dc2_out: "",
                        Rm3_dc3_out: "",
                        Rm3_len: "",
                        Rm3_out: "",
                        Rm3_wth: "",
                        Rm4_dc1_out: "",
                        Rm4_dc2_out: "",
                        Rm4_dc3_out: "",
                        Rm4_len: "",
                        Rm4_out: "",
                        Rm4_wth: "",
                        Rm5_dc1_out: "",
                        Rm5_dc2_out: "",
                        Rm5_dc3_out: "",
                        Rm5_len: "",
                        Rm5_out: "",
                        Rm5_wth: "",
                        Rm6_dc1_out: "",
                        Rm6_dc2_out: "",
                        Rm6_dc3_out: "",
                        Rm6_len: "",
                        Rm6_out: "",
                        Rm6_wth: "",
                        Rm7_dc1_out: "",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "",
                        Rm7_out: "",
                        Rm7_wth: "",
                        Rm8_dc1_out: "",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "",
                        Rm8_out: "",
                        Rm8_wth: "",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "",
                        Rm9_out: "",
                        Rm9_wth: "",
                        Rms: "",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "None",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "",
                        St: "Hamilton",
                        St_dir: "",
                        St_num: "0",
                        St_sfx: "Rd",
                        Status: "A",
                        Style: "",
                        Taxes: "1036.00",
                        Timestamp_sql: "2023-12-28 09:09:55.0",
                        Tot_park_spcs: "",
                        Tour_url:
                            "https://www.londonhousephoto.ca/whites-road-quinte-west/?ub=true",
                        Tv: "",
                        Type_own_srch: ".V.",
                        Type_own1_out: "Vacant Land",
                        Uffi: "",
                        Util_cable: "N",
                        Util_tel: "N",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "None",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "None",
                        Wcloset_p1: "",
                        Wcloset_p2: "",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "",
                        Wcloset_t1lvl: "",
                        Wcloset_t2: "",
                        Wcloset_t2lvl: "",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2023",
                        Yr_built: "",
                        Zip: "K8V 5P8",
                        Zoning: "A",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "",
                        },
                        {
                            name: "Home Type",
                            value: "Vacant Land",
                        },
                        {
                            name: "Basement",
                            value: "",
                        },
                        {
                            name: "Size",
                            value: "25-49.99",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: "1036.00",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:09:55",
                        },
                        {
                            name: "MLS",
                            value: "X6746326",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "ROYAL LEPAGE PROALLIANCE REALTY",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Vacant Land",
                                },
                                {
                                    name: "Style",
                                    value: "",
                                },
                                {
                                    name: "Area",
                                    value: "Hastings",
                                },
                                {
                                    name: "Community",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 0,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 0,
                                },
                                {
                                    name: "Kitchen",
                                    value: "",
                                },
                                {
                                    name: "Rooms",
                                    value: "",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "",
                                },
                                {
                                    name: "Fireplace",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "",
                                },
                                {
                                    name: "Heating",
                                    value: "",
                                },
                                {
                                    name: "Water Supply",
                                    value: "None",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "",
                                },
                                {
                                    name: "Garage",
                                    value: "",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "1036.00",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2023",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "N",
                                },
                                {
                                    name: "Gas",
                                    value: "N",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "River/Stream",
                                },
                                {
                                    name: "Feature",
                                    value: "Rolling",
                                },
                                {
                                    name: "Feature",
                                    value: "School Bus Route",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "S",
                                },
                                {
                                    name: "Frontage",
                                    value: "399.36",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "2368.00",
                                },
                                {
                                    name: "Lot Size",
                                    value: "25-49.99",
                                },
                                {
                                    name: "Acres",
                                    value: "25-49.99",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "",
                                },
                                {
                                    name: "Sewer",
                                    value: "None",
                                },
                                {
                                    name: "Zoining",
                                    value: "A",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "403690136",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Whites Rd / Hamilton Rd",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Quinte West",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Trenton High School",
                            school_address:
                                "Trenton High School, Trenton, Ontario K8V 5N4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Bayside High School",
                            school_address:
                                "Bayside High School, 1247 Old Hwy 2, Belleville, Ontario K8R 0A1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Parkdale Public School",
                            school_address:
                                "Parkdale Public School, Belleville, Ontario K8P 4J3, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Centennial Secondary School",
                            school_address:
                                "Centennial Secondary School, 160 Palmer St., Belleville, Ontario K8P 4E1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Albert College",
                            school_address:
                                "Albert College, 160 Dundas St W, Belleville, Ontario K8P 1A5, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Harry J. Clarke Public School",
                            school_address:
                                "Harry J. Clarke Public School, 77 Rollins Dr, Belleville, Ontario K8N 4J6, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Napanee District Secondary School",
                            school_address:
                                "Napanee District Secondary School, 245 Belleville Road, Napanee, Ontario K7R 2J9, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "St. Mary Catholic Secondary School",
                            school_address:
                                "St. Mary Catholic Secondary School, 1050 Birchwood Trail, Cobourg, Ontario K9A 5S2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Thomas A. Stewart Secondary School",
                            school_address:
                                "Thomas A. Stewart Secondary School, 1009 Armour Road, Peterborough, Ontario K9H 7H2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Kenner CVI",
                            school_address:
                                "Kenner CVI, Peterborough, Ontario K9J 5J2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                    ],
                },
                {
                    id: "N7369388",
                    unique_id: 2051934,
                    property_description:
                        "Welcome To A Fully Renovated One Of A Kind Home, built on new foundation, heated crawl space and concrete base, new electrical and plumbing systems, Custom Finishes Top To Bottom $$$, Beautiful Trim Work, German Ac5 Flooring. Large Bright Living Room, Custom Kitchen With Built-In Appliances And Coat & Shoe Closet, Two Beautiful Washrooms. Family Room With Walk-Out To Two-Tier Covered Deck. New Asphalted Driveway (2022), Many Parking Spots For Company To Entertain In The Beautiful Backyard Under The Covered Two-Tier Deck With Gazebo And Huge Yard For The Children To Play. Excellent Location; quiet neighbourhood and Close To Beautiful Beaches, Grocery Stores, And Only Minutes To Highway 404 And Public Transit.",
                    community: "Historic Lakeshore Communities",
                    basement_one: "Crawl Space",
                    basement_two: "",
                    property_type: "Detached",
                    bedrooms: 3,
                    bathrooms: 2,
                    kitchens: "1",
                    square_footage: "",
                    area: "York",
                    city: "Georgina",
                    property_style: "Bungalow",
                    list_price: 748000,
                    sale_or_lease: "Sale",
                    virtual_tour_url: "",
                    virtual_tour_upload_date: "",
                    address: "1010 Metro Rd N",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Metro Rd North & Woodbine",
                    zip_code: "L4P 3E9",
                    county: "Ontario",
                    realtor: "RE/MAX ALL-STARS REALTY INC.",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "2022",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "8.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "None",
                    garage_spaces: "0.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369388-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:08:05",
                    pictures_updated_at: "2023-12-28 09:08:15.0",
                    last_updated_at: "2023-12-28 09:08:05",
                    additional_bathroom: "",
                    rooms: "8",
                    rooms_plus: "",
                    den_family_room: "Y",
                    ac: "Central Air",
                    fire_place: "N",
                    heating: "Forced Air",
                    water_supply: "Municipal",
                    drive_way: "Private",
                    taxes: "3751.22",
                    electricity: "Y",
                    gas: "N",
                    cable: "",
                    prop_feat1_out: "Beach",
                    prop_feat2_out: "Golf",
                    prop_feat3_out: "Level",
                    prop_feat4_out: "Park",
                    prop_feat5_out: "Public Transit",
                    prop_feat6_out: "School",
                    front_age: "50.00",
                    depth: "255.00",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "",
                    parcel_id: "034971260",
                    garage: "Private",
                    mls_num: "N7369388",
                    constr_out: "Vinyl Siding",
                    fronting_on: "N",
                    latitude: "44.30057300",
                    longitude: "-79.46906500",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text:
                            "Welcome To A Fully Renovated One Of A Kind Home, built on new foundation, heated crawl space and concrete base, new electrical and plumbing systems, Custom Finishes Top To Bottom $$$, Beautiful Trim Work, German Ac5 Flooring. Large Bright Living Room, Custom Kitchen With Built-In Appliances And Coat & Shoe Closet, Two Beautiful Washrooms. Family Room With Walk-Out To Two-Tier Covered Deck. New Asphalted Driveway (2022), Many Parking Spots For Company To Entertain In The Beautiful Backyard Under The Covered Two-Tier Deck With Gazebo And Huge Yard For The Children To Play. Excellent Location; quiet neighbourhood and Close To Beautiful Beaches, Grocery Stores, And Only Minutes To Highway 404 And Public Transit.",
                        Addl_mo_fee: "",
                        Addr: "1010 Metro Rd N",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "York",
                        Area_code: "09",
                        Ass_year: "",
                        Bath_tot: "2",
                        Br: "3",
                        Br_plus: "",
                        Bsmt1_out: "Crawl Space",
                        Bsmt2_out: "",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Historic Lakeshore Communities",
                        Community_code: "09.09.0010",
                        Comp_pts: "N",
                        Constr1_out: "Vinyl Siding",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "Metro Rd North & Woodbine",
                        Den_fr: "Y",
                        Depth: "255.00",
                        Disp_addr: "Y",
                        Drive: "Private",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "Y",
                        Elevator: "",
                        Extras: "",
                        Farm_agri: "",
                        Fpl_num: "N",
                        Front_ft: "50.00",
                        Fuel: "Propane",
                        Furnished: "",
                        Gar_spaces: "0.0",
                        Gar_type: "None",
                        Gas: "N",
                        Heat_inc: "",
                        Heating: "Forced Air",
                        Hydro_inc: "",
                        Idx_dt: "2014-02-21 09:38:05.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "Main",
                        Legal_desc: "See Schedule B for Full Legal Description",
                        Level1: "Main",
                        Level10: "",
                        Level11: "",
                        Level12: "",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "Main",
                        Level7: "Main",
                        Level8: "Main",
                        Level9: "",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "748000.00",
                        Lse_terms: "",
                        Ml_num: "N7369388",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Georgina",
                        Municipality_code: "09.09",
                        Municipality_district: "Georgina",
                        Num_kit: "1",
                        Occ: "TBD",
                        Oth_struc1_out: "Garden Shed",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "034971260",
                        Park_chgs: "",
                        Park_spcs: "8",
                        Pix_updt: "2023-12-28 09:08:15.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "Beach",
                        Prop_feat2_out: "Golf",
                        Prop_feat3_out: "Level",
                        Prop_feat4_out: "Park",
                        Prop_feat5_out: "Public Transit",
                        Prop_feat6_out: "School",
                        Pvt_ent: "",
                        Retirement: "",
                        Rltr: "RE/MAX ALL-STARS REALTY INC.",
                        Rm1_dc1_out: "Broadloom",
                        Rm1_dc2_out: "Saloon Doors",
                        Rm1_dc3_out: "Pot Lights",
                        Rm1_len: "6.43",
                        Rm1_out: "Living",
                        Rm1_wth: "4.03",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "",
                        Rm10_wth: "",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "",
                        Rm12_wth: "",
                        Rm2_dc1_out: "Breakfast Area",
                        Rm2_dc2_out: "Quartz Counter",
                        Rm2_dc3_out: "B/I Appliances",
                        Rm2_len: "3.58",
                        Rm2_out: "Kitchen",
                        Rm2_wth: "3.99",
                        Rm3_dc1_out: "Bay Window",
                        Rm3_dc2_out: "Large Window",
                        Rm3_dc3_out: "W/O To Deck",
                        Rm3_len: "3.50",
                        Rm3_out: "Family",
                        Rm3_wth: "3.45",
                        Rm4_dc1_out: "Large Closet",
                        Rm4_dc2_out: "Picture Window",
                        Rm4_dc3_out: "Laminate",
                        Rm4_len: "4.03",
                        Rm4_out: "Prim Bdrm",
                        Rm4_wth: "3.50",
                        Rm5_dc1_out: "O/Looks Backyard",
                        Rm5_dc2_out: "Large Closet",
                        Rm5_dc3_out: "Laminate",
                        Rm5_len: "3.08",
                        Rm5_out: "2nd Br",
                        Rm5_wth: "3.44",
                        Rm6_dc1_out: "O/Looks Backyard",
                        Rm6_dc2_out: "Closet",
                        Rm6_dc3_out: "Laminate",
                        Rm6_len: "3.08",
                        Rm6_out: "3rd Br",
                        Rm6_wth: "3.44",
                        Rm7_dc1_out: "5 Pc Bath",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "",
                        Rm7_out: "Bathroom",
                        Rm7_wth: "",
                        Rm8_dc1_out: "4 Pc Bath",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "",
                        Rm8_out: "Bathroom",
                        Rm8_wth: "",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "",
                        Rm9_out: "",
                        Rm9_wth: "",
                        Rms: "8",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "",
                        St: "Metro",
                        St_dir: "N",
                        St_num: "1010",
                        St_sfx: "Rd",
                        Status: "A",
                        Style: "Bungalow",
                        Taxes: "3751.22",
                        Timestamp_sql: "2023-12-28 09:08:05.0",
                        Tot_park_spcs: "8.0",
                        Tour_url: "",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "Y",
                        Util_tel: "Y",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "5",
                        Wcloset_p2: "4",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "Main",
                        Wcloset_t2: "1",
                        Wcloset_t2lvl: "Main",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2022",
                        Yr_built: "",
                        Zip: "L4P 3E9",
                        Zoning: "",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "Bungalow",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Crawl Space",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: "3751.22",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:08:05",
                        },
                        {
                            name: "MLS",
                            value: "N7369388",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "RE/MAX ALL-STARS REALTY INC.",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "Bungalow",
                                },
                                {
                                    name: "Area",
                                    value: "York",
                                },
                                {
                                    name: "Community",
                                    value: "Historic Lakeshore Communities",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 3,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 2,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "8",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "N",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Crawl Space",
                                },
                                {
                                    name: "Heating",
                                    value: "Forced Air",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                                {
                                    name: "Garage",
                                    value: "0.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "8.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "3751.22",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2022",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "Y",
                                },
                                {
                                    name: "Gas",
                                    value: "N",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "Beach",
                                },
                                {
                                    name: "Feature",
                                    value: "Golf",
                                },
                                {
                                    name: "Feature",
                                    value: "Level",
                                },
                                {
                                    name: "Feature",
                                    value: "Park",
                                },
                                {
                                    name: "Feature",
                                    value: "Public Transit",
                                },
                                {
                                    name: "Feature",
                                    value: "School",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "N",
                                },
                                {
                                    name: "Frontage",
                                    value: "50.00",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "255.00",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "034971260",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Metro Rd North & Woodbine",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Georgina",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Nantyr Shores Secondary",
                            school_address:
                                "Nantyr Shores Secondary, Innisfil, Ontario L9S 4Z4, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Alcona Glen Elementary School",
                            school_address:
                                "Alcona Glen Elementary School, Innisfil, Ontario L9S 0A9, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "Sutton High Ontario",
                            school_address:
                                "Sutton High Ontario, Sutton West, Ontario L0E 1R0, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Keswick High School",
                            school_address:
                                "Keswick High School, Keswick, Ontario L4P 3S2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Lake Simcoe Public School",
                            school_address:
                                "Lake Simcoe Public School, Keswick, Ontario L4P 4A3, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Ecole La Source",
                            school_address:
                                "Ecole La Source, 70 Madelaine Drive, Barrie, Ontario L4N 9T1, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Mapleview Heights Elementary School",
                            school_address:
                                "Mapleview Heights Elementary School, 180 Esther Dr., Barrie, Ontario L4N 9S8, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name:
                                "Le Grand Chic Italia School of Fashion",
                            school_address:
                                "Le Grand Chic Italia School of Fashion, 23 Grandview Crescent, Oro-Medonte, Ontario L0L 2E0, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Thornton Academy",
                            school_address:
                                "Thornton Academy, 4073 4th Line, Cookstown, Ontario L0L 1L0, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "Fred C. Cook Public School",
                            school_address:
                                "Fred C. Cook Public School, 20 Fletcher Street, Bradford, Ontario L3Z 2G1, Canada",
                            school_category: "education, school",
                        },
                    ],
                },
                {
                    id: "W7369386",
                    unique_id: 2051927,
                    property_description:
                        "Welcome Home! This Redesigned Residence Located In Lakeview Is Mins From Sherway Gardens,Dixie Outlet Mall,The Qew,Grocery Stores & 20 Mins To Dt Toronto.As You Step Into The Foyer,You Are Greeted W/ An O/C Flr Plan,A Mix Of Sophisticated Porcelain Tile & H/W Flrs As Well As An Abundance Of Natural Light Elevated By Led Pot Lights.The Gourmet Kitchen Fts A Lg Waterfall Island,Elegant Cabinetry W/ Gold Finishes That Is Lightened By Crisp White Countertops,Subway Tile Backsplash & Thermador S/S Appls.For Those That Enjoy Entertaining Family & Friends, The Seamless Transition From Rm To Rm Makes This Home The Perfect Place To Entertain Indoors/Out.The Dining Rm O/L The Spacious Living Rm Fts A B/I Fireplace W/ Stone Surround.Also On This Lvl Is A 2Pc Powder Rm,Family Rm & Laundry W/ Sep Entrance To The Bsmt Apt.Upstairs Lies The Primary Suite W/ Lg W/I Closet & Stunning 4Pc Ensuite W/ Double Vanity,Porcelain Flrs & Porcelain Shower Surround! 3 More Bdrms On This Lvl That Share A 5Pc Bath.",
                    community: "Lakeview",
                    basement_one: "Apartment",
                    basement_two: "Sep Entrance",
                    property_type: "Detached",
                    bedrooms: 6,
                    bathrooms: 5,
                    kitchens: "1",
                    square_footage: "2000-2500",
                    area: "Peel",
                    city: "Mississauga",
                    property_style: "2-Storey",
                    list_price: 1738000,
                    sale_or_lease: "Sale",
                    virtual_tour_url:
                        "https://unbranded.youriguide.com/1099_hedge_dr_mississauga_on/",
                    virtual_tour_upload_date: "",
                    address: "1099 Hedge Dr",
                    display_address_on_internet: "Y",
                    directions_or_cross_street:
                        "North Service R / Stanfield Rd",
                    zip_code: "L4Y 1G3",
                    county: "Ontario",
                    realtor: "SAM MCDADI REAL ESTATE INC.",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "2022",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "6.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "Attached",
                    garage_spaces: "2.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "Bsmt Hosts Its Own Apt W/2 Bdrms,A Kitchen Ft S/S Appls,Dining Rm,Living Rm,Wet Bar & 2X3Pc Baths Currently Rented For $1,600/Month! With 224 Ft In Depth You Can Create The Bckyrd Of Your Dreams!",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-28.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-29.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-30.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-31.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-32.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-33.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-34.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369386-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:06:34",
                    pictures_updated_at: "2023-12-28 09:06:59.0",
                    last_updated_at: "2023-12-28 09:06:34",
                    additional_bathroom: "2",
                    rooms: "9",
                    rooms_plus: "6",
                    den_family_room: "Y",
                    ac: "Central Air",
                    fire_place: "Y",
                    heating: "Forced Air",
                    water_supply: "Municipal",
                    drive_way: "Pvt Double",
                    taxes: "6978.00",
                    electricity: "",
                    gas: "",
                    cable: "",
                    prop_feat1_out: "",
                    prop_feat2_out: "",
                    prop_feat3_out: "",
                    prop_feat4_out: "",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "39.43",
                    depth: "224.67",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "",
                    parcel_id: "133420660",
                    garage: "Pvt Double",
                    mls_num: "W7369386",
                    constr_out: "Brick",
                    fronting_on: "S",
                    latitude: "43.59372000",
                    longitude: "-79.57763000",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text:
                            "Welcome Home! This Redesigned Residence Located In Lakeview Is Mins From Sherway Gardens,Dixie Outlet Mall,The Qew,Grocery Stores & 20 Mins To Dt Toronto.As You Step Into The Foyer,You Are Greeted W/ An O/C Flr Plan,A Mix Of Sophisticated Porcelain Tile & H/W Flrs As Well As An Abundance Of Natural Light Elevated By Led Pot Lights.The Gourmet Kitchen Fts A Lg Waterfall Island,Elegant Cabinetry W/ Gold Finishes That Is Lightened By Crisp White Countertops,Subway Tile Backsplash & Thermador S/S Appls.For Those That Enjoy Entertaining Family & Friends, The Seamless Transition From Rm To Rm Makes This Home The Perfect Place To Entertain Indoors/Out.The Dining Rm O/L The Spacious Living Rm Fts A B/I Fireplace W/ Stone Surround.Also On This Lvl Is A 2Pc Powder Rm,Family Rm & Laundry W/ Sep Entrance To The Bsmt Apt.Upstairs Lies The Primary Suite W/ Lg W/I Closet & Stunning 4Pc Ensuite W/ Double Vanity,Porcelain Flrs & Porcelain Shower Surround! 3 More Bdrms On This Lvl That Share A 5Pc Bath.",
                        Addl_mo_fee: "",
                        Addr: "1099 Hedge Dr",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Peel",
                        Area_code: "05",
                        Ass_year: "",
                        Bath_tot: "5",
                        Br: "4",
                        Br_plus: "2",
                        Bsmt1_out: "Apartment",
                        Bsmt2_out: "Sep Entrance",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Lakeview",
                        Community_code: "05.03.0330",
                        Comp_pts: "S",
                        Constr1_out: "Brick",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "North Service R / Stanfield Rd",
                        Den_fr: "Y",
                        Depth: "224.67",
                        Disp_addr: "Y",
                        Drive: "Pvt Double",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "",
                        Elevator: "",
                        Extras: "Bsmt Hosts Its Own Apt W/2 Bdrms,A Kitchen Ft S/S Appls,Dining Rm,Living Rm,Wet Bar & 2X3Pc Baths Currently Rented For $1,600/Month! With 224 Ft In Depth You Can Create The Bckyrd Of Your Dreams!",
                        Farm_agri: "",
                        Fpl_num: "Y",
                        Front_ft: "39.43",
                        Fuel: "Gas",
                        Furnished: "",
                        Gar_spaces: "2.0",
                        Gar_type: "Attached",
                        Gas: "",
                        Heat_inc: "",
                        Heating: "Forced Air",
                        Hydro_inc: "",
                        Idx_dt: "2016-05-27 15:02:05.0",
                        Irreg: "",
                        Kit_plus: "1",
                        Laundry: "",
                        Laundry_lev: "Main",
                        Legal_desc:
                            "Pcl 39-1, Sec 43M544; Lt 39, Pl 43M544 ; S/T Lt513",
                        Level1: "Main",
                        Level10: "Bsmt",
                        Level11: "Bsmt",
                        Level12: "Bsmt",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "2nd",
                        Level7: "2nd",
                        Level8: "2nd",
                        Level9: "2nd",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "1738000.00",
                        Lse_terms: "",
                        Ml_num: "W7369386",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Mississauga",
                        Municipality_code: "05.03",
                        Municipality_district: "Mississauga",
                        Num_kit: "1",
                        Occ: "Flex",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "133420660",
                        Park_chgs: "",
                        Park_spcs: "4",
                        Pix_updt: "2023-12-28 09:06:59.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "",
                        Prop_feat2_out: "",
                        Prop_feat3_out: "",
                        Prop_feat4_out: "",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "",
                        Retirement: "",
                        Rltr: "SAM MCDADI REAL ESTATE INC.",
                        Rm1_dc1_out: "Centre Island",
                        Rm1_dc2_out: "W/O To Garden",
                        Rm1_dc3_out: "Porcelain Floor",
                        Rm1_len: "4.28",
                        Rm1_out: "Kitchen",
                        Rm1_wth: "5.81",
                        Rm10_dc1_out: "Closet",
                        Rm10_dc2_out: "Window",
                        Rm10_dc3_out: "Laminate",
                        Rm10_len: "3.37",
                        Rm10_out: "5th Br",
                        Rm10_wth: "3.10",
                        Rm11_dc1_out: "Open Concept",
                        Rm11_dc2_out: "Stainless Steel Appl",
                        Rm11_dc3_out: "Laminate",
                        Rm11_len: "4.20",
                        Rm11_out: "Kitchen",
                        Rm11_wth: "2.77",
                        Rm12_dc1_out: "Open Concept",
                        Rm12_dc2_out: "Pot Lights",
                        Rm12_dc3_out: "Laminate",
                        Rm12_len: "3.39",
                        Rm12_out: "Living",
                        Rm12_wth: "3.16",
                        Rm2_dc1_out: "Fireplace",
                        Rm2_dc2_out: "O/Looks Living",
                        Rm2_dc3_out: "Hardwood Floor",
                        Rm2_len: "6.48",
                        Rm2_out: "Dining",
                        Rm2_wth: "3.10",
                        Rm3_dc1_out: "Pot Lights",
                        Rm3_dc2_out: "O/Looks Family",
                        Rm3_dc3_out: "Hardwood Floor",
                        Rm3_len: "3.95",
                        Rm3_out: "Living",
                        Rm3_wth: "3.10",
                        Rm4_dc1_out: "Pot Lights",
                        Rm4_dc2_out: "Window",
                        Rm4_dc3_out: "Hardwood Floor",
                        Rm4_len: "4.70",
                        Rm4_out: "Family",
                        Rm4_wth: "2.98",
                        Rm5_dc1_out: "Side Door",
                        Rm5_dc2_out: "B/I Shelves",
                        Rm5_dc3_out: "Stainless Steel Sink",
                        Rm5_len: "2.50",
                        Rm5_out: "Laundry",
                        Rm5_wth: "2.29",
                        Rm6_dc1_out: "W/I Closet",
                        Rm6_dc2_out: "Window",
                        Rm6_dc3_out: "4 Pc Ensuite",
                        Rm6_len: "3.63",
                        Rm6_out: "Prim Bdrm",
                        Rm6_wth: "5.92",
                        Rm7_dc1_out: "Closet",
                        Rm7_dc2_out: "Window",
                        Rm7_dc3_out: "Hardwood Floor",
                        Rm7_len: "3.36",
                        Rm7_out: "2nd Br",
                        Rm7_wth: "3.00",
                        Rm8_dc1_out: "Closet",
                        Rm8_dc2_out: "Window",
                        Rm8_dc3_out: "Hardwood Floor",
                        Rm8_len: "4.64",
                        Rm8_out: "3rd Br",
                        Rm8_wth: "3.01",
                        Rm9_dc1_out: "Closet",
                        Rm9_dc2_out: "Window",
                        Rm9_dc3_out: "Hardwood Floor",
                        Rm9_len: "4.94",
                        Rm9_out: "4th Br",
                        Rm9_wth: "3.01",
                        Rms: "9",
                        Rooms_plus: "6",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "2000-2500",
                        St: "Hedge",
                        St_dir: "",
                        St_num: "1099",
                        St_sfx: "Dr",
                        Status: "A",
                        Style: "2-Storey",
                        Taxes: "6978.00",
                        Timestamp_sql: "2023-12-28 09:06:34.0",
                        Tot_park_spcs: "6.0",
                        Tour_url:
                            "https://unbranded.youriguide.com/1099_hedge_dr_mississauga_on/",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "",
                        Util_tel: "",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "2",
                        Wcloset_p2: "3",
                        Wcloset_p3: "4",
                        Wcloset_p4: "5",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "Main",
                        Wcloset_t2: "2",
                        Wcloset_t2lvl: "Bsmt",
                        Wcloset_t3: "1",
                        Wcloset_t3lvl: "2nd",
                        Wcloset_t4: "1",
                        Wcloset_t4lvl: "2nd",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2022",
                        Yr_built: "",
                        Zip: "L4Y 1G3",
                        Zoning: "",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "2-Storey",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Apartment",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: "6978.00",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:06:34",
                        },
                        {
                            name: "MLS",
                            value: "W7369386",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "SAM MCDADI REAL ESTATE INC.",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "2-Storey",
                                },
                                {
                                    name: "Area",
                                    value: "Peel",
                                },
                                {
                                    name: "Community",
                                    value: "Lakeview",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 6,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "2",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 5,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "9",
                                },
                                {
                                    name: "Room Plus",
                                    value: "6",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "Y",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Apartment",
                                },
                                {
                                    name: "Heating",
                                    value: "Forced Air",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Pvt Double",
                                },
                                {
                                    name: "Garage",
                                    value: "2.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "6.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Pvt Double",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "6978.00",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2022",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "",
                                },
                                {
                                    name: "Gas",
                                    value: "",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "S",
                                },
                                {
                                    name: "Frontage",
                                    value: "39.43",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "224.67",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "133420660",
                                },
                                {
                                    name: "Cross Street",
                                    value: "North Service R / Stanfield Rd",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Mississauga",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Cawthra Park Secondary School",
                            school_address:
                                "Cawthra Park Secondary School, Mississauga, Ontario L5G 4L1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "F45 Training Dixie",
                            school_address:
                                "F45 Training Dixie, 1370 Dundas Street East, Mississauga, Ontario L4Y 4G4, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Toronto French School",
                            school_address:
                                "Toronto French School, 1293 Meredith Ave, Mississauga, Ontario L5E 1P6, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "CA Academy of Music",
                            school_address:
                                "CA Academy of Music, 9-1606 Sedlescomb Dr, Mississauga, Ontario L4X 1M6, Canada",
                            school_category: "music school, school",
                        },
                        {
                            school_name: "Sales Training Experts",
                            school_address:
                                "Sales Training Experts, 1573 Woodhenge Way, Etobicoke, Ontario M8W 2K6, Canada",
                            school_category:
                                "adult education, adult education school, school",
                        },
                        {
                            school_name: "Lakeshore Arms Academy",
                            school_address:
                                "Lakeshore Arms Academy, 1300A Lakeshore Rd West, Mississauga, Ontario L5E 1E9, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "F45 Training Long Branch TO",
                            school_address:
                                "F45 Training Long Branch TO, 3773 Lake Shore Boulevard West, Etobicoke, Ontario M8W 1R1, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Sir Adam Beck Public School",
                            school_address:
                                "Sir Adam Beck Public School, 544 Horner Ave., Etobicoke, Ontario M8W 2C2, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name:
                                "Tiny Treasure Mississauga Montessori School",
                            school_address:
                                "Tiny Treasure Mississauga Montessori School, 84 South Service Rd, Mississauga, Ontario L5G 2R9, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "F45 Training Port Credit",
                            school_address:
                                "F45 Training Port Credit, 378 Lakeshore Road East, Mississauga, Ontario L5G 1H5, Canada",
                            school_category: "education, school",
                        },
                    ],
                },
                {
                    id: "W7369382",
                    unique_id: 2051926,
                    property_description:
                        "Standout Modern Designed Home,Back on Islington golf course, Floor to Ceiling Wall Windows,Provide you lot of Sunlight. Open and Transparent Interior Layout.18 ft Cathedral Ceiling at Back Stair Landing Area. $$$ Natural Stone Landscaping Front and Back. Prox 4000 sqft, Spectacular Golf Course Views from Premium Bedroom Terr. Elevator For 3 Floors, Basement is Above Ground ( You have a 3 story home with no Basement). Separated Entrance from Garage to the House, Easy access for Owner, Contain One Large Sunny and Bright bedroom, 3 Pieces Washroom,Perfect In-Law Suit. Oversized Eat-In Kitchen W/O to Yard, Dining For 15, Generous Halls & Foyers, Master Wing W/5Pc Ensuite,Heated Flooring, Custom Staircases/Glass Railings.Minutes To Airport. Walk 2 Rosethorn Ps, Sub Zero Fridge,Wolf Stove, Dw, B/I Coffee/Espresso Maker, Full Sized Glass Wine Cellar, Elevator, 2 Furnaces,2 A/C, Washer/Dryer, Irrigation,Security,Sound, Camera System, Elevator. Hot Water Tank(own), Elf's, Garage Shelving,",
                    community: "Princess-Rosethorn",
                    basement_one: "Fin W/O",
                    basement_two: "Sep Entrance",
                    property_type: "Detached",
                    bedrooms: 4,
                    bathrooms: 5,
                    kitchens: "1",
                    square_footage: "3500-5000",
                    area: "Toronto",
                    city: "Toronto",
                    property_style: "2-Storey",
                    list_price: 3353000,
                    sale_or_lease: "Sale",
                    virtual_tour_url: "",
                    virtual_tour_upload_date: "",
                    address: "81 Bywood Dr",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Islington Golf & Bywood15",
                    zip_code: "M9A 1M2",
                    county: "Ontario",
                    realtor: "ROYAL LEPAGE SIGNATURE REALTY",
                    approx_age: "6-15",
                    leased_terms: "",
                    tax_year: "2023",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "6.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "Attached",
                    garage_spaces: "2.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-28.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-29.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-30.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-31.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-32.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-33.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-34.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-35.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-36.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-37.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-38.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-39.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-40.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-W7369382-9.jpg",
                    ],
                    updated_at: "2023-12-28 09:03:45",
                    pictures_updated_at: "2023-12-28 09:04:15.0",
                    last_updated_at: "2023-12-28 09:03:45",
                    additional_bathroom: "1",
                    rooms: "8",
                    rooms_plus: "4",
                    den_family_room: "N",
                    ac: "Central Air",
                    fire_place: "Y",
                    heating: "Forced Air",
                    water_supply: "Municipal",
                    drive_way: "Pvt Double",
                    taxes: "15906.00",
                    electricity: "A",
                    gas: "A",
                    cable: "",
                    prop_feat1_out: "Clear View",
                    prop_feat2_out: "Golf",
                    prop_feat3_out: "Park",
                    prop_feat4_out: "Public Transit",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "60.10",
                    depth: "138.76",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "Single Family Residential",
                    parcel_id: "074720003",
                    garage: "Pvt Double",
                    mls_num: "W7369382",
                    constr_out: "Brick",
                    fronting_on: "N",
                    latitude: "43.65653600",
                    longitude: "-79.54150400",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text:
                            "Standout Modern Designed Home,Back on Islington golf course, Floor to Ceiling Wall Windows,Provide you lot of Sunlight. Open and Transparent Interior Layout.18 ft Cathedral Ceiling at Back Stair Landing Area. $$$ Natural Stone Landscaping Front and Back. Prox 4000 sqft, Spectacular Golf Course Views from Premium Bedroom Terr. Elevator For 3 Floors, Basement is Above Ground ( You have a 3 story home with no Basement). Separated Entrance from Garage to the House, Easy access for Owner, Contain One Large Sunny and Bright bedroom, 3 Pieces Washroom,Perfect In-Law Suit. Oversized Eat-In Kitchen W/O to Yard, Dining For 15, Generous Halls & Foyers, Master Wing W/5Pc Ensuite,Heated Flooring, Custom Staircases/Glass Railings.Minutes To Airport. Walk 2 Rosethorn Ps, Sub Zero Fridge,Wolf Stove, Dw, B/I Coffee/Espresso Maker, Full Sized Glass Wine Cellar, Elevator, 2 Furnaces,2 A/C, Washer/Dryer, Irrigation,Security,Sound, Camera System, Elevator. Hot Water Tank(own), Elf's, Garage Shelving,",
                        Addl_mo_fee: "",
                        Addr: "81 Bywood Dr",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Toronto",
                        Area_code: "01",
                        Ass_year: "",
                        Bath_tot: "5",
                        Br: "3",
                        Br_plus: "1",
                        Bsmt1_out: "Fin W/O",
                        Bsmt2_out: "Sep Entrance",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "Y",
                        Comel_inc: "",
                        Community: "Princess-Rosethorn",
                        Community_code: "01.W08.0100",
                        Comp_pts: "N",
                        Constr1_out: "Brick",
                        Constr2_out: "Stone",
                        County: "Ontario",
                        Cross_st: "Islington Golf & Bywood15",
                        Den_fr: "N",
                        Depth: "138.76",
                        Disp_addr: "Y",
                        Drive: "Pvt Double",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "A",
                        Elevator: "Y",
                        Extras: "",
                        Farm_agri: "",
                        Fpl_num: "Y",
                        Front_ft: "60.10",
                        Fuel: "Gas",
                        Furnished: "",
                        Gar_spaces: "2.0",
                        Gar_type: "Attached",
                        Gas: "A",
                        Heat_inc: "",
                        Heating: "Forced Air",
                        Hydro_inc: "",
                        Idx_dt: "2012-04-03 09:10:41.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "Upper",
                        Legal_desc:
                            "PLAN 4405 LOT 98 CON A CLERGY BLOCK PT LOT 11 RP 6",
                        Level1: "Main",
                        Level10: "Lower",
                        Level11: "Lower",
                        Level12: "Lower",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "Main",
                        Level5: "Main",
                        Level6: "Upper",
                        Level7: "Upper",
                        Level8: "Upper",
                        Level9: "Lower",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "3353000.00",
                        Lse_terms: "",
                        Ml_num: "W7369382",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Toronto",
                        Municipality_code: "01.W08",
                        Municipality_district: "Toronto W08",
                        Num_kit: "1",
                        Occ: "30/60/Tba",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "074720003",
                        Park_chgs: "",
                        Park_spcs: "4",
                        Pix_updt: "2023-12-28 09:04:15.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "Clear View",
                        Prop_feat2_out: "Golf",
                        Prop_feat3_out: "Park",
                        Prop_feat4_out: "Public Transit",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "",
                        Retirement: "N",
                        Rltr: "ROYAL LEPAGE SIGNATURE REALTY",
                        Rm1_dc1_out: "Hardwood Floor",
                        Rm1_dc2_out: "Overlook Golf Course",
                        Rm1_dc3_out: "Fireplace",
                        Rm1_len: "6.21",
                        Rm1_out: "Family",
                        Rm1_wth: "4.60",
                        Rm10_dc1_out: "Hardwood Floor",
                        Rm10_dc2_out: "Pot Lights",
                        Rm10_dc3_out: "Cathedral Ceiling",
                        Rm10_len: "6.31",
                        Rm10_out: "Great Rm",
                        Rm10_wth: "4.60",
                        Rm11_dc1_out: "Hardwood Floor",
                        Rm11_dc2_out: "Combined Wi/Game",
                        Rm11_dc3_out: "Open Concept",
                        Rm11_len: "6.58",
                        Rm11_out: "Exercise",
                        Rm11_wth: "6.34",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "2.01",
                        Rm12_out: "Other",
                        Rm12_wth: "4.37",
                        Rm2_dc1_out: "Hardwood Floor",
                        Rm2_dc2_out: "Large Window",
                        Rm2_dc3_out: "Open Concept",
                        Rm2_len: "6.27",
                        Rm2_out: "Dining",
                        Rm2_wth: "4.30",
                        Rm3_dc1_out: "Open Concept",
                        Rm3_dc2_out: "W/O To Garden",
                        Rm3_dc3_out: "Window",
                        Rm3_len: "6.43",
                        Rm3_out: "Kitchen",
                        Rm3_wth: "4.81",
                        Rm4_dc1_out: "Hardwood Floor",
                        Rm4_dc2_out: "Combined W/Office",
                        Rm4_dc3_out: "Fireplace",
                        Rm4_len: "9.33",
                        Rm4_out: "Living",
                        Rm4_wth: "4.51",
                        Rm5_dc1_out: "Hardwood Floor",
                        Rm5_dc2_out: "Combined W/Living",
                        Rm5_dc3_out: "Large Window",
                        Rm5_len: "2.63",
                        Rm5_out: "Office",
                        Rm5_wth: "2.56",
                        Rm6_dc1_out: "5 Pc Ensuite",
                        Rm6_dc2_out: "Fireplace",
                        Rm6_dc3_out: "W/O To Terrace",
                        Rm6_len: "5.51",
                        Rm6_out: "Prim Bdrm",
                        Rm6_wth: "5.15",
                        Rm7_dc1_out: "Hardwood Floor",
                        Rm7_dc2_out: "3 Pc Ensuite",
                        Rm7_dc3_out: "Large Closet",
                        Rm7_len: "4.00",
                        Rm7_out: "2nd Br",
                        Rm7_wth: "4.84",
                        Rm8_dc1_out: "Hardwood Floor",
                        Rm8_dc2_out: "3 Pc Ensuite",
                        Rm8_dc3_out: "W/I Closet",
                        Rm8_len: "3.81",
                        Rm8_out: "3rd Br",
                        Rm8_wth: "4.60",
                        Rm9_dc1_out: "Large Closet",
                        Rm9_dc2_out: "3 Pc Bath",
                        Rm9_dc3_out: "Above Grade Window",
                        Rm9_len: "6.25",
                        Rm9_out: "4th Br",
                        Rm9_wth: "4.35",
                        Rms: "8",
                        Rooms_plus: "4",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "3500-5000",
                        St: "Bywood",
                        St_dir: "",
                        St_num: "81",
                        St_sfx: "Dr",
                        Status: "A",
                        Style: "2-Storey",
                        Taxes: "15906.00",
                        Timestamp_sql: "2023-12-28 09:03:45.0",
                        Tot_park_spcs: "6.0",
                        Tour_url: "",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "A",
                        Util_tel: "",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "2",
                        Wcloset_p2: "5",
                        Wcloset_p3: "3",
                        Wcloset_p4: "3",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "Main",
                        Wcloset_t2: "1",
                        Wcloset_t2lvl: "2nd",
                        Wcloset_t3: "2",
                        Wcloset_t3lvl: "2nd",
                        Wcloset_t4: "1",
                        Wcloset_t4lvl: "Lower",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2023",
                        Yr_built: "6-15",
                        Zip: "M9A 1M2",
                        Zoning: "Single Family Residential",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "2-Storey",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Fin W/O",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "6-15",
                        },
                        {
                            name: "Taxes",
                            value: "15906.00",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 09:03:45",
                        },
                        {
                            name: "MLS",
                            value: "W7369382",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "ROYAL LEPAGE SIGNATURE REALTY",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "2-Storey",
                                },
                                {
                                    name: "Area",
                                    value: "Toronto",
                                },
                                {
                                    name: "Community",
                                    value: "Princess-Rosethorn",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 4,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "1",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 5,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "8",
                                },
                                {
                                    name: "Room Plus",
                                    value: "4",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "N",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "Y",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Fin W/O",
                                },
                                {
                                    name: "Heating",
                                    value: "Forced Air",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Pvt Double",
                                },
                                {
                                    name: "Garage",
                                    value: "2.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "6.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Pvt Double",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "15906.00",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2023",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "A",
                                },
                                {
                                    name: "Gas",
                                    value: "A",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "Clear View",
                                },
                                {
                                    name: "Feature",
                                    value: "Golf",
                                },
                                {
                                    name: "Feature",
                                    value: "Park",
                                },
                                {
                                    name: "Feature",
                                    value: "Public Transit",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "N",
                                },
                                {
                                    name: "Frontage",
                                    value: "60.10",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "138.76",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "Single Family Residential",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "074720003",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Islington Golf & Bywood15",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Toronto",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Toronto Faculty Of Music",
                            school_address:
                                "Toronto Faculty Of Music, 3820 Bloor St W, Etobicoke, Ontario M9B 1K8, Canada",
                            school_category: "music school, school",
                        },
                        {
                            school_name: "Humber Valley Village School",
                            school_address:
                                "Humber Valley Village School, Etobicoke, Ontario M9A 3B5, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "St George's Junior Public School",
                            school_address:
                                "St George's Junior Public School, 70 Princess Anne Cres, Etobicoke, Ontario M9A 1Y1, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "John G Althouse",
                            school_address:
                                "John G Althouse, 130 lloyd manor, Etobicoke, Ontario M9B 5K1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name:
                                "Michael Power / St Joseph High School",
                            school_address:
                                "Michael Power / St Joseph High School, 105 Eringate Dr, Etobicoke, Ontario M9C 3Z7, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Etobicoke Collegiate Institute",
                            school_address:
                                "Etobicoke Collegiate Institute, 86 Montgomery Road, Etobicoke, Ontario M9A 3N5, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Richview Collegiate Institute",
                            school_address:
                                "Richview Collegiate Institute, 1738 Islington Ave, Etobicoke, Ontario M9A 3N2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Martingrove Collegiate",
                            school_address:
                                "Martingrove Collegiate, Etobicoke, Ontario M9B 3G7, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Etobicoke School of the Arts",
                            school_address:
                                "Etobicoke School of the Arts, 675 Royal York Rd., Etobicoke, Ontario M8Y 2T1, Canada",
                            school_category: "music school, school",
                        },
                        {
                            school_name: "Warren Park Public School",
                            school_address:
                                "Warren Park Public School, York, Ontario M6S 4P4, Canada",
                            school_category: "education, school",
                        },
                    ],
                },
                {
                    id: "E7369360",
                    unique_id: 2051925,
                    property_description:
                        "Discover a Remarkable Kassigner Home! This Durable, High-Quality Residence is Nestled in a Fantastic Family-Friendly Locale. With Close Proximity to 5 Outstanding Schools and a Quick 5-Minute Stroll to 3 Beautiful Parks, It Provides an Ideal Setting for Your Family. Looking for More Recreational Options? Enjoy a Pleasant 20-Minute Walk to 4 Inviting Playgrounds and 2 Expansive Sports Fields. Commuters Benefit from the Unmatched Convenience of a Transit Stop Just 4 Minutes Away. Indoors, the Main Level Boasts a Functional Layout, and the Second Level Showcases Elegant Parquet Flooring. The Spacious Garage, featuring a 9ft Header, Accommodates Large Vehicles Perfectly. The Fully Enclosed Backyard Ensures Privacy and Safety. Welcome Home!",
                    community: "Centennial",
                    basement_one: "Part Fin",
                    basement_two: "",
                    property_type: "Detached",
                    bedrooms: 4,
                    bathrooms: 3,
                    kitchens: "1",
                    square_footage: "",
                    area: "Durham",
                    city: "Oshawa",
                    property_style: "2-Storey",
                    list_price: 749900,
                    sale_or_lease: "Sale",
                    virtual_tour_url:
                        "https://my.matterport.com/show/?m=r5Zf1cbQpdN&mls=1",
                    virtual_tour_upload_date: "",
                    address: "892 Carnaby Cres",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Rossland E & Central Park N",
                    zip_code: "L1G 2Y8",
                    county: "Ontario",
                    realtor: "COLDWELL BANKER - R.M.R. REAL ESTATE",
                    approx_age: "",
                    leased_terms: "",
                    tax_year: "2023",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "7.0",
                    parking_included: "",
                    parking_charges: "",
                    garage_type: "Attached",
                    garage_spaces: "1.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "The last 7 photos are renderings professionally completed for work that was planned in the past. You can proceed with those said plans on your own. Seller will provide all drawings including for the 2nd garage building",
                    link: "N",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-10.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-11.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-12.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-13.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-14.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-15.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-16.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-17.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-18.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-19.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-20.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-21.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-22.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-23.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-24.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-25.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-26.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-27.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-28.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-29.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-30.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-31.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-32.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-33.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-34.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-35.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-36.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-37.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-38.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-39.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-40.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-6.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-7.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-8.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-E7369360-9.jpg",
                    ],
                    updated_at: "2023-12-28 08:45:05",
                    pictures_updated_at: "2023-12-28 08:28:45.0",
                    last_updated_at: "2023-12-28 08:45:05",
                    additional_bathroom: "",
                    rooms: "7",
                    rooms_plus: "",
                    den_family_room: "N",
                    ac: "None",
                    fire_place: "N",
                    heating: "Baseboard",
                    water_supply: "Municipal",
                    drive_way: "Private",
                    taxes: "4016.64",
                    electricity: "Y",
                    gas: "A",
                    cable: "",
                    prop_feat1_out: "Fenced Yard",
                    prop_feat2_out: "Level",
                    prop_feat3_out: "Other",
                    prop_feat4_out: "Park",
                    prop_feat5_out: "Public Transit",
                    prop_feat6_out: "School",
                    front_age: "50.00",
                    depth: "100.00",
                    acres: "< .50",
                    irreg: "",
                    pool: "None",
                    sewer: "Sewers",
                    zoning: "R1-C Residential",
                    parcel_id: "162760248",
                    garage: "Private",
                    mls_num: "E7369360",
                    constr_out: "Alum Siding",
                    fronting_on: "E",
                    latitude: "43.92434200",
                    longitude: "-78.85993000",
                    property_details: {
                        A_c: "None",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "< .50",
                        Ad_text:
                            "Discover a Remarkable Kassigner Home! This Durable, High-Quality Residence is Nestled in a Fantastic Family-Friendly Locale. With Close Proximity to 5 Outstanding Schools and a Quick 5-Minute Stroll to 3 Beautiful Parks, It Provides an Ideal Setting for Your Family. Looking for More Recreational Options? Enjoy a Pleasant 20-Minute Walk to 4 Inviting Playgrounds and 2 Expansive Sports Fields. Commuters Benefit from the Unmatched Convenience of a Transit Stop Just 4 Minutes Away. Indoors, the Main Level Boasts a Functional Layout, and the Second Level Showcases Elegant Parquet Flooring. The Spacious Garage, featuring a 9ft Header, Accommodates Large Vehicles Perfectly. The Fully Enclosed Backyard Ensures Privacy and Safety. Welcome Home!",
                        Addl_mo_fee: "",
                        Addr: "892 Carnaby Cres",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "Durham",
                        Area_code: "10",
                        Ass_year: "",
                        Bath_tot: "3",
                        Br: "4",
                        Br_plus: "",
                        Bsmt1_out: "Part Fin",
                        Bsmt2_out: "",
                        Cable: "",
                        Cac_inc: "",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Centennial",
                        Community_code: "10.07.0110",
                        Comp_pts: "E",
                        Constr1_out: "Alum Siding",
                        Constr2_out: "Brick",
                        County: "Ontario",
                        Cross_st: "Rossland E & Central Park N",
                        Den_fr: "N",
                        Depth: "100.00",
                        Disp_addr: "Y",
                        Drive: "Private",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "Y",
                        Elevator: "",
                        Extras: "The last 7 photos are renderings professionally completed for work that was planned in the past. You can proceed with those said plans on your own. Seller will provide all drawings including for the 2nd garage building",
                        Farm_agri: "",
                        Fpl_num: "N",
                        Front_ft: "50.00",
                        Fuel: "Electric",
                        Furnished: "",
                        Gar_spaces: "1.0",
                        Gar_type: "Attached",
                        Gas: "A",
                        Heat_inc: "",
                        Heating: "Baseboard",
                        Hydro_inc: "",
                        Idx_dt: "2014-02-11 05:21:59.0",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "",
                        Laundry_lev: "Lower",
                        Legal_desc:
                            "LT 82 PL 954 OSHAWA; S/T EXECUTION 93-03704, IF ENFORCEABLE; S/T EXECUTION 97-00240, IF ENFORCEABLE; OSHAWA",
                        Level1: "Main",
                        Level10: "",
                        Level11: "",
                        Level12: "",
                        Level2: "Main",
                        Level3: "Main",
                        Level4: "2nd",
                        Level5: "2nd",
                        Level6: "2nd",
                        Level7: "2nd",
                        Level8: "Bsmt",
                        Level9: "Bsmt",
                        Link_comment: "",
                        Link_yn: "N",
                        Lotsz_code: "Feet",
                        Lp_dol: "749900.00",
                        Lse_terms: "",
                        Ml_num: "E7369360",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Oshawa",
                        Municipality_code: "10.07",
                        Municipality_district: "Oshawa",
                        Num_kit: "1",
                        Occ: "TBA",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "162760248",
                        Park_chgs: "",
                        Park_spcs: "6",
                        Pix_updt: "2023-12-28 08:28:45.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "",
                        Prop_feat1_out: "Fenced Yard",
                        Prop_feat2_out: "Level",
                        Prop_feat3_out: "Other",
                        Prop_feat4_out: "Park",
                        Prop_feat5_out: "Public Transit",
                        Prop_feat6_out: "School",
                        Pvt_ent: "",
                        Retirement: "",
                        Rltr: "COLDWELL BANKER - R.M.R. REAL ESTATE",
                        Rm1_dc1_out: "Large Window",
                        Rm1_dc2_out: "Hardwood Floor",
                        Rm1_dc3_out: "Combined W/Dining",
                        Rm1_len: "5.00",
                        Rm1_out: "Living",
                        Rm1_wth: "3.64",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "",
                        Rm10_wth: "",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "",
                        Rm12_wth: "",
                        Rm2_dc1_out: "Window",
                        Rm2_dc2_out: "Hardwood Floor",
                        Rm2_dc3_out: "Combined W/Living",
                        Rm2_len: "3.15",
                        Rm2_out: "Dining",
                        Rm2_wth: "3.14",
                        Rm3_dc1_out: "Window",
                        Rm3_dc2_out: "Tile Floor",
                        Rm3_dc3_out: "O/Looks Garden",
                        Rm3_len: "3.52",
                        Rm3_out: "Kitchen",
                        Rm3_wth: "2.10",
                        Rm4_dc1_out: "Double Closet",
                        Rm4_dc2_out: "Parquet Floor",
                        Rm4_dc3_out: "Window",
                        Rm4_len: "4.19",
                        Rm4_out: "Prim Bdrm",
                        Rm4_wth: "3.12",
                        Rm5_dc1_out: "Window",
                        Rm5_dc2_out: "Parquet Floor",
                        Rm5_dc3_out: "Closet",
                        Rm5_len: "3.19",
                        Rm5_out: "2nd Br",
                        Rm5_wth: "2.83",
                        Rm6_dc1_out: "Window",
                        Rm6_dc2_out: "Parquet Floor",
                        Rm6_dc3_out: "Closet",
                        Rm6_len: "3.12",
                        Rm6_out: "3rd Br",
                        Rm6_wth: "2.88",
                        Rm7_dc1_out: "Window",
                        Rm7_dc2_out: "Parquet Floor",
                        Rm7_dc3_out: "Closet",
                        Rm7_len: "3.87",
                        Rm7_out: "4th Br",
                        Rm7_wth: "2.78",
                        Rm8_dc1_out: "",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "3.38",
                        Rm8_out: "Rec",
                        Rm8_wth: "2.95",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "4.95",
                        Rm9_out: "5th Br",
                        Rm9_wth: "3.18",
                        Rms: "7",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Sale",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Sewers",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "",
                        St: "Carnaby",
                        St_dir: "",
                        St_num: "892",
                        St_sfx: "Cres",
                        Status: "A",
                        Style: "2-Storey",
                        Taxes: "4016.64",
                        Timestamp_sql: "2023-12-28 08:45:05.0",
                        Tot_park_spcs: "7.0",
                        Tour_url:
                            "https://my.matterport.com/show/?m=r5Zf1cbQpdN&mls=1",
                        Tv: "",
                        Type_own_srch: ".D.",
                        Type_own1_out: "Detached",
                        Uffi: "",
                        Util_cable: "A",
                        Util_tel: "A",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "4",
                        Wcloset_p2: "2",
                        Wcloset_p3: "2",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "2nd",
                        Wcloset_t2: "1",
                        Wcloset_t2lvl: "Main",
                        Wcloset_t3: "1",
                        Wcloset_t3lvl: "Bsmt",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "2023",
                        Yr_built: "",
                        Zip: "L1G 2Y8",
                        Zoning: "R1-C Residential",
                        Portion_property_lease1_out: "",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: "",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "2-Storey",
                        },
                        {
                            name: "Home Type",
                            value: "Detached",
                        },
                        {
                            name: "Basement",
                            value: "Part Fin",
                        },
                        {
                            name: "Size",
                            value: "< .50",
                        },
                        {
                            name: "Age",
                            value: "",
                        },
                        {
                            name: "Taxes",
                            value: "4016.64",
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 08:45:05",
                        },
                        {
                            name: "MLS",
                            value: "E7369360",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "COLDWELL BANKER - R.M.R. REAL ESTATE",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Sale",
                                },
                                {
                                    name: "Type",
                                    value: "Detached",
                                },
                                {
                                    name: "Style",
                                    value: "2-Storey",
                                },
                                {
                                    name: "Area",
                                    value: "Durham",
                                },
                                {
                                    name: "Community",
                                    value: "Centennial",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 4,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 3,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "7",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "N",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "None",
                                },
                                {
                                    name: "Fireplace",
                                    value: "N",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Part Fin",
                                },
                                {
                                    name: "Heating",
                                    value: "Baseboard",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                                {
                                    name: "Garage",
                                    value: "1.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "7.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Private",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "4016.64",
                                },
                                {
                                    name: "Tax Year",
                                    value: "2023",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "Y",
                                },
                                {
                                    name: "Gas",
                                    value: "A",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "Fenced Yard",
                                },
                                {
                                    name: "Feature",
                                    value: "Level",
                                },
                                {
                                    name: "Feature",
                                    value: "Other",
                                },
                                {
                                    name: "Feature",
                                    value: "Park",
                                },
                                {
                                    name: "Feature",
                                    value: "Public Transit",
                                },
                                {
                                    name: "Feature",
                                    value: "School",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "E",
                                },
                                {
                                    name: "Frontage",
                                    value: "50.00",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "100.00",
                                },
                                {
                                    name: "Lot Size",
                                    value: "< .50",
                                },
                                {
                                    name: "Acres",
                                    value: "< .50",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Sewers",
                                },
                                {
                                    name: "Zoining",
                                    value: "R1-C Residential",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "162760248",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Rossland E & Central Park N",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Oshawa",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name:
                                "O'Neill Collegiate and Vocational Institute",
                            school_address:
                                "O'Neill Collegiate and Vocational Institute, Oshawa, Ontario L1G 4T2, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name:
                                "Eastdale Collegiate and Vocational Institute",
                            school_address:
                                "Eastdale Collegiate and Vocational Institute, 265 Harmony Road North, Oshawa, Ontario L1G 6L4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name:
                                "R S McLaughlin Collegiate & Vocational School",
                            school_address:
                                "R S McLaughlin Collegiate & Vocational School, Stevenson Road North, Oshawa, Ontario L1J 5P1, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "College Park Elementary",
                            school_address:
                                "College Park Elementary, Courtice, Ontario L1E 2J3, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "Sir Samuel Steele PS",
                            school_address:
                                "Sir Samuel Steele PS, Whitby, Ontario L1R 2N4, Canada",
                            school_category:
                                "elementary school, primary school, elementary, school",
                        },
                        {
                            school_name: "Mother Teresa Catholic School",
                            school_address:
                                "Mother Teresa Catholic School, Glenabbey Dr, Courtice, ON L1E, Courtice, Ontario L1E 2B6, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Courtice Secondary School",
                            school_address:
                                "Courtice Secondary School, Courtice, Ontario L1E 2L8, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Sinclair Secondary School",
                            school_address:
                                "Sinclair Secondary School, 300 Taunton Rd., Whitby, Ontario L1R 2K5, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Whitby Montessori School",
                            school_address:
                                "Whitby Montessori School, Whitby, Ontario L1R 0H4, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Pringle Creek Public School",
                            school_address:
                                "Pringle Creek Public School, 80 Ribblesdale Dr., Whitby, Ontario L1N 6K5, Canada",
                            school_category: "education, school",
                        },
                    ],
                },
                {
                    id: "N7369368",
                    unique_id: 2051921,
                    property_description: "",
                    community: "Kleinburg",
                    basement_one: "Sep Entrance",
                    basement_two: "",
                    property_type: "Lower Level",
                    bedrooms: 2,
                    bathrooms: 1,
                    kitchens: "1",
                    square_footage: "700-1100",
                    area: "York",
                    city: "Vaughan",
                    property_style: "Bungalow-Raised",
                    list_price: 2300,
                    sale_or_lease: "Lease",
                    virtual_tour_url: "",
                    virtual_tour_upload_date: "",
                    address: "881 Nashville Rd",
                    display_address_on_internet: "Y",
                    directions_or_cross_street: "Nashville And Highway 50",
                    zip_code: "L0J 1C0",
                    county: "Ontario",
                    realtor: "ROYAL LEPAGE SIGNATURE REALTY",
                    approx_age: "New",
                    leased_terms: "",
                    tax_year: "",
                    seller_property_info_statement: "N",
                    total_parking_spaces: "2.0",
                    parking_included: "Y",
                    parking_charges: "",
                    garage_type: "Carport",
                    garage_spaces: "0.0",
                    additional_monthly_fees: "",
                    all_inclusive_charges: "",
                    extras: "",
                    link: "",
                    link_comment: "",
                    photos: [
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369368-1.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369368-2.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369368-3.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369368-4.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369368-5.jpg",
                        "https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N7369368-6.jpg",
                    ],
                    updated_at: "2023-12-28 08:36:27",
                    pictures_updated_at: "2023-12-28 08:36:29.0",
                    last_updated_at: "2023-12-28 08:36:27",
                    additional_bathroom: "",
                    rooms: "4",
                    rooms_plus: "",
                    den_family_room: "Y",
                    ac: "Central Air",
                    fire_place: "Y",
                    heating: "Other",
                    water_supply: "Municipal",
                    drive_way: "Available",
                    taxes: null,
                    electricity: "",
                    gas: "",
                    cable: "",
                    prop_feat1_out: "",
                    prop_feat2_out: "",
                    prop_feat3_out: "",
                    prop_feat4_out: "",
                    prop_feat5_out: "",
                    prop_feat6_out: "",
                    front_age: "",
                    depth: "",
                    acres: "",
                    irreg: "",
                    pool: "None",
                    sewer: "Septic",
                    zoning: "",
                    parcel_id: "033220171",
                    garage: "Available",
                    mls_num: "N7369368",
                    constr_out: "Brick Front",
                    fronting_on: "S",
                    latitude: "43.84142100",
                    longitude: "-79.65998500",
                    property_details: {
                        A_c: "Central Air",
                        Access_prop1: "",
                        Access_prop2: "",
                        Acres: "",
                        Ad_text: "",
                        Addl_mo_fee: "",
                        Addr: "881 Nashville Rd",
                        All_inc: "",
                        Alt_power1: "",
                        Alt_power2: "",
                        Apt_num: "",
                        Area: "York",
                        Area_code: "09",
                        Ass_year: "",
                        Bath_tot: "1",
                        Br: "2",
                        Br_plus: "",
                        Bsmt1_out: "Sep Entrance",
                        Bsmt2_out: "",
                        Cable: "",
                        Cac_inc: "Y",
                        Central_vac: "",
                        Comel_inc: "",
                        Community: "Kleinburg",
                        Community_code: "09.02.0020",
                        Comp_pts: "S",
                        Constr1_out: "Brick Front",
                        Constr2_out: "",
                        County: "Ontario",
                        Cross_st: "Nashville And Highway 50",
                        Den_fr: "Y",
                        Depth: "",
                        Disp_addr: "Y",
                        Drive: "Available",
                        Easement_rest1: "",
                        Easement_rest2: "",
                        Easement_rest3: "",
                        Easement_rest4: "",
                        Elec: "",
                        Elevator: "",
                        Extras: "",
                        Farm_agri: "",
                        Fpl_num: "Y",
                        Front_ft: "",
                        Fuel: "Electric",
                        Furnished: "N",
                        Gar_spaces: "0.0",
                        Gar_type: "Carport",
                        Gas: "",
                        Heat_inc: "",
                        Heating: "Other",
                        Hydro_inc: "",
                        Idx_dt: "",
                        Irreg: "",
                        Kit_plus: "",
                        Laundry: "Shared",
                        Laundry_lev: "",
                        Legal_desc: "",
                        Level1: "",
                        Level10: "",
                        Level11: "",
                        Level12: "",
                        Level2: "",
                        Level3: "",
                        Level4: "",
                        Level5: "",
                        Level6: "",
                        Level7: "",
                        Level8: "",
                        Level9: "",
                        Link_comment: "",
                        Link_yn: "",
                        Lotsz_code: "",
                        Lp_dol: "2300.00",
                        Lse_terms: "",
                        Ml_num: "N7369368",
                        Mmap_col: "",
                        Mmap_page: "",
                        Mmap_row: "",
                        Municipality: "Vaughan",
                        Municipality_code: "09.02",
                        Municipality_district: "Vaughan",
                        Num_kit: "1",
                        Occ: "",
                        Oth_struc1_out: "",
                        Oth_struc2_out: "",
                        Outof_area: "",
                        Parcel_id: "033220171",
                        Park_chgs: "",
                        Park_spcs: "2",
                        Pix_updt: "2023-12-28 08:36:29.0",
                        Pool: "None",
                        Potl: "",
                        Prkg_inc: "Y",
                        Prop_feat1_out: "",
                        Prop_feat2_out: "",
                        Prop_feat3_out: "",
                        Prop_feat4_out: "",
                        Prop_feat5_out: "",
                        Prop_feat6_out: "",
                        Pvt_ent: "Y",
                        Retirement: "",
                        Rltr: "ROYAL LEPAGE SIGNATURE REALTY",
                        Rm1_dc1_out: "",
                        Rm1_dc2_out: "",
                        Rm1_dc3_out: "",
                        Rm1_len: "",
                        Rm1_out: "",
                        Rm1_wth: "",
                        Rm10_dc1_out: "",
                        Rm10_dc2_out: "",
                        Rm10_dc3_out: "",
                        Rm10_len: "",
                        Rm10_out: "",
                        Rm10_wth: "",
                        Rm11_dc1_out: "",
                        Rm11_dc2_out: "",
                        Rm11_dc3_out: "",
                        Rm11_len: "",
                        Rm11_out: "",
                        Rm11_wth: "",
                        Rm12_dc1_out: "",
                        Rm12_dc2_out: "",
                        Rm12_dc3_out: "",
                        Rm12_len: "",
                        Rm12_out: "",
                        Rm12_wth: "",
                        Rm2_dc1_out: "",
                        Rm2_dc2_out: "",
                        Rm2_dc3_out: "",
                        Rm2_len: "",
                        Rm2_out: "",
                        Rm2_wth: "",
                        Rm3_dc1_out: "",
                        Rm3_dc2_out: "",
                        Rm3_dc3_out: "",
                        Rm3_len: "",
                        Rm3_out: "",
                        Rm3_wth: "",
                        Rm4_dc1_out: "",
                        Rm4_dc2_out: "",
                        Rm4_dc3_out: "",
                        Rm4_len: "",
                        Rm4_out: "",
                        Rm4_wth: "",
                        Rm5_dc1_out: "",
                        Rm5_dc2_out: "",
                        Rm5_dc3_out: "",
                        Rm5_len: "",
                        Rm5_out: "",
                        Rm5_wth: "",
                        Rm6_dc1_out: "",
                        Rm6_dc2_out: "",
                        Rm6_dc3_out: "",
                        Rm6_len: "",
                        Rm6_out: "",
                        Rm6_wth: "",
                        Rm7_dc1_out: "",
                        Rm7_dc2_out: "",
                        Rm7_dc3_out: "",
                        Rm7_len: "",
                        Rm7_out: "",
                        Rm7_wth: "",
                        Rm8_dc1_out: "",
                        Rm8_dc2_out: "",
                        Rm8_dc3_out: "",
                        Rm8_len: "",
                        Rm8_out: "",
                        Rm8_wth: "",
                        Rm9_dc1_out: "",
                        Rm9_dc2_out: "",
                        Rm9_dc3_out: "",
                        Rm9_len: "",
                        Rm9_out: "",
                        Rm9_wth: "",
                        Rms: "4",
                        Rooms_plus: "",
                        Rural_svc1: "",
                        Rural_svc2: "",
                        Rural_svc3: "",
                        Rural_svc4: "",
                        Rural_svc5: "",
                        S_r: "Lease",
                        Sewage1: "",
                        Sewage2: "",
                        Sewer: "Septic",
                        Shore_allow: "",
                        Shoreline_exp: "",
                        Shoreline1: "",
                        Shoreline2: "",
                        Spec_des1_out: "Unknown",
                        Spec_des2_out: "",
                        Spec_des3_out: "",
                        Spec_des4_out: "",
                        Spec_des5_out: "",
                        Spec_des6_out: "",
                        Sqft: "700-1100",
                        St: "Nashville",
                        St_dir: "",
                        St_num: "881",
                        St_sfx: "Rd",
                        Status: "A",
                        Style: "Bungalow-Raised",
                        Taxes: "",
                        Timestamp_sql: "2023-12-28 08:36:27.0",
                        Tot_park_spcs: "2.0",
                        Tour_url: "",
                        Tv: "",
                        Type_own_srch: ".7.",
                        Type_own1_out: "Lower Level",
                        Uffi: "",
                        Util_cable: "",
                        Util_tel: "",
                        Vend_pis: "N",
                        Vtour_updt: "",
                        Water: "Municipal",
                        Water_acc_bldg1: "",
                        Water_acc_bldg2: "",
                        Water_body: "",
                        Water_del_feat1: "",
                        Water_del_feat2: "",
                        Water_feat1: "",
                        Water_feat2: "",
                        Water_feat3: "",
                        Water_feat4: "",
                        Water_feat5: "",
                        Water_front: "",
                        Water_inc: "",
                        Water_type: "",
                        Waterfront: "",
                        Wcloset_p1: "4",
                        Wcloset_p2: "",
                        Wcloset_p3: "",
                        Wcloset_p4: "",
                        Wcloset_p5: "",
                        Wcloset_t1: "1",
                        Wcloset_t1lvl: "",
                        Wcloset_t2: "",
                        Wcloset_t2lvl: "",
                        Wcloset_t3: "",
                        Wcloset_t3lvl: "",
                        Wcloset_t4: "",
                        Wcloset_t4lvl: "",
                        Wcloset_t5: "",
                        Wcloset_t5lvl: "",
                        Wtr_suptyp: "",
                        Yr: "",
                        Yr_built: "New",
                        Zip: "L0J 1C0",
                        Zoning: "",
                        Portion_property_lease1_out: "Basement",
                        Portion_property_lease2_out: "",
                        Portion_property_lease3_out: "",
                        Portion_property_lease4_out: "",
                        Portion_property_lease_srch: ".AB.",
                        Portion_lease_comments: "",
                        Assignment: "",
                        Fractional_ownership: "",
                    },
                    property_details_updated: [],
                    propertyDetails: [
                        {
                            name: "Building Type",
                            value: "Bungalow-Raised",
                        },
                        {
                            name: "Home Type",
                            value: "Lower Level",
                        },
                        {
                            name: "Basement",
                            value: "Sep Entrance",
                        },
                        {
                            name: "Size",
                            value: "",
                        },
                        {
                            name: "Age",
                            value: "New",
                        },
                        {
                            name: "Taxes",
                            value: null,
                        },
                        {
                            name: "Added",
                            value: "2023-12-28 08:36:27",
                        },
                        {
                            name: "MLS",
                            value: "N7369368",
                        },
                        {
                            name: "Listing Brokerage",
                            value: "ROYAL LEPAGE SIGNATURE REALTY",
                        },
                    ],
                    factsFeatures: [
                        {
                            name: "Properties",
                            children: [
                                {
                                    name: "Status",
                                    value: "Lease",
                                },
                                {
                                    name: "Type",
                                    value: "Lower Level",
                                },
                                {
                                    name: "Style",
                                    value: "Bungalow-Raised",
                                },
                                {
                                    name: "Area",
                                    value: "York",
                                },
                                {
                                    name: "Community",
                                    value: "Kleinburg",
                                },
                            ],
                        },
                        {
                            name: "Inside",
                            children: [
                                {
                                    name: "Bedrooms",
                                    value: 2,
                                },
                                {
                                    name: "Bedrooms Plus",
                                    value: "",
                                },
                                {
                                    name: "Bathrooms",
                                    value: 1,
                                },
                                {
                                    name: "Kitchen",
                                    value: "1",
                                },
                                {
                                    name: "Rooms",
                                    value: "4",
                                },
                                {
                                    name: "Room Plus",
                                    value: "",
                                },
                                {
                                    name: "Den Family Room",
                                    value: "Y",
                                },
                                {
                                    name: "Air Conditioning",
                                    value: "Central Air",
                                },
                                {
                                    name: "Fireplace",
                                    value: "Y",
                                },
                            ],
                        },
                        {
                            name: "Building",
                            children: [
                                {
                                    name: "Basement",
                                    value: "Sep Entrance",
                                },
                                {
                                    name: "Heating",
                                    value: "Other",
                                },
                                {
                                    name: "Water Supply",
                                    value: "Municipal",
                                },
                                {
                                    name: "Exterior",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Parking",
                            children: [
                                {
                                    name: "Driveway",
                                    value: "Available",
                                },
                                {
                                    name: "Garage",
                                    value: "0.0",
                                },
                                {
                                    name: "Parking Spaces",
                                    value: null,
                                },
                                {
                                    name: "Total Parking",
                                    value: "2.0",
                                },
                                {
                                    name: "Covered Parking Place",
                                    value: null,
                                },
                                {
                                    name: "Driveway",
                                    value: "Available",
                                },
                            ],
                        },
                        {
                            name: "Suite",
                            children: [
                                {
                                    name: "Kitchen Plus",
                                    value: "1",
                                },
                            ],
                        },
                        {
                            name: "Fees",
                            children: [
                                {
                                    name: "Taxes",
                                    value: "",
                                },
                                {
                                    name: "Tax Year",
                                    value: "",
                                },
                                {
                                    name: "Tax Legal Desription",
                                    value: null,
                                },
                            ],
                        },
                        {
                            name: "Utilities",
                            children: [
                                {
                                    name: "Electricity",
                                    value: "",
                                },
                                {
                                    name: "Gas",
                                    value: "",
                                },
                                {
                                    name: "Cable",
                                    value: "",
                                },
                                {
                                    name: "Telephone",
                                    value: "telephone",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                                {
                                    name: "Feature",
                                    value: "",
                                },
                            ],
                        },
                        {
                            name: "HighLights",
                            children: [
                                {
                                    name: "Fronting On",
                                    value: "S",
                                },
                                {
                                    name: "Frontage",
                                    value: "",
                                },
                                {
                                    name: "Lot Depth",
                                    value: "",
                                },
                                {
                                    name: "Lot Size",
                                    value: "",
                                },
                                {
                                    name: "Acres",
                                    value: "",
                                },
                                {
                                    name: "Lot Irregularities",
                                    value: "",
                                },
                                {
                                    name: "Pool",
                                    value: "None",
                                },
                                {
                                    name: "Sewer",
                                    value: "Septic",
                                },
                                {
                                    name: "Zoining",
                                    value: "",
                                },
                                {
                                    name: "Parcel Number",
                                    value: "033220171",
                                },
                                {
                                    name: "Cross Street",
                                    value: "Nashville And Highway 50",
                                },
                                {
                                    name: "Municipality District",
                                    value: "Vaughan",
                                },
                            ],
                        },
                    ],
                    nearby_school: [
                        {
                            school_name: "Academy for Mathematics & English",
                            school_address:
                                "Academy for Mathematics & English, 4-10501 Weston Rd, Vaughan, Ontario L4H 4G8, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Humberview Secondary School",
                            school_address:
                                "Humberview Secondary School, 135 Kingsview Drive, Bolton, Ontario L7E 3V6, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "James Bolton Public School",
                            school_address:
                                "James Bolton Public School, 225 Kingsview Drive, Bolton, Ontario L7E 3X8, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Father Bressani Catholic High School",
                            school_address:
                                "Father Bressani Catholic High School, 250 Ansley Grove Rd., Vaughan, Ontario L4L 3W4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name:
                                "St. Michael Catholic Secondary School",
                            school_address:
                                "St. Michael Catholic Secondary School, 9130 Columbia Way, Bolton, Ontario L7E 1S8, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Maple Academy of Dance",
                            school_address:
                                "Maple Academy of Dance, Vaughan, Ontario L4K 2M9, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Arcadia Academy Of Music",
                            school_address:
                                "Arcadia Academy Of Music, 205 Marycroft Ave., Unit 6, Vaughan, Ontario L4L 5Y3, Canada",
                            school_category: "music school, school",
                        },
                        {
                            school_name: "F45 Training Vaughan Metropolitan",
                            school_address:
                                "F45 Training Vaughan Metropolitan, 120 Carlauren Road, Vaughan, Ontario L4L 8A8, Canada",
                            school_category: "education, school",
                        },
                        {
                            school_name: "Maple High School",
                            school_address:
                                "Maple High School, 55 Springside Rd., Vaughan, Ontario L6A 3P4, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                        {
                            school_name: "Sandalwood Heights Secondary School",
                            school_address:
                                "Sandalwood Heights Secondary School, Brampton, Ontario L6R 0K7, Canada",
                            school_category:
                                "high school, highschool, secondary school, school",
                        },
                    ],
                },
            ],
            currentListMetadata: LIST_METADATA["MLS"],
        };
    },
    mounted() {
        if (this.listFor && LIST_METADATA[this.listFor]) {
            this.currentListMetadata = LIST_METADATA[this.listFor];
            this.getProperties();
        } else {
            this.initOwlCarousel();
        }
    },
    components: {
        "skeleton-loader": SkeletonLoader,
        "common-property-card": CommonPropertyCard,
    },
    methods: {
        getProperties() {
            this.isLoading = true;
            this.propertyList = [];
            axios?.[this.currentListMetadata?.method || "get"]?.(
                this.currentListMetadata?.endpoint
            )
                .then((response) => {
                    this.propertyList =
                        this.currentListMetadata.cardType === "NEW_CONSTRUCTION"
                            ? response?.data?.data?.property?.data
                            : response?.data?.data;
                    if (this.propertyList.length > 0) {
                        this.loadCarousel();
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally((result) => {
                    this.isLoading = false;
                });
        },
        loadCarousel() {
            setTimeout(() => {
                this.initOwlCarousel();
            }, 200);
        },
        initOwlCarousel() {
            const $owl = $(".owl-carousel");
            $owl.children().each(function (index) {
                $(this).attr("data-position", index);
            });

            $owl.owlCarousel({
                autoplay: false,
                items: 4,
                // stagePadding: 100,
                center: false,
                nav: true,
                margin: 10,
                dots: false,
                loop: false,
                autoHeight: true,
                responsive: {
                    0: {
                        items: 1,
                    },
                    480: {
                        items: 1,
                    },
                    575: {
                        items: 1,
                    },
                    768: {
                        items: 1,
                    },
                    991: {
                        items: 4,
                    },
                    1200: {
                        items: 4,
                    },
                },
            });

            let makeid = +new Date();
            $(".owl-prev").attr("aria-label", "Owl Prev_" + makeid);
            $(".owl-next").attr("aria-label", "Owl Next_" + makeid);
            $(".owl-dot").attr("aria-label", "Owl Dot_" + makeid);
        },
    },
    computed: {
        propertyCardType() {
            return LIST_METADATA?.[this.listFor]?.cardType || "BROWSE_HOME";
        },
        viewAllLink() {
            return LIST_METADATA?.[this.listFor]?.viewAllLink || "#";
        },
        hidePrice() {
            return this.listFor === "RECENTLY_SOLD_HOMES";
        },
    },
};
</script>

<style lang="scss">
h2.heading {
    font-weight: bold;
    margin-bottom: 20px;
}

.view-all-btn {
    border-radius: 15px;
    background: #0061df;
    box-shadow: 2px 2px 4px 0px rgba(4, 119, 255, 0.3);
    color: #fff;
    display: inline-flex;
    padding: 16px 32px;
    margin-top: 10px;
}

.view-all-btn:hover {
    color: #fff;
}

.view-all-btn-container {
    bottom: 10px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.property-card {
    min-width: 300px;
}

.owl-theme .owl-nav {
    margin-top: 20px;
    text-align: right;
    margin-right: 10px;
}

.owl-nav button span {
    display: none;
}

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    width: 55px;
    height: 55px;
}

.owl-theme .owl-nav .owl-prev:hover,
.owl-theme .owl-nav .owl-next:hover {
    background: var(--clr-primary);
}

.owl-theme .owl-nav .owl-prev:hover::before,
.owl-theme .owl-nav .owl-next:hover::before {
    color: #fff;
}

.owl-nav .owl-prev::before {
    content: "\f104";
    font-family: "Font Awesome 5 Free";
    color: var(--clr-primary);
    font-weight: 600;
    font-size: 22px;
}

.owl-nav .owl-next::before {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    color: var(--clr-primary);
    font-weight: 600;
    font-size: 22px;
}

.properties-carousel-container {
    min-height: 380px;
    position: relative;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.properties-carousel-container .owl-carousel .owl-stage {
    display: flex;
}

.properties-carousel-container .owl-carousel .property-card {
    height: 100%;
}

@media (max-width: 576px) {
    h2.heading {
        text-align: center;
    }

    .owl-theme .owl-nav .owl-prev,
    .owl-theme .owl-nav .owl-next {
        width: 50px;
        height: 50px;
    }

    .properties-carousel-container .view-all-btn-container {
        bottom: 10px;
        left: 0;
        transform: translateX(0);
    }

    .view-all-btn-container .view-all-btn {
        font-size: 14px;
        padding: 15px 20px;
    }

    .properties-carousel-container {
        .common-property-list-grid {
            & > * {
                display: none;
            }

            & > :first-child {
                display: block;
            }

            .skeleton-card {
                width: 100%;
            }
        }
    }
}
</style>
