<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-4">
                <a class="btn btn-outline-primary" href="/seo/add-page">Add SEO Page</a>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4 text-right">
                <input
                  type="text"
                  name="search"
                  placeholder="Search"
                  id="search"
                  class="form-control rounded w-100"
                />
              </div>
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col" class="w-25">Page</th>
                  <th scope="col" class="w-25">Link</th>
                  <th scope="col" class="w-25">Tags</th>
                  <th scope="col" class="w-25 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="seoPageAvailable">
                  <tr v-for="(item, index) in allSeoList" :key="'seo-page-'+index">
                    <td class="w-25">{{ item.page_name }}</td>
                    <td class="w-25">{{ item.page_link }}</td>
                    <td class="w-25">{{ getPageTags(item) }}</td>
                    <td class="w-25 text-center">
                      <a
                        href="javascript:void(0);"
                        @click.prevent="openPageDetailsModal(item)"
                        class="btn btn-icon btn-sm text-success mr-1 fa-tip"
                        title="Show"
                      >
                        <i class="fa fa-eye pointer-icon"></i>
                      </a>
                      <a
                        :href="'/seo/'+item.id+'/edit-seo'"
                        class="btn btn-icon btn-sm text-info fa-tip"
                        title="Edit"
                        data-original-title="Edit"
                      >
                        <i class="fa fa-pen pointer-icon"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        @click.prevent="openPageDeleteModal(item.id)"
                        class="btn btn-icon btn-sm mx-1 text-danger fa-tip"
                        title="Delete"
                        data-original-title="Delete"
                      >
                        <i class="fa fa-trash pointer-icon"></i>
                      </a>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="5" class="text-center">No SEO Page Available</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-4">
                <label for="total" class="text-sm">Show:</label>
                <select
                  name="total"
                  id="total"
                  class="form-control"
                  style="width:max-content;margin:0;display:revert;"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span class="text-sm">&nbsp;records</span>
              </div>
              <div class="col-md-8"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SEO Page Details Modal -->
    <b-modal
      ref="pageDetailsModal"
      id="page-details-modal"
      centered
      title="Page Details"
      hide-footer
    >
      <div class="form-group">
        <b>Page:</b>
        {{ page_name }}
      </div>
      <div class="form-group">
        <b>Link:</b>
        {{ page_link }}
      </div>
      <div class="form-group">
        <div>
          <b>Tags:</b>
        </div>
        <span
          class="p-2 m-2 badge page-tags"
          v-for="(item, index) in page_tags"
          :key="'tag-name-'+index"
        >{{ item.name }}</span>
      </div>
    </b-modal>
    <!-- ./SEO Page Details Modal -->
    <!-- Delete SEO Page Modal -->
    <b-modal ref="propertyDeleteModal" id="property-delete-modal" centered title="Are you sure?">
      <p class="text-secondary">Do you really want to delete this SEO page?</p>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="float-left" variant="danger" @click.prevent="confirmPageDelete">Confirm</b-button>
          <b-button
            class="float-right"
            variant="success"
            @click.prevent="closePageDetailsModal"
          >Cancel</b-button>
        </div>
      </template>
    </b-modal>
    <!-- ./Delete SEO Page Modal -->
  </div>
</template>

<script>
export default {
  name: "ListSEO",
  data() {
    return {
      allSeoList: [],
      page_name: "",
      page_link: "",
      page_tags: [],
      page_id: 0
    };
  },
  mounted() {
    this.getAllSeo();
  },
  methods: {
    openPageDeleteModal(id) {
      this.page_id = id;
      this.$refs["propertyDeleteModal"].show();
    },
    confirmPageDelete() {
      let url = "/seo/delete-seo-page/" + this.page_id;
      axios
        .post(url)
        .then(response => {
          let data = response.data?.data;
          let page_index = this.allSeoList.findIndex(
            el => data.id == this.page_id
          );
          this.$delete(this.allSeoList, page_index);
          this.page_id = 0;
        })
        .catch(error => {
          console.error(error?.response?.data);
        })
        .finally(res => {
          this.$refs["propertyDeleteModal"].hide();
        });
    },
    openPageDetailsModal(item) {
      this.page_name = item.page_name;
      this.page_link = item.page_link;
      this.page_tags = item.seo_tags.map(el => {
        return {
          name: el.meta_name,
          description: el.meta_description
        };
      });
      this.$refs["pageDetailsModal"].show();
    },
    closePageDetailsModal() {
      this.page_id = 0;
      this.$refs["pageDetailsModal"].hide();
    },
    getAllSeo() {
      axios
        .get("/seo/page-list")
        .then(response => {
          // this.allSeoList = response.data?.data?.data;
          this.allSeoList = response.data?.data;
        })
        .catch(error => {
          console.error(error?.response?.data);
        });
    },
    getPageTags(item) {
      if (item?.seo_tags) {
        if (item.seo_tags?.length > 0) {
          let seo_tags = item.seo_tags;
          let static_seo_tags = "";
          if (seo_tags.length == 1) {
            static_seo_tags = seo_tags[0].meta_name;
          } else {
            static_seo_tags = seo_tags[0].meta_name;
            seo_tags.forEach((element, index) => {
              if (index != 0) {
                static_seo_tags += ", " + element.meta_name;
              }
            });
          }
          return static_seo_tags;
        } else {
          return "No tags available.";
        }
      } else {
        return "No tags available.";
      }
    }
  },
  computed: {
    seoPageAvailable() {
      if (this.allSeoList.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
.page-tags {
  border-radius: 20px;
  color: #fff;
  background: gray;
}
#page-details-modal___BV_modal_footer_,
#property-delete-modal___BV_modal_footer_ {
  background: #fff;
}
</style>
