<template>
  <div>
    <div class="card builders-form-card">
      <div class="card-body">
        <h3 class="builder-form__title">Your Request Has Been Submitted!</h3>
        <p class="builder-form__subtitle">Now let’s add some photos</p>
        <p class="text-danger text-center">
          Video and snap shots virtually assist. Sellers who provide images are
          40% more likely to promote to RedBuyers.
        </p>
        <div class="row">
          <div class="col-md-12 my-2">
            <button class="btn builders-from__primary-action-button" @click.prevent="moveToFormFour" :disabled="final_form_processing">{{ final_form_processing ? "Processing..." : "Upload Photos Now" }}</button>
          </div>
          <div class="col-md-12 my-2">
            <button class="btn builders-from__primary-action-button" @click.prevent="submitFormData" :disabled="final_form_processing">{{ final_form_processing ? "Processing..." : "Upload Photos Later" }}</button>
          </div>
          <!-- <div class="col-md-12 my-2">
            <button class="btn builders-from__primary-action-button" @click.prevent="moveToFormFive" :disabled='serverResponding'>{{ serverResponding ? "Processing..." : "Skip Photos All Together" }}</button>
          </div> -->
        </div>
        <!-- <div class="my-4">
          <p class="buuton-group-info text-center">Add your virtual tour or Video Link</p>
        </div> -->
        <!-- <div class="form-group text-center my-4">
          <button class="btn btn-danger builders-from__secondary-action-button" @click.prevent="moveToFormFive" :disabled="serverResponding">{{ serverResponding ? "Processing..." : "Schedule A Virtual Walk Through" }}</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuilderFormThree",
  props: {
    final_form_processing: {
      default: false
    }
  },
  data() {
    return {
      user_response: [],
      serverResponding: false
    };
  },
  methods: {
    moveToFormFour() {
      this.$emit('moveToFormFour', {
        user_response: this.user_response
      });
    },
    submitFormData() {
        this.$emit("submitFormData", {});
      }
  }
};
</script>
