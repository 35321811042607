<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step
                {{ currentStep }}
            </h5>
        </div>
        <div class="card-body">
            <div class="form-group" v-if="isEdit">
                <label for="short_description">Short Description</label>
                <ckeditor
                    :editor="editor"
                    v-model="short_description"
                    :config="editorConfig"
                ></ckeditor>
            </div>
            <div class="form-group" v-if="isEdit">
                <label for="detailed_description">Detailed Description</label>
                <ckeditor
                    :editor="editor"
                    v-model="detailed_description"
                    :config="editorConfig"
                ></ckeditor>
            </div>
            <div class="form-group">
                <label for="property_description">Property Description</label>
                <ckeditor
                    :editor="editor"
                    v-model="property_description"
                    :config="editorConfig"
                ></ckeditor>
            </div>

            <div class="form-group">
                <label for="virtual_tour">Virtual Tour</label>
                <input
                    type="text"
                    class="form-control builders-form__input"
                    id="virtual_tour"
                    placeholder="Virtual Tour"
                    v-model="virtual_tour"
                />
            </div>
            <div>
                <h6 class="builder-form__title font-weight-bold">Attachment</h6>
                <div class="form-group exclusive-create-drop">
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        id="firstDropZone"
                        ref="firstDropZoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handlePhotoUploadError"
                        @vdropzone-complete="updatePropertyImages"
                        @vdropzone-files-added="onAttachmentAdd"
                        @vdropzone-removed-file="onRemovedFile"
                    >
                        <div class="dropzone-custom-content">
                            <img
                                src="/assets/images/builders-form/dropzone-picture-icon.png"
                                alt="Drop Image"
                                class="img-responsive img-fluid"
                            />
                            <h4>Drop Your Image Here!</h4>
                        </div>
                    </vue-dropzone>
                    <p>
                        <b>
                            <i class="fas fa-info-circle"></i
                            >&nbsp;&nbsp;Allowed types – jpg, jpeg and png.
                            Maximum file size – 5MB
                        </b>
                    </p>
                </div>
                <div
                    class="invalid-feedback"
                    v-if="
                        !$v.attachments.$model.length && $v.attachments.$dirty
                            ? true
                            : false
                    "
                    :class="
                        !$v.attachments.$model.length && $v.attachments.$dirty
                            ? 'd-block'
                            : 'd-none'
                    "
                >
                    This is required.
                </div>
            </div>
            <!-- <div v-if="propertyDetails.photos" class="photos-grid mb-4">
                <div v-for="fileObj in propertyDetails.photos">
                    <img
                        :src="base_url + fileObj.url"
                        alt="Property"
                        class="img-fluid"
                    />
                </div>
            </div> -->
            <!-- Drag Preview Image List -->
            <draggable
                v-model="photos"
                v-if="photos"
                class="d-flex flex-column draggable-photos-container"
            >
                <img
                    v-for="(item, index) in photos"
                    :key="index"
                    :src="base_url + item.url"
                    alt="Property"
                    class="img-thumbnail"
                />
            </draggable>
            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="submit()"
                    >
                        {{ isEdit ? "Next" : "Submit" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { minLength, required } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import pick from "lodash/pick";
import axios from "axios";

const initFormFields = {
    property_description: "<p>No information available.</p>",
    short_description: "<p>No information available.</p>",
    detailed_description: "<p>No information available.</p>",
    virtual_tour: "",
    attachments: [],
};

export default {
    name: "FormStepFour",
    props: {
        propertyDetails: {
            default: {},
        },
        isEdit: {
            default: false,
        },
        currentStep: {},
        base_url: {
            default: "",
        },
    },
    components: {
        draggable,
    },
    data() {
        return {
            dropzoneOptions: {
                url: "/builders/upload-property-imag",
                autoProcessQueue: false,
                addRemoveLinks: true,
            },
            formSubmitting: false,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "outdent",
                        "indent",
                        "undo",
                        "redo",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "blockQuote",
                        "insertTable",
                    ],
                },
            },
            ...initFormFields,
        };
    },
    created() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                console.log(
                    "pickFormFields",
                    pickFormFields,
                    this.propertyDetails
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key];
                }
            }
        },
        updatePropertyImages(response) {
            if (response?.xhr?.status == 200) {
                this.layoutUploadError = false;
                if (response?.xhr?.response) {
                    let serverResponse = JSON.parse(response?.xhr?.response);
                    if (
                        serverResponse?.data?.thumbnail_path &&
                        serverResponse?.data?.image_path
                    ) {
                        this.propertyImages.push(
                            serverResponse.data.thumbnail_path
                        );
                        this.propertyImages.push(
                            serverResponse.data.image_path
                        );
                        localStorage.setItem(
                            "property_images",
                            JSON.stringify(this.propertyImages)
                        );
                    }
                }
            }
        },
        handlePhotoUploadError(file, message, xhr) {
            this.photoUploadError = true;
            this.$refs.firstDropZoneRef.removeFile(file);
        },
        onAttachmentAdd(files) {
            this.attachments = [...this.attachments, ...files].filter(
                (item) => item instanceof File
            );
        },
        submit() {
            this.$v.$touch();
            console.log(this.$v);
            if (!this.$v.$invalid) {
                const formData = pick(this, Object.keys(initFormFields));
                if (this.isEdit) {
                    this.$emit("finalSubmit", formData);
                } else {
                    delete formData.short_description;
                    delete formData.detailed_description;
                    this.$emit("finalSubmit", formData);
                }
            }
        },
        onRemovedFile(file) {
            console.log("Removed File", file);
            const { status, name } = file;
            if (status === "queued") {
                this.attachments = this.attachments.filter(
                    (fileObj) => fileObj.name !== name
                );
            }
        },
    },
    validations: {
        attachments: {
            minLength: minLength(1),
            $each: {
                required,
            },
        },
    },
    computed: {
        photos: {
            get() {
                return this.propertyDetails.photos;
            },
            set(value) {
                const formData = new FormData();
                formData.append("property_id", this.propertyDetails.id);
                let count = 0;
                for (let imageObj of value) {
                    for (let key in imageObj) {
                        formData.append(
                            `images[${count}][${key}]`,
                            imageObj[key]
                        );
                    }
                    count++;
                }

                axios
                    .post("/api/change-image-sequence", formData)
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                this.propertyDetails.photos = value;
            },
        },
    },
};
</script>
<style>
.exclusive-create-drop .dz-progress {
    background: transparent !important;
}
.photos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
.draggable-photos-container {
    gap: 10px;
}
.draggable-photos-container img {
    height: 150px !important;
    width: 100% !important;
    object-fit: cover !important;
}
</style>
