import moment from "moment"
export default {
    name: "CommonPropertyCard",
    props: {
        property_details: {
            required: true,
        },
        hidePrice: {
            default: false,
        },
        image_base_url: {
            default: "https://redbuyers.com/",
        },
        type: {
            default: "BROWSE_HOME",
            validator: (value) => {
                return [
                    "BROWSE_HOME",
                    "NEW_CONSTRUCTION",
                    "COMPARABLE_HOME",
                    "CURRENTLY_FOR_SALE",
                    "FEATURED_DEVELOPMENTS",
                    "EXCLUSIVE",
                ].includes(value);
            },
        },
        user_id: {
            default: null,
        },
        userFavProperties: {
            type: Array,
            default: function () {
                return [];
            },
        },
        canShare: false,
        isCheckedForShare: false,
        displayTimeLabel: false
    },
    emits: ["onFavoriteSuccess", "share"],
    data() {
        return {
            url: "",
            exclusive_property: false,
            imgBaseUrl: "https://s3.us-west-1.wasabisys.com/redbuyers-main/",
            isFav: false,
            isGarage: true
        };
    },
    mounted() {
        this.url = this.image_base_url;
        if (
            this.type === "FEATURED_DEVELOPMENTS" ||
            this.type === "NEW_CONSTRUCTION"
        ) {
            this.property_details = {
                ...this.property_details,
                photos: this.property_details["covers"],
            };
        }
        // some where not parsed `property_details.property_details` for COMPARABLE_HOME
        if (
            this.type === "COMPARABLE_HOME" &&
            typeof this.property_details?.property_details === "string"
        ) {
            this.property_details.property_details = JSON.parse(
                this.property_details.property_details
            );
        }
        // Change img url for exclusive listing
        if (this.type === "EXCLUSIVE") {
            this.imgBaseUrl = this.url;
        }
    },
    computed: {
        topLeftLabelValue() {
            const typeWiseValue = {
                BROWSE_HOME: this.property_type,
                NEW_CONSTRUCTION: this.daysInConstruct + " days", //!need to set dynamic value,
                COMPARABLE_HOME: "Sold",
                CURRENTLY_FOR_SALE: this.listing_purpose,
            };
            return typeWiseValue?.[this.type];
        },
        property_slug() {
            return (
                "/sellers/property-details/" +
                this.convertToSlug(
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                ) +
                "/" +
                this.property_details["unique_id"]
            );
        },
        property_address() {
            if (
                this.type === "NEW_CONSTRUCTION" ||
                this.type === "FEATURED_DEVELOPMENTS"
            ) {
                if (
                    this.property_details?.street_address &&
                    this.property_details?.street_address !== "null"
                ) {
                    return this.property_details.street_address;
                } else {
                    return "No address available";
                }
            } else if (this.type === "COMPARABLE_HOME") {
                return (
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details?.property_details?.["County"] +
                    ", " +
                    this.property_details["zip"]
                );
            } else {
                if (
                    this.property_details["unique_id"] === undefined ||
                    this.property_details["unique_id"] == "undefined" ||
                    this.property_details["unique_id"] === "undefined"
                ) {
                    return (
                        this.property_details["street_address"] +
                        ", " +
                        this.property_details["city"] +
                        ", " +
                        this.property_details["area"] +
                        ", " +
                        this.property_details["zip_code"] +
                        ", " +
                        this.property_details["county"]
                    );
                } else {
                    return (
                        this.property_details["address"] +
                        ", " +
                        this.property_details["city"] +
                        ", " +
                        this.property_details["area"] +
                        ", " +
                        this.property_details["zip_code"] +
                        ", " +
                        this.property_details["county"]
                    );
                }
            }
        },
        property_address_community() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (
                    this.property_details["street_address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            } else {
                return (
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            }
        },
        bedrooms() {
            if (this.type === "COMPARABLE_HOME") {
                if (this.property_details?.property_details) {
                    let property_details =
                        this.property_details?.property_details;
                    let bedroom = "";
                    if (property_details["Br"]) {
                        bedroom = bedroom + property_details["Br"];
                        if (property_details["Br_plus"]) {
                            bedroom =
                                bedroom + " + " + property_details["Br_plus"];
                        }
                    }
                    return bedroom;
                } else {
                    return this.property_details?.bedrooms;
                }
            } else {
                if (this.type === "NEW_CONSTRUCTION") {
                    if (this.property_details?.bedroom) {
                        const words = this.property_details?.bathroom?.split(' ');
                        const filteredWords = words.filter(function (word) {
                            return word !== 'Bath';
                        });
                        const resultString = filteredWords.join(' ');
                        return resultString
                    } else {
                        return 0
                    }
                }
                if (
                    this.property_details["unique_id"] === undefined ||
                    this.property_details["unique_id"] == "undefined"
                ) {
                    return this.property_details["bedrooms"]
                        ? this.property_details["bedrooms"]
                        : 0;
                } else {
                    return this.property_details["bedrooms"]
                        ? this.property_details["bedrooms"] -
                        this.addtional_bedrooms
                        : 0;
                }
            }
        },
        addtional_bedrooms() {
            if (
                this.property_details["additional_bathroom"] == "0" ||
                this.property_details["additional_bathroom"] === 0 ||
                this.property_details["additional_bathroom"] === "undefined" ||
                this.property_details["additional_bathroom"] == "undefined" ||
                this.property_details["additional_bathroom"] === undefined
            ) {
                return this.property_details["additional_bedrooms"]
                    ? this.property_details["additional_bedrooms"]
                    : 0;
            } else {
                return this.property_details["additional_bathroom"];
            }
        },
        bathrooms() {
            if (this.type === "NEW_CONSTRUCTION") {
                if (this.property_details?.bathroom) {
                    const words = this.property_details?.bathroom?.split(' ');
                    const filteredWords = words.filter(function (word) {
                        return word !== 'Bath';
                    });
                    const resultString = filteredWords.join(' ');
                    return resultString
                } else {
                    return 0
                }
            }
            return this.property_details["bathrooms"]
                ? this.property_details["bathrooms"]
                : 0;
        },
        garage() {
            if (this.property_details?.garage || this.property_details?.garage === ' ') {
                this.isGarage = false
            }
            if (this.type === "NEW_CONSTRUCTION") {
                return this.property_details?.garage || 0;
            }
            if (this.type === "COMPARABLE_HOME") {
                return this.property_details?.property_details?.Gar_spaces;
            } else {
                if (
                    this.property_details["garage_spaces"] === "undefined" ||
                    this.property_details["garage_spaces"] === undefined
                ) {
                    return this.property_details["garage"]
                        ? this.property_details["garage"]
                        : 0;
                } else {
                    return this.property_details["garage_spaces"]
                        ? this.property_details["garage_spaces"]
                        : 0;
                }
            }
        },
        square_footage() {
            return this.property_details["square_footage"]
                ? this.property_details["square_footage"]
                : 0;
        },
        list_price() {
            if (this.type === "COMPARABLE_HOME") {
                return this.property_details?.property_details?.["Lp_dol"];
            }
            if (this.type === "NEW_CONSTRUCTION") {
                return this.property_details["list_price"]
                    ? this.property_details["list_price"]?.replace(/[$,]/g, "")
                    : '0';
            }
            return this.property_details["list_price"];
        },
        virtual_tour_url() {
            return this.property_details["virtual_tour_url"];
        },
        total_photos() {
            return this.property_details["photos"].length;
        },
        property_type() {
            return this.property_details["property_type"];
        },
        listing_purpose() {
            return this.property_details["sale_or_lease"];
        },
        u_id() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["id"];
            } else {
                return this.property_details["unique_id"];
            }
        },
        thumbnail() {
            if (typeof this.property_details?.["photos"]?.[0] === "object") {
                return this.property_details?.["photos"]?.[0]
                    ? this.imgBaseUrl +
                    this.property_details?.["photos"]?.[0]["url"]
                    : "/assets/images/no-image-available.png";
            } else {
                return this.property_details?.["photos"]?.[0]
                    ? this.property_details?.["photos"]?.[0]
                    : "/assets/images/no-image-available.png";
            }
        },
        thumbnail_1() {
            if (typeof this.property_details?.["photos"]?.[1] === "object") {
                return this.property_details?.["photos"]?.[1]
                    ? this.imgBaseUrl +
                    this.property_details?.["photos"]?.[1]["url"]
                    : "/assets/images/no-image-available.png";
            } else {
                return this.property_details?.["photos"]?.[1]
                    ? this.property_details?.["photos"]?.[1]
                    : "/assets/images/no-image-available.png";
            }
        },
        thumbnail_2() {
            if (typeof this.property_details?.["photos"]?.[2] === "object") {
                return this.property_details?.["photos"]?.[2]
                    ? this.imgBaseUrl +
                    this.property_details?.["photos"]?.[2]["url"]
                    : "/assets/images/no-image-available.png";
            } else {
                return this.property_details?.["photos"]?.[2]
                    ? this.property_details?.["photos"]?.[2]
                    : "/assets/images/no-image-available.png";
            }
        },
        isExclusiveProperty() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (this.exclusive_property = true);
            }
        },
        sale_property_type() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["property_listing_type"]
                    ? this.property_details["property_listing_type"]
                    : "Exclsuive Property";
            }
        },
        daysInMarket() {
            const startDate =
                this.property_details?.property_details?.Input_date;
            const endDate = this.property_details?.property_details?.Unavail_dt;
            if (!startDate || !endDate) return null;
            let start = moment(startDate);
            let end = moment(endDate);
            return end.diff(start, "days");
        },
        daysInConstruct() {
            let start = moment(this.property_details?.created_at);
            let end = moment();
            return end.diff(start, "days");
        },
        soldPrice() {
            return this.property_details?.sold_price;
        },
        soldDate() {
            return this.property_details?.sold_date?.split(" ")?.[0];
        },
        propertyNameForNewConstruction() {
            if (this.property_details?.Addr) {
                return this.property_details.Addr;
            } else if (this.property_details?.Ml_num) {
                return "Property " + this.property_details.Ml_num;
            } else {
                return "";
            }
        },
        addedTimeLabel() {
            if (this.displayTimeLabel)
                return this.getPropertyAddedTime()
        },


    },
    methods: {
        loginRedirect() {
            if (!this.user_id) window.location.href = "/login";
        },
        openSummaryModal() {
            this.$bvModal.show("modal-center-" + this.u_id);
        },
        goToPropertyDetails(e) {
            e.stopPropagation();
            if (
                this.type === "NEW_CONSTRUCTION" ||
                this.type === "FEATURED_DEVELOPMENTS"
            ) {
                window.open(this.property_details["plan_details_url"], "_self");
            } else if (this.type === "COMPARABLE_HOME") {
                window.location.href =
                    "/idx-listings/sold/properties/" +
                    this.convertToSlug(
                        this.property_details["address"] +
                        ", " +
                        this.property_details["city"] +
                        ", " +
                        this.property_details["property_details"]["Area"] +
                        ", " +
                        this.property_details["property_details"]["Zip"] +
                        ", " +
                        this.property_details["property_details"]["County"]
                    ) +
                    "/" +
                    this.property_details["id"];
            } else {
                if (
                    this.property_details["unique_id"] === undefined ||
                    this.property_details["unique_id"] == "undefined" ||
                    this.property_details["unique_id"] === "undefined"
                ) {
                    window.open(
                        this.url +
                        "/exclusive-property-detail/" +
                        this.property_details["unique_code"],
                        "_self"
                    );
                } else {
                    window.location.href =
                        "/sellers/property-details/" +
                        this.convertToSlug(
                            this.property_details["address"] +
                            ", " +
                            this.property_details["city"] +
                            ", " +
                            this.property_details["area"] +
                            ", " +
                            this.property_details["zip_code"] +
                            ", " +
                            this.property_details["county"]
                        ) +
                        "/" +
                        this.property_details["unique_id"];
                }
            }
        },
        convertToSlug(val) {
            return val
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        handleImageError(event) {
            event.preventDefault();
            if (this.type === "COMPARABLE_HOME") {
                this.handleImageForSoldProperty(event);
            } else {
                event.target.src = "/assets/images/no-image-available.png";
            }
        },
        handleImageForSoldProperty(event) {
            if (event.target.src.match(/\/\//g).length === 2) {
                const splitByProperties =
                    event.target.src.split("/soldProperties");
                const updated_url = splitByProperties.join("soldProperties");
                event.target.src = updated_url;
            } else {
                event.target.src = "/assets/images/no-image-available.png";
            }
        },
        handleFavorite() {
            if (!this.user_id) window.location.href = "/login";
            this.saveFavorite();
        },
        saveFavorite() {
            let endpoint = "/api/set-favorite-property";
            let propertyTypes = {
                EXCLUSIVE: "exclusive",
                NEW_CONSTRUCTION: "new-construction",
                BROWSE_HOME: "idx",
                COMPARABLE_HOME: "vow",
                CURRENTLY_FOR_SALE: "idx",
            };
            if (
                this.type === "BROWSE_HOME" ||
                this.type === "COMPARABLE_HOME" ||
                this.type === "CURRENTLY_FOR_SALE"
            ) {
                endpoint =
                    "https://valuation.santhoshmathew.com/api/v1/set-favorite-property";
            }
            const formData = new FormData();
            formData.append("user_id", this.user_id),
                formData.append("property_id", this.u_id);
            formData.append(
                "property_type",
                propertyTypes[this.type] || "exclusive"
            );
            axios
                .post(endpoint, formData)
                .then((res) => {
                    console.log("❤️", res);
                    this.isFav = !this.isFav;
                    this.$emit?.("onFavoriteSuccess", {
                        ...res?.data,
                        id: this.u_id,
                    });
                })
                .catch((err) => { });
        },
        handleShareCheckboxChange() {
            this.$emit("share", this.property_details);
        },
        getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60 * 24)) | this.round_off;
        },
        getDifferenceInHours(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60)) | this.round_off;
        },
        getPropertyAddedTime() {
            let todays_day = new Date();
            let last_updated_date = new Date(this.property_details.updated_at);
            // const differenceFromNow = moment().diff(last_updated_date); 
            // return moment.duration(differenceFromNow).humanize()
            if (
                this.getDifferenceInDays(last_updated_date, todays_day) == 1 ||
                this.getDifferenceInDays(last_updated_date, todays_day) == 0
            ) {
                return (
                    "Added " +
                    this.getDifferenceInHours(last_updated_date, todays_day) +
                    " Hours ago"
                );
            } else {
                return (
                    "Added " +
                    this.getDifferenceInDays(last_updated_date, todays_day) +
                    " Hours ago"
                );
            }
        }
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        soldDateFilter(val) {
            if (val) {
                return moment(val).format("MMM Do YYYY");
            } else {
                return val;
            }
        },
    },
    watch: {
        userFavProperties: {
            handler(favPropertiesIds) {
                this.isFav = favPropertiesIds?.includes(this.u_id);
            },
            immediate: true,
        },
    },
};
