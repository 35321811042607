<template>
  <div>
    <!-- header -->
    <section>
      <div class="header" id="top-header">
        <!-- nav bar -->
        <landing-two-header
          :baseurl="baseurl"
          :displaynumber="displaynumber"
          :infoemail="infoemail"
          :contactnumber="contactnumber"
        ></landing-two-header>
        <!-- ./nav bar -->
        <!-- banner section -->
        <div class="container">
          <div class="col-md-12">
            <div class="row headerset text-center">
              <div class=" center-div col-lg-6">
                <h1 class="home text-white text-left">Need to Sell<br>Your House Fast?</h1>
                <div class="feature-item">
                  <img src="/assets/images/sell-home-fast/house.png" class="house" alt="house">
                  <p class="text-white text-left font-weight-bold house1">You Can sell your home As-is.<br>Get an instant
                    offer in 24 hours</p>
                </div>
                <div class="feature-item">
                  <img src="/assets/images/sell-home-fast/hand.png" class="hand" alt="hand">
                  <p class="text-white text-left font-weight-bold hand1">Not Only just sell Your Home in Days.<br>We Make
                    Sure You Do not Settle for Less.</p>
                </div>
              </div>
              <landing-two-banner-form
                :buildingoption="buildingoption"
                :bedoption="bedoption"
                :bathoption="bathoption"
                :parkingoption="parkingoption"
                :utm_source="utm_source"
                :utm_medium="utm_medium"
                :utm_campaign="utm_campaign"
                :utm_id="utm_id"
                :utm_content="utm_content"
              ></landing-two-banner-form>
            </div>
          </div>
        </div>
        <!-- ./banner section -->
      </div>
    </section>
    <!-- sell your home -->
    <section class="sell text-center">
        <landing-two-valuation-savings></landing-two-valuation-savings>
    </section>
    <!-- ./sell your home -->
    <!-- reviews -->
    <section class="header-extradiv">
        <landing-two-reviews></landing-two-reviews>
    </section>
    <!-- ./reviews -->
    <!-- footer -->
    <landing-two-footer
        :displaynumber="displaynumber"
        :infoemail="infoemail"
        :contactnumber="contactnumber"
        :linkedin="linkedin"
        :twitter="twitter"
        :facebook="facebook"
        :instagram="instagram"
        :youtube="youtube"
    ></landing-two-footer>
    <!-- ./footer -->
    <!-- Notification Component -->
    <notifications group="foo" />
    <!-- ./Notification Component -->
  </div>
</template>

<script>
import LandingTwoHeader from "../components/LandingTwoComponents/LandingTwoHeader";
import LandingTwoBannerForm from "../components/LandingTwoComponents/LandingTwoBannerForm.vue";
import LandingTwoValuationSavings from "../components/LandingTwoComponents/LandingTwoValuationSavings.vue";
import LandingTwoReviews from "../components/LandingTwoComponents/LandingTwoReviews.vue";
import LandingTwoFooter from "../components/LandingTwoComponents/LandingTwoFooter.vue";
export default {
  name: "LandingThree",
  props: {
    baseurl: {
      default: ""
    },
    displaynumber: {
      default: ""
    },
    infoemail: {
      default: ""
    },
    contactnumber: {
      default: ""
    },
    linkedin: {
      default: ""
    },
    twitter: {
      default: ""
    },
    facebook: {
      default: ""
    },
    instagram: {
      default: ""
    },
    youtube: {
      default: ""
    },
    buildingoption: {
      default: ""
    },
    bedoption: {
      default: ""
    },
    bathoption: {
      default: ""
    },
    parkingoption: {
      default: ""
    },
    utm_source: {
      default: ""
    },
    utm_medium: {
      default: ""
    },
    utm_campaign: {
      default: ""
    },
    utm_id: {
      default: ""
    },
    utm_content: {
      default: ""
    }
  },
  components: {
    "landing-two-header": LandingTwoHeader,
    "landing-two-banner-form": LandingTwoBannerForm,
    "landing-two-valuation-savings": LandingTwoValuationSavings,
    "landing-two-reviews": LandingTwoReviews,
    "landing-two-footer": LandingTwoFooter
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
    .home {
        font-size: 39px;
    }
    .sub-home {
        font-size: 16px;
    }
}
</style>