<template>
  <div>
      <div class="card builders-form-card">
          <div class="card-body">
              <p class="builder-form__subtitle">Add your virtual tour or Video Link</p>
              <div class="w-100 p-2 bg-gray my-4 rounded">
                <h5 class="text-center">Schedule A Virtual Walk Through</h5>
              </div>
              <div class="form-group text-center">
                <button class="btn btn-danger builders-form-prev-btn" @click.prevent="backToFormThree" :disabled="final_form_processing">BACK</button>
                <!-- <button class="btn btn-danger builders-form-next-btn" @click.prevent="skipForNow">SKIP</button> -->
                <button class="btn btn-danger builders-form-next-btn" @click.prevent="submitFormData" :disabled="final_form_processing">{{ final_form_processing ? 'PROCESSING...' : 'SKIP' }}</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "BuilderFormFive",
    props: {
      final_form_processing: {
        default: false
      }
    },
    methods: {
      backToFormThree() {
        this.$emit("backToFormThree", {});
      },
      skipForNow() {
        this.$emit("backToFormThree", {});
      },
      submitFormData() {
        this.$emit("submitFormData", {});
      }
    }
}
</script>
