<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container p-3">
      <a class="navbar-brand" :href="baseurl">
        <img src="/images/index_two_assets/logo.svg" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" :href="'mailto:'+infoemail">
              <img src="/images/index_two_assets/icons-email.png" />
              <span class="text-primary__redbuyers">{{ infoemail }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :href="'tel:'+contactnumber">
              <img src="/images/index_two_assets/icons-cell.png" />
              <span class="text-primary__redbuyers">{{ displaynumber }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "LandingTwoHeader",
  props: {
    baseurl: {
        default: ""
    },
    displaynumber: {
        default: ""
    },
    infoemail: {
        default: ""
    },
    contactnumber: {
        default: ""
    }
  }
};
</script>