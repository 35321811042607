<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step {{ currentStep }}
            </h5>
        </div>
        <div class="card-body">
            <!-- Drive Way -->
            <div class="form-group">
                <label for="drive_way">Drive Way</label>
                <input
                    class="form-control builders-form__input"
                    id="drive_way"
                    placeholder="Enter drive way"
                    v-model="drive_way"
                />
            </div>

            <!-- Select Total Parking Count -->
            <div class="form-group">
                <label for="total_parking_spaces">Total Parking Place</label>
                <select
                    class="form-control builders-form__input"
                    id="total_parking_spaces"
                    v-model="total_parking_spaces"
                >
                    <option :value="''">Select Total Parking Place</option>
                    <option
                        v-for="item in defaultPropertyOption"
                        :value="item"
                        :key="'total_parking_spaces-' + item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>

            <!-- Parking Included -->
            <div class="form-group">
                <label>Parking Included</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="parking_included_options"
                            id="parking_included_1"
                            value="1"
                            v-model="parking_included"
                        />
                        <label class="form-check-label" for="parking_included_1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="parking_included_options"
                            id="parking_included_2"
                            value="0"
                            v-model="parking_included"
                        />
                        <label class="form-check-label" for="parking_included_2"
                            >No</label
                        >
                    </div>
                </div>
            </div>

            <!-- Parking Charges -->
            <div class="form-group">
                <label for="parking_charges">Parking Charges</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="parking_charges"
                    placeholder="Enter parking charges"
                    v-model="parking_charges"
                />
            </div>

            <!-- Garage Type -->
            <div class="form-group">
                <label for="garage_type">Garage Type</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="garage_type"
                    placeholder="Enter garage type"
                    v-model="garage_type"
                />
            </div>

            <!-- Additional Monthly Fees -->
            <div class="form-group">
                <label for="additional_monthly_fees"
                    >Additional Monthly Fees</label
                >
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="additional_monthly_fees"
                    placeholder="Enter additional monthly fees"
                    v-model="additional_monthly_fees"
                />
            </div>

            <!-- All Inclusive Charges -->
            <div class="form-group">
                <label for="all_inclusive_charges">All Inclusive Charges</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="all_inclusive_charges"
                    placeholder="Enter all inclusive charges"
                    v-model="all_inclusive_charges"
                />
            </div>

            <!-- Constr Out -->
            <div class="form-group">
                <label for="constr_out">Constr Out</label>
                <input
                    class="form-control builders-form__input"
                    id="constr_out"
                    placeholder="Enter constr out"
                    v-model="constr_out"
                />
            </div>

            <!-- Irreg -->
            <div class="form-group">
                <label for="irreg">Irreg</label>
                <input
                    class="form-control builders-form__input"
                    id="irreg"
                    placeholder="Enter irreg"
                    v-model="irreg"
                />
            </div>

            <!-- Extras -->
            <div class="form-group">
                <label for="extras">Extras</label>
                <input
                    class="form-control builders-form__input"
                    id="extras"
                    placeholder="Enter extras"
                    v-model="extras"
                />
            </div>

            <!-- Link -->
            <div class="form-group">
                <label for="link">Link</label>
                <input
                    class="form-control builders-form__input"
                    id="link"
                    placeholder="Enter link"
                    v-model="link"
                />
            </div>

            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";
import { requiredValidationFields } from "../../../../utils";

const initFormFields = {
    drive_way: "",
    total_parking_spaces: "",
    parking_included: 0,
    parking_charges: "",
    garage_type: "",
    additional_monthly_fees: "",
    all_inclusive_charges: "",
    constr_out: "",
    irreg: "",
    extras: "",
    link: "",
};
const requiredFields = {};
export default {
    name: "FormStepEight",
    props: {
        propertyDetails: {
            default: {},
        },
        currentStep: {},
        isEdit: {
            default: false,
        },
    },
    data() {
        return {
            cities: {},
            communities: {},
            defaultPropertyOption: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            ...initFormFields,
        };
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key] || '';
                }
            }
        },
        next() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    this.currentStep + 1,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
    },
    validations: {
        // ...requiredValidationFields(requiredFields),
    },
};
</script>
