import axios from "axios";
/**
 * @param  {number} userId
 * @param  {string} type // 'new_construction' or 'exclusive' or 'idx' or 'vow'
 */
export const getUserFavPropertiesIds = async (userId, type) => {
    if(!userId || !type) return
    let endpoint = "/api/get-favorite-properties";
    const qs = `?user_id=${userId}&type=${type}&pagination=0`;
    if (["idx", "vow"].includes(type)) {
        endpoint =
            "https://valuation.santhoshmathew.com/api/v1/get-favorite-properties";
    }
    const res  = await axios.get(endpoint + qs)
    // return res?.data?.data[type].map(item=>item?.unique_id);
    return res?.data;
};
