<template>
    <div>
        <div class="card builders-form-card">
            <div class="card-body">
                <h3 class="builder-form__title">
                    Let's Collect Builder Information
                </h3>
                <p class="builder-form__subtitle">Enter Details</p>
                <div class="row my-3">
                    <div class="col-md-12">
                        <!-- <div class="text-sm">Select building status</div> -->
                        <div class="building-status-radio-group">
                            <div>
                                <input
                                    type="radio"
                                    name="building_status"
                                    v-model="building_status"
                                    value="Pre-construction"
                                    id="Pre-construction"
                                /><label
                                    for="Pre-construction"
                                    class="btn btn-outline-primary w-100 mb-0"
                                    >Pre-construction</label
                                >
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    name="building_status"
                                    v-model="building_status"
                                    value="Inventory"
                                    id="Inventory"
                                />
                                <label
                                    for="Inventory"
                                    class="btn btn-outline-primary w-100 mb-0"
                                    >Inventory</label
                                >
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    name="building_status"
                                    v-model="building_status"
                                    value="Assignment sale"
                                    id="Assignment sale"
                                />
                                <label
                                    for="Assignment sale"
                                    class="btn btn-outline-primary w-100 mb-0"
                                    >Assignment sale</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <select class="form-control" v-model="home_type">
                        <option value="">Select Home Type</option>
                        <option v-for="hType in homeTypes" :value="hType">
                            {{ hType }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <input
                        type="text"
                        v-model="builder_name"
                        class="form-control builders-form__input"
                        :class="builder_name_err ? 'is-invalid' : ''"
                        placeholder="Builder Name"
                    />
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="builder_name_err"
                    >
                        <strong>Builder name is required</strong>
                    </small>
                </div>
                <div class="form-group">
                    <input
                        type="text"
                        v-model="project_name"
                        class="form-control builders-form__input"
                        placeholder="Project Name"
                    />
                </div>
                <div class="form-group">
                    <input
                        type="text"
                        v-model="project_model"
                        class="form-control builders-form__input"
                        placeholder="Architect"
                    />
                </div>
                <div class="form-group">
                    <vue-autocomplete
                        :source="project_city_list"
                        class="form-control builders-form__input"
                        :class="project_city_err ? 'is-invalid' : ''"
                        v-model="project_city"
                        placeholder="Project City"
                        inputClass="builders-project-cities"
                        :initialValue="+project_city"
                        :initialDisplay="project_city_name"
                        @selected="citySelected"
                    ></vue-autocomplete>
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="project_city_err"
                    >
                        <strong>Project city is required</strong>
                    </small>
                </div>
                <div class="form-group">
                    <input type="hidden" v-model="builder_address" />
                    <vue-google-autocomplete
                        id="map"
                        ref="mapElement"
                        class="form-control builders-form__input"
                        placeholder="Builder address"
                        :country="['ca']"
                        @placechanged="getAddressData"
                    ></vue-google-autocomplete>
                </div>
                <div class="form-group">
                    <label for="community">Community</label>
                    <input
                        type="text"
                        class="form-control builders-form__input"
                        id="community"
                        placeholder="Enter community"
                        v-model="community"
                    />
                </div>
                <div class="form-group">
                    <v-select
                        label="name"
                        id="builder_plans"
                        :clearable="false"
                        :reduce="(option) => option.id"
                        class="form-control builders-form__input h-min-content"
                        placeholder="Select Plan"
                        :multiple="true"
                        v-model="builder_plans"
                        :options="prpoerty_plans"
                    />
                </div>
                <div class="form-group">
                    <button
                        class="btn btn-danger builders-form-btn"
                        @click.prevent="goToFormTwo"
                    >
                        CONTINUE
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

const initialFormFields = {
    building_status: "",
    home_type: "",
    builder_name: "",
    project_name: "",
    project_model: "",
    project_city: "",
    builder_address: "",
    builder_plans: [],
    relation: "",
    project_city_name: "",
    community: "",
};

export default {
    name: "BuilderFormOne",
    props: {
        cities: {
            default: "",
        },
        prpoerty_plans: {
            default: [],
        },
    },
    data() {
        return {
            ...initialFormFields,
            city_list: [],
            homeTypes: [
                "Detached",
                "Semi-detached",
                "Town House",
                "Condo Town House",
                "Condo Apartment",
                "Condominium",
                "Condo"
            ],
        };
    },
    created() {
        this.city_list = JSON.parse(this.cities);
        for (let key of Object.keys(initialFormFields)) {
            const prevValue = localStorage.getItem(key);
            if (key === "builder_plans") {
                this[key] = JSON.parse(prevValue);
            } else {
                this[key] = prevValue || initialFormFields[key];
            }
        }
    },
    validations: {
        builder_name: {
            required,
        },
        project_city: {
            required,
        },
    },
    computed: {
        builder_name_err() {
            if (this.$v.builder_name.$error) {
                return true;
            } else {
                return false;
            }
        },
        project_city_err() {
            if (this.$v.project_city.$error) {
                return true;
            } else {
                return false;
            }
        },
        project_city_list() {
            if (this.cities) {
                let tempArr = this.city_list.map((el) => {
                    return { id: el.id, name: el.city };
                });
                return tempArr;
            } else {
                return [];
            }
        },
    },
    methods: {
        goToFormTwo() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                // do nothing
            } else {
                this.$v.$reset();
                let payloadObj = {
                    home_type: this.home_type,
                    builder_name: this.builder_name,
                    project_name: this.project_name,
                    project_model: this.project_model,
                    project_city: this.project_city,
                    builder_address: this.builder_address,
                    community: this.community,
                    building_status: this.building_status,
                    project_city_name: this.project_city_name,
                };
                console.log("payloadObj", payloadObj);
                if (
                    this.builder_plans?.length &&
                    this.builder_plans.length > 0
                ) {
                    payloadObj.builder_plans = JSON.stringify(
                        this.builder_plans
                    );
                    payloadObj.relation = this.relation;
                }
                this.$emit("moveToFormTwo", payloadObj);
            }
        },
        getAddressData(addressData, placeResultData, id) {
            if (placeResultData?.formatted_address) {
                this.builder_address = placeResultData.formatted_address;
            }
        },
        citySelected(event) {
            if (event?.selectedObject?.name)
                this.project_city_name = event.selectedObject.name;
        },
    },
    watch: {
        builder_plans(planIds) {
            if (!planIds) return;
            let planNames = [];
            for (let planId of planIds) {
                const findPlan = this.prpoerty_plans.find(
                    (item) => item.id === planId
                );
                planNames.push(findPlan.name);
            }
            this.relation = planNames.join();
        },
    },
};
</script>

<style>
.builders-form__input > .autocomplete__box {
    border: none !important;
    padding-top: 6px !important;
}
.builders-form__input > .autocomplete__box > img {
    display: none !important;
}
.vs__dropdown-toggle {
    border: none !important;
}
.building-status-radio-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    gap: 10px;
}
.building-status-radio-group input {
    opacity: 0;
    position: absolute;
}
.building-status-radio-group input:checked + label {
    background-color: #0061df;
    color: #fff;
}
.building-status-radio-group > * {
    position: relative;
}
.h-min-content {
    height: min-content;
}
</style>
