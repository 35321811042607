<template>
    <!-- Skeleton Home Card -->
    <div class="skeleton-card border">
        <div class="img skeleton"></div>
        <div class="address skeleton"></div>
        <div class="address skeleton"></div>
        <div class="feature-grid border-bottom border-top">
          <div class="skeleton"></div>
          <div class="skeleton"></div>
          <div class="skeleton"></div>
          <div class="skeleton"></div>
        </div>
        <div class="price skeleton"></div>
    </div>
    <!-- Skeleton Home End -->
</template>

<script>
export default {
    name: "SkeletonLoader"
}
</script>