<template>
    <section class="filter-top-sec">
        <div class="filter-top-container container py-3">
            <!-- Type -->
            <div class="filter-item">
                <div class="dropdown type-filter-dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonForType"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="filter-active-circle"
                            v-if="home_type"
                        ></span>
                        {{ home_type ? home_type : "All Type" }}
                    </button>
                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButtonForType"
                    >
                        <div class="dropdown-header border-bottom">
                            <label class="font-weight-bold"
                                >Type of House</label
                            >
                        </div>
                        <div>
                            <a
                                class="dropdown-item"
                                :class="{
                                    active: isActive(home_type, item),
                                }"
                                href="javascript:void(0)"
                                v-for="item in homeTypes"
                                :key="item"
                                @click="handleHomeTypeChange(item)"
                            >
                                {{ item }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Building Status -->
            <div class="filter-item">
                <div class="dropdown style-filter-dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonForStyle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="filter-active-circle"
                            v-if="property_type"
                        ></span>
                        {{ property_type ? property_type : "All Building" }}
                    </button>
                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButtonForStyle"
                    >
                        <div class="dropdown-header border-bottom">
                            <label class="font-weight-bold"
                                >Building Status</label
                            >
                        </div>
                        <div>
                            <a
                                class="dropdown-item"
                                :class="{
                                    active: isActive(property_type, item),
                                }"
                                href="javascript:void(0)"
                                v-for="item in buildingStatusList"
                                :key="item"
                                @click="handleBuildingStatusChange(item)"
                            >
                                {{ item }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Style -->
            <!-- <div class="filter-item">
                <div class="dropdown style-filter-dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonForStyle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="filter-active-circle"
                            v-if="selected_style"
                        ></span>
                        {{ selected_style ? selected_style : "All Style" }}
                    </button>
                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButtonForStyle"
                    >
                        <div class="dropdown-header border-bottom">
                            <label class="font-weight-bold"
                                >Style of House</label
                            >
                        </div>
                        <div>
                            <a
                                class="dropdown-item"
                                :class="{
                                    active: isActive(selected_style, item),
                                }"
                                href="javascript:void(0)"
                                v-for="item in styles"
                                :key="item"
                                @click="handleHomeStyleChange(item)"
                            >
                                {{ item }}
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Bed -->
            <div class="filter-item">
                <div class="dropdown bed-filter-dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonForBed"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="filter-active-circle"
                            v-if="bedroom"
                        ></span>
                        {{ bedroom ? bedroom + " Beds" : "All Beds" }}
                    </button>
                    <div
                        class="dropdown-menu pb-0"
                        aria-labelledby="dropdownMenuButtonForBed"
                    >
                        <div class="dropdown-header border-bottom">
                            <label class="font-weight-bold"
                                >Select Bedrooms</label
                            >
                        </div>
                        <div
                            class="btn-group m-3"
                            role="group"
                            aria-label="Bedrooms group"
                        >
                            <button
                                button
                                type="button"
                                :class="{ active: isActive(bedroom, item) }"
                                class="btn btn-outline-dark"
                                v-for="item in bedList"
                                :key="item"
                                @click="handleBedChange(item)"
                            >
                                {{ item }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bath -->
            <div class="filter-item">
                <div class="dropdown bath-filter-dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonForBath"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="filter-active-circle"
                            v-if="bathroom"
                        ></span>
                        {{ bathroom ? bathroom + " Baths" : "All Baths" }}
                    </button>
                    <div
                        class="dropdown-menu pb-0"
                        aria-labelledby="dropdownMenuButtonForBath"
                    >
                        <div class="dropdown-header border-bottom">
                            <label class="font-weight-bold"
                                >Select Bathrooms</label
                            >
                        </div>
                        <div
                            class="btn-group m-3"
                            role="group"
                            aria-label="Bathrooms group"
                        >
                            <button
                                button
                                type="button"
                                :class="{ active: isActive(bathroom, item) }"
                                class="btn btn-outline-dark"
                                v-for="item in bathList"
                                :key="item"
                                @click="handleBathChange(item)"
                            >
                                {{ item }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Price Filter -->
            <div class="filter-item">
                <div class="dropdown bath-filter-dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonForPrice"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="filter-active-circle"
                            v-if="selectedPriceOption"
                        ></span>
                        {{
                            selectedPriceOption
                                ? selectedPriceOption
                                : "All Price"
                        }}
                    </button>
                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButtonForPrice"
                    >
                        <div class="dropdown-header border-bottom">
                            <label class="font-weight-bold">Price Range</label>
                        </div>
                        <div>
                            <a
                                class="dropdown-item"
                                :class="{
                                    active: isActive(selectedPriceOption, item),
                                }"
                                href="javascript:void(0)"
                                v-for="item in priceList"
                                :key="item"
                                @click="handlePriceChange(item)"
                            >
                                {{ item }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Recommended -->
            <div class="filter-item ml-auto d-flex">
                <div class="dropdown bath-filter-dropdown recommended-dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonForFilter"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="filter-active-circle"
                            v-if="sortByFilter"
                        ></span>
                        {{ sortByFilter ? sortByFilter : "Most Recent" }}
                    </button>
                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButtonForFilter"
                    >
                        <div class="dropdown-header border-bottom">
                            <label class="font-weight-bold">Sort By</label>
                        </div>
                        <div>
                            <a
                                class="dropdown-item"
                                :class="{
                                    active: isActive(sortByFilter, item),
                                }"
                                href="javascript:void(0)"
                                v-for="item in sortByVal"
                                :key="item"
                                @click="handleSortFilter(item)"
                            >
                                {{ item }}
                            </a>
                        </div>
                    </div>
                </div>
                <button class="btn btn-outline-primary ml-2" @click="$emit('openShareModal')" v-if="displayShareButton">Share <i class="fas fa-share"></i></button>
                <button class="btn btn-outline-primary ml-2" @click="$emit('sendPropertyEmail')" v-if="!displayShareButton">Send <i class="fas fa-paper-plane"></i></button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "SearchConstructionFilter",
    emits:['openShareModal', 'sendPropertyEmail'],
    props: {
        displayShareButton: true
    },
    data: () => ({
        homeTypes: [
            "All",
            "Detached",
            "Semi-detached",
            "Town House",
            "Condo Town House",
            "Condo Apartment",
        ],
        buildingStatusList:[
            "All",
            "Pre-construction",
            "Inventory",
            "Assignment sale"
        ],
        styles: [
            "All",
            "2-Storey",
            "3-Storey",
            "Bungalow",
            "Split level",
            "Other",
        ],
        bedList: ["0+", "1+", "2+", "3+", "4+", "5+"],
        bathList: ["0+", "1+", "2+", "3+", "4+", "5+"],
        priceList: [
            "All",
            "0-500",
            "500-1M",
            "1M-1.5M",
            "1.5M-2M",
            "2M-3M",
            "3M+",
        ],
        sortByVal: [
            "Recommended",
            "Most Recent",
            "Oldest",
            "Most Expensive",
            "Least Expensive",
            "Most Bedroom",
        ],
        home_type: "",
        property_type: "",
        bedroom: "",
        bathroom: "",
        min_price: "",
        max_price: "",
        sortBy: "created_at",
        sortDir: "desc",
        sortByFilter: "Most Recent",
        selectedPriceOption: "",
        skeletonList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        type_of_listing: "Sale",
        min_bedrooms: "",
        max_bedrooms: "",
        min_bathrooms: "",
        max_bathrooms: "",
        square_footage: "",
        selected_style: "",
        search_mls_only: 1,
        last_week: 0,
    }),
    methods: {
        handleHomeTypeChange(val) {
            if (val === "All") {
                this.home_type = "";
            } else {
                this.home_type = val;
            }
            this.$emit("onFilterChange", { home_type: this.home_type });
        },
        handleHomeStyleChange(val) {
            if (val === "All") {
                this.selected_style = "";
            } else {
                if (val == "Split level") {
                    this.selected_style = "Multi-Level";
                } else {
                    this.selected_style = val;
                }
            }
        },
        handleBuildingStatusChange(val) {
            if (val === "All") {
                this.property_type = "";
            } else {
                this.property_type = val;
            }
            this.$emit("onFilterChange", { property_type: this.property_type });
        },
        handleBedChange(val) {
            if (val === "0+") {
                this.bedroom = "";
            } else {
                this.bedroom = val;
            }
            this.$emit("onFilterChange", { bedroom: this.bedroom });
        },
        handleBathChange(val) {
            if (val === "0+") {
                this.bathroom = "";
            } else {
                this.bathroom = val;
            }
            this.$emit("onFilterChange", { bathroom: this.bathroom });
        },
        handlePriceChange(val) {
            if (val === "All") {
                this.min_price = "All";
                this.max_price = "All";
                this.selectedPriceOption = "";
            } else {
                const [min, max = ""] = val.split("-");
                // Min
                if (min == 0) {
                    this.min_price = 0;
                } else if (min == 500) {
                    this.min_price = 500000;
                } else if (min == "1M") {
                    this.min_price = 1000000;
                } else if (min == "1.5M") {
                    this.min_price = 1500000;
                } else if (min == "2M") {
                    this.min_price = 2000000;
                } else if (min == "3M") {
                    this.min_price = 3000000;
                } else {
                    this.min_price = "";
                }
                // Max
                if (max == 500) {
                    this.max_price = 500000;
                } else if (max == "1M") {
                    this.max_price = 1000000;
                } else if (max == "1.5M") {
                    this.max_price = 1500000;
                } else if (max == "2M") {
                    this.max_price = 2000000;
                } else if (max == "3M") {
                    this.max_price = 3000000;
                } else {
                    this.max_price = "";
                }
                // this.min_price = min;
                // this.max_price = max;
                this.selectedPriceOption = val;
            }
            this.$emit("onFilterChange", { max_price: this.max_price, min_price: this.min_price, });
        },
        isActive(selectedVal, val) {
            if (selectedVal === val) {
                return true;
            } else if (val === "All" && !selectedVal) return true;
        },
        handleSortFilter(val) {
            if (val === "Most Recent") {
                this.sortBy = "created_at";
                this.sortDir = "desc";
            } else if (val === "Oldest") {
                this.sortBy = "created_at";
                this.sortDir = "asc";
            } else if (val === "Most Expensive") {
                this.sortBy = "list_price";
                this.sortDir = "desc";
            } else if (val === "Least Expensive") {
                this.sortBy = "list_price";
                this.sortDir = "asc";
            } else if (val === "Most Bedroom") {
                this.sortBy = "bedroom";
                this.sortDir = "desc";
            } else {
                this.sortBy = "";
                this.sortDir = "";
            }
            // console.log(val);
            this.sortByFilter = val;
            this.$emit("onFilterChange", { sortBy: this.sortBy, sortDir: this.sortDir });
        }
    },
};
</script>
<style lang="scss">
// @import "../../pages/";
</style>
