<template>
  <div class="col-lg-6 col-xl-4">
    <div class="property_feature_listings_box_item">
      <div class="property_feature_listings_box_item_image">
        <img
          :src="thumbnail"
          @error="handleError"
          class="img-fluid"
          alt=""
        />
        <span class="type_point">{{ property_type }}</span>
        <span class="sale">{{ saleorlease }}</span>
        <span class="image"
          ><img
            src="/images/camera.png"
            class="img-fluid"
          />{{ total_photos }}</span
        >
        <span class="video"
          ><img
            src="/images/play.png"
            class="img-fluid"
          />{{ total_videos }}</span
        >
      </div>
      <div class="property_feature_listings_box_item_content">
        <span class="location">{{ address }}</span>
      </div>
      <div class="property_feature_listings_box_item_bottomcontent">
        <span class="bedroom"
          ><img
            src="/images/double-bed.png"
            class="img-fluid"
          />{{ beds }} Beds</span
        >
        <span class="bathroom"
          ><img
            src="/images/fbath.png"
            class="img-fluid"
          />{{ bathrooms }} Baths</span
        >
        <span class="garage"
          ><img
            src="/images/garage.png"
            class="img-fluid"
          />{{ garage }} Garage</span
        >
        <span class="measure"
          ><img
            src="/images/measure.png"
            class="img-fluid"
          />{{ sqft }} Sq Ft</span
        >
      </div>
      <div class="property_feature_listings_box_item_price">
        <span class="price">${{ price }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdxPropertyCard",
  props: {
      beds: {
          default: 0
      },
      bathrooms: {
          default: 0
      },
      sqft: {
          default: '0'
      },
      garage: {
          default: '0'
      },
      price: {
          default: '0'
      },
      address: {
          default: ''
      },
      property_type: {
          default: 'new'
      },
      saleorlease: {
          default: 'SALE'
      },
      thumbnail: {
          default: '/assets/images/no-image-available.png'
      },
      total_photos: {
          default: '0'
      },
      total_videos: {
          default: '0'
      }
  },
  methods: {
      handleError(event) {
          event.preventDefault();
          event.target.src='/assets/images/no-image-available.png'
      }
  }
};
</script>