<template>
  <section class="our-listing pb30-991">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pt70">
          <div class="listing_sidebar dn db-lg">
            <div class="sidebar_content_details style3">
              <!-- <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a> -->
              <div
                class="sidebar_listing_list style2 mobile_sytle_sidebar mb0"
                style="margin-top: 100px"
              >
                <div class="siderbar_widget_header">
                  <h4 class="title mb0">
                    Find Your New Home<a
                      class="filter_closed_btn float-right"
                      href="javascript:void(0)"
                      ><small>x</small><span class="flaticon-close"></span
                    ></a>
                  </h4>
                </div>
                <div class="sidebar_advanced_search_widget">
                  <ul class="sasw_list mb0">
                    <li class="search_area">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control form_control"
                          placeholder="Enter Keyword"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="search_option_two">
                        <div class="sidebar_select_options">
                          <select class="selectpicker w100 show-tick">
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="search_option_two">
                        <div class="sidebar_select_options">
                          <select class="selectpicker w100 show-tick">
                            <option>Type</option>
                            <option value="Apartment">Apartment</option>
                            <option value="Condo">Condo</option>
                            <option value="Studio">Studio</option>
                            <option value="Villa">Villa</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="search_option_two">
                        <div class="sidebar_select_options">
                          <select class="selectpicker w100 show-tick">
                            <option>All Cities</option>
                            <option value="London">London</option>
                            <option value="NewYork">New York</option>
                            <option value="Paris">Paris</option>
                            <option value="Istanbul">Istanbul</option>
                            <option value="Amsterdam">Amsterdam</option>
                            <option value="Rome">Rome</option>
                            <option value="LogAngeles">Log Angeles</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="search_option_two">
                        <div class="sidebar_select_options">
                          <select class="selectpicker w100 show-tick">
                            <option>Bedrooms</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li class="search_area">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control form_control"
                          placeholder="Min. Area"
                        />
                      </div>
                    </li>
                    <li class="search_area">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control form_control"
                          placeholder="Max. Area"
                        />
                      </div>
                    </li>
                    <li class="search_area">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control form_control"
                          placeholder="Property ID"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="sidebar_priceing_slider_mobile">
                        <div class="wrapper">
                          <p class="mb0">Price Range</p>
                          <div class="mt20 ml10" id="slider"></div>
                          <span id="slider-range-value1"></span>
                          <span id="slider-range-value2"></span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="sidebar_accordion_widget mt40">
                        <div id="accordion2" class="panel-group">
                          <div class="panel">
                            <div class="panel-heading">
                              <h4 class="panel-title other_fet">
                                <a
                                  href="#panelBodyRating2"
                                  class="accordion-toggle link text-thm"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  ><i class="icon fa fa-plus"></i> Other
                                  Features</a
                                >
                              </h4>
                            </div>
                            <div
                              id="panelBodyRating2"
                              class="panel-collapse collapse"
                            >
                              <div class="panel-body">
                                <ul class="ui_kit_checkbox selectable-list">
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck10"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck10"
                                        >Air Conditioning</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck11"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck11"
                                        >Barbeque</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck12"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck12"
                                        >Dryer</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck13"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck13"
                                        >Gym</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck14"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck14"
                                        >Laundry</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck15"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck15"
                                        >Lawn</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck16"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck16"
                                        >Microwave</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck17"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck17"
                                        >Outdoor Shower</label
                                      >
                                    </div>
                                  </li>
                                  <li class="mb0">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck18"
                                      />
                                      <label
                                        class="custom-control-label mb0"
                                        for="customCheck18"
                                        >Refrigerator</label
                                      >
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="search_option_button text-center mt25">
                        <button
                          type="submit"
                          class="btn btn-block btn-thm mb25"
                        >
                          Search
                        </button>
                        <ul class="mb0">
                          <li class="list-inline-item mb0">
                            <a href="javascript:void(0)"
                              ><span class="vam flaticon-return mr10"></span>
                              Reset Search</a
                            >
                          </li>
                          <li class="list-inline-item mb0 ml30">
                            <a href="javascript:void(0)"
                              ><span
                                class="vam flaticon-heart-shape-outline mr10"
                              ></span>
                              Saved Search</a
                            >
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <idx-listings-banner></idx-listings-banner>
        </div>
        <div class="col-lg-12">
          <div class="dn db-lg mb30-md text-center">
            <div id="main2">
              <a id="open2" class="filter_open_btn btn-thm" href="javascript:void(0)"
                >Show Filter <span class="flaticon-setting-lines ml10"></span
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3">
          <idx-listings-search-filter></idx-listings-search-filter>
        </div>
        <div class="col-xl-9">
          <div class="row">
            <idx-listings-header></idx-listings-header>
          </div>
          <div class="row">
            <!-- Property Card -->
            <idx-property-card></idx-property-card>
            <!-- ./Property Card -->
            <!-- Paginations -->
            <idx-paginations></idx-paginations>
            <!-- ./Paginations -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IdxPaginations from "../components/IdxSearchListings/IdxPaginations.vue";
import IdxPropertyCard from "../components/IdxSearchListings/IdxPropertyCard.vue";
import IdxListingsHeader from "../components/IdxSearchListings/IdxListingsHeader.vue";
import IdxListingSearchFilter from "../components/IdxSearchListings/IdxListingSearchFilter.vue";
import IdxListingBanner from "../components/IdxSearchListings/IdxListingBanner.vue";
export default {
  name: "IdxSearchListing",
  components: {
      "idx-paginations": IdxPaginations,
      "idx-property-card": IdxPropertyCard,
      "idx-listings-header": IdxListingsHeader,
      "idx-listings-search-filter": IdxListingSearchFilter,
      "idx-listings-banner": IdxListingBanner
  }
};
</script>