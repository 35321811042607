<template>
    <div>
        {{ total_fav }}
    </div>
</template>

<script>
export default {
    name: "UserFavouriteCount",
    data() {
        return {
            total_fav:0,
        };
    },
    methods: {
        getIdxFav() {
            axios
                .get("/get-user-favourite-properties-count")
                .then((response) => {
                    this.total_fav = response.data;
                    console.log(response.data);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    },
    mounted() {
        this.getIdxFav();
    },
    computed: {
    },
};
</script>