<template>
    <div class="exclusive-properties">
        <form-step-one
            v-if="currentStep === 1"
            :propertyDetails="formData"
            @persistAndNext="persistAndNext"
            @back="back"
            :cityList="cityList"
            :communityList="communityList"
        />
        <form-step-two
            v-if="currentStep === 2"
            :propertyDetails="formData"
            @persistAndNext="persistAndNext"
            @back="back"
            :communityList="communityList"
        />
        <form-step-three
            v-if="currentStep === 3"
            :propertyDetails="formData"
            @persistAndNext="persistAndNext"
            @back="back"
        />
        <form-step-four
            v-if="currentStep === 4"
            :propertyDetails="formData"
            @back="back"
            @finalSubmit="finalSubmit"
        />
    </div>
</template>

<script>
import axios from "axios";
import FormStepOne from "../../components/Admin/ExclusiveProperties/forms/FormStepOne.vue";
import FormStepTwo from "../../components/Admin/ExclusiveProperties/forms/FormStepTwo.vue";
import FormStepThree from "../../components/Admin/ExclusiveProperties/forms/FormStepThree.vue";
import FormStepFour from "../../components/Admin/ExclusiveProperties/forms/FormStepFour.vue";
import Swal from "sweetalert2";
export default {
    name: "AddExclusiveProperties",
    props: {
        all_cities: {
            default: [],
        },
        all_communities: {
            default: [],
        },
        created_by: {
            default: "",
        },
    },
    data() {
        return {
            cities: [],
            communities: [],
            currentStep: 1,
            formData: {},
        };
    },
    components: {
        "form-step-one": FormStepOne,
        "form-step-two": FormStepTwo,
        "form-step-three": FormStepThree,
        "form-step-four": FormStepFour,
    },
    mounted() {
        if (this.all_cities) {
            this.cities = JSON.parse(this.all_cities);
        }
        if (this.all_communities) {
            this.communities = JSON.parse(this.all_communities);
        }
    },
    computed: {
        cityList() {
            return this.cities.map((el) => ({ id: el.city, name: el.city }));
        },
        communityList() {
            return this.communities.map((el) => ({
                id: el.community,
                name: el.community,
            }));
        },
    },
    methods: {
        nextStep(step) {
            this.currentStep = step;
        },
        back() {
            this.currentStep = this.currentStep - 1;
        },
        persistAndNext(nextStep, data) {
            this.nextStep(nextStep);
            this.formData = { ...this.formData, ...data };
        },
        finalSubmit(data) {
            this.formData = { ...this.formData, ...data };
            console.log("Final Submit", this.formData);
            const formData = new FormData();
            formData.append("created_by", this.created_by);
            
            for (let [key, value] of Object.entries(this.formData)) {
                if (key === "attachments") {
                    let fileCount = 0;
                    for (let file of value) {
                        formData.append(`attachments[${fileCount}]`, file);
                        fileCount++;
                    }
                } else formData.append(key, value);
            }
            axios
                .post("/api/store-exclusive-properties", formData)
                .then((response) => {
                    this.currentStep = 1;
                    this.formData = {};
                    Swal.fire({
                        title: response.data.message,
                        icon: "success",
                        timer: 3000,
                    });
                })
                .catch((error) => {
                    // console.error(error?.response?.data);
                    const restObj = {};
                    if (error?.response?.data?.errors) {
                        restObj.html = "";
                        Object.values(error?.response?.data?.errors)?.forEach(
                            (err) => {
                                restObj.html += `<p style="color:red">${err[0]}</p>`;
                            }
                        );
                    }
                    if (error?.response?.data?.message) {
                        Swal.fire({
                            title: error?.response.data.message,
                            icon: "error",
                            timer: 3000,
                            ...restObj,
                        });
                    }
                })
                .finally((res) => {});
        },
    },
};
</script>
<style>
.exclusive-properties {
    max-width: min(600px, 100%);
    margin: 0 auto;
}
</style>
