<template>
  <div class="builders-form-wrapper">
    <div
      class="builders-form-container"
      :style="currentForm==5 ? 'height:500px;min-height:500px;' : ''"
      v-if="currentForm!=0"
    >
      <div class="container">
        <builder-form-one
          :cities="cities"
          :prpoerty_plans="prpoerty_plans"
          v-if="currentForm==1"
          @moveToFormTwo="saveFormOneData"
        />
        <builder-form-two
          v-else-if="currentForm==2"
          @moveToFormThree="saveFormTwoData"
          @backToFormOne="currentForm=1"
        />
        <builder-form-three
          v-else-if="currentForm==3"
          @goBackToFormTwo="currentForm=2"
          @submitFormData="submitBuildersForm"
          @moveToFormFour="moveToFormFour"
          @moveToFormFive="moveToFormFive"
          :final_form_processing="final_form_processing"
        />
        <builder-form-four
          v-else-if="currentForm==4"
          @backToFormThreeFromFour="currentForm=3"
          @submitFormFour="currentForm=1"
          @submitFormData="submitBuildersForm"
          :final_form_processing="final_form_processing"
        />
        <builder-form-five
          v-else-if="currentForm==5"
          @backToFormThree="backToFormThree"
          @skipForNow="skipForNow"
          @submitFormData="saveBuildersFormResponse"
          :final_form_processing="final_form_processing"
        />
      </div>
    </div>
    <div class="container my-4 p-4" v-if="currentForm==0">
      <h3 class="builder-form__title">Your request has been submitted. Thanks for uploading images.</h3>
      <p class="pl-40">Here’s what to expect next:</p>
      <ul class="builder-form__final-status">
        <li>RedBuyers will verify your information, and our sales associate will contact you if any information is missing. This is typically a take 24 hours.</li>
        <li>Once we Verify all the information, we will upload in the website. If yopu have any changes, you can notify us, we will be happy to change it.</li>
        <li>You have options to select other services like our professional photography, 3D virtual tours, and more. Please let our associate know what service you need, and he will coordinate that with you.</li>
      </ul>
    </div>
    <!-- Alert Success -->
    <sweet-modal icon="success" ref="successInfo">{{ successMessage }}</sweet-modal>
    <!-- ./Alert Success -->
    <sweet-modal icon="error" ref="errorInfo">{{ errorMessage }}</sweet-modal>
  </div>
</template>

<script>
import BuilderFormOne from "../components/BuilderInfoForms/BuilderFormOne.vue";
import BuilderFormTwo from "../components/BuilderInfoForms/BuilderFormTwo.vue";
import BuilderFormThree from "../components/BuilderInfoForms/BuilderFormThree.vue";
import BuilderFormFour from "../components/BuilderInfoForms/BuilderFormFour.vue";
import BuilderFormFive from "../components/BuilderInfoForms/BuilderFormFive.vue";
export default {
  name: "BuildersInfoForm",
  props: {
    cities: {
      default: ""
    },
    builder_formatted_data: {
      default: ""
    },
    user_type: {
      default: "admin"
    }
  },
  data() {
    return {
      prpoerty_plans: [],
      currentForm: 1,
      // form one data
      home_type: "",
      builder_name: "",
      project_name: "",
      project_model: "",
      project_city: "",
      builder_address: "",
      community: "",
      builder_plans: [],
      building_status: [],
      // form two data
      price: "",
      completed_at: "",
      bed: "",
      bath: "",
      sqft: "",
      price_per_sqft: "",
      deposit_structure: "",
      amnities: "",
      virtual_tour: "",
      project_description: "",
      community_description: "",
      // form 3 data
      user_response: [],
      final_form_processing: false,
      successMessage: "Congrats! Your request has been submitted.",
      errorMessage: "Oops! An error occurred."
    };
  },
  mounted() {
    this.prpoerty_plans = JSON.parse(this.builder_formatted_data);
    console.log(this.user_type);
  },
  components: {
    "builder-form-one": BuilderFormOne,
    "builder-form-two": BuilderFormTwo,
    "builder-form-three": BuilderFormThree,
    "builder-form-four": BuilderFormFour,
    "builder-form-five": BuilderFormFive
  },
  methods: {
    saveFormOneData(payload) {
      let formOneData = payload;
      this.building_status = payload?.building_status;
      this.home_type = payload?.home_type;
      this.builder_name = payload?.builder_name;
      this.project_name = payload?.project_name;
      this.project_model = payload?.project_model;
      this.project_city = payload?.project_city;
      this.project_city_name = payload?.project_city_name;
      this.builder_address = payload?.builder_address;
      this.community = payload?.community;
      if (payload?.builder_plans) {
        this.builder_plans = JSON.parse(payload.builder_plans);
        localStorage.setItem("builder_plans", payload.builder_plans);
        localStorage.setItem("relation", payload.relation);
      }
      // set items in local storage
      localStorage.setItem("home_type", this.home_type);
      localStorage.setItem("builder_name", this.builder_name);
      localStorage.setItem("project_name", this.project_name);
      localStorage.setItem("project_model", this.project_model);
      localStorage.setItem("project_city", this.project_city);
      localStorage.setItem("project_city_name", this.project_city_name);
      localStorage.setItem("builder_address", this.builder_address);
      localStorage.setItem("community", this.community);
      localStorage.setItem("building_status", this.building_status);
      // set current form 2
      this.currentForm = 2;
    },
    saveFormTwoData(payload) {
      let formTwoData = payload;
      this.price = formTwoData.price;
      this.completed_at = formTwoData.completed_at;
      this.bed = formTwoData.bed;
      this.bath = formTwoData.bath;
      this.sqft = formTwoData.sqft;
      this.price_per_sqft = formTwoData.price_per_sqft;
      this.deposit_structure = formTwoData.deposit_structure;
      this.amnities = formTwoData.amnities;
      this.virtual_tour = formTwoData.virtual_tour;
      this.project_description = formTwoData.project_description;
      this.community_description = formTwoData.community_description;
      // set items in local storage
      localStorage.setItem("price", this.price);
      localStorage.setItem("completed_at", this.completed_at);
      localStorage.setItem("bed", this.bed);
      localStorage.setItem("bath", this.bath);
      localStorage.setItem("sqft", this.sqft);
      localStorage.setItem("price_per_sqft", this.price_per_sqft);
      localStorage.setItem("deposit_structure", this.deposit_structure);
      localStorage.setItem("amnities", this.amnities);
      localStorage.setItem("virtual_tour", this.virtual_tour);
      localStorage.setItem("project_description", this.project_description);
      localStorage.setItem("community_description", this.community_description);
      // set current form 3
      this.currentForm = 3;
    },
    moveToFormFour(payload) {
      this.user_response = payload.user_response;
      // set items in local storage
      localStorage.setItem("user_response", this.user_response);
      // set current form 4
      this.currentForm = 4;
    },
    saveBuildersFormResponse(payload) {
      this.successMessage = "Congrats! Your request has been submitted.";
      this.$refs.successInfo.open();
      this.currentForm = 0;
      localStorage.clear();
    },
    moveToFormFive: async function(payload) {
      this.user_response = payload.user_response;
      // set items in local storage
      localStorage.setItem("user_response", this.user_response);
      await this.submitBuildersForm();
    },
    backToFormThree(payload) {
      this.currentForm = 3;
    },
    skipForNow(payload) {
      this.currentForm = 1;
    },
    allStorage() {
      let values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

      while (i--) {
        values.push({ key: keys[i], value: localStorage.getItem(keys[i]) });
      }
      return values;
    },
    submitBuildersForm() {
      this.final_form_processing = true;
      let buildersParams = this.allStorage();
      const formData = new FormData();
      if (Array.isArray(buildersParams)) {
        buildersParams.forEach(el => {
          console.log("EL",el)
          if(el.key === 'amnities' && el.value){
            try{
              const parsedAmnities = JSON.parse(el.value)
              console.log("amnities",parsedAmnities)
              for(let i=0; i< parsedAmnities?.length; i++){
                formData.append(`amnities[${i}]`, parsedAmnities[i]);
              }
            }catch(err){
              console.log("err",err)
            }
          }else{
            formData.append(el.key, el.value);
          }
        });
      }
      formData.append("user_type", this.user_type);
      axios
        .post("/builders/submit-builder-property", formData)
        .then(response => {
          this.currentForm = 5;
          let message = "";
          let modal = this.$refs.successInfo;
          if (response?.data?.message) {
            message = response.data.message;
            this.successMessage = message;
            localStorage.clear();
          }
          modal.open();
        })
        .catch(error => {
          let message = "";
          let modal = this.$refs.errorInfo;
          if (error?.response?.data?.message) {
            message = response.data.message;
            this.errorMessage = message;
          }
          modal.open();
        })
        .finally(res => {
          this.final_form_processing = false;
          this.currentForm = 0;
        });
    }
  }
};
</script>
