<template>
    <div
        class="modal fade ownership-info-modal-modal"
        id="ownershipInfoModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ownershipInfoModal"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-dialog-centered ownership-info-modal-modal__dialog"
            role="document"
        >
            <div class="modal-content">
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close_co_ownersip_modal"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="ownership-info-modal-modal__main pt-1">
                    <div class="align-self-center">
                        <img
                            src="/property_detail_asset/image/ownership-modal-banner.png"
                            alt=""
                        />
                    </div>
                    <h4 class="text-primary text-center mb-0">
                        Pricing details for 1/8 share in Co-Ownership
                    </h4>
                    <p class="text-center mb-0 mx-sm-4">
                        We co-invest with you and find other qualified
                        investors. We provide a makeover and sell the property,
                        or hold it till we can make our estimated profit.
                    </p>

                    <div class="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <td colspan="2"  class="text-center font-weight-bold">(Example)</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="d-block font-weight-bold">
                                            Purchase Price for 1/8 share
                                        </span>
                                        <span
                                            class="d-block font-weight-light"
                                        >
                                            Whole house Price: ${{ list_price | round_off | numberWithCommas }}
                                        </span>
                                    </td>
                                    <td class="text-right">${{ one_by_eight | round_off | numberWithCommas }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-block font-weight-bold">
                                            Closing, Renovations, & Carrying
                                            costs
                                        </span>
                                        <span
                                            class="d-block font-weight-light"
                                        >
                                            12% of total cost (estimated)- Your
                                            cost = 1/8 of 12%
                                        </span>
                                    </td>
                                    <td class="text-right">${{ one_by_tweleve | round_off | numberWithCommas }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h6>Total 1/8 Ownership</h6>
                                        <span
                                            class="d-block font-weight-light"
                                        >
                                            Whole Property Value: ${{ complete_price | round_off | numberWithCommas }}
                                        </span>
                                    </td>
                                    <td class="text-right">${{ total_price | round_off | numberWithCommas }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center">
                                        <span
                                            class="font-weight-light d-block mb-2"
                                        >
                                            Not all the homes are eligible for
                                            co-ownership,
                                        </span>
                                        <div class="d-flex align-items-center justify-content-center">
                                            <h6 class="font-weight-normal mr-3 mb-0">
                                                Have questions?
                                            </h6>
                                            <button
                                                class="btn btn-sm btn-primary"
                                                @click="schedule_form_new"
                                            >
                                                Learn More
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="d-block font-weight-bold">
                                            Min. Return of Investment (ROI)
                                        </span>
                                        <span
                                            class="d-block font-weight-light"
                                        >
                                            25% min ROI. Duration 6 months to
                                            2years.
                                        </span>
                                    </td>
                                    <td class="text-right">${{ roi_price | round_off | numberWithCommas }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OwnershipInfoModal",
    props: ["price"],
    data: () => ({
        list_price:''
    }),
    mounted() {
        this.list_price = this.price;
    },
    computed: {
        one_by_eight() {
            return this.list_price / 8;
        },
        one_by_tweleve() {
            let percent = this.list_price*12/100;
            return percent/8;
        },
        total_price() {
            return this.one_by_eight + this.one_by_tweleve;
        },
        complete_price() {
            return this.total_price*8;
        },
        roi_price() {
            let new_price = this.total_price * 25/100;
            return this.total_price + new_price;
        }
    },
    methods: { 
        schedule_form_new() {
            sessionStorage.setItem('lead_type', 'investor');
            document.getElementById('close_co_ownersip_modal').click();
            document.getElementById('schedule_form_btn').click();
        }
    },
    filters: {
        round_off(num) {
          return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
};
</script>
<style>
.ownership-info-modal-modal__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ownership-info-modal-modal__main table {
    width: 100%;
}
.ownership-info-modal-modal__main table tr td {
    padding: 10px;
}
.ownership-info-modal-modal__main table tr td:not(:first-child) {
    font-weight: bold;
}
.ownership-info-modal-modal__dialog .close {
    position: absolute;
    right: 15px;
    top: 15px;
}
.ownership-info-modal-modal__main img {
    width: 340px;
}
.ownership-info-modal-modal__main table tbody td {
    vertical-align: top;
}
.ownership-info-modal-modal__main .table-container {
    background: #edf3ff;
    border-radius: 30px 30px 0px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.ownership-info-modal-modal__main .table-container table:first-child{
    border-bottom: 1px dashed #0061DF;
}
@media (min-width: 576px) {
    .ownership-info-modal-modal__dialog {
        max-width: 553px !important;
    }
    .ownership-info-modal-modal {
        font-size: 15px;
    }
    .ownership-info-modal-modal td {
        white-space: nowrap;
    }
    .ownership-info-modal-modal__main > * {
        padding: 0 20px;
    }
}
</style>
