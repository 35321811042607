<template>
  <div class="container">
    <div class="col-md-12 my-4">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card shadow">
            <div class="card-body">
              <div class="form-group">
                <label for="page_name">Page Name</label>
                <input
                  type="text"
                  name="page_name"
                  id="page_name"
                  class="form-control"
                  placeholder="Enter page name"
                  v-model="page_name"
                />
              </div>
              <div class="form-group">
                <label for="page_link">Page Link</label>
                <input
                  type="text"
                  name="page_link"
                  id="page_link"
                  class="form-control"
                  placeholder="Enter page link"
                  v-model="page_link"
                />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <button class="btn btn-success w-100" @click.prevent="addSeoPage">Add Page</button>
                  </div>
                  <div class="col-md-6">
                    <a class="btn btn-danger w-100" href="/seo/seo-page-list">Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddSEO",
  data() {
    return {
      page_name: "",
      page_link: ""
    };
  },
  methods: {
    addSeoPage() {
      const formData = new FormData();

      formData.append("page_name", this.page_name);
      formData.append("page_link", this.page_link);

      axios
        .post("/seo/add-seo-page", formData)
        .then(response => {
          console.log(response?.data?.data);
          window.location.href = "/seo/seo-page-list";
        })
        .catch(error => {
          console.error(error?.response?.data);
        });
    }
  }
};
</script>
