<template>
    <div class="card mt-4 shadow rounded">
        <div class="card-header bg-dark">
            <h5 class="text-center mb-0 my-2">
                {{ !isEdit ? "Add" : "Edit" }} Exclusive Property - Step
                {{ currentStep }}
            </h5>
        </div>
        <div class="card-body">
            <!-- Share Left -->
            <div class="form-group">
                <label>Share Left</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="share_left_options"
                            id="share_left_1"
                            value="1"
                            v-model="share_left"
                        />
                        <label class="form-check-label" for="share_left_1"
                            >Yes</label
                        >
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="share_left_options"
                            id="share_left_2"
                            value="0"
                            v-model="share_left"
                        />
                        <label class="form-check-label" for="share_left_2"
                            >No</label
                        >
                    </div>
                </div>
            </div>

            <!-- How Many Share -->
            <div class="form-group">
                <label for="how_many_share">How Many Share</label>
                <input
                    type="number"
                    class="form-control builders-form__input"
                    id="how_many_share"
                    placeholder="Enter how many share"
                    v-model="how_many_share"
                />
            </div>

            <div class="row">
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-info w-100"
                        @click.prevent="$emit('back')"
                    >
                        Back
                    </button>
                </div>
                <div class="col-md-6 text-center my-2">
                    <button
                        class="btn btn-outline-primary w-100"
                        @click.prevent="next()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";
import { requiredValidationFields } from "../../../../utils";

const initFormFields = {
    share_left: "",
    how_many_share: "",
    amenities: "",
};
const requiredFields = {};
export default {
    name: "FormStepNine",
    props: {
        propertyDetails: {
            default: {},
        },
        currentStep: {},
        isEdit: {
            default: false,
        },
    },
    data() {
        return {
            cities: {},
            communities: {},
            defaultPropertyOption: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            ...initFormFields,
        };
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            if (this.propertyDetails) {
                const pickFormFields = pick(
                    this.propertyDetails,
                    Object.keys(initFormFields)
                );
                for (let key of Object.keys(pickFormFields)) {
                    this[key] = pickFormFields[key];
                }
            }
        },
        next() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit(
                    "persistAndNext",
                    this.currentStep + 1,
                    pick(this, Object.keys(initFormFields))
                );
            }
        },
    },
    validations: {
        // ...requiredValidationFields(requiredFields),
    },
};
</script>
