<template>
    <div class="new_into_offer">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="new_into_offer_title">
                        <h5>What Went Into Your Offer</h5>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="new_into_offer_box">
                        <div class="new_into_offer_box_button">
                            <ul
                                class="nav nav-pills"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li class="nav-item">
                                    <a
                                        class="nav-link active"
                                        id="pills-comparable_home_sales-tab"
                                        data-toggle="pill"
                                        href="#pills-comparable_home_sales"
                                        role="tab"
                                        aria-controls="pills-comparable_home_sales"
                                        aria-selected="true"
                                        >Comparable home sales</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        id="pills-currently_for_sale-tab"
                                        data-toggle="pill"
                                        href="#pills-currently_for_sale"
                                        role="tab"
                                        aria-controls="pills-currently_for_sale"
                                        aria-selected="false"
                                        >Currently For Sale</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        id="pills-market_trends-tab"
                                        data-toggle="pill"
                                        href="#pills-market_trends"
                                        role="tab"
                                        aria-controls="pills-market_trends"
                                        aria-selected="false"
                                        >Market Trends</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        id="pills-home_details-tab"
                                        data-toggle="pill"
                                        href="#pills-home_details"
                                        role="tab"
                                        aria-controls="pills-home_details"
                                        aria-selected="false"
                                        >Home Details</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="new_into_offer_box_content">
                            <div class="tab-content" id="pills-tabContent">
                                <div
                                    class="tab-pane fade show active"
                                    id="pills-comparable_home_sales"
                                    role="tabpanel"
                                    aria-labelledby="pills-comparable_home_sales-tab"
                                >
                                    <div
                                        class="new_into_offer_box_content_box"
                                        v-if="valuationPropertiesAvailable"
                                    >
                                        <div class="col-md-12">
                                            <div class="common-property-list-grid">
                                                <common-property-card
                                                    v-for="(
                                                        value, index
                                                    ) in valuation.properties"
                                                    :key="'vow-property-' + index"
                                                    :property_details="value"
                                                    :base_url="baseUrl"
                                                    type="COMPARABLE_HOME"
                                                    :userFavProperties="userFavSoldProperties"
                                                    @onFavoriteSuccess="handleSoldFavoriteSuccess"
                                                />
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-12">
                                            <div
                                                class="new_into_offer_box_see_more"
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    class="seemore"
                                                    style="
                                                        text-decoration: none;
                                                    "
                                                    @click="seeMore = !seeMore"
                                                    >{{
                                                        seeMore
                                                            ? "See Less"
                                                            : "See More"
                                                    }}</a
                                                >
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="pills-currently_for_sale"
                                    role="tabpanel"
                                    aria-labelledby="pills-currently_for_sale-tab"
                                >
                                    <div
                                        class="pills-currently_for_sale_row common-property-list-grid"
                                    >
                                        <common-property-card
                                            v-for="(
                                                value, index
                                            ) in valuation.idx_properties"
                                            :key="'idx-property-' + index"
                                            :property_details="value"
                                            :base_url="baseUrl"
                                            type="CURRENTLY_FOR_SALE"
                                            :user_id="user_id"
                                            :userFavProperties="userFavSaleProperties"
                                            @onFavoriteSuccess="fetchUserFavSaleProperties"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="pills-market_trends"
                                    role="tabpanel"
                                    aria-labelledby="pills-market_trends-tab"
                                >
                                    <h3>Trends in {{ selectedAddress }}</h3>
                                    <div class="chart-wrapper bg-light">
                                        <div class="row chart-info">
                                            <div class="col-md-6 col-lg-5">
                                                <div>
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <p
                                                            class="mr-2 font-weight-bold text-black-50"
                                                        >
                                                            Median sale price
                                                        </p>
                                                        &nbsp;&nbsp;
                                                        <p
                                                            class="text-primary font-weight-bold"
                                                        >
                                                            ${{
                                                                valuation.average
                                                                    | fixedPrice
                                                                    | numberWithCommas
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="col-md-6 col-lg-5"
                                            ></div>
                                        </div>
                                        <div class="chart-container">
                                            <canvas
                                                id="market_trend_chart"
                                            ></canvas>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="pills-home_details"
                                    role="tabpanel"
                                    aria-labelledby="pills-home_details-tab"
                                >
                                    <div
                                        class="home-details-tab__header border-bottom"
                                    >
                                        <h3 class="text-primary">
                                            Home details
                                        </h3>
                                    </div>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Bedrooms</th>
                                                <th>Bathrooms</th>
                                                <th>Square footage</th>
                                                <th>Style of home</th>
                                                <th>Garage spaces</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ bedrooms }}</td>
                                                <td>{{ bathrooms }}</td>
                                                <td>{{ square_foot }}</td>
                                                <td>{{ home_style }}</td>
                                                <td>{{ garage }}</td>
                                            </tr>
                                            <tr>
                                                <th>Extra details</th>
                                                <td colspan="4">
                                                    {{ extra_details }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonPropertyCard from "../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import { getUserFavPropertiesIds } from "../../utils/app";
import RelatedPropertyNewCard from "../IdxProperties/PropertyDetails/RelatedPropertyNewCard.vue";
export default {
    name: "NewOfferTabs",
    props: {
        selectedAddress: {
            default: "",
        },
        valuation: {
            default: {
                properties: [],
                average: "",
                idx_properties: [],
            },
        },
        bedrooms: "",
        bathrooms: "",
        square_foot: "",
        home_style: "",
        garage: "",
        extra_details: "",
        baseUrl: "",
        user_id:""
    },
    data() {
        return {
            seeMore: false,
            userFavSoldProperties: [],
            userFavSaleProperties: [],
        };
    },
    mounted() {
        this.fetchUserFavSoldProperties()
        this.fetchUserFavSaleProperties()
    },
    components: {
        "idx-property-card": RelatedPropertyNewCard,
        "common-property-card": CommonPropertyCard,
    },
    methods: {
        fetchUserFavSoldProperties(){
            getUserFavPropertiesIds(this.user_id, 'vow')
            .then(res=>{
                this.userFavSoldProperties = res;
            });
        },
        handleSoldFavoriteSuccess(res){
            if(this.userFavSoldProperties.includes(res.id)){
                this.userFavSoldProperties = this.userFavSoldProperties.filter(propertyId=> propertyId !== res.id)
            }
            else {
                this.userFavSoldProperties = [...this.userFavSoldProperties, res.id]
            }
        },
        fetchUserFavSaleProperties(){
            getUserFavPropertiesIds(this.user_id, 'idx')
            .then(res=>{
                this.userFavSaleProperties = res;
            });
        },
        handleSaleFavoriteSuccess(res){
            if(this.userFavSaleProperties.includes(res.id)){
                this.userFavSaleProperties = this.userFavSaleProperties.filter(propertyId=> propertyId !== res.id)
            }
            else {
                this.userFavSaleProperties = [...this.userFavSaleProperties, res.id]
            }
        },
    },
    filters: {
        numberWithCommas(x) {
            let val = x.toString().replace(".00", "");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        fixedPrice(val) {
            if (val) {
                let price = val.toFixed();
                if (price) {
                    return price;
                } else {
                    return val;
                }
            } else {
                return val;
            }
        },
        soldDateFilter(val) {
            if (val) {
                return moment(val).format("MMM Do YYYY");
            } else {
                return val;
            }
        },
    },
    computed: {
        valuationPropertiesAvailable() {
            if (
                this.valuation?.properties &&
                this.valuation?.properties.length == 0
            ) {
                return false;
            }
            return true;
        },
    },
};
</script>
<style scoped>
/***into****/
.new_into_offer_title h5 {
    color: #0061df;
    font-family: "helveticabold";
}

.new_into_offer_title {
    text-align: center;
    margin-bottom: 45px;
}

.new_into_offer {
    padding: 50px 0;
}
.new_into_offer_box_button {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
}

.new_into_offer_box_button ul {
    width: fit-content;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 6px;
}

.new_into_offer_box_button ul li a.active {
    background: #0061df !important;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 7px;
    font-family: "helveticabold";
}

.new_into_offer_box_button ul li a {
    color: #707070;
    padding: 0.5rem 1.8rem;
}
.new_property_card_image img {
    width: 100%;
}
.new_property_card_content {
    padding: 14px;
}

.new_property_card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    height: 100%;
}

.new_property_card_title span {
    display: block;
    color: #4b4b4b;
    font-size: 16px;
    border-bottom: 1px solid #70707085;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.new_property_card_ame ul li {
    color: #373738;
    font-size: 14px;
    width: 50%;
    display: flex;
    align-items: center;
}

.new_property_card_ame ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.new_property_card_ame ul li span {
    display: block;
    line-height: 0;
    margin-right: 2px;
}

.new_property_card_ame ul li span img {
    width: 26px;
}

.new_property_card_ame {
    border-bottom: 1px solid #70707085;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.new_property_card_p p {
    color: #373738;
    font-family: "helveticabold";
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 5px;
}

.new_property_card_p span {
    display: block;
    color: #373738;
    font-size: 16px;
}

.new_property_card_p span small {
    font-size: 16px;
    text-decoration: line-through;
}

.new_into_offer_box_see_more {
    text-align: center;
    margin-top: 50px;
}

.new_into_offer_box_see_more .seemore {
    color: #0061df;
    font-family: "helveticabold";
    font-size: 22px;
    text-decoration: underline;
    border: none;
    background: transparent;
}
#market_trend_chart {
    width: 1200px;
    height: 400px;
}
.new_property_card_image {
    height: 165px;
}
.property_feature_listings_box_item {
    overflow: hidden;
    border: 1px solid #dddddd;
    cursor: pointer;
}

.property_feature_listings_box_item span.type_point {
    font-size: 12px;
    background: #0061df;
    border-radius: 0;
    padding: 3px 14px;
    text-transform: uppercase;
}

.property_feature_listings_box_item span.sale {
    position: absolute;
    top: 13px;
    color: #fff;
    padding: 3px 10px;
    right: 12px;
    font-size: 12px;
    background: #414141;
    border-radius: 0;
}

.property_feature_listings_box_item span.image {
    position: absolute;
    left: 25px;
    bottom: 15px;
    display: flex;
    align-items: center;
    line-height: 1;
    color: #fff;
    font-weight: 500;
    visibility: hidden;
}

.property_feature_listings_box_item_image {
    position: relative;
}

.property_feature_listings_box_item span.video {
    position: absolute;
    bottom: 15px;
    left: 56px;
    display: flex;
    align-items: center;
    line-height: 1;
    color: #fff;
    font-weight: 500;
    margin-left: 19px;
    visibility: hidden;
}

.property_feature_listings_box_item span.video img {
    margin-right: 5px;
    margin-top: -2px;
}

.property_feature_listings_box_item span.image img {
    margin-right: 5px;
    margin-top: -2px;
}

.property_feature_listings_box_item_content {
    margin: 20px 12px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
}

.property_feature_listings_box_item_content span.location {
    letter-spacing: 1px;
    font-size: 15px;
}

.property_feature_listings_box_item_bottomcontent {
    display: flex;
    align-items: baseline;
    flex-flow: wrap;
    margin: 20px 12px 0;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 7px;
}

.property_feature_listings_box_item_bottomcontent span {
    width: 50%;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
}

.property_feature_listings_box_item_bottomcontent span img {
    width: 19px !important;
    margin-right: 7px;
}

.property_feature_listings_box_item_price {
    margin: 14px 12px;
}

.property_feature_listings_box_item_price span {
    font-weight: 600;
    color: #414141;
    letter-spacing: 1px;
    font-size: 19px;
}

.property_feature_listings_box_item:hover
    .property_feature_listings_box_item_image:before {
    content: "";
    background: #00000054;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.property_feature_listings_box_item:hover.property_feature_listings_box_item
    span.image {
    visibility: visible;
}

.property_feature_listings_box_item:hover.property_feature_listings_box_item
    span.video {
    visibility: visible;
}

.property_feature_listings_box_item:hover {
    box-shadow: 0 0 7px 3px #00000040;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.feature_residental_list .property_feature_listings_box_item {
    margin: 12px 10px;
}

.featured_listings.new_feature .featured_listings_title {
    text-align: center;
}

.featured_listings.new_feature .featured_listings_title:before {
    content: none;
}

.featured_listings.new_feature .featured_listings_title h4 {
    font-size: 32px;
}

.featured_listings.new_feature .featured_listings_title span {
    display: block;
    font-size: 15px;
    margin-top: 9px;
}

.new_feature_tab ul#pills-tab {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 29px;
    gap: 20px;
}

.new_feature_tab ul#pills-tab .nav-link {
    border: 1px solid #0261df;
    border-radius: 0;
    text-align: center;
    color: #0061df;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.new_feature_tab ul#pills-tab li:not(:last-child) {
    /* margin-right: 30px; */
}

.new_feature_tab ul#pills-tab .nav-link.active {
    color: #fff;
    background: #0061df;
}

.new_feature_tab {
    margin-top: 25px;
}

.new_feature_tab ul#pills-tab .nav-link:hover {
    background: #0061df;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
</style>
<style>
#pills-currently_for_sale .new_property_card_image img {
    width: 100%;
}
#pills-currently_for_sale .new_property_card_content {
    padding: 14px;
}

#pills-currently_for_sale .new_property_card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    height: 100%;
}

#pills-currently_for_sale .new_property_card_title span {
    display: block;
    color: #4B4B4B;
    font-size: 16px;
    border-bottom: 1px solid #70707085;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

#pills-currently_for_sale .new_property_card_ame ul li {
    color: #373738;
    font-size: 14px;
    width: 50%;
    display: flex;
    align-items: center;
}

#pills-currently_for_sale .new_property_card_ame ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

#pills-currently_for_sale .new_property_card_ame ul li span {
    display: block;
    line-height: 0;
    margin-right: 2px;
}

#pills-currently_for_sale .new_property_card_ame ul li span img {
    width: 26px;
}

#pills-currently_for_sale .new_property_card_ame {
    border-bottom: 1px solid #70707085;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

#pills-currently_for_sale .new_property_card_p p {
    color: #373738;
    font-family: 'helveticabold';
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 5px;
}

#pills-currently_for_sale .new_property_card_p span {
    display: block;
    color: #373738;
    font-size: 16px;
}

#pills-currently_for_sale .new_property_card_p span small {
    font-size: 16px;
    text-decoration: line-through;
}


#pills-currently_for_sale .new_property_card_image {
    position: relative;
}
#pills-currently_for_sale .new_property_card_image img{
    height: 170px;
}
#pills-currently_for_sale .new_property_card_image span.detatched_new {
    position: absolute;
    top: 10px;
    background: #ffffffd9;
    font-size: 13px;
    padding: 3px 9px;
    left: 14px;
    border-radius: 5px;
}
#pills-currently_for_sale .new_property_card_image span.sale_new {
    position: absolute;
    top: 10px;
    background: #ffffffd9;
    font-size: 13px;
    padding: 3px 9px;
    right: 14px;
    border-radius: 5px;
}
#pills-currently_for_sale .new_property_card_image span.hour_new {
    position: absolute;
    bottom: 10px;
    background: #ffffffd9;
    font-size: 13px;
    padding: 3px 9px;
    right: 14px;
    border-radius: 5px;
}
.pills-currently_for_sale_row{

    margin-bottom: 30px !important;
}
.common-property-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(280px,1fr));
    gap: 10px;
}
</style>
