<template>
  <div class="card mt-4 shadow rounded">
    <div class="card-title">
      <h6 class="text-center pt-4">Edit Property - Step 1</h6>
    </div>
    <div class="card-body">
      <!-- Building Status -->
      <div class="building-status-radio-group form-group">
          <div v-for="(item,index) in building_status_list" :key="index">
              <input
                  type="radio"
                  name="building_status"
                  v-model="building_status"
                  :value="item"
                  :id="item"
              /><label
                  :for="item"
                  class="btn btn-outline-primary w-100 mb-0"
                  >{{item}}</label
              >
          </div>
      </div>
      <div class="form-group">
        <label for="unique_code">Unique Code</label>
        <input
          type="text"
          :disabled="true"
          class="form-control builders-form__input"
          :value="property_unique_code"
          id="unique_code"
          placeholder="Enter property name"
        />
      </div>
      <div class="form-group">
        <label for="property_name">Property Name</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="property_name"
          placeholder="Enter property name"
          v-model="p_name"
        />
      </div>
      <div class="form-group">
        <label for="seller_type">Seller Type</label>
        <select class="form-control builders-form__input" id="seller_type" v-model="s_type">
          <option :value="''">Select Type</option>
          <option
            v-for="(item, index) in author_types"
            :value="item"
            :key="'type-'+index"
          >{{ item }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="seller_name">Seller Name</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="seller_name"
          placeholder="Enter seller name"
          v-model="s_name"
        />
      </div>
      <div class="form-group">
          <label for="seller_name">Address</label>
          <input type="hidden" v-model="street_address" />
          <vue-google-autocomplete
              id="map"
              ref="mapElement"
              class="form-control builders-form__input"
              placeholder="Address"
              :country="['ca']"
              @placechanged="getAddressData"
              :value="street_address"
          ></vue-google-autocomplete>
      </div>
      <div class="form-group">
        <label for="property_city">Selet City</label>
        <select class="form-control builders-form__input" id="property_city" v-model="p_city">
          <option :value="''">Select City</option>
          <option
            v-for="item in project_city_list"
            :value="item.id"
            :key="'type-'+item.id"
          >{{ item.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="property_state">State</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="property_state"
          placeholder="Enter state"
          v-model="p_state"
        />
      </div>
      <div class="form-group">
        <label for="community">Community</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="community"
          placeholder="Enter community"
          v-model="community"
        />
      </div>
      <div class="form-group">
        <label for="zip_code">Zip Code</label>
        <input
          type="text"
          class="form-control builders-form__input"
          id="zip_code"
          placeholder="Enter zip code"
          v-model="p_zipcode"
        />
      </div>
      <div class="row">
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-info w-100"
            :disabled="formSubmitting"
            @click.prevent="resetForm"
          >{{ formSubmitting ? "Processing..." : "Reset" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-success w-100"
            :disabled="formSubmitting"
            @click.prevent="updatePropertyDetails"
          >{{ formSubmitting ? "Processing..." : "Save" }}</button>
        </div>
        <div class="col-md-4 text-center my-2">
          <button
            class="btn btn-outline-primary w-100"
            :disabled="formSubmitting"
            @click.prevent="moveToFormTwo"
          >{{ formSubmitting ? "Processing..." : "Next" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormStepOne",
  props: {
    property_unique_code: {
      required: true,
      default: ""
    },
    project_city_list: {
      required: true,
      default: []
    },
    property_details: {
      required: true,
      default: {}
    }
  },
  data() {
    return {
      author_types: ["seller", "builder","admin"],
      project_city: {},
      p_name: "",
      s_type: "",
      s_name: "",
      street_address:"",
      p_city: "",
      p_state: "",
      community:"",
      p_zipcode: "",
      p_id: "",
      formSubmitting: false,
      building_status:"",
      building_status_list: [
        "Pre-construction",
        "Inventory",
        "Assignment sale"
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.resetForm();
    }, 1000);
  },
  methods: {
    resetForm() {
      // building status
      if (this.property_details?.building_status) {
        this.building_status = this.property_details.building_status;
      }
      // property name
      if (this.property_details?.property_name) {
        this.p_name = this.property_details.property_name;
      }
      // address
      if (this.property_details?.street_address) {
        this.street_address = this.property_details.street_address;
      }
      // type
      if (this.property_details?.type) {
        this.s_type = this.property_details.type;
      }
      // seller
      if (this.property_details?.seller) {
        this.s_name = this.property_details.seller;
      }
      // city
      if (this.property_details?.city) {
        this.p_city = this.property_details.city;
      }
      // state
      if (this.property_details?.state) {
        this.p_state = this.property_details.state;
      }
      // community
      if (this.property_details?.community) {
        this.community = this.property_details.community;
      }
      // zipcode
      if (this.property_details?.zipcode) {
        this.p_zipcode = this.property_details.zipcode;
      }
      // id
      if (this.property_details?.id) {
        this.p_id = this.property_details.id;
      }
    },
    updatePropertyDetails() {
      const formData = new FormData();

      let property_id = this.p_id;
      if (property_id) {
        const formData = new FormData();

        formData.append("building_status", this.building_status);
        formData.append("property_name", this.p_name);
        formData.append("type", this.s_type);
        formData.append("seller", this.s_name);
        formData.append("city", this.p_city);
        formData.append("state", this.p_state);
        formData.append("community", this.community);
        formData.append("zipcode", this.p_zipcode);
        formData.append("street_address", this.street_address);

        axios
          .post("/property-management/update-property-details/" + property_id, formData)
          .then(response => {
            this.formSubmitting = false;
            sessionStorage.setItem('formStep', 1);
          })
          .catch(error => {
            console.error(error?.response?.data);
          })
          .finally(res => {
            window.location.reload();
          });
      }
    },
    moveToFormTwo() {
      this.$emit("moveToFormTwo", {});
    },
    getAddressData(addressData, placeResultData, id) {
            if (placeResultData?.formatted_address) {
                this.street_address = placeResultData.formatted_address;
            }
        },
  }
};
</script>
