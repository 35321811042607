<template>
    <div class="col-xl-3 col-lg-4 col-md-6 mb-4 mb-xl-0 mb-lg-4 mb-md-4 mb-3">
        <div class="new_property_card" @click="goToPropertyDetails">
            <div class="new_property_card_image">
                <img :src="thumbnail" @error="handleImageError" class="img-fluid" alt="home" />
                <span class="detatched_new">{{ property_type }}</span>
                <span class="sale_new">{{ listing_purpose }}</span>
                <span class="hour_new" v-if="get_updated_date_or_days">{{ get_updated_date_or_days }}</span>
            </div>
            <div class="new_property_card_content">
                <div class="new_property_card_title">
                    <a :href="property_slug" style="text-decoration: none; color: #212529">
                        <span>{{ property_address }}</span>
                    </a>
                </div>
                <div class="new_property_card_ame">
                    <ul>
                        <li><span><img :src="base_url + '/property_detail_asset/image/bed_new_com.png'" class="img-fluid" alt="bed"></span> {{ bedrooms }} Beds</li>
                        <li><span><img :src="base_url + '/property_detail_asset/image/bath_new_com.png'" class="img-fluid" alt="bed"></span>{{ bathrooms }} Baths</li>
                        <li><span><img :src="base_url + '/property_detail_asset/image/garage_new_com.png'" class="img-fluid" alt="garage"></span>{{ garage }} Garage</li>
                        <li><span><img :src="base_url + '/property_detail_asset/image/sqft_new_com.png'" class="img-fluid" alt="bed"></span>{{ square_footage }} Sq Ft</li>
                    </ul>
                </div>
                <div class="new_property_card_p no_onli">
                    <span>List price: <small class="text-decoration-none">$ {{ list_price | round_off | numberWithCommas }}</small></span>
                    <!-- <span v-if="days_ago">{{  days_ago }}</span> -->
                    <!-- <span>{{  get_updated_date_or_days }}</span> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "RelatedPropertyNewCard",
    props: {
        property_details: {
            required: true,
        },
        base_url:{
            default:'https://redbuyers.com/'
        }
    },
    data() {
         return {
            hours_ago: '',
            days_ago: '',
         };
    },
    computed: {
        property_slug() {
            // console.log(this.property_details);
            return (
                "/sellers/property-details/" +
                this.convertToSlug(
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                ) +
                "/" +
                this.property_details["unique_id"]
            );
        },
        property_address() {
            return (
                this.property_details["address"] +
                ", " +
                this.property_details["city"] +
                ", " +
                this.property_details["area"] +
                ", " +
                this.property_details["zip_code"] +
                ", " +
                this.property_details["county"]
            );
        },
        property_address_community() {
            return (
                this.property_details["address"] +
                ", " +
                this.property_details["city"] +
                ", " +
                this.property_details["area"] +
                ", " +
                this.property_details["community"]
            );
        },
        bedrooms() {
            if(this.property_details?.['property_details']?.['Br']) {
                let bed = this.property_details['property_details']['Br'];
                if(this.property_details?.['property_details']?.['Br_plus']) {
                    bed = bed + " + " + this.property_details['property_details']['Br_plus']
                }
                return bed;
            } else {
                return 0;
            }
        },
        bathrooms() {
            return this.property_details["bathrooms"]
                ? this.property_details["bathrooms"]
                : 0;
        },
        garage() {
            return this.property_details["garage_spaces"]
                ? this.property_details["garage_spaces"]
                : 0;
        },
        square_footage() {
            return this.property_details["square_footage"]
                ? this.property_details["square_footage"]
                : 0;
        },
        list_price() {
            return this.property_details["list_price"];
        },
        virtual_tour_url() {
            return this.property_details["virtual_tour_url"];
        },
        total_photos() {
            return this.property_details["photos"].length;
        },
        property_type() {
            return this.property_details["property_type"];
        },
        listing_purpose() {
            return this.property_details["sale_or_lease"];
        },
        u_id() {
            return this.property_details["unique_id"];
        },
        thumbnail() {
            return this.property_details["photos"][0]
                ? this.property_details["photos"][0]
                : "/assets/images/no-image-available.png";
        },
        thumbnail_1() {
            return this.property_details["photos"][1]
                ? this.property_details["photos"][1]
                : "/assets/images/no-image-available.png";
        },
        thumbnail_2() {
            return this.property_details["photos"][2]
                ? this.property_details["photos"][2]
                : "/assets/images/no-image-available.png";
        },
        get_updated_date_or_days() {
            let todays_day = new Date();
            let last_updated_date = new Date(this.property_details.updated_at);
            if(this.getDifferenceInDays(last_updated_date, todays_day) == 1 || this.getDifferenceInDays(last_updated_date, todays_day) == 0) {
                return this.getDifferenceInHours(last_updated_date, todays_day) + ' Hours';
            }
            else {
                return this.getDifferenceInDays(last_updated_date, todays_day) +' Days';
            }
        }                                  
    },
    methods: {
        getDifferenceInDays(date1, date2){
            const diffInMs = Math.abs(date2 - date1);
            return diffInMs / (1000 * 60 * 60 * 24) | this.round_off;
        },
        getDifferenceInHours(date1, date2){
            const diffInMs = Math.abs(date2 - date1);
            return diffInMs / (1000 * 60 * 60) | this.round_off;
        },
        goToPropertyDetails(value) {
            window.location.href =
                "/sellers/property-details/" +
                this.convertToSlug(
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                ) +
                "/" +
                this.property_details["unique_id"]
        },
        convertToSlug(val) {
            return val
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        handleImageError(event) {
            event.preventDefault();
            event.target.src = "/assets/images/no-image-available.png";
        },
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>

<style scoped>
.new_property_card {
    height: 100%;
}
</style>